export enum SharedActionTypes {
  GET_USER = "@@SHARED/GET_USER",
  GET_USER_SUCCESS = "@@SHARED/GET_USER_SUCCESS",
  GET_USER_FAILURE = "@@SHARED/GET_USER_FAILURE",

  SHOW_NOTIFICATION = "@@SHARED/SHOW_NOTIFICATION",
  CLEAR_NOTIFICATION = "@@SHARED/CLEAR_NOTIFICATION",

  ADD_LOADING_TYPE = "@@APP/ADD_LOADING_TYPE",
  REMOVE_LOADING_TYPE = "@@APP/REMOVE_LOADING_TYPE",

  ADD_LOADING_SECTION = "@@APP/ADD_LOADING_SECTION",
  REMOVE_LOADING_SECTION = "@@APP/REMOVE_LOADING_SECTION",
  ADD_NEW_NOTIFICATION_HISTORY = "@@APP/ADD_NEW_NOTIFICATION_HISTORY",

  GET_FILE = "@@SHARED/GET_FILE",
  GET_FILE_SUCCESS = "@@SHARED/GET_FILE_SUCCESS",
  GET_FILE_FAILURE = "@@SHARED/GET_FILE_FAILURE",

  GET_FIELD_GUIDES = "@@SHARED/GET_FIELD_GUIDES",
  GET_FIELD_GUIDES_SUCCESS = "@@SHARED/GET_FIELD_GUIDES_SUCCESS",
  GET_FIELD_GUIDES_FAILURE = "@@SHARED/GET_FIELD_GUIDES_FAILURE",

  GET_PROFILE_FIELD_GUIDES = "@@SHARED/GET_PROFILE_FIELD_GUIDES",
  GET_PROFILE_FIELD_GUIDES_SUCCESS = "@@SHARED/GET_PROFILE_FIELD_GUIDES_SUCCESS",
  GET_PROFILE_FIELD_GUIDES_FAILURE = "@@SHARED/GET_PROFILE_FIELD_GUIDES_FAILURE",

  SET_FIELD_GUIDE = "@@DASHBOARD/SET_FIELD_GUIDE",

  SET_FAVORITE_FIELD_GUIDE = "@@SHARED/SET_FAVORITE_FIELD_GUIDE",
  SET_FAVORITE_FIELD_GUIDE_SUCCESS = "@@SHARED/SET_FAVORITE_FIELD_GUIDE_SUCCESS",
  SET_FAVORITE_FIELD_GUIDE_FAILURE = "@@SHARED/SET_FAVORITE_FIELD_GUIDE_FAILURE",

  GET_IMAGE_TYPES = "@@SHARED/GET_IMAGE_TYPES",
  GET_IMAGE_TYPES_SUCCESS = "@@SHARED/GET_IMAGE_TYPES_SUCCESS",
  GET_IMAGE_TYPES_FAILURE = "@@SHARED/GET_IMAGE_TYPES_FAILURE",

  GET_REVIEW_STATUSES = "@@SHARED/GET_REVIEW_STATUSES",
  GET_REVIEW_STATUSES_SUCCESS = "@@SHARED/GET_REVIEW_STATUSES_SUCCESS",
  GET_REVIEW_STATUSES_FAILURE = "@@SHARED/GET_REVIEW_STATUSES_FAILURE",

  GET_NOTIFICATION_HISTORY_LIST = "@@SHARED/GET_NOTIFICATION_HISTORY_LIST",
  GET_NOTIFICATION_HISTORY_LIST_SUCCESS = "@@SHARED/GET_NOTIFICATION_HISTORY_LIST_SUCCESS",
  GET_NOTIFICATION_HISTORY_LIST_FAILURE = "@@SHARED/GET_NOTIFICATION_HISTORY_LIST_FAILURE",

  MARK_ALL_NOTIFICATIONS_HISTORY_AS_READ = "@@SHARED/MARK_ALL_NOTIFICATION_HISTORY_AS_READ",
  MARK_ALL_NOTIFICATIONS_HISTORY_AS_READ_SUCCESS = "@@SHARED/GET_NOTIFICATION_HISTORY_LIST_SUCCESS",
  MARK_ALL_NOTIFICATIONS_HISTORY_AS_READ_FAILURE = "@@SHARED/GET_NOTIFICATION_HISTORY_LIST_FAILURE",

  MARK_ONE_NOTIFICATION_HISTORY_AS_READ = "@@SHARED/MARK_ONE_NOTIFICATION_HISTORY_AS_READ",
  MARK_ONE_NOTIFICATION_HISTORY_AS_READ_SUCCESS = "@@SHARED/MARK_ONE_NOTIFICATION_HISTORY_AS_READ_SUCCESS",
  MARK_ONE_NOTIFICATION_HISTORY_AS_READ_FAILURE = "@@SHARED/MARK_ONE_NOTIFICATION_HISTORY_AS_READ_FAILURE",

  GET_USER_GUIDE = "@@SHARED/GET_USER_GUIDE",
  GET_USER_GUIDE_SUCCESS = "@@SHARED/GET_USER_GUIDE_SUCCESS",
  GET_USER_GUIDE_FAILURE = "@@SHARED/GET_USER_GUIDE_FAILURE",

  GET_ROLES = "@@SHARED/GET_ROLES",
  GET_ROLES_SUCCESS = "@@SHARED/GET_ROLES_SUCCESS",
  GET_ROLES_FAILURE = "@@SHARED/GET_ROLES_FAILURE",

  CLEAN_FILE = "@@SHARED/CLEAN_FILE",

  SET_UPDATE_LAYOUT = "@@SHARED/SET_UPDATE_LAYOUT",
}
