import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AccessControl } from "../../../../../../shared/components";
import { PERMISSION } from "../../../../../../shared/constants";
import { Plant } from "../../../../../../shared/models";
import { FieldGuideReference } from "../../../../../../shared/models/FieldGuideReference";
import { getProfileFieldGuides, setFieldGuide } from "../../../../../../shared/store/actions";
import { getFieldGuides } from "../../../../../../shared/store/selectors";

import "./instanceReferences.scss";

interface InstanceReferencesProps {
  plant: Plant | null;
  fieldGuideReferences: FieldGuideReference[];
  openReference: Function;
  deleteReference: Function;
  showIcon?: boolean;
  degradationId?: number | null;
}

export default function InstanceReferences(props: InstanceReferencesProps) {
  const { fieldGuideReferences, openReference, deleteReference, plant, showIcon = true, degradationId } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfileFieldGuides.request({}));
  }, [dispatch]);

  const fieldGuides = useSelector(getFieldGuides());

  useEffect(() => {
    dispatch(setFieldGuide(undefined));
  }, [dispatch]);

  const referenceClickHandler = useCallback(
    (reference) => {
      if (fieldGuides?.length) {
        dispatch(
          setFieldGuide(
            reference.fieldGuideDocumentId
              ? fieldGuides.find((fg) => fg.id === reference.fieldGuideDocumentId)
              : fieldGuides[0],
          ),
        );
      }
      openReference(reference, degradationId);
    },
    [fieldGuides, openReference, degradationId, dispatch],
  );

  const references = useMemo(
    () =>
      fieldGuideReferences
        ?.filter((reference) => !degradationId || reference.degradationId === degradationId)
        .sort((r1, r2) => r1.page - r2.page),
    [fieldGuideReferences, degradationId],
  );

  const renderReferences = () =>
    references.map((reference) => (
      <div className="instance_reference" key={reference.id}>
        <div
          className="instance_reference_page"
          onClick={(e) => {
            e.stopPropagation();
            referenceClickHandler(reference);
          }}
        >
          Page {reference.page + 1}
        </div>
        <AccessControl permissions={[PERMISSION.INSTANCE_EDIT]} plant={plant} fieldGuideReference={reference}>
          <div
            className="delete-button"
            onClick={(e) => {
              e.stopPropagation();
              deleteReference(reference.id);
            }}
          >
            <img className="delete_icon" src="/icons/general/cancel-grey.svg" alt="delete" />
          </div>
        </AccessControl>
      </div>
    ));

  return (
    <div className="leftPanel_section_item">
      {showIcon && <img className="iconCentering" src="/icons/general/book.svg" alt="location" />}
      <div className="instance_references">{renderReferences()}</div>
    </div>
  );
}
