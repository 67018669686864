import { API, METHODS } from "../../../shared/constants";
import { request } from "../../../shared/utils";
import { Plant } from "../../../shared/models";
import { SortFilterOptions } from "../../../shared/interfaces";

const fieldNotesApi = {
  getFieldNotesList: (payload: SortFilterOptions) =>
    request(
      METHODS.GET,
      API.FIELD_NOTE.GET_FIELD_NOTES_LIST,
    )({
      params: {
        ...payload,
      },
    }),
  getFieldNote: (id: number) => request(METHODS.GET, API.FIELD_NOTE.GET_FIELD_NOTE(id))(),
  getFieldNotePDF: (id: number) => request(METHODS.GET, API.FIELD_NOTE.GET_FIELD_NOTE_PDF(id))(),
  updateFieldNote: (payload: Partial<Plant>) =>
    request(
      METHODS.PATCH,
      API.FIELD_NOTE.UPDATE_FIELD_NOTE((payload.id && payload.id.toString()) || ""),
    )({ displayName: payload.displayName, plantId: payload.plantId }),
  createFieldNote: (payload: Partial<Plant>) => request(METHODS.POST, API.FIELD_NOTE.CREATE_FIELD_NOTE)(payload),
  deleteFieldNote: (id: number) => request(METHODS.DELETE, API.FIELD_NOTE.DELETE_FIELD_NOTE(id))(),
};

export default {
  ...fieldNotesApi,
};
