import React, { useMemo, useState } from "react";
import classnames from "classnames";

import "./booleanAnswers.scss";

interface BooleanAnswersProps {
  text: string;
  initialValue?: boolean;
  questionNumber?: number;
  handleChangeValue?: (value: boolean) => void;
}

const BooleanAnswers: React.FunctionComponent<BooleanAnswersProps> = (props) => {
  const { text, initialValue, handleChangeValue, questionNumber } = props;

  const [value, setValue] = useState<boolean | undefined>(initialValue);

  const handleChange = (value: boolean) => {
    if (handleChangeValue) {
      setValue(value);
      handleChangeValue(value);
    }
  };

  const isNoSelected = useMemo(() => value !== undefined && !value, [value]);
  const isYesSelected = useMemo(() => value !== undefined && value, [value]);
  const questionText = useMemo(() => (questionNumber ? `${questionNumber}. ${text}` : text), [questionNumber, text]);

  return (
    <div className="answers">
      <div className="answers_text">{questionText}</div>
      <div className="margin-wrapper">
        <div
          className={classnames("answers-reject-button", { selected: isNoSelected })}
          onClick={() => handleChange(false)}
        >
          <img
            src={isNoSelected ? "/icons/general/red_circle_cross_checked.svg" : "/icons/general/red_circle_cross.svg"}
            alt="question answer no"
          />
          NO
        </div>
        <div
          className={classnames("answers-accept-button", { selected: isYesSelected })}
          onClick={() => handleChange(true)}
        >
          <img
            src={
              isYesSelected ? "/icons/general/white-circle-cross_checked.svg" : "/icons/general/white-circle-cross.svg"
            }
            alt="question answer yes"
          />
          YES
        </div>
      </div>
    </div>
  );
};

export default BooleanAnswers;
