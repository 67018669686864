import React from "react";
import { Profile } from "../../../../../../shared/models";
import { CollaboratorCard } from "../";
import "./index.scss";
import { AppLoader } from "../../../../../../shared/components";

interface CollaboratorCardListProps {
  collaborators: Profile[];
  search: string;
  isLoading: boolean;
}

const CollaboratorCardList: React.FunctionComponent<CollaboratorCardListProps> = (props) => {
  const { collaborators, search, isLoading } = props;

  return (
    <>
      {isLoading ? (
        <AppLoader />
      ) : (
        <>
          {collaborators.map((collaburator: Profile) => (
            <CollaboratorCard key={collaburator.id} collaborator={collaburator} />
          ))}
          {collaborators.length === 0 &&
            (!search.length ? <div className="collaborator-list-empty" /> : <div className="no-matches-found" />)}
        </>
      )}
    </>
  );
};

export default CollaboratorCardList;
