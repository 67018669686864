import { useState, useEffect } from "react";

interface WindowHookState {
  width?: number;
  height?: number;
}

const useElementSize = (element: HTMLDivElement | null, debounceDelay: number = 0) => {
  const [elementSize, setElementSize] = useState<WindowHookState>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let observer: any;
    let timer: number;
    const updateSize =
      // @ts-ignore
      (entries?: ResizeObserverEntry[]) => {
        const container = (entries ? entries[0]?.target : element) as HTMLDivElement;
        if (!!container) {
          timer = setTimeout(() => {
            setElementSize({
              width: container.offsetWidth,
              height: container.offsetHeight,
            });
          }, debounceDelay);
        }
      };
    if (element) {
      // @ts-ignore
      if (!!ResizeObserver) {
        // @ts-ignore
        observer = new ResizeObserver(updateSize);
        observer.observe(element);
      }
      updateSize();
    }
    return () => {
      if (element) {
        clearTimeout(timer);
        observer?.unobserve(element);
      }
    };
  }, [element, debounceDelay]);
  return elementSize;
};

export default useElementSize;
