import { createSelector } from "reselect";
import { RootReducer } from "../interfaces";

const selectShared = (state: RootReducer) => state.shared;
const selectRoute = (state: RootReducer) => state.router;

export const getRouterState = () => createSelector(selectRoute, (state) => state.location.state);

export const getNotification = () => createSelector(selectShared, (state) => state.notification);

export const getUser = () => createSelector(selectShared, (state) => state.user);

export const getFile = (key?: string | undefined) =>
  createSelector(selectShared, (state) => (key ? state.files[key] : undefined));

export const getImageTypes = () => createSelector(selectShared, (state) => state.imageTypes);

export const getReviewStatuses = () => createSelector(selectShared, (state) => state.reviewStatuses);

export const getFieldGuide = () => createSelector(selectShared, (state) => state.fieldGuide);

export const getFieldGuides = () => createSelector(selectShared, (state) => state.fieldGuides);

export const getUserGuideDoc = () => createSelector(selectShared, (state) => state.userGuide);

export const getNotificationHistoryList = () => createSelector(selectShared, (state) => state.notificationHistory);

export const getRoles = () => createSelector(selectShared, (state) => state.roles);

export const getLoadingSections = (sectionName: string) =>
  createSelector(selectShared, (state) => state.loadingSections.find((section) => sectionName === section));

export const selectRouter = () =>
  createSelector(
    (state: RootReducer) => state,
    (state) => state,
  );

export const getUpdateLayoutStatus = () => createSelector(selectShared, (state) => state.shouldUpdateLayout);
