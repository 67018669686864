import React, { useCallback, useEffect, useRef, useState } from "react";
import { useOutsideClick } from "../../../../hooks";
import { IDateRange } from "../../../../interfaces";
import { getUSFormattedDate } from "../../../../utils";
import { DateInput } from "./DateInput";
import "./index.scss";

interface DateRangeInputProps {
  startDateValue?: Date | null;
  endDateValue?: Date | null;
  placeholder?: string;
  onChange: (dateRange: IDateRange) => void;
}

const getTodayRange = (): IDateRange => {
  const date = new Date();
  return { start_date: date, end_date: date };
};

const getYesterdayRange = (): IDateRange => {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return { start_date: date, end_date: date };
};

const getThisWeekRange = (): IDateRange => {
  const date = new Date();
  const firstDateWeek = new Date(date.setDate(date.getDate() - date.getDay()));
  const lastDateWeek = new Date(date.setDate(date.getDate() - date.getDay() + 6));
  return { start_date: firstDateWeek, end_date: lastDateWeek };
};

const getThisMonthRange = (): IDateRange => {
  const date = new Date();
  const firstDateMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDateMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return { start_date: firstDateMonth, end_date: lastDateMonth };
};

const getThisYearRange = (): IDateRange => {
  const date = new Date();
  const firstDateYear = new Date(date.getFullYear(), 0);
  const lastDateYear = new Date(date.getFullYear(), 12, 0);
  return { start_date: firstDateYear, end_date: lastDateYear };
};

const getStartDayDate = (date: Date | null) => {
  if (!date) return null;
  const startDayDate = new Date(date);
  startDayDate.setHours(0, 0, 0, 0);
  return startDayDate;
};

const getEndDayDate = (date: Date | null) => {
  if (!date) return null;
  const endDayDate = new Date(date);
  endDayDate.setHours(23, 59, 59, 999);
  return endDayDate;
};

const getPreviousMonth = () => {
  const today = new Date();
  today.setMonth(today.getMonth() - 1);
  return today;
};

const DateRangeInput = (props: DateRangeInputProps) => {
  const { startDateValue, endDateValue, placeholder, onChange } = props;
  const [start_date, setStartDate] = useState(startDateValue || null);
  const [end_date, setEndDate] = useState(endDateValue || null);
  const [isOpen, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  const { isOutside } = useOutsideClick(wrapperRef);

  const changeRange = useCallback((dateRange) => {
    setEndDate(getEndDayDate(dateRange.end_date));
    setStartDate(getStartDayDate(dateRange.start_date));
  }, []);

  const onApply = useCallback(() => {
    if (start_date || end_date) {
      const start = start_date ? start_date : getStartDayDate(end_date);
      const end = end_date ? end_date : getEndDayDate(start_date);
      changeRange({ start_date: start, end_date: end });
      onChange({ start_date: start, end_date: end });
    } else {
      onChange({ start_date, end_date });
    }

    setOpen(false);
  }, [onChange, changeRange, start_date, end_date]);

  const onCancel = useCallback(() => {
    changeRange({ start_date: startDateValue || null, end_date: endDateValue || null });
    setOpen(false);
  }, [changeRange, startDateValue, endDateValue]);

  const onReset = useCallback(() => {
    changeRange({ start_date: null, end_date: null });
    onChange({ start_date: null, end_date: null });
    setOpen(false);
  }, [changeRange, onChange]);

  useEffect(() => {
    setOpen(false);
  }, [isOutside]);

  return (
    <div className="date-range-input-wrapper">
      <div className="date-range-value-block" onClick={() => setOpen(!isOpen)}>
        {start_date || end_date ? (
          <span>{`${start_date ? getUSFormattedDate(start_date) : ""} ${start_date && end_date ? "-" : ""} ${
            end_date ? getUSFormattedDate(end_date) : ""
          }`}</span>
        ) : (
          <span className="placeholder">{placeholder || "Select Date Range"}</span>
        )}
        <div className="icon calendar" />
      </div>
      {isOpen && (
        <div className="box date-range-wrapper" ref={wrapperRef}>
          <div className="date-range-content">
            <div className="date-ranges">
              <div className="date-range-item" onClick={() => changeRange(getTodayRange())}>
                Today
              </div>
              <div className="date-range-item" onClick={() => changeRange(getYesterdayRange())}>
                Yesterday
              </div>
              <div className="date-range-item" onClick={() => changeRange(getThisWeekRange())}>
                This Week
              </div>
              <div className="date-range-item" onClick={() => changeRange(getThisMonthRange())}>
                This Month
              </div>
              <div className="date-range-item" onClick={() => changeRange(getThisYearRange())}>
                This Year
              </div>
              <div className="date-range-item reset" onClick={() => onReset()}>
                Reset
              </div>
            </div>
            <div className="start-date-wrapper">
              <DateInput
                label={"From Date"}
                onChange={(date: Date) => setStartDate(getStartDayDate(date))}
                selected={start_date}
                selectsStart={true}
                disabledKeyboardNavigation={true}
                startDate={start_date}
                endDate={end_date}
                inline={true}
                openToDate={start_date || getPreviousMonth()}
              />
            </div>
            <div className="end-date-wrapper">
              <DateInput
                label={"To Date"}
                onChange={(date: Date) => setEndDate(getEndDayDate(date))}
                selected={end_date}
                selectsEnd={true}
                disabledKeyboardNavigation={true}
                startDate={start_date}
                endDate={end_date}
                minDate={start_date}
                inline={true}
                openToDate={end_date || new Date()}
              />
            </div>
          </div>
          <div className="date-range-footer">
            <button className="btn peach-text" onClick={onCancel}>
              CANCEL
            </button>
            <button className="btn blue-text remove-padding" onClick={onApply}>
              APPLY
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangeInput;
