import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { Image } from "../../../shared/models";
import { actions } from "./";
import api from "./api";

function* getImages({ payload }: AnyAction) {
  try {
    const data: { images: Image[]; count: number } = yield call(api.getImages, payload);
    yield put(actions.getImages.success(data));
  } catch (error) {
    yield put(actions.getImages.failure(error));
  }
}

function* reviewImageSaga({ payload }: AnyAction) {
  try {
    const { cb, ...rest } = payload;
    const { images, rejected }: { images: Image[]; rejected: { count: number; reason: string } } = yield call(
      api.reviewImage,
      rest,
    );
    const messageStatus = payload.reviewStatusId === 2 ? "approved" : "rejected";
    const message = images.length === 1 ? "The image has been successfully" : "The images have been successfully";

    if (images.length) {
      yield put(
        actions.reviewImage.success({
          images,
          message: `${message} ${messageStatus}`,
        }),
      );
    }

    if (rejected.count) {
      yield put(actions.reviewImage.failure({ message: rejected.reason } as Error));
    }
    cb?.();
  } catch (error) {
    yield put(actions.reviewImage.failure(error));
  }
}

function* validateImagePositionSaga({ payload }: AnyAction) {
  try {
    const { cb, ...rest } = payload;
    const { isExistReplacedImage }: any = yield call(api.validateImagePositionIndex, rest);
    cb(!isExistReplacedImage);
  } catch (error) {
    yield put(actions.validateImagePosition.failure(error));
  }
}

function* imagesSaga() {
  yield takeLatest(actions.getImages.request, getImages);
  yield takeLatest(actions.reviewImage.request, reviewImageSaga);
  yield takeLatest(actions.validateImagePosition.request, validateImagePositionSaga);
}

export default imagesSaga;
