import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PdfReader } from "../../../../shared/components";
import { setFieldGuide } from "../../../../shared/store/actions";
import { getFieldGuide } from "../../../../shared/store/selectors";
import { setSelectedPdfAreas } from "../../store/actions";
import { CompanyFieldGuides } from "./components/CompanyFieldGuides";

import "./index.scss";

export default function FieldGuideSelectContainer() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFieldGuide(undefined));
    dispatch(setSelectedPdfAreas(null));
  }, [dispatch]);

  const fieldGuide = useSelector(getFieldGuide());

  return (
    <div className="field-guide-select-container">
      {fieldGuide ? (
        <>
          <div className="field-guide-select-back" onClick={() => dispatch(setFieldGuide(undefined))}>
            <div className="field-guide-select-back-icon" />
            <div className="field-guide-select-back-label">Back</div>
          </div>
          <PdfReader />
        </>
      ) : (
        <CompanyFieldGuides />
      )}
    </div>
  );
}
