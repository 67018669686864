import React, { useMemo } from "react";
import { useHistory, generatePath } from "react-router";
import { useDispatch } from "react-redux";

import { Instance, FieldNote } from "../../../../../../shared/models";
import { formatDate } from "../../../../../../shared/utils";
import { ROUTE_PATHS } from "../../../../../../shared/routes";
import { getImageTypeById } from "../../../../../../shared/constants";
import { BinaryImage } from "../../../../../../shared/components/BinaryImage";
import { setUpdateLayout } from "../../../../../../shared/store/actions";

import "./instance.scss";

interface InstanceContainerProps {
  instance: Instance;
  fieldNote: FieldNote | null;
  filteredItemsNames: string;
}

const InstanceContainer: React.FunctionComponent<InstanceContainerProps> = (props) => {
  const { instance, fieldNote, filteredItemsNames } = props;

  const history = useHistory();

  const dispatch = useDispatch();

  const lastUpdateDate = useMemo(() => formatDate(new Date(instance.updatedAt ?? instance.createdAt)), [instance]);

  const thumbnailText = useMemo(() => {
    const attachmentsCount = instance?.attachments?.length;

    return attachmentsCount ? `${attachmentsCount} attachment${attachmentsCount === 1 ? "" : "s"}` : "No attachments";
  }, [instance]);

  const previewKey = useMemo(() => {
    const imageWithBaseType = instance?.images?.find((image) => !!getImageTypeById(image.imageTypeId));
    const imageKey = imageWithBaseType?.key;
    return imageKey;
  }, [instance]);

  const redirectToInstanceView = () => {
    if (fieldNote) {
      history.push(
        generatePath(ROUTE_PATHS.INSTANCE_VIEW, {
          id: instance.id,
          fieldNoteId: String(fieldNote.id),
          plantId: String(fieldNote.plantId),
        }),
      );
    }
  };

  return (
    <div className="instance_container" onClick={redirectToInstanceView}>
      <div className="instance_logo">
        <BinaryImage
          uid={previewKey}
          alt="instance"
          placeholderImg="/images/dashboard/empty_photos.svg"
          onLoad={() => dispatch(setUpdateLayout(true))}
        />
        <div className="instance_thumbnail">
          <span>{thumbnailText}</span>
        </div>
      </div>
      <div className="instance_details">
        <div className="instance_details_name truncate">{instance.displayName}</div>
        <div className="instance_details_items">
          <img src="/icons/general/items.svg" alt="collaborators count" />
          <div className="truncate">{filteredItemsNames}</div>
        </div>
        <div className="instance_details_footer">
          <div className="instance_details_items">
            <img src="/icons/general/tagged-members.svg" alt="collaborators count" />
            {`${instance.instancesProfilesCount} collaborators`}
          </div>
          <div className="instance_details_date">{lastUpdateDate}</div>
        </div>
      </div>
    </div>
  );
};

export default InstanceContainer;
