import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "../../store";
import classnames from "classnames";
import { formatTimeToMinutesSeconds } from "../../utils";

import "./BinaryAudio.scss";

// doc here: https://www.w3schools.com/tags/ref_av_dom.asp

interface AudioProps {
  uid?: string;
}

const BinaryAudio: React.FunctionComponent<AudioProps> = (props) => {
  const { uid } = props;

  const binaryAudio = useSelector(selectors.getFile(uid));

  const dispatch = useDispatch();

  useEffect(() => {
    if (uid) {
      dispatch(actions.getFile.request(uid));
    }
  }, [dispatch, uid]);

  const fileUrl = useMemo(() => {
    if (binaryAudio) {
      return URL.createObjectURL(new Blob([binaryAudio], { type: "audio/mpeg" }));
    }
    return undefined;
  }, [binaryAudio]);

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const [isPaused, setIsPaused] = useState(true);
  const [audioDuration, setAudioDuration] = useState("");
  const [currentTime, setCurrentTime] = useState("00:00");

  const togglePlay = () => {
    if (audioRef?.current) {
      const { paused } = audioRef.current;
      if (paused) {
        audioRef.current?.play();
      } else {
        audioRef.current?.pause();
      }
      setIsPaused(!paused);
    }
  };

  const loaded = () => {
    if (audioRef?.current) {
      setAudioDuration(formatTimeToMinutesSeconds(audioRef?.current.duration));
    }
    if (uid) {
      dispatch(actions.cleanFile(uid));
    }
  };

  const currentTimeUpdated = () => {
    if (audioRef?.current) {
      setCurrentTime(formatTimeToMinutesSeconds(audioRef?.current.currentTime));
    }
  };

  return (
    <div className="binary-audio">
      <div className={classnames("audio-btn", { playing: !isPaused })} onClick={togglePlay} />
      <audio
        ref={audioRef}
        controls
        src={fileUrl}
        controlsList="nodownload noremoteplayback"
        onLoadedData={loaded}
        onEnded={(e) => setIsPaused(true)}
        onTimeUpdate={currentTimeUpdated}
      />
      <div className="binary-audio-duration">{audioDuration ? `${currentTime} / ${audioDuration}` : "Loading..."}</div>
    </div>
  );
};

export default BinaryAudio;
