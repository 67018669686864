import { AnyAction } from "redux";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import api from "./api";
import { tokenHandler } from "../utils";
import * as actions from "./actions";
import { logout } from "../../containers/Auth/store/actions";
import { SharedActionTypes } from "./constants";
import { FieldGuideSortFilterOptions, SharedStateType } from "../interfaces";
import { ReviewStatus } from "../models/ReviewStatus";
import { ImageType } from "../models/ImageTypes";
import { FieldGuideDocument } from "../models/FieldGuideDocument";
import { UserGuideDocument } from "../models";
import history from "../utils/history";
import { ROUTE_PATHS } from "../routes";

function* getUserDataSaga({ payload }: AnyAction) {
  try {
    const profileData = yield call(api.getUserDetails, payload);
    yield tokenHandler.setUser(profileData);
    yield put(actions.getUserData.success(profileData));
  } catch (error) {
    yield put(actions.getUserData.failure(error));
    yield put(logout.request());
  }
}

function* getFieldGuidesSaga() {
  try {
    const fieldGuides: FieldGuideDocument[] = yield call(api.getFieldGuides);
    yield put(actions.getFieldGuides.success(fieldGuides));
  } catch (error) {
    yield put(actions.getFieldGuides.failure(error));
  }
}

function* getProfileFieldGuidesSaga({ payload }: { payload: FieldGuideSortFilterOptions }) {
  try {
    const fieldGuides: FieldGuideDocument[] = yield call(api.getProfileFieldGuides, payload);
    yield put(actions.getProfileFieldGuides.success(fieldGuides));
  } catch (error) {
    yield put(actions.getProfileFieldGuides.failure(error));
  }
}

function* setFavoriteFieldGuideSaga({
  payload: { companyId, fieldGuideDocumentId, isFavorite },
}: {
  payload: { companyId: number; fieldGuideDocumentId: number; isFavorite: boolean };
}) {
  try {
    const result: { isFavorite: boolean } = yield call(
      api.setFavoriteFieldGuide,
      companyId,
      fieldGuideDocumentId,
      isFavorite,
    );
    yield put(
      actions.setFavoriteFieldGuide.success({ fieldGuideDocumentId, companyId, isFavorite: result.isFavorite }),
    );
  } catch (error) {
    yield put(actions.setFavoriteFieldGuide.failure(error));
  }
}

function* watchLoaders(payload: AnyAction) {
  const state = yield select();
  const { loadingTypes } = state.shared as SharedStateType;

  const startedSections = loadingTypes.find(({ startActions }) => startActions.includes(payload.type));
  const stoppedSections = loadingTypes.find(({ stopActions }) => stopActions.includes(payload.type));

  if (!!startedSections) {
    yield put(actions.addLoadingSection(startedSections.name));
  }
  if (!!stoppedSections) {
    yield put(actions.removeLoadingSection(stoppedSections.name));
  }
}

function* getFileSaga({ payload }: { payload: string }) {
  try {
    const fileData: Blob = yield call(api.getFile, payload);
    yield put(actions.getFile.success({ [payload]: fileData }));
  } catch (error) {
    // we call success here to suppress red error messages. Error icon will still be shown on UI.
    yield put(actions.getFile.success({ [payload]: error }));
  }
}

function* getImageTypesSaga() {
  try {
    const imageTypes: ImageType[] = yield call(api.getImageTypes);
    yield put(actions.getImageTypes.success(imageTypes));
  } catch (error) {
    yield put(actions.getImageTypes.failure(error));
  }
}

function* getReviewStatusesSaga() {
  try {
    const reviewStatuses: ReviewStatus[] = yield call(api.getReviewStatuses);
    yield put(actions.getReviewStatuses.success(reviewStatuses));
  } catch (error) {
    yield put(actions.getReviewStatuses.failure(error));
  }
}

function* getNotificationHistoryList() {
  try {
    const notificationHistoryList = yield call(api.getNotificationHistoryList);

    yield put(actions.getNotificationHistoryList.success(notificationHistoryList));
  } catch (error) {
    yield put(actions.getNotificationHistoryList.failure(error));
  }
}

function* markAllNotificationsHistoryAsRead() {
  try {
    const notificationHistoryList = yield call(api.markAllNotificationsAsRead);
    yield put(actions.markAllNotificationsHistoryAsRead.success(notificationHistoryList));
  } catch (error) {
    yield put(actions.markAllNotificationsHistoryAsRead.failure(error));
  }
}

function* markOneNotificationHistoryAsRead({ payload }: AnyAction) {
  try {
    const notificationHistory = yield call(api.markOneNotificationAsRead, Number(payload));
    yield put(actions.markOneNotificationHistoryAsRead.success(notificationHistory));
  } catch (error) {
    yield put(actions.markOneNotificationHistoryAsRead.failure(error));
  }
}

function* getRolesList() {
  try {
    const rolesList = yield call(api.getRolesList);

    yield put(actions.getRoles.success(rolesList));
  } catch (error) {
    yield put(actions.getRoles.failure(error));
  }
}

function* getUserGuideSaga() {
  try {
    const userGuide: UserGuideDocument = yield call(api.getUserGuide);
    yield put(actions.getUserGuide.success(userGuide));
  } catch (error) {
    yield put(actions.getUserGuide.failure(error));
    history.push(ROUTE_PATHS.COMPANIES_DASHBOARD);
  }
}

function* sharedSagas() {
  yield takeLatest(SharedActionTypes.GET_USER, getUserDataSaga);
  yield takeLatest(actions.getFieldGuides.request, getFieldGuidesSaga);
  yield takeLatest(actions.getProfileFieldGuides.request, getProfileFieldGuidesSaga);
  yield takeLatest(actions.setFavoriteFieldGuide.request, setFavoriteFieldGuideSaga);
  yield takeLatest(actions.getImageTypes.request, getImageTypesSaga);
  yield takeLatest(actions.getReviewStatuses.request, getReviewStatusesSaga);
  yield takeLatest(actions.getUserGuide.request, getUserGuideSaga);

  yield takeLatest(actions.getNotificationHistoryList.request, getNotificationHistoryList);
  yield takeLatest(actions.markOneNotificationHistoryAsRead.request, markOneNotificationHistoryAsRead);
  yield takeLatest(actions.markAllNotificationsHistoryAsRead.request, markAllNotificationsHistoryAsRead);
  yield takeLatest(actions.getRoles.request, getRolesList);

  yield takeEvery(actions.getFile.request, getFileSaga);
  yield takeEvery("*", watchLoaders);
}

export default sharedSagas;
