import { ROUTE_PATHS, PERMISSION } from "../../../../shared";

interface NavbarItem {
  name: string;
  link: string;
  iconPath: string;
  checkPermissions?: boolean | PERMISSION[];
}

export const NavbarItemsMap: NavbarItem[] = [
  {
    name: "Users",
    link: ROUTE_PATHS.USERS_LIST,
    iconPath: "/images/menu/menu-users.svg",
    checkPermissions: true,
  },
  {
    name: "Companies & Plants",
    link: ROUTE_PATHS.COMPANIES_DASHBOARD,
    iconPath: "/images/menu/menu-companies.svg",
  },
  {
    name: "Field Notes",
    link: ROUTE_PATHS.FIELD_NOTES_DASHBOARD,
    iconPath: "/images/menu/menu-notes.svg",
  },
  {
    name: "Field Guides",
    link: ROUTE_PATHS.FIELD_GUIDES,
    iconPath: "/images/menu/menu-guide.svg",
  },
  {
    name: "Image Review",
    link: ROUTE_PATHS.IMAGE_REVIEWS_DASHBOARD,
    iconPath: "/images/menu/menu-image-review.svg",
    checkPermissions: [PERMISSION.REVIEW_SECTION_VIEW],
  },
];
