import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { useOutsideClick } from "../../hooks";

import "./actionMenu.scss";

export interface ActionItemMenu {
  label: string;
  handler?: () => void;
  isAlert?: boolean;
}
interface ActionMenuProps {
  actions: ActionItemMenu[];
}

const ActionMenu: React.FunctionComponent<ActionMenuProps> = (props) => {
  const { actions } = props;

  const [isOpen, setIsOpen] = useState(false);

  const actionsRef = useRef(null);
  const { isOutside } = useOutsideClick(actionsRef);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOutside) {
      setIsOpen(false);
    }
  }, [isOutside]);

  return (
    <div className="actionMenu" onClick={toggleCollapse} ref={actionsRef}>
      <div className="actionMenu_root">
        <img src="/icons/general/actions-empty.svg" alt="actions" />
      </div>
      {isOpen && (
        <div className="actionMenu_actions">
          {actions.map((action) => (
            <div
              className={classnames("actionMenu_actions_action", { alert: action.isAlert })}
              key={action.label}
              onClick={action.handler}
            >
              {action.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ActionMenu;
