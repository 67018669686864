export enum ActionTypes {
  SET_CURRENT_PLANT = "@@DASHBOARD/SET_CURRENT_PLANT",

  GET_COMPANIES = "@@DASHBOARD/GET_COMPANIES",
  GET_COMPANIES_SUCCESS = "@@DASHBOARD/GET_COMPANIES_SUCCESS",
  GET_COMPANIES_FAILURE = "@@DASHBOARD/GET_COMPANIES_FAILURE",

  GET_COMPANIES_PLANTS = "@@DASHBOARD/GET_COMPANIES_PLANTS",
  GET_COMPANIES_PLANTS_SUCCESS = "@@DASHBOARD/GET_COMPANIES_PLANTS_SUCCESS",
  GET_COMPANIES_PLANTS_FAILURE = "@@DASHBOARD/GET_COMPANIES_PLANTS_FAILURE",

  GET_COMPANY = "@@DASHBOARD/GET_COMPANY",
  GET_COMPANY_SUCCESS = "@@DASHBOARD/GET_COMPANY_SUCCESS",
  GET_COMPANY_FAILURE = "@@DASHBOARD/GET_COMPANY_FAILURE",

  GET_CURRENT_COMPANY = "@@DASHBOARD/GET_CURRENT_COMPANY",
  GET_CURRENT_COMPANY_SUCCESS = "@@DASHBOARD/GET_CURRENT_COMPANY_SUCCESS",
  GET_CURRENT_COMPANY_FAILURE = "@@DASHBOARD/GET_CURRENT_COMPANY_FAILURE",

  CREATE_COMPANY = "@@DASHBOARD/CREATE_COMPANY",
  CREATE_COMPANY_SUCCESS = "@@DASHBOARD/CREATE_COMPANY_SUCCESS",
  CREATE_COMPANY_FAILURE = "@@DASHBOARD/CREATE_COMPANY_FAILURE",

  UPDATE_COMPANY = "@@DASHBOARD/UPDATE_COMPANY",
  UPDATE_COMPANY_SUCCESS = "@@DASHBOARD/UPDATE_COMPANY_SUCCESS",
  UPDATE_COMPANY_FAILURE = "@@DASHBOARD/UPDATE_COMPANY_FAILURE",

  DELETE_COMPANY = "@@DASHBOARD/DELETE_COMPANY",
  DELETE_COMPANY_SUCCESS = "@@DASHBOARD/DELETE_COMPANY_SUCCESS",
  DELETE_COMPANY_FAILURE = "@@DASHBOARD/DELETE_COMPANY_FAILURE",

  RESTORE_COMPANY = "@@DASHBOARD/RESTORE_COMPANY",
  RESTORE_COMPANY_SUCCESS = "@@DASHBOARD/RESTORE_COMPANY_SUCCESS",
  RESTORE_COMPANY_FAILURE = "@@DASHBOARD/RESTORE_COMPANY_FAILURE",

  GET_PLANT = "@@DASHBOARD/GET_PLANT",
  GET_PLANT_SUCCESS = "@@DASHBOARD/GET_PLANT_SUCCESS",
  GET_PLANT_FAILURE = "@@DASHBOARD/GET_PLANT_FAILURE",

  CREATE_PLANT = "@@DASHBOARD/CREATE_PLANT",
  CREATE_PLANT_SUCCESS = "@@DASHBOARD/CREATE_PLANT_SUCCESS",
  CREATE_PLANT_FAILURE = "@@DASHBOARD/CREATE_PLANT_FAILURE",

  UPDATE_PLANT = "@@DASHBOARD/UPDATE_PLANT",
  UPDATE_PLANT_SUCCESS = "@@DASHBOARD/UPDATE_PLANT_SUCCESS",
  UPDATE_PLANT_FAILURE = "@@DASHBOARD/UPDATE_PLANT_FAILURE",

  DELETE_PLANT = "@@DASHBOARD/DELETE_PLANT",
  DELETE_PLANT_SUCCESS = "@@DASHBOARD/DELETE_PLANT_SUCCESS",
  DELETE_PLANT_FAILURE = "@@DASHBOARD/DELETE_PLANT_FAILURE",

  RESTORE_PLANT = "@@DASHBOARD/RESTORE_PLANT",
  RESTORE_PLANT_SUCCESS = "@@DASHBOARD/RESTORE_PLANT_SUCCESS",
  RESTORE_PLANT_FAILURE = "@@DASHBOARD/RESTORE_PLANT_FAILURE",

  GET_PLANT_DOCUMENTS = "@@DASHBOARD/GET_PLANT_DOCUMENTS",
  GET_PLANT_DOCUMENTS_SUCCESS = "@@DASHBOARD/GET_PLANT_DOCUMENTS_SUCCESS",
  GET_PLANT_DOCUMENTS_FAILURE = "@@DASHBOARD/GET_PLANT_DOCUMENTS_FAILURE",

  DELETE_PLANT_DOCUMENT = "@@DASHBOARD/DELETE_PLANT_DOCUMENT",
  DELETE_PLANT_DOCUMENT_SUCCESS = "@@DASHBOARD/DELETE_PLANT_DOCUMENT_SUCCESS",
  DELETE_PLANT_DOCUMENT_FAILURE = "@@DASHBOARD/DELETE_PLANT_DOCUMENT_FAILURE",

  CREATE_PLANT_DOCUMENT = "@@DASHBOARD/CREATE_PLANT_DOCUMENT",
  CREATE_PLANT_DOCUMENT_SUCCESS = "@@DASHBOARD/CREATE_PLANT_DOCUMENT_SUCCESS",
  CREATE_PLANT_DOCUMENT_FAILURE = "@@DASHBOARD/CREATE_PLANT_DOCUMENT_FAILURE",

  UPDATE_PLANT_DOCUMENT = "@@DASHBOARD/UPDATE_PLANT_DOCUMENT",
  UPDATE_PLANT_DOCUMENT_SUCCESS = "@@DASHBOARD/UPDATE_PLANT_DOCUMENT_SUCCESS",
  UPDATE_PLANT_DOCUMENT_FAILURE = "@@DASHBOARD/UPDATE_PLANT_DOCUMENT_FAILURE",
}
