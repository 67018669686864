import React, { CSSProperties } from "react";
import { FieldArrayRenderProps, FormikProps, getIn } from "formik";
import classnames from "classnames";
import { Tooltip } from "../../../shared/components";

import {
  CheckBoxFieldItem,
  PasswordFieldItem,
  TextFieldItem,
  SelectFieldItem,
  MultiSelectFieldItem,
  AttachmentSelectFieldItem,
  TextAreaFieldItem,
  SimpleAudioFieldItem,
  SimpleImageFieldItem,
  DropUploadFileFieldItem,
} from "../interfaces/formInterfaces";
import { Option } from "../../interfaces";
import {
  AudioUpload,
  AudioUploadValue,
  CheckboxInput,
  Input,
  Select,
  SimpleImagesUpload,
  SimpleImagesUploadValue,
  Textarea,
  MultiSelect,
  AttachmentsUpload,
  ErrorMessage,
} from "../../formComponents/common";
import { DropFileZone } from "../../components";
import { DropFileItem } from "../../components/DropFileZone/DropFileZone";
import { FileError } from "react-dropzone";

// eslint-disable-next-line
type AnyFormikProps = FormikProps<any>;

export const generateTextField: React.FunctionComponent<TextFieldItem & { formikProps: AnyFormikProps }> = (props) => {
  const {
    formikProps: { errors, touched, handleBlur, handleChange, values },
    wrapperClass,
    name,
    placeholder = "Start typing here",
    disabled,
    type,
    label,
  } = props;

  return (
    <div className={classnames(wrapperClass, { error: errors[name] && touched[name] })} id={name}>
      <Input
        type={type}
        label={label}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[name]}
      />
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};

export const generatePasswordField: React.FunctionComponent<PasswordFieldItem & { formikProps: AnyFormikProps }> = (
  props,
) => {
  const {
    formikProps: { errors, touched, handleBlur, handleChange, values },
    wrapperClass,
    name,
    placeholder = "Start typing here",
    disabled,
    type,
    label,
    isShowPasswordIcon,
  } = props;

  return (
    <div className={classnames(wrapperClass, { error: errors[name] && touched[name] })} id={name}>
      <Input
        isShowPasswordIcon={isShowPasswordIcon}
        type={type}
        label={label}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[name]}
      />
      {touched[name] && errors[name] && <div className="error-message">{errors[name]}</div>}
    </div>
  );
};

export const generateCheckbox: React.FunctionComponent<CheckBoxFieldItem & { formikProps: AnyFormikProps }> = (
  props,
) => {
  const {
    formikProps: { errors, touched, handleBlur, values, setFieldValue },
    name,
    inputClass,
    parentPrefix,
    label,
    type,
    disabled,
    handleClick,
  } = props;

  const valueShape = parentPrefix ? values[parentPrefix][name] : values[name];
  // eslint-disable-next-line
  const handleChange = (e: React.ChangeEvent<any>) => {
    const name = e.target.name;
    const fieldValue = e.target.checked;

    const prefixedName = parentPrefix ? `${parentPrefix}.${name}` : name;
    if (name) {
      setFieldValue(prefixedName, { ...valueShape, value: fieldValue });
    }
  };

  return (
    <div key={name} id={name}>
      <CheckboxInput
        label={label}
        type={type}
        name={name}
        className={inputClass}
        onChange={handleChange}
        onBlur={handleBlur}
        value={valueShape?.value}
        disabled={disabled}
        handleClick={handleClick}
      />
      {touched[name] && errors[name] && <div className="error-message">{errors[name]}</div>}
    </div>
  );
};

export const generateSelect: React.FunctionComponent<SelectFieldItem & { formikProps: AnyFormikProps }> = (props) => {
  const {
    formikProps: { errors, touched, setFieldValue, values, setFieldTouched },
    wrapperClass,
    disabled,
    label,
    name,
    options,
    placeholder,
    styles,
    helpText = null,
    isSearchable = true,
    tooltip = null,
    tooltipLabel = null,
    relatedFields,
  } = props;

  const handleChange = (option: Option) => {
    habdleClear(option.value);
    setFieldValue(name, option.value);
  };

  const habdleClear = (value: string) => {
    if (relatedFields) {
      const { cleaningCondition, fields } = relatedFields;
      if (Number(value) === cleaningCondition) {
        (fields as string[]).forEach((fName) => setFieldValue(fName, null, true));
      }
    }
  };

  const handleInputChange = () => {
    setFieldTouched(name, true, false);
  };

  const setValue = () => {
    return options.find((option) => option.value === values[name]) || null;
  };

  return (
    <div
      className={classnames("render-select", wrapperClass, {
        error: errors[name] && touched[name],
      })}
      key={name}
      id={name}
    >
      <Tooltip tooltip={tooltip as string} tooltipLabel={tooltipLabel as string} show={!!tooltip} />
      <label className={classnames({ disabled })}>{label}</label>
      <div className={classnames("select", { disabled })}>
        <Select
          isDisabled={disabled || !options.length}
          components={{
            IndicatorSeparator: () => null,
          }}
          isSearchable={isSearchable}
          onChange={(option) => handleChange(option as Option)}
          onInputChange={handleInputChange}
          value={setValue()}
          options={options}
          name={name}
          placeholder={placeholder || "Start typing here"}
          noOptionsMessage={() => "No matches found"}
          styles={{
            ...styles,
            placeholder: (styles: CSSProperties) => ({
              ...styles,
              color: "#bdbdbd",
              fontSize: "16px",
            }),
          }}
        />
      </div>
      {touched[name] && errors[name] ? (
        <div className="error-message">{errors[name]}</div>
      ) : (
        <div className={classnames("help-text", { hide: !helpText })}>{helpText}</div>
      )}
    </div>
  );
};

export const generateMultiSelect: React.FunctionComponent<MultiSelectFieldItem & { formikProps: AnyFormikProps }> = (
  props,
) => {
  const {
    formikProps: { values, setFieldValue, errors, touched },
    options,
    placeholder,
    name,
    label,
    wrapperClass,
  } = props;

  const multiSelectValues = values[name];

  return (
    <MultiSelect
      isDisabled={!options.length}
      key={name}
      name={name}
      placeholder={placeholder}
      label={label}
      values={multiSelectValues}
      options={options}
      setFieldValue={setFieldValue}
      className={wrapperClass}
      errorText={touched[name] && errors[name] ? errors[name]?.toString() : ""}
    />
  );
};
export const generateAttachmentSelect: React.FunctionComponent<
  AttachmentSelectFieldItem & { formikProps: AnyFormikProps; formikFieldArrayHelpers: FieldArrayRenderProps }
> = (props) => {
  const {
    formikProps: { setFieldValue, values },
    formikFieldArrayHelpers,
    index,
    name,
  } = props;

  const value = getIn(values, name);
  return (
    <AttachmentsUpload
      key={name}
      name={name}
      index={index}
      setFieldValue={setFieldValue}
      value={value}
      formHelpers={formikFieldArrayHelpers}
    />
  );
};

export const generateTextarea: React.FunctionComponent<TextAreaFieldItem & { formikProps: AnyFormikProps }> = (
  props,
) => {
  const {
    name,
    inputClass,
    label,
    placeholder,
    maxLength,
    formikProps: { handleChange, handleBlur, values, touched, errors },
  } = props;

  return (
    <div key={name} id={name}>
      <Textarea
        name={name}
        maxLength={maxLength}
        placeholder={placeholder}
        label={label}
        className={inputClass}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[name]}
      />
      {touched[name] && errors[name] && <div className="error-message">{errors[name]}</div>}
    </div>
  );
};

export const generateSimpleImage: React.FunctionComponent<SimpleImageFieldItem & { formikProps: AnyFormikProps }> = (
  props,
) => {
  const {
    name,
    formikProps: { values, setFieldValue },
  } = props;

  const value: SimpleImagesUploadValue[] = values[name];

  return <SimpleImagesUpload key={name} name={name} setFieldValues={setFieldValue} values={value} />;
};

export const generateAudioUpload: React.FunctionComponent<SimpleAudioFieldItem & { formikProps: AnyFormikProps }> = (
  props,
) => {
  const {
    name,
    formikProps: { values, setFieldValue },
  } = props;

  const value: AudioUploadValue[] = values[name];

  return <AudioUpload key={name} name={name} setFieldValues={setFieldValue} values={value} />;
};

export const generateDropFileUpload: React.FunctionComponent<
  DropUploadFileFieldItem & { formikProps: AnyFormikProps }
> = (props) => {
  const {
    name,
    maxSize,
    formikProps: { values, setFieldValue, touched, errors, setFieldError, setFieldTouched },
  } = props;
  const value: DropFileItem | null = values[name];

  const setValue = (value: DropFileItem | null) => {
    setFieldValue(name, value);
  };

  const rejectFile = (fileError: FileError | null) => {
    if (!fileError) {
      setFieldTouched(name, false);
      setFieldError(name, "");
      return;
    }

    switch (fileError.code) {
      case "file-too-large": {
        setFieldTouched(name, true);
        setFieldError(name, `Max size of the file should be ${(maxSize || 0) / 1000000} MB`);
        break;
      }
      default: {
        setFieldTouched(name, true);
        setFieldError(name, fileError.message);
      }
    }
  };

  return (
    <div key={name} className="drop-zone-container">
      <DropFileZone onUploadFile={setValue} file={value} maxSize={maxSize} onRejectedFile={rejectFile} />
      {touched[name] && errors[name] && <div className="error-message">{errors[name]}</div>}
    </div>
  );
};
