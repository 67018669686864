import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, generatePath } from "react-router-dom";
import classnames from "classnames";

import { SearchInput } from "../../../../shared/formComponents/common";
import {
  InstanceHeader,
  Modal,
  EditFieldNoteModal,
  RemoveModalContent,
  AccessControl,
} from "../../../../shared/components";
import { FieldNote } from "../../../../shared/models";
import { useModal, useLoader, useSortFilter } from "../../../../shared/hooks";
import { LOADERS_NAMES, ORDER_TYPES, PERMISSION } from "../../../../shared/constants";
import { ROUTE_PATHS } from "../../../../shared/routes";
import { RouteComponentProps } from "../../../../shared/interfaces";
import { actions, selectors } from "../../store";
import { InstanceContainer } from "./components/InstanceContainer";
import { selectors as companiesPlantsSelectors } from "../../../CompaniesPlants/store";
import { selectors as fieldNotesSelectors, actions as fieldNotesActions } from "../../../FieldNotes/store";
import { mapComponentPropertiesToString } from "../../../../shared/mappers";
import MasonryLayout from "../../../../shared/components/MasonryLayout/MasonryLayout";

import "./index.scss";

interface InstancesListContainerProps extends RouteComponentProps<{ fieldNoteId: number }> {}

const InstancesListContainer: React.FunctionComponent<InstancesListContainerProps> = (props) => {
  const {
    match: {
      params: { fieldNoteId },
    },
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const { isShowing, onOpen, onClose } = useModal(false);

  const instances = useSelector(selectors.getInstances());
  const currentFieldNote = useSelector(fieldNotesSelectors.getCurrentFieldNote());
  const plant = useSelector(companiesPlantsSelectors.getCurrentPlant());
  const plants = useSelector(companiesPlantsSelectors.getPlants());
  const pdf = useSelector(fieldNotesSelectors.getFieldNotePDF());

  const { isLoading, AppLoader } = useLoader({
    name: LOADERS_NAMES.INSTANCES_LOADER,
    actionTypes: [actions.getInstancesByFieldNote],
  });

  const {
    requestOptions,
    searchString,
    sortOrder,
    sortOrderLabel,
    showMine = true,
    utils: { setSearchString, toggleSortOrder, toggleShowMine },
  } = useSortFilter({ showMineInitial: false });

  const [showFieldNoteEdit, setFieldNoteEdit] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalEntity, setModalEntity] = useState("");
  const [editedFieldNote, setEditedFieldNote] = useState<null | FieldNote>(null);

  useEffect(() => {
    if (fieldNoteId) {
      dispatch(fieldNotesActions.getFieldNote.request(fieldNoteId));
    }
  }, [dispatch, fieldNoteId]);

  useEffect(() => {
    if (currentFieldNote?.id) {
      dispatch(
        actions.getInstancesByFieldNote.request({
          fieldNoteId: currentFieldNote.id,
          ...requestOptions,
        }),
      );
    }

    return () => {
      dispatch(actions.getInstancesByFieldNote.success([]));
    };
  }, [dispatch, currentFieldNote, sortOrder, requestOptions]);

  const handlePdfLoad = useCallback(async () => {
    dispatch(fieldNotesActions.cleanFieldNotePdf());
  }, [dispatch]);

  useEffect(() => {
    if (pdf) {
      const file = new Blob([pdf], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const win = window.open(fileURL);
      if (win) {
        win.onload = handlePdfLoad;
      }
    }
  }, [pdf, handlePdfLoad]);

  const openFieldNoteEdit = useCallback(() => {
    setFieldNoteEdit(true);
  }, []);

  const closeFieldNoteModalHandler = useCallback(() => {
    onClose();
    setFieldNoteEdit(false);
    setEditedFieldNote(null);
  }, [setFieldNoteEdit, onClose, setEditedFieldNote]);

  const showHideUnsavedFieldNoteChangeModal = useCallback(
    (dirty: boolean) => {
      if (dirty) {
        setModalEntity("FieldNote");
        onOpen();
      } else {
        closeFieldNoteModalHandler();
      }
    },
    [closeFieldNoteModalHandler, onOpen],
  );

  const submitFieldNote = useCallback(
    (fieldNote: Partial<FieldNote>) => {
      dispatch(fieldNotesActions.updateFieldNote.request({ ...fieldNote, id: currentFieldNote?.id }));
      closeFieldNoteModalHandler();
    },
    [currentFieldNote, closeFieldNoteModalHandler, dispatch],
  );

  const onFieldNoteDelete = useCallback(() => {
    setModalType("delete");
    setModalEntity("FieldNote");
    setEditedFieldNote(currentFieldNote);
    onOpen();
  }, [onOpen, currentFieldNote]);

  const redirectToPlant = useCallback(
    (plantId: number) => {
      history.push(generatePath(ROUTE_PATHS.PLANT_DETAILS_DASHBOARD, { plantId: plantId }));
    },
    [history],
  );

  const deleteFieldNote = useCallback(() => {
    if (editedFieldNote && editedFieldNote.id) {
      dispatch(
        fieldNotesActions.deleteFieldNote.request({
          fieldNoteId: editedFieldNote.id,
          callback: () => redirectToPlant(editedFieldNote.plantId),
        }),
      );
    }
  }, [editedFieldNote, dispatch, redirectToPlant]);

  const download = useCallback(async () => {
    if (currentFieldNote) {
      dispatch(fieldNotesActions.getFieldNotePDF.request(currentFieldNote.id));
    }
  }, [currentFieldNote, dispatch]);

  const redirectToCreateInstance = () => {
    if (currentFieldNote) {
      history.push(
        generatePath(ROUTE_PATHS.CREATE_INSTANCE, {
          fieldNoteId: String(currentFieldNote.id),
          plantId: String(currentFieldNote.plantId),
        }),
      );
    }
  };

  const redirectToCompanies = () => {
    if (currentFieldNote?.plantId) {
      history.push(generatePath(ROUTE_PATHS.PLANT_DETAILS_DASHBOARD, { plantId: currentFieldNote.plantId }));
    }
  };

  const getInstancesCountLabel = (count: number) => {
    if (count === 1) {
      return "1 Instance";
    }
    return `${instances.length} Instances`;
  };

  const renderModal = useCallback(() => {
    let onDelete, heading, content, removeText;
    switch (modalType) {
      case "delete": {
        removeText = "Remove";
        heading = `Remove Field Note`;
        content = `Are you sure that you want to remove field note?`;
        onDelete = () => {
          if (modalEntity === "FieldNote") {
            deleteFieldNote();
            closeFieldNoteModalHandler();
          }
        };
        break;
      }

      default: {
        removeText = "LEAVE";
        heading = "Save changes";
        content = "Your changes will be lost. Are you sure?";
        onDelete = () => {
          if (modalEntity === "FieldNote") {
            closeFieldNoteModalHandler();
          }
        };
      }
    }

    return (
      <RemoveModalContent
        heading={heading}
        content={content}
        cancelText={modalType === "delete" ? "Cancel" : "Save"}
        removeText={removeText}
        onClose={() => {
          onClose();
          setModalType("");
          setModalEntity("");
        }}
        onDelete={onDelete}
        isSubmitType
      />
    );
  }, [modalType, modalEntity, closeFieldNoteModalHandler, deleteFieldNote, onClose]);

  return (
    <div className="instances-container">
      <InstanceHeader
        headerLabel={currentFieldNote?.displayName}
        onBackClick={redirectToCompanies}
        backLabel={plant?.displayName}
        rightButtons={
          <>
            <AccessControl permissions={[PERMISSION.FIELDNOTE_DELETE]} plant={plant} fieldNote={currentFieldNote}>
              <div className="instances-remove-button" onClick={onFieldNoteDelete}>
                REMOVE
              </div>
            </AccessControl>
            <div className="instances-export-button" onClick={download}>
              <div className="instances-download-button" />
              <div className="instances-download-button-label">DOWNLOAD</div>
            </div>
            <div className="instances-showMine-button" onClick={toggleShowMine}>
              {showMine ? "Show All" : "Show Mine"}
            </div>

            <AccessControl permissions={[PERMISSION.FIELDNOTE_EDIT]} plant={plant} fieldNote={currentFieldNote}>
              <div className="instances-edit-button" onClick={openFieldNoteEdit}>
                EDIT FIELD NOTE
              </div>
            </AccessControl>
          </>
        }
      />

      <div className="instances-filters">
        <div>{getInstancesCountLabel(instances?.length)}</div>
        <div className="search-block-wrapper">
          <div className="search-block">
            <SearchInput onChangeSearch={setSearchString} placeholder="Search" value={searchString} />
          </div>

          <div className="filter sort-order-block no-select">
            <div onClick={toggleSortOrder} className="sort-order-wrapper">
              {sortOrderLabel}
              <span className={classnames("icon sort-order", { "v-flip": sortOrder === ORDER_TYPES.DESC })} />
            </div>
          </div>
        </div>
      </div>
      <div className={classnames("instances-body", { empty: !instances?.length })}>
        {isLoading ? (
          <AppLoader />
        ) : (
          <MasonryLayout className="instances-body-section" layoutOptions={{ columnWidth: 322 }}>
            {!!instances?.length ? (
              instances?.map((instance) => {
                const filteredItemsNames = mapComponentPropertiesToString(instance.instanceComponentProperties || []);

                return (
                  <InstanceContainer
                    key={instance.id}
                    instance={instance}
                    fieldNote={currentFieldNote}
                    filteredItemsNames={filteredItemsNames}
                  />
                );
              })
            ) : !searchString ? (
              <div className="instances-list-empty" />
            ) : (
              <div className="instances-not-found" />
            )}
          </MasonryLayout>
        )}
      </div>
      <AccessControl permissions={[PERMISSION.INSTANCE_CREATE]} plant={plant}>
        <div className="add-button-container">
          <div className="bottom-row">
            <div className="add-button" onClick={redirectToCreateInstance} />
          </div>
        </div>
      </AccessControl>
      <Modal isShowing={isShowing} onClose={onClose} boxPaddingThick>
        {renderModal()}
      </Modal>
      {showFieldNoteEdit && (
        <EditFieldNoteModal
          currentFieldNote={currentFieldNote}
          plants={plants}
          currentPlant={null}
          showHideUnsavedChangeModal={showHideUnsavedFieldNoteChangeModal}
          submitFieldNote={submitFieldNote}
        />
      )}
    </div>
  );
};

export default InstancesListContainer;
