import React from "react";

import "./instanceHeader.scss";

interface InstanceHeaderProps {
  onBackClick?: () => void;
  backLabel?: string | null;
  headerLabel?: string | null;
  rightButtons?: React.ReactNode;
}

export const InstanceHeader: React.FunctionComponent<InstanceHeaderProps> = (props) => {
  const { onBackClick, backLabel, headerLabel, rightButtons } = props;

  return (
    <div className="instances-header">
      <div className="instances-back-name" onClick={() => onBackClick?.()}>
        <div className="instances-back" />
        <div className="instances-back-label">{backLabel ?? ""}</div>
      </div>
      <div className="instances-field-note-name">{headerLabel ?? ""}</div>
      <div className="instances-right-buttons">{rightButtons}</div>
    </div>
  );
};
