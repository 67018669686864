import { createAsyncAction, createAction } from "typesafe-actions";

import { ActionTypes } from "./constants";
import { Image } from "../../../shared/models";
import { ImageSortFilterOptions } from "../../../shared/interfaces";

export const getImages = createAsyncAction(
  ActionTypes.GET_IMAGES,
  ActionTypes.GET_IMAGES_SUCCESS,
  ActionTypes.GET_IMAGES_FAILURE,
)<ImageSortFilterOptions, { images: Image[]; count: number }, Error>();

export const clearImages = createAction(ActionTypes.CLEAR_IMAGES)();

export const reviewImage = createAsyncAction(
  ActionTypes.REVIEW_IMAGE,
  ActionTypes.REVIEW_IMAGE_SUCCESS,
  ActionTypes.REVIEW_IMAGE_FAILURE,
)<{ imageIds: number[]; reviewStatusId: number; cb?: () => void }, { images: Image[]; message: string }, Error>();

export const validateImagePosition = createAsyncAction(
  ActionTypes.VALIDATE_IMAGE_POSITION_INDEX,
  ActionTypes.VALIDATE_IMAGE_POSITION_INDEX_SUCCESS,
  ActionTypes.VALIDATE_IMAGE_POSITION_INDEX_FAILURE,
)<
  { imageId: number | string; instanceId: number; positionIndex: number; cb: (value: boolean) => void },
  { isExistReplacedImage: boolean },
  Error
>();
