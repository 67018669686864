import React, { useMemo } from "react";

import { FieldNote } from "../../models";
import { formatDate } from "../../utils";
import "./index.scss";

interface FieldNoteCardProps {
  fieldNote: FieldNote;
  onClick: () => void;
}

const FieldNoteCard: React.FunctionComponent<FieldNoteCardProps> = (props) => {
  const { fieldNote, onClick } = props;

  const formattedDate = useMemo(() => {
    return fieldNote.createdAt ? formatDate(new Date(fieldNote.createdAt)) : "";
  }, [fieldNote.createdAt]);

  return (
    <div className="field-note-container" onClick={onClick}>
      <div className="field-note-name">{fieldNote.displayName}</div>
      <div className="field-note-buttons">
        {!!fieldNote.instancesCount && (
          <div className="field-note-button">
            <div className="button-label">{fieldNote.instancesCount} Instances</div>
          </div>
        )}
      </div>
      <div className="created-label">{`Created: ${formattedDate}`}</div>
    </div>
  );
};

export default FieldNoteCard;
