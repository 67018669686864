import React, { useEffect, useMemo, useCallback, useState, useRef } from "react";
import { useHistory, generatePath } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { HighlightArea } from "@react-pdf-viewer/highlight";

import {
  InstanceHeader,
  NavigationPanel,
  Modal,
  ModalContent,
  RemoveModalContent,
  AddButton,
  AddMembersModal,
  AccessControl,
} from "../../../../shared/components";
import { ROUTE_PATHS } from "../../../../shared/routes";
import { RouteComponentProps } from "../../../../shared/interfaces";
import {
  Profile,
  InstanceCollaborator,
  ComponentPropertyType,
  FieldGuideReference,
  RemoveFileType,
} from "../../../../shared/models";
import { formatDateWithTime, notEmpty } from "../../../../shared/utils";
import { PdfReader } from "../../../../shared/components";

import {
  actions as companiesPlantsActions,
  selectors as companiesPlantsSelectors,
} from "../../../CompaniesPlants/store";
import { selectors as fieldNotesSelectors, actions as fieldNotesActions } from "../../../FieldNotes/store";
import { actions, selectors } from "../../store";
import { selectors as sharedSelectors } from "../../../../shared/store";
import { InstanceComments, CommentFormFields } from "./components/InstanceComments";
import { InstanceReferences } from "./components/InstanceReferences";

import { useConditionOptions, useLoader } from "../../../../shared/hooks";
import { LOADERS_NAMES, NOTIFICATION_TYPES, PERMISSION } from "../../../../shared/constants";
import { checkIsOneOfCollaborators, checkPermissions } from "../../../../shared/utils/ACL";
import { ImageAnnotate } from "../../../../shared/formComponents/common/ImageAnnotate";

import { InstanceAttachments } from "./components/InstanceAttachments";
import { setFieldGuide, showNotification } from "../../../../shared/store/actions";
import { FieldGuideReferenceArea } from "../../../../shared/models/FieldGuideReferenceArea";
import { AudioUploadValue, ImagesUploadValue } from "../../../../shared/formComponents/common";
import { getFieldGuide } from "../../../../shared/store/selectors";

import "./instanceView.scss";
import { CompanyFieldGuides } from "../FieldGuideSelectContainer/components/CompanyFieldGuides";

export enum Panels {
  Attachments = "Attachments",
  Comments = "Comments",
}

interface InstanceViewContainerProps extends RouteComponentProps<{ id: number; fieldNoteId: number }> {}

const InstanceViewContainer: React.FunctionComponent<InstanceViewContainerProps> = (props) => {
  const {
    match: {
      params: { id: instanceId, fieldNoteId },
    },
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const contentWrapperRef = useRef<HTMLDivElement | null>(null);

  const { getEnvironments, environmentsOptions } = useConditionOptions();

  const { isLoading: isCommentsLoading } = useLoader({
    name: LOADERS_NAMES.INSTANCE_COMMENTS_LOADER,
    actionTypes: [actions.getInstanceComments, actions.createComment, actions.updateComment, actions.deleteComment],
  });

  const { isLoadingDebounced: isPhotoUpdating } = useLoader({
    name: LOADERS_NAMES.INSTANCE_PHOTO_LOADER,
    actionTypes: [actions.updateImage],
  });

  const user = useSelector(sharedSelectors.getUser());
  const currentFieldNote = useSelector(fieldNotesSelectors.getCurrentFieldNote());
  const currentPlant = useSelector(companiesPlantsSelectors.getCurrentPlant());
  const instance = useSelector(selectors.getInstance());
  const profiles = useSelector(selectors.getProfiles());
  const collaborators = useSelector(selectors.getCollaborators());
  const instanceComments = useSelector(selectors.getInstanceComments());
  const currentCompany = useSelector(companiesPlantsSelectors.getCurrentCompany());
  const pdf = useSelector(selectors.getInstancePDF());
  const fieldGuide = useSelector(getFieldGuide());

  const [fileToSave, setFileToSave] = useState<ImagesUploadValue | undefined>(undefined);
  const [fileToEdit, setFileToEdit] = useState<(ImagesUploadValue & { label: string }) | undefined>(undefined);

  const [fileToDelete, setFileToDelete] = useState<RemoveFileType | null>(null);
  const [isInstanceDeleteOpened, setIsInstanceDeleteOpened] = useState(false);
  const [collaboratorToDelete, setCollaboratorToDelete] = useState<number | undefined>(undefined);
  const [fieldGuideToDelete, setFieldGuideToDelete] = useState<number | undefined>(undefined);
  const [isAddMembersOpened, setIsAddMembersOpened] = useState(false);
  const [isAddComment, setIsAddComment] = useState(false);
  const [immediatePanel, setImmediatePanel] = useState<Panels | undefined>(undefined);
  const [showFieldGuide, setShowFieldGuide] = useState(false);
  const [currentPage, setCurrentPage] = useState<number | undefined>();
  const [highlightAreas, setHighlightAreas] = useState<HighlightArea[] | undefined>();
  const [notificationType, setNotificationType] = useState<string | undefined>(undefined);
  const [notificationTime, setNotificationTime] = useState<Date | undefined>(undefined);
  const [voiceNote, setVoiceNote] = useState<AudioUploadValue | undefined>(undefined);
  const [isNewImage, setIsNewImage] = useState<boolean>(false);
  const [isNewVoiceNote, setIsNewVoiceNote] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setFieldGuide(undefined));
  }, [dispatch]);

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const notificationTimeValue = searchParams.get("at");
    if (notificationTimeValue) {
      setNotificationTime(new Date(notificationTimeValue));
    }
    const notificationTypeValue = searchParams.get("notificationType");
    if (notificationTypeValue) {
      setNotificationType(notificationTypeValue);
    }
    const panel = searchParams.get("panel");
    if (Object.keys(Panels).includes(panel as string)) {
      setImmediatePanel(panel as Panels);
    }
  }, [history.location.search]);

  const redirectToInstances = useCallback(() => {
    if (currentFieldNote) {
      history.push(
        generatePath(ROUTE_PATHS.INSTANCES_DASHBOARD, {
          fieldNoteId: String(currentFieldNote.id),
          plantId: String(currentFieldNote.plantId),
        }),
      );
    }
  }, [history, currentFieldNote]);

  const redirectToEditInstance = () => {
    if (currentFieldNote) {
      history.push(
        generatePath(ROUTE_PATHS.EDIT_INSTANCE, {
          id: instanceId,
          fieldNoteId: String(currentFieldNote.id),
          plantId: String(currentFieldNote.plantId),
        }),
      );
    }
  };

  const redirectToConditionNotes = () => {
    if (currentFieldNote) {
      history.push(
        generatePath(ROUTE_PATHS.CONDITION_NOTES, {
          id: instanceId,
          fieldNoteId: String(currentFieldNote.id),
          plantId: String(currentFieldNote.plantId),
        }),
      );
    }
  };

  const redirectToConditionNoteForm = () => {
    if (currentFieldNote) {
      history.push(
        generatePath(ROUTE_PATHS.CONDITION_NOTES_FORM, {
          id: instanceId,
          fieldNoteId: String(currentFieldNote.id),
          plantId: String(currentFieldNote.plantId),
        }),
      );
    }
  };

  useEffect(() => {
    if (fieldNoteId) {
      if (currentFieldNote?.plantId && !currentPlant) {
        dispatch(companiesPlantsActions.getPlant.request(currentFieldNote.plantId));
      }
    }
  }, [currentFieldNote, currentPlant, dispatch, fieldNoteId]);

  useEffect(() => {
    if (fieldNoteId) {
      dispatch(fieldNotesActions.getFieldNote.request(fieldNoteId));
    }
  }, [dispatch, fieldNoteId]);

  useEffect(() => {
    dispatch(actions.getInstance.request(instanceId));
    dispatch(actions.getInstanceCollaborators.request(instanceId));
    dispatch(actions.getInstanceComments.request({ instanceId }));

    return () => {
      dispatch(actions.getInstanceCollaborators.success([]));
      dispatch(actions.getInstanceComments.success([]));
      dispatch(actions.getInstance.success(undefined));
    };
  }, [instanceId, dispatch, history.location.search]);

  useEffect(() => {
    if (currentPlant && currentPlant.id) {
      dispatch(actions.getPlantProfiles.request({ plantId: currentPlant.id, filter: {} }));
    }
    if (currentPlant && currentPlant.companyId) {
      dispatch(companiesPlantsActions.getCompany.request(currentPlant.companyId));
    }
  }, [currentPlant, dispatch]);

  useEffect(() => {
    if (instance) {
      const material = instance.instanceComponentProperties.find(({ type }) => type === ComponentPropertyType.material);

      if (material !== undefined) {
        getEnvironments({ materials: [material.value.toString()] });
      }
    }
  }, [instance, getEnvironments]);

  const handlePdfLoad = useCallback(async () => {
    dispatch(actions.cleanInstancePdf());
  }, [dispatch]);

  useEffect(() => {
    if (pdf) {
      const file = new Blob([pdf], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const win = window.open(fileURL);
      if (win) {
        win.onload = handlePdfLoad;
      }
    }
  }, [pdf, handlePdfLoad]);

  const canEditInstance: Boolean = useMemo(
    () =>
      checkIsOneOfCollaborators(collaborators) ||
      checkPermissions([PERMISSION.INSTANCE_EDIT], { plant: currentPlant, instance }),
    [collaborators, currentPlant, instance],
  );

  const composedItemsName = useMemo(() => {
    if (instance?.instanceComponentProperties) {
      const materialName = instance.instanceComponentProperties.find(
        ({ type }) => type === ComponentPropertyType.material,
      )?.name;
      const disciplineName = instance.instanceComponentProperties.find(
        ({ type }) => type === ComponentPropertyType.discipline,
      )?.name;
      const typeName = instance.instanceComponentProperties.find(
        ({ type }) => type === ComponentPropertyType.materialType,
      )?.name;
      const environmentNames = instance.instanceComponentProperties
        .filter(({ type }) => type === ComponentPropertyType.environment)
        .filter(({ value: instanceMaterialId }) => environmentsOptions.some(({ id }) => instanceMaterialId === id))
        .map(({ name }) => name);

      return [materialName, typeName, disciplineName, ...environmentNames].filter(notEmpty).join(", ");
    }

    return "";
  }, [instance, environmentsOptions]);

  const creationDate = useMemo(
    () => (instance && instance.createdAt ? formatDateWithTime(new Date(instance.createdAt)) : ""),
    [instance],
  );

  const instanceProfiles: { id?: number; name?: string; collaboratorId?: number }[] = useMemo(() => {
    const profilesMap = new Map<number, Profile>();
    profiles.forEach((profile) => {
      profilesMap.set(profile.id, profile);
    });

    return collaborators
      .map((collaborator) => {
        const instanceProfile = profilesMap.get(collaborator.profileId);

        return instanceProfile
          ? {
              id: instanceProfile.id,
              name: `${instanceProfile.firstName} ${instanceProfile.lastName}`,
              collaboratorId: collaborator.id,
            }
          : {};
      })
      .filter((profile) => !!profile?.id);
  }, [collaborators, profiles]);

  useEffect(() => {
    if (instance && notificationType === NOTIFICATION_TYPES.TAGGED_USER_TO_INSTANCE && instanceProfiles && user) {
      if (!instanceProfiles.find((profile) => profile.id === user.id)) {
        dispatch(
          showNotification({
            message: `You are not tagged at ${instance?.displayName} anymore`,
            appearance: "error",
          }),
        );
      }
    }
  }, [user, notificationType, instanceProfiles, instance, dispatch]);

  useEffect(() => {
    if (notificationType === NOTIFICATION_TYPES.COMMENTED_INSTANCE && notificationTime && instance) {
      const getLatestCreatedAge = (array: { createdAt: string }[], fromTime: Date) =>
        array.map((comment) => fromTime.getTime() - new Date(comment.createdAt).getTime()).sort()[0];
      if (!instanceComments.length || getLatestCreatedAge(instanceComments, notificationTime) > 1000) {
        dispatch(
          showNotification({
            message: `The comment was deleted from ${instance?.displayName}`,
            appearance: "error",
          }),
        );
      }
    }
  }, [instanceComments, notificationType, notificationTime, dispatch, instance]);

  const instanceNotSelectedProfiles = useMemo(() => {
    const collaboratorsMap = new Map<number, InstanceCollaborator>();
    collaborators.forEach((collaborator) => {
      collaboratorsMap.set(collaborator.profileId, collaborator);
    });

    return profiles?.filter((profile) => !collaboratorsMap.get(profile.id));
  }, [profiles, collaborators]);

  const handleSaveFile = (
    isNewImageFlag: boolean,
    isNewVoiceNoteFlag: boolean,
    imgBase64: string,
    voiceNoteData: AudioUploadValue,
  ) => {
    if (fileToEdit && instance) {
      if (fileToEdit.parentId) {
        dispatch(
          actions.updateImage.request({
            instanceId: instance.id,
            fieldNoteId: fieldNoteId,
            image: {
              id: fileToEdit.id,
              srcAnnotated: imgBase64,
              imageType: fileToEdit.imageType,
              positionIndex: fileToEdit.positionIndex,
            },
            voiceNote: voiceNoteData,
            isNewImage: isNewImageFlag,
            isNewVoiceNote: isNewVoiceNoteFlag,
            callback: () => {},
          }),
        );
      } else {
        setVoiceNote(voiceNoteData);
        setIsNewImage(isNewImageFlag);
        setIsNewVoiceNote(isNewVoiceNoteFlag);
        setFileToSave({
          id: fileToEdit.id,
          src: imgBase64,
          imageType: fileToEdit.imageType,
          positionIndex: fileToEdit.positionIndex,
        });
      }
    }

    setFileToEdit(undefined);
  };

  const handleSaveOnlyAnnotated = () => {
    if (fileToSave && instance) {
      dispatch(
        actions.updateImage.request({
          instanceId: instance.id,
          fieldNoteId: fieldNoteId,
          image: {
            id: fileToSave.id,
            src: fileToSave.src,
            imageType: fileToSave.imageType,
            positionIndex: fileToSave.positionIndex,
          },
          voiceNote,
          isNewImage,
          isNewVoiceNote,
          callback: () => {},
        }),
      );
    }

    setFileToSave(undefined);
    setIsNewImage(false);
    setIsNewVoiceNote(false);
  };

  const handleSaveBoth = () => {
    if (fileToSave && instance) {
      dispatch(
        actions.updateImage.request({
          instanceId: instance.id,
          fieldNoteId: fieldNoteId,
          image: {
            id: fileToSave.id,
            srcAnnotated: fileToSave.src,
            imageType: fileToSave.imageType,
            positionIndex: fileToSave.positionIndex,
          },
          voiceNote,
          isNewImage,
          isNewVoiceNote,
          callback: () => {},
        }),
      );
    }

    setFileToSave(undefined);
    setIsNewImage(false);
    setIsNewVoiceNote(false);
  };

  const handleDeleteFile = () => {
    if (fileToDelete) {
      fileToDelete.type === "image"
        ? dispatch(actions.deleteImage.request({ imageId: fileToDelete.id, instanceId }))
        : dispatch(actions.deleteVideo.request({ videoId: fileToDelete.id, instanceId }));
    }
    setFileToDelete(null);
  };

  const handleDeleteInstance = () => {
    dispatch(actions.deleteInstance.request({ instanceId, callback: redirectToInstances }));
    setIsInstanceDeleteOpened(false);
  };

  const handleAddCollaborators = () => {
    setIsAddMembersOpened(true);
  };

  const addCollaborators = (collaboratorsIds: number[]) => {
    dispatch(actions.addInstanceCollaborators.request({ profileIds: collaboratorsIds, instanceId }));
    setIsAddMembersOpened(false);
  };

  const handleRemoveCollaborator = (collaboratorId?: number) => {
    if (collaboratorId) {
      setCollaboratorToDelete(undefined);
      dispatch(actions.deleteInstanceCollaborator.request({ profileId: collaboratorId, instanceId }));
    }
  };

  const handleAddComment = (values: CommentFormFields) => {
    if (values.id) {
      dispatch(
        actions.updateComment.request({
          ...values,
          instanceId,
          id: values.id,
          instanceComment: instanceComments?.find((instanceComment) => instanceComment.id === values.id),
        }),
      );
    } else {
      dispatch(actions.createComment.request({ ...values, instanceId }));
    }
    handleCancelCommenting();
  };

  const handleDeleteComment = (commentIdToDelete: number) => {
    dispatch(actions.deleteComment.request({ commentId: commentIdToDelete }));
  };

  const handleStartCommenting = () => {
    setImmediatePanel(Panels.Comments);
    setIsAddComment(true);
    contentWrapperRef?.current?.scrollTo(0, 0);
  };

  const handleCancelCommenting = () => {
    setIsAddComment(false);
  };

  const isConditionNotes = useMemo(() => {
    return !!instance?.instanceConditionOptions?.some((option) => option.isActive);
  }, [instance]);

  const toggleFieldGuideDisplayState = () => {
    if (showFieldGuide) {
      setCurrentPage(undefined);
    }

    setShowFieldGuide(!showFieldGuide);
  };

  const handleCopyPage = (data: { page?: number; areas?: FieldGuideReferenceArea[] }) => {
    const payload = {
      fieldGuideDocumentId: Number(fieldGuide?.id),
      instanceId,
      page: data.page,
      areas: data.areas,
      callback: toggleFieldGuideDisplayState,
    };
    dispatch(actions.addFieldGuideReferenceToInstance.request(payload));
  };

  const openFieldGuide = (reference: FieldGuideReference, degrId?: number) => {
    if (reference.page) {
      setCurrentPage(reference.page);
    }
    if (reference.fieldGuideReferenceAreas) {
      setHighlightAreas(reference.fieldGuideReferenceAreas);
    }
    toggleFieldGuideDisplayState();
  };

  const handleDownload = useCallback(async () => {
    if (instance) {
      dispatch(actions.getInstancePDF.request(instance.id));
    }
  }, [instance, dispatch]);

  const renderAddButtons = () => {
    const isGuide = Boolean(currentCompany?.fieldGuideDocuments?.length);

    return (
      <AddButton
        items={[
          ...(checkPermissions([PERMISSION.COMMENT_CREATE], { plant: currentPlant })
            ? [{ label: "Comment", icon: "/images/dashboard/comment.svg", onClick: handleStartCommenting }]
            : []),
          ...(isGuide && checkPermissions([PERMISSION.CONDITION_NOTE_CREATE], { plant: currentPlant, instance })
            ? [
                {
                  label: "Field guide reference",
                  icon: "/images/dashboard/book.svg",
                  onClick: toggleFieldGuideDisplayState,
                },
              ]
            : []),
          ...(checkPermissions([PERMISSION.CONDITION_NOTE_CREATE], { plant: currentPlant, instance })
            ? [
                {
                  label: "Condition note",
                  icon: "/images/dashboard/condition-note.svg",
                  onClick: redirectToConditionNoteForm,
                },
              ]
            : []),
          ...(checkPermissions([PERMISSION.TAG_MEMBER], { plant: currentPlant })
            ? [
                {
                  label: "Collaborator",
                  icon: "/images/dashboard/collaborator.svg",
                  onClick: handleAddCollaborators,
                },
              ]
            : []),
        ]}
      />
    );
  };

  const deleteFieldGuide = (referenceId?: number) => {
    if (referenceId) {
      setFieldGuideToDelete(undefined);
      dispatch(actions.deleteFieldGuideReferenceFromInstance.request({ referenceId, instanceId }));
    }
  };
  return (
    <>
      {!showFieldGuide ? (
        <div className="instance_view">
          <>
            <InstanceHeader
              backLabel={currentFieldNote?.displayName}
              onBackClick={redirectToInstances}
              rightButtons={
                <>
                  <AccessControl permissions={[PERMISSION.INSTANCE_DELETE]} plant={currentPlant} instance={instance}>
                    <div className="instances-remove-button" onClick={() => setIsInstanceDeleteOpened(true)}>
                      REMOVE
                    </div>
                  </AccessControl>
                  <div className="instances-export-button" onClick={handleDownload}>
                    <div className="instances-download-button" />
                    <div className="instances-download-button-label">DOWNLOAD</div>
                  </div>
                  {canEditInstance && (
                    <div className="instances-edit-button" onClick={redirectToEditInstance}>
                      EDIT INSTANCE
                    </div>
                  )}
                </>
              }
            />
            <div className={"instance_view_content"}>
              <div className="instance_view_content_leftPanel">
                <div className="leftPanel_section">
                  <div className="leftPanel_section_header">{instance?.displayName}</div>
                </div>
                <div className="leftPanel_section">
                  <div className="leftPanel_section_item">
                    <img src="/icons/general/location.svg" alt="location" />
                    <p>{instance?.buildingName}</p>
                  </div>
                  <div className="leftPanel_section_item">
                    <img src="/icons/general/component.svg" alt="location" />
                    <p>{instance?.nameOfComponent}</p>
                  </div>
                  <div className="leftPanel_section_item">
                    <img src="/icons/general/items.svg" alt="location" />
                    <p>{composedItemsName}</p>
                  </div>
                  <div className="leftPanel_section_item">
                    <img src="/icons/general/date.svg" alt="location" />
                    <p>{creationDate}</p>
                  </div>
                </div>
                <div className="leftPanel_section">
                  {!!instanceProfiles?.length && (
                    <div className="leftPanel_section_item">
                      <img src="/icons/general/tagged-members.svg" alt="location" />
                      <div className="instance_items">
                        {instanceProfiles.map((profile) => (
                          <div key={profile.id} className="instance_items_item">
                            <div className="instance_items_item_name">{profile.name}</div>
                            <AccessControl permissions={[PERMISSION.TAG_MEMBER]} plant={currentPlant}>
                              <div
                                className="instance_items_item_remove"
                                onClick={() => setCollaboratorToDelete(profile.collaboratorId)}
                              />
                            </AccessControl>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {instance && instance.fieldGuideReferences && instance.fieldGuideReferences.length ? (
                    <InstanceReferences
                      plant={currentPlant}
                      fieldGuideReferences={instance.fieldGuideReferences}
                      openReference={openFieldGuide}
                      deleteReference={setFieldGuideToDelete}
                    />
                  ) : null}
                  {isConditionNotes && (
                    <AccessControl permissions={[PERMISSION.CONDITION_NOTE_VIEW]} plant={currentPlant}>
                      <div className="leftPanel_section_item ">
                        <img className="iconCentering" src="/icons/general/round_checked.svg" alt="location" />
                        <div className="instance_items">
                          <div className="instance_items_item secondary">
                            <div className="instance_items_item_name" onClick={redirectToConditionNotes}>
                              See condition notes
                            </div>
                          </div>
                        </div>
                      </div>
                    </AccessControl>
                  )}
                </div>
              </div>
              <div className="instance_view_content_main" ref={contentWrapperRef}>
                <NavigationPanel
                  panels={[
                    Panels.Attachments,
                    ...(checkPermissions([PERMISSION.COMMENT_VIEW], { plant: currentPlant }) ? [Panels.Comments] : []),
                  ]}
                  className="instance_view_component"
                  immediatePanel={immediatePanel}
                >
                  <InstanceAttachments
                    instance={instance}
                    plant={currentPlant}
                    setFileToEdit={setFileToEdit}
                    setFileToDelete={setFileToDelete}
                    isPhotoUpdating={isPhotoUpdating}
                  />
                  <InstanceComments
                    isLoading={isCommentsLoading}
                    comments={instanceComments}
                    isAddComment={isAddComment}
                    handleAddComment={handleAddComment}
                    handleDeleteComment={handleDeleteComment}
                    handleCancelCommenting={handleCancelCommenting}
                    handleStartCommenting={handleStartCommenting}
                    plant={currentPlant}
                  />
                </NavigationPanel>
              </div>
              {renderAddButtons()}
            </div>
            {isAddMembersOpened && (
              <AddMembersModal
                onClose={() => setIsAddMembersOpened(false)}
                membersProfiles={instanceNotSelectedProfiles}
                onSubmit={addCollaborators}
              />
            )}
            {fileToEdit && (
              <ImageAnnotate
                header={fileToEdit.label}
                imageTypeId={fileToEdit.imageType}
                uid={fileToEdit.key || ""}
                src={fileToEdit.srcAnnotated || fileToEdit.src || ""}
                show={!!fileToEdit}
                voiceNote={fileToEdit.voiceNote || undefined}
                onCancel={() => setFileToEdit(undefined)}
                onClose={() => {}}
                onSave={handleSaveFile}
                maxWidth={680}
                maxHeight={520}
              />
            )}
            <Modal isShowing={!!fileToDelete} onClose={() => setFileToDelete(null)} boxPadding>
              <RemoveModalContent
                heading="Remove Photo"
                content="Are you sure you want to delete photo?"
                cancelText="Cancel"
                removeText="Remove"
                onClose={() => setFileToDelete(null)}
                onDelete={handleDeleteFile}
                isSubmitType
              />
            </Modal>
            <Modal
              isShowing={collaboratorToDelete !== undefined}
              onClose={() => setCollaboratorToDelete(undefined)}
              boxPadding
            >
              <RemoveModalContent
                heading="Remove Collaborator"
                content="Are you sure you want to remove Collaborator?"
                cancelText="Cancel"
                removeText="Remove"
                onClose={() => setCollaboratorToDelete(undefined)}
                onDelete={() => handleRemoveCollaborator(collaboratorToDelete)}
                isSubmitType
              />
            </Modal>
            <Modal
              isShowing={fieldGuideToDelete !== undefined}
              onClose={() => setFieldGuideToDelete(undefined)}
              boxPadding
            >
              <RemoveModalContent
                heading="Remove Reference"
                content="Are you sure you want to remove Reference?"
                cancelText="Cancel"
                removeText="Remove"
                onClose={() => setFieldGuideToDelete(undefined)}
                onDelete={() => deleteFieldGuide(fieldGuideToDelete)}
                isSubmitType
              />
            </Modal>
            <Modal isShowing={isInstanceDeleteOpened} onClose={() => setIsInstanceDeleteOpened(false)} boxPaddingThick>
              <RemoveModalContent
                heading="Remove Instance"
                content="Are you sure you want to delete instance?"
                cancelText="Cancel"
                removeText="Remove"
                onClose={() => setIsInstanceDeleteOpened(false)}
                onDelete={handleDeleteInstance}
                isSubmitType
              />
            </Modal>
            <Modal isShowing={!!fileToSave} onClose={() => setFileToSave(undefined)} boxPaddingThick>
              <ModalContent
                heading="Save Photo"
                content="Would you like to save the original photo?"
                minorActionText="Annotated only"
                mainActionText="Save both"
                onMinorAction={handleSaveOnlyAnnotated}
                onMainAction={handleSaveBoth}
              />
            </Modal>
          </>
        </div>
      ) : (
        <div className="field-guide-select-container">
          {fieldGuide ? (
            <PdfReader
              closeModalHandler={toggleFieldGuideDisplayState}
              currentPage={currentPage}
              highlightAreas={highlightAreas}
              createReferenceFromInstance={handleCopyPage}
              instanceName={instance?.displayName}
            />
          ) : (
            currentPlant?.companyId && <CompanyFieldGuides companyId={currentPlant.companyId} />
          )}
        </div>
      )}
    </>
  );
};

export default InstanceViewContainer;
