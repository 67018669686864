import { call, put, takeLatest } from "redux-saga/effects";

import { AnyAction } from "redux";
import { ROUTE_PATHS } from "../../../shared/routes";
import history from "../../../shared/utils/history";
import { actions as sharedActions } from "../../../shared/store";
import { tokenHandler } from "../../../shared/utils";
import api from "../api";
import { actions } from "./";

function* loginSaga({ payload }: AnyAction) {
  try {
    yield localStorage.clear();

    const authData = yield call(api.login, payload);
    yield put(actions.login.success());

    yield put(sharedActions.getUserData.request(authData.profile.id));

    yield tokenHandler.setAccessToken(authData.accessToken);
    yield tokenHandler.setRefreshToken(authData.refreshToken);
    yield tokenHandler.setProfile(authData.profile);
    yield tokenHandler.setIsLicenseAgreed(false);

    yield history.push(ROUTE_PATHS.DASHBOARD);
  } catch (error) {
    const code = error.code;
    yield put(
      actions.login.failure({
        code,
        message: code === 401 ? "Entered EPRI Username and Password combination is incorrect" : error.message,
      }),
    );
  }
}

function* logoutSaga() {
  yield localStorage.clear();
  yield put(actions.logout.success());
  yield history.push(ROUTE_PATHS.AUTH_LOGIN);
  yield tokenHandler.setIsLicenseAgreed(false);
}

function* epriLoginStatusSaga({ payload }: AnyAction) {
  try {
    yield localStorage.clear();

    const authData = yield call(api.epriLoginStatus, payload);
    yield put(actions.epriLoginStatus.success());

    yield put(sharedActions.getUserData.request(authData.profile.id));

    yield tokenHandler.setAccessToken(authData.accessToken);
    yield tokenHandler.setRefreshToken(authData.refreshToken);
    yield tokenHandler.setProfile(authData.profile);
    yield tokenHandler.setIsLicenseAgreed(false);

    yield history.push(ROUTE_PATHS.DASHBOARD);
  } catch (error) {
    const code = error.code;
    yield put(
      actions.epriLoginStatus.failure({
        code,
        message: code === 401 ? "Unable to get user profile data" : error.message,
      }),
    );
  }
}

function* requestPasswordSaga({ payload }: AnyAction) {
  try {
    yield call(api.forgotPassword, payload);

    yield put(actions.requestPassword.success());
  } catch (error) {
    yield put(actions.requestPassword.failure(error));
  }
}

function* submitPasswordSaga({ payload }: AnyAction) {
  try {
    yield put(actions.submitPassword.success());
  } catch (error) {
    yield put(actions.submitPassword.failure(error));
  }
}

function* authSagas() {
  yield takeLatest(actions.epriLoginStatus.request, epriLoginStatusSaga);
  yield takeLatest(actions.login.request, loginSaga);
  yield takeLatest(actions.requestPassword.request, requestPasswordSaga);
  yield takeLatest(actions.submitPassword.request, submitPasswordSaga);
  yield takeLatest(actions.logout.request, logoutSaga);
}

export default authSagas;
