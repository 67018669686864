export enum AuthActionTypes {
  LOGIN = "@@AUTH/LOGIN",
  LOGIN_SUCCESS = "@@AUTH/LOGIN_SUCCESS",
  LOGIN_FAILURE = "@@AUTH/LOGIN_FAILURE",

  EPRI_LOGIN = "@@AUTH/EPRI_LOGIN",
  EPRI_LOGIN_SUCCESS = "@@AUTH/EPRI_LOGIN_SUCCESS",
  EPRI_LOGIN_FAILURE = "@@AUTH/EPRI_LOGIN_FAILURE",

  EPRI_LOGIN_STATUS = "@@AUTH/EPRI_LOGIN_STATUS",
  EPRI_LOGIN_STATUS_SUCCESS = "@@AUTH/EPRI_LOGIN_STATUS_SUCCESS",
  EPRI_LOGIN_STATUS_FAILURE = "@@AUTH/EPRI_LOGIN_STATUS_FAILURE",

  LOGOUT = "@@AUTH/LOGOUT",
  LOGOUT_SUCCESS = "@@AUTH/LOGOUT_SUCCESS",
  LOGOUT_FAILURE = "@@AUTH/LOGOUT_FAILURE",

  REQUEST_PASSWORD = "@@AUTH/REQUEST_PASSWORD",
  REQUEST_PASSWORD_SUCCESS = "@@AUTH/REQUEST_PASSWORD_SUCCESS",
  REQUEST_PASSWORD_FAILURE = "@@AUTH/REQUEST_PASSWORD_FAILURE",

  GET_USER_HASH = "@@AUTH/GET_USER_HASH",
  GET_USER_HASH_SUCCESS = "@@AUTH/GET_USER_HASH_SUCCESS",
  GET_USER_HASH_FAILURE = "@@AUTH/GET_USER_HASH_FAILURE",

  SUBMIT_PASSWORD = "@@AUTH/SUBMIT_PASSWORD",
  SUBMIT_PASSWORD_SUCCESS = "@@AUTH/SUBMIT_PASSWORD_SUCCESS",
  SUBMIT_PASSWORD_FAILURE = "@@AUTH/SUBMIT_PASSWORD_FAILURE",

  BACK_TO_FORGOT_PASSWORD_FORM = "@@AUTH/BACK_TO_FORGOT_PASSWORD_FORM",
}
