import { createSelector } from "reselect";

import { RootReducer } from "../../../shared/interfaces";
import { CompaniesPlants, Company, Plant } from "../../../shared/models";
import { FieldGuideDocument } from "../../../shared/models/FieldGuideDocument";
import { sortValues } from "../../../shared/utils";

const selectCompaniesPlants = (state: RootReducer) => state.companiesPlants;

export const getCompanies = () =>
  createSelector(selectCompaniesPlants, (state) =>
    [...state.companiesPlants].sort((a: Company, b: Company) => sortValues(a, b)),
  );
export const getCompaniesPlants = () => createSelector(selectCompaniesPlants, (state) => state.companiesPlants);
export const getCompaniesPlantsByFieldGuide = (fieldGuideDocumentId: number) =>
  createSelector(selectCompaniesPlants, (state) =>
    state.companiesPlants.filter((company: CompaniesPlants) =>
      company.fieldGuideDocuments?.map((fgd: FieldGuideDocument) => fgd.id).includes(fieldGuideDocumentId),
    ),
  );
export const getCurrentPlant = () => createSelector(selectCompaniesPlants, (state) => state.currentPlant);
export const getCurrentCompany = () => createSelector(selectCompaniesPlants, (state) => state.currentCompany);
export const getCurrentPlantDocuments = () =>
  createSelector(selectCompaniesPlants, (state) => state.currentPlantDocuments);
export const getPlants = () =>
  createSelector(selectCompaniesPlants, (state) =>
    state.companiesPlants
      .map((company) => company.plants || [])
      .flat()
      .sort((a: Plant, b: Plant) => sortValues(a, b)),
  );
