import React, { useCallback, useMemo, useState } from "react";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { isTomorrow } from "date-fns";

import { BinaryImage } from "../../../../shared/components/BinaryImage";
import { Image } from "../../../../shared/models";
import { formatDate } from "../../../../shared/utils";
import { AppLoader } from "../../../../shared/components";
import { setUpdateLayout } from "../../../../shared/store/actions";

import "./index.scss";

interface ImageCardProps {
  imageType: string;
  reviewStatus: string;
  image: Image;
  selected: boolean;
  showCheckbox: boolean;
  imageSelectHandler: (document: Image, isSelected: boolean) => void;
  onClick: Function;
}

const ImageCard: React.FunctionComponent<ImageCardProps> = (props) => {
  const { image, imageType, reviewStatus, selected, imageSelectHandler, onClick, showCheckbox } = props;

  const dispatch = useDispatch();

  const formattedDate = useMemo(() => {
    return image.createdAt ? formatDate(new Date(image.createdAt)) : "";
  }, [image.createdAt]);

  const formattedExpiresDate = useMemo(() => {
    return image.expiresAt ? (
      <div
        className={classnames("image-card-container-expired", {
          error: isTomorrow(new Date(image.expiresAt)),
        })}
      >
        Expiration Date: {formatDate(new Date(image.expiresAt))}
      </div>
    ) : null;
  }, [image.expiresAt]);

  const [hover, setHover] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const handleLoadImage = useCallback(() => {
    setLoading(false);
    dispatch(setUpdateLayout(true));
  }, [dispatch]);

  return (
    <div
      className="image-card"
      onClick={() => onClick()}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={classnames("image-card-container", { loading: loading })}>
        {loading && <AppLoader />}
        <div className="image-card-container-image">
          <BinaryImage alt="image" isLazy={true} onLoad={handleLoadImage} uid={image.key} />
          {image?.voiceNote && (
            <div className="image-card-container-audio">
              <img src="/icons/general/speaker.svg" alt="has audio file" />
            </div>
          )}
        </div>
        {(showCheckbox || hover) && (
          <div
            className={classnames("image-card-container-mark", { checked: selected })}
            onClick={(e) => {
              e.stopPropagation();
              imageSelectHandler(image, !selected);
            }}
          ></div>
        )}
        <div className="image-card-container-details">
          <div className="image-card-title">
            {imageType}
            <div className={`image-card-status ${reviewStatus?.toLowerCase()}`}>{reviewStatus}</div>
          </div>
          <div className="image-card-container-instance">{image.instanceName}</div>
          <div className="image-card-container-divider"></div>
          <div className="image-card-container-created">
            Created: {formattedDate} by {image.createdByName}
          </div>
          <div className="image-card-container-expired">{formattedExpiresDate}</div>
        </div>
      </div>
    </div>
  );
};

export default ImageCard;
