import { ATTACHMENT_TYPES, ATTACHMENT_TYPES_MAP } from "../constants";
import { Attachment } from "../models";
import { AttachmentParsingValue, AttachmentUploadValue } from "../formComponents/common/AttachmentsUpload";

export const mapToDefaultAttachmentTypes = (
  attachments?: Attachment[],
  isAdditional?: boolean,
): AttachmentUploadValue[] =>
  [
    ATTACHMENT_TYPES.STRAIGHT_ON,
    ATTACHMENT_TYPES.LEFT_SIDE,
    ATTACHMENT_TYPES.RIGHT_SIDE,
    ATTACHMENT_TYPES.ZOOMED_OUT,
    ATTACHMENT_TYPES.COMPONENT_TAG,
    ATTACHMENT_TYPES.ATTACHMENT,
  ]
    .map((type) => getAttachmentDetails(type, attachments, isAdditional))
    .flat(1);

const getAttachmentDetails = (
  type: ATTACHMENT_TYPES,
  attachments?: Attachment[],
  isAdditional?: boolean,
): AttachmentUploadValue | AttachmentUploadValue[] => {
  const imagesByType: AttachmentParsingValue[] | undefined = attachments?.filter((att) => {
    return (
      (type === ATTACHMENT_TYPES.ATTACHMENT && att.type === "video") ||
      ("imageTypeId" in att && att.imageTypeId === ATTACHMENT_TYPES_MAP[type].id)
    );
  });

  if (type === ATTACHMENT_TYPES.ATTACHMENT && isAdditional) {
    imagesByType?.push({ key: "" });
  }
  return imagesByType?.length
    ? imagesByType.map((att) => ({
        key: att.key,
        imageType: type,
        id: att.id,
        parentId: att.parentId,
        positionIndex: att.positionIndex,
        voiceNote: att.voiceNote,
        fileType: att.fileType,
        type: att?.type || "image",
      }))
    : { key: "", imageType: type, type: "image" };
};
