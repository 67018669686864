import React from "react";
import classnames from "classnames";

import classNames from "./modal.module.scss";

interface ModalContentProps {
  heading: string;
  content: string;
  onMinorAction: () => void;
  onMainAction: () => void;
  minorActionText: string;
  mainActionText: string;
  isSubmitType?: boolean;
}

const ModalContent = (props: ModalContentProps) => {
  return (
    <div className={classNames.modalContent}>
      <div className={classNames.modalHeading}>{props.heading}</div>
      <div className={classNames.modalContent}>{props.content}</div>
      <div className={classNames.modalActions}>
        <button className={classNames.btn} onClick={props.onMinorAction}>
          {props.minorActionText}
        </button>
        <button
          className={classnames(classNames.btn, {
            [classNames.whiteOrange]: props.isSubmitType,
            [classNames.whiteBlue]: !props.isSubmitType,
          })}
          type="submit"
          onClick={props.onMainAction}
        >
          {props.mainActionText}
        </button>
      </div>
    </div>
  );
};
export default ModalContent;
