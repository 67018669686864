import React, { useMemo, useRef, useState } from "react";
import classnames from "classnames";

import { formatTimeToMinutesSeconds } from "../../utils";

import "./audio.scss";

// doc here: https://www.w3schools.com/tags/ref_av_dom.asp

interface AudioProps {
  src?: string;
  file?: File;
}

const Audio: React.FunctionComponent<AudioProps> = (props) => {
  const { src, file } = props;

  const fileUrl = useMemo(() => {
    if (src) {
      return src;
    }
    if (file) {
      return URL.createObjectURL(file);
    }
    return undefined;
  }, [src, file]);

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const [isPaused, setIsPaused] = useState(true);
  const [audioDuration, setAudioDuration] = useState("");
  const [currentTime, setCurrentTime] = useState("00:00");

  const togglePlay = () => {
    if (audioRef?.current) {
      const { paused } = audioRef.current;
      if (paused) {
        audioRef.current?.play();
      } else {
        audioRef.current?.pause();
      }
      setIsPaused(!paused);
    }
  };

  const loaded = () => {
    if (audioRef?.current) {
      const durationInSeconds = audioRef?.current.duration;
      const minutes = String(Math.floor(durationInSeconds / 60)).padStart(2, "0");
      const seconds = String(Math.floor(durationInSeconds % 60)).padStart(2, "0");

      setAudioDuration(`${minutes}:${seconds}`);
    }
  };
  const currentTimeUpdated = () => {
    if (audioRef?.current) {
      setCurrentTime(formatTimeToMinutesSeconds(audioRef?.current.currentTime));
    }
  };

  return (
    <div className="audio">
      <div className={classnames("audio-btn", { playing: !isPaused })} onClick={togglePlay} />
      <audio
        ref={audioRef}
        controls
        src={fileUrl}
        controlsList="nodownload noremoteplayback"
        onLoadedData={loaded}
        onEnded={(e) => setIsPaused(true)}
        onTimeUpdate={currentTimeUpdated}
      />
      <div className="audio-duration">{audioDuration ? `${currentTime} / ${audioDuration}` : "Loading..."}</div>
    </div>
  );
};

export default Audio;
