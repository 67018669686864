import React, { ComponentClass, FunctionComponent } from "react";
import { Redirect } from "react-router";

import { UserGuideContainer, UsersContainer } from "../../containers/Users";
import { CompaniesPlantsContainer, PlantDetailsContainer } from "../../containers/CompaniesPlants";
import { FieldNotesContainer } from "../../containers/FieldNotes";
import {
  InstanceFormContainer,
  InstancesConcernContainer,
  InstancesConditionNoteFormContainer,
  InstancesConditionNotesContainer,
  InstancesListContainer,
  InstanceViewContainer,
  FieldGuideReferenceContainer,
} from "../../containers/Instances";
import { ROUTE_PATHS } from "./routePaths";
import { PERMISSION } from "../constants";
import { AboutUs, LicenseAgreement, Tutorial } from "../../containers/AboutUs";
import { ImageReviewContainer } from "../../containers/Images";
import { ROLE } from "../constants/role";
import FieldGuideSelectContainer from "../../containers/Instances/containers/FieldGuideSelectContainer/FieldGuideSelectContainer";

interface RoutesMapItem {
  path: string;
  // eslint-disable-next-line
  component: ComponentClass<any, any> | FunctionComponent<any>;
  exact: boolean;
  authenticated: boolean;
  permissions?: string[];
  roles?: string[];
}

export const ROUTES_MAP: RoutesMapItem[] = [
  {
    path: ROUTE_PATHS.USERS_LIST,
    component: UsersContainer,
    exact: true,
    authenticated: true,
    permissions: [PERMISSION.USER_VIEW],
  },
  {
    path: ROUTE_PATHS.ABOUT,
    component: AboutUs,
    exact: true,
    authenticated: true,
  },
  {
    path: ROUTE_PATHS.LICENSE_AGREEMENT,
    component: LicenseAgreement,
    exact: true,
    authenticated: true,
  },
  {
    path: ROUTE_PATHS.TUTORIAL,
    component: Tutorial,
    exact: true,
    authenticated: true,
  },
  {
    path: ROUTE_PATHS.COMPANIES_DASHBOARD,
    component: CompaniesPlantsContainer,
    exact: true,
    authenticated: true,
  },
  {
    path: ROUTE_PATHS.FIELD_NOTES_DASHBOARD,
    component: FieldNotesContainer,
    exact: true,
    authenticated: true,
    permissions: [PERMISSION.FIELDNOTE_VIEW],
  },
  {
    path: ROUTE_PATHS.PLANT_DETAILS_DASHBOARD,
    component: PlantDetailsContainer,
    exact: true,
    authenticated: true,
    permissions: [PERMISSION.PLANT_VIEW],
  },
  {
    path: ROUTE_PATHS.INSTANCES_DASHBOARD,
    component: InstancesListContainer,
    exact: true,
    authenticated: true,
    permissions: [PERMISSION.INSTANCE_VIEW],
  },
  {
    path: ROUTE_PATHS.IMAGE_REVIEWS_DASHBOARD,
    component: ImageReviewContainer,
    exact: true,
    authenticated: true,
    permissions: [],
    roles: [ROLE.CORPORATE_ADMIN, ROLE.SITE_MANAGER, ROLE.SUPER_ADMIN],
  },
  {
    path: ROUTE_PATHS.CONDITION_NOTES,
    component: InstancesConditionNotesContainer,
    exact: true,
    authenticated: true,
    permissions: [PERMISSION.CONDITION_NOTE_VIEW],
  },
  {
    path: ROUTE_PATHS.CONDITION_NOTE_CONCERN,
    component: InstancesConcernContainer,
    exact: true,
    authenticated: true,
    permissions: [PERMISSION.CONDITION_NOTE_VIEW],
  },
  {
    path: ROUTE_PATHS.CONDITION_NOTES_FORM,
    component: InstancesConditionNoteFormContainer,
    exact: true,
    authenticated: true,
    permissions: [PERMISSION.CONDITION_NOTE_CREATE],
  },
  {
    path: ROUTE_PATHS.CREATE_INSTANCE,
    component: InstanceFormContainer,
    exact: true,
    authenticated: true,
    permissions: [PERMISSION.INSTANCE_CREATE],
  },
  {
    path: ROUTE_PATHS.EDIT_INSTANCE,
    component: InstanceFormContainer,
    exact: true,
    authenticated: true,
    permissions: [PERMISSION.INSTANCE_EDIT],
  },

  {
    path: ROUTE_PATHS.INSTANCE_VIEW,
    component: InstanceViewContainer,
    exact: true,
    authenticated: true,
    permissions: [PERMISSION.INSTANCE_VIEW],
  },
  {
    path: ROUTE_PATHS.FIELD_GUIDES,
    component: FieldGuideSelectContainer,
    authenticated: true,
    exact: true,
  },
  {
    path: ROUTE_PATHS.FIELD_GUIDE_REFERENCE,
    component: FieldGuideReferenceContainer,
    authenticated: true,
    exact: true,
  },
  {
    path: ROUTE_PATHS.DASHBOARD,
    component: () => <Redirect to={ROUTE_PATHS.COMPANIES_DASHBOARD} />,
    exact: true,
    authenticated: true,
    permissions: [PERMISSION.COMPANY_VIEW],
  },
  {
    path: ROUTE_PATHS.USER_GUIDE,
    component: UserGuideContainer,
    exact: true,
    authenticated: true,
  },
];
