import React from "react";
import { Document, Plant } from "../../../../../../shared/models";
import { DocumentCard } from "../";
import "./index.scss";
import { AppLoader } from "../../../../../../shared/components";

interface DocumentCardListProps {
  documents: Document[];
  plant: Plant | null;
  onEditDocument: (document: Document) => void;
  onRemoveDocument: (document: Document) => void;
  search: string;
  isLoading: boolean;
}

const DocumentCardList: React.FunctionComponent<DocumentCardListProps> = (props) => {
  const { documents, search, plant, onRemoveDocument, onEditDocument, isLoading } = props;

  return (
    <>
      {isLoading ? (
        <AppLoader />
      ) : (
        <>
          {documents.map((document: Document) => (
            <DocumentCard
              key={document.id}
              documentFile={document}
              plant={plant}
              onRemove={() => onRemoveDocument(document)}
              onEdit={() => onEditDocument(document)}
            />
          ))}
          {documents.length === 0 &&
            (!search.length ? <div className="document-list-empty" /> : <div className="no-matches-found" />)}
        </>
      )}
    </>
  );
};

export default DocumentCardList;
