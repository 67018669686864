import React from "react";
import classnames from "classnames";

import classNames from "./removeModal.module.scss";

interface RemoveModalContentProps {
  heading: string;
  content: string;
  onClose: () => void;
  onDelete: () => void;
  cancelText?: string;
  removeText?: string;
  isSubmitType?: boolean;
}

const RemoveModalContent = (props: RemoveModalContentProps) => {
  return (
    <div className={classNames.modalContent}>
      <div className={classNames.modalHeading}>{props.heading}</div>
      <div className={classNames.modalContent}>{props.content}</div>
      <div className={classNames.modalActions}>
        <button className={classNames.btn} onClick={props.onClose}>
          {props.cancelText ? props.cancelText : "CANCEL"}
        </button>
        <button
          className={classnames(classNames.btn, {
            [classNames.whiteOrange]: props.isSubmitType,
            [classNames.whiteBlue]: !props.isSubmitType,
          })}
          type="submit"
          onClick={props.onDelete}
        >
          {props.removeText ? props.removeText : "REMOVE"}
        </button>
      </div>
    </div>
  );
};
export default RemoveModalContent;
