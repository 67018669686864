import React from "react";
import "./index.scss";

function AppInitLoader() {
  return (
    <div className="theme-loader">
      <div className="ball-scale">
        <div />
      </div>
    </div>
  );
}

export default AppInitLoader;
