import React, { useCallback, useMemo } from "react";
import { FieldArray, FieldArrayRenderProps, Form, FormikProps } from "formik";

import { FieldItem, FieldItemType } from "./interfaces/formInterfaces";
import {
  generatePasswordField,
  generateCheckbox,
  generateTextField,
  generateSelect,
  generateMultiSelect,
  generateAttachmentSelect,
  generateTextarea,
  generateSimpleImage,
  generateAudioUpload,
  generateDropFileUpload,
} from "./formRenderers/formRenderers";

// eslint-disable-next-line
const FieldsMap = new Map<FieldItemType, React.FunctionComponent<any>>([
  [FieldItemType.TEXT, generateTextField],
  [FieldItemType.PASSWORD, generatePasswordField],
  [FieldItemType.CHECKBOX, generateCheckbox],
  [FieldItemType.SELECT, generateSelect],
  [FieldItemType.MULTISELECT, generateMultiSelect],
  [FieldItemType.ATTACHMENT, generateAttachmentSelect],
  [FieldItemType.TEXTAREA, generateTextarea],
  [FieldItemType.SIMPLE_IMAGE, generateSimpleImage],
  [FieldItemType.AUDIO, generateAudioUpload],
  [FieldItemType.DROP_UPLOAD_FILE, generateDropFileUpload],
]);

// eslint-disable-next-line
type AnyFormikProps = FormikProps<any>;

interface FormGeneratorProps<T> {
  className?: string;
  fields: FieldItem[];
  formikProps: FormikProps<T>;
  children?: React.ReactNode;
  FieldsWrapper?: React.ComponentType;
}

const renderField = (
  field: FieldItem,
  index: number,
  formikProps: AnyFormikProps,
  formikFieldArrayHelpers?: FieldArrayRenderProps,
) => {
  const FieldComponent = FieldsMap.get(field.type);

  if (!FieldComponent) {
    return null;
  }

  return (
    <FieldComponent
      key={index}
      {...field}
      formikProps={formikProps}
      formikFieldArrayHelpers={formikFieldArrayHelpers}
    />
  );
};

const renderFieldsList = (fieldItem: FieldItem, formikProps: AnyFormikProps) => {
  const { name } = fieldItem;

  if (!formikProps.values[name]) {
    return null;
  }

  return (
    <FieldArray
      key={name}
      name={name}
      render={(formikFieldArrayHelpers) =>
        // eslint-disable-next-line
        formikProps.values[name].map((value: any, index: number) =>
          renderField({ ...fieldItem, name: `${name}.${index}`, index }, index, formikProps, formikFieldArrayHelpers),
        )
      }
    />
  );
};

const FormGenerator = <T,>(props: FormGeneratorProps<T>) => {
  const { formikProps, className, fields, children, FieldsWrapper } = props;

  const generateFields: Function = useCallback(
    (field: FieldItem, index: number) => {
      if (field.isFieldsList) {
        return renderFieldsList(field, formikProps);
      } else {
        return renderField(field, index, formikProps);
      }
    },

    [formikProps],
  );
  const mappedFields = useMemo(() => {
    return fields.map((field, index) => generateFields(field, index));
  }, [fields, generateFields]);

  return (
    <Form onSubmit={formikProps.handleSubmit} className={className}>
      {FieldsWrapper ? <FieldsWrapper>{mappedFields}</FieldsWrapper> : mappedFields}
      {children}
    </Form>
  );
};

export default FormGenerator;
