import React from "react";
import { Profile } from "../../../../../../shared/models";
import "./index.scss";

interface CollaboratorCardProps {
  collaborator: Profile;
}

const CollaboratorCard: React.FunctionComponent<CollaboratorCardProps> = (props) => {
  const { collaborator } = props;

  return (
    <div className="collaborator-container">
      <div className="left-content">
        <img src="/icons/general/profile_image.svg" alt="member" />
      </div>
      <div className="right-content">
        <div className="name-block">{`${collaborator.firstName} ${collaborator.lastName}`}</div>
        <div className="email-block">{collaborator.email}</div>
      </div>
    </div>
  );
};

export default CollaboratorCard;
