import React, { CSSProperties } from "react";
import ReactSelect, { Props, StylesConfig } from "react-select";
import classnames from "classnames";

import { mainTheme } from "../../../../../theme";
import "./select.scss";

const customStyles: StylesConfig = {
  control: (provided: CSSProperties) => {
    return {
      ...provided,
      borderRadius: "8px",
      fontSize: "14px",
      fontFamily: mainTheme.globalFontFamily,
      background: mainTheme.grayColor,
    };
  },
};

const SelectInput = (props: Props) => (
  <ReactSelect
    className={classnames("customSelect", { disabled: props.isDisabled })}
    styles={customStyles}
    {...props}
    components={{
      IndicatorSeparator: () => null,
    }}
  />
);

export default SelectInput;
