import * as Yup from "yup";
import { Option } from "../../../../shared/interfaces";
import { ComponentPropertyType, ConditionOption, Instance } from "../../../../shared/models";
import { AttachmentUploadValue } from "../../../../shared/formComponents/common";
import { mapToDefaultAttachmentTypes } from "../../../../shared/utils/attachmentFiles";
import { FieldItem, FieldItemType } from "../../../../shared/formComponents";

export interface InstanceFormFields {
  id?: number;
  displayName: string;
  buildingName: string;
  elevation: string;
  nameOfComponent: string;
  attachments: AttachmentUploadValue[];
  componentDisciplineId?: string;
  componentFormId?: string;
  componentTypeId?: string;
  componentEnvironmentIds: Option[];

  redirectErrors: { name: string; text: string }[];
  isComponentTypeIdEmpty: boolean;
  areEnvironmentIdsEmpty: boolean;
  isComponentFormIdEmpty: boolean;
  // eslint-disable-next-line
  [key: string]: any;
}

export const validationSchema = Yup.object<InstanceFormFields>().shape({
  displayName: Yup.string().max(100, "Instance Name is too long").required("Instance Name is required"),
  buildingName: Yup.string().max(100, "Building Name is too long").required("Building Name is required"),
  elevation: Yup.string()
    .max(20, "Elevation of component name field is too long")
    .required("Elevation of component is required"),
  nameOfComponent: Yup.string().max(100, "Name of Component is too long").required("Name is required"),
  componentDisciplineId: Yup.number().when("redirectErrors", (redirectErrors: { name: string; text: string }[]) => {
    const redirectError = redirectErrors[0]?.text;
    return redirectError ? Yup.number().oneOf([], redirectError) : Yup.number().required("Discipline is required");
  }),
  componentFormId: Yup.number()
    .nullable()
    .when("isComponentFormIdEmpty", (isComponentFormIdEmpty: boolean) => {
      return isComponentFormIdEmpty
        ? Yup.number().nullable()
        : Yup.number().nullable().required("Material is required");
    }),
  componentTypeId: Yup.number()
    .nullable()
    .when("isComponentTypeIdEmpty", (isComponentTypeIdEmpty: boolean) => {
      return isComponentTypeIdEmpty ? Yup.number().nullable() : Yup.number().nullable().required("Type is required");
    }),
  componentEnvironmentIds: Yup.array().when("areEnvironmentIdsEmpty", (areEnvironmentIdsEmpty: boolean) => {
    return areEnvironmentIdsEmpty ? Yup.array() : Yup.array().min(1, "Environment is required");
  }),
  photos: Yup.array().of(Yup.object().shape({ src: Yup.string(), imageTypeId: Yup.number() })),
});

export const prepareFormValues = (
  instance?: Instance,
  savedEnvironmentsOptions?: (ConditionOption & { isSecondary?: boolean })[],
  isComponentTypeIdEmpty?: boolean,
  isComponentFormIdEmpty?: boolean,
): InstanceFormFields => {
  const savedEnvironmentsOptionsMap = new Map<
    number,
    {
      name: string;
      isSecondary?: boolean;
    }
  >();

  if (savedEnvironmentsOptions) {
    savedEnvironmentsOptions.forEach((savedEnvironmentsOption) => {
      savedEnvironmentsOptionsMap.set(savedEnvironmentsOption.id, {
        name: savedEnvironmentsOption.name,
        isSecondary: !!savedEnvironmentsOption.isSecondary,
      });
    });
  }

  const componentDisciplineId =
    instance?.instanceComponentProperties
      ?.find(({ type }) => type === ComponentPropertyType.discipline)
      ?.value.toString() || undefined;
  const componentFormId =
    instance?.instanceComponentProperties
      ?.find(({ type }) => type === ComponentPropertyType.material)
      ?.value.toString() || undefined;
  const componentTypeId =
    instance?.instanceComponentProperties
      ?.find(({ type }) => type === ComponentPropertyType.materialType)
      ?.value.toString() || undefined;
  const componentEnvironmentIds =
    instance?.instanceComponentProperties
      ?.filter(
        ({ type, value }) => type === ComponentPropertyType.environment && savedEnvironmentsOptionsMap.get(value),
      )
      .map(({ value }) => ({
        value: value.toString(),
        label: savedEnvironmentsOptionsMap.get(value)?.name || "",
        isSecondary: savedEnvironmentsOptionsMap.get(value)?.isSecondary,
      })) || [];

  return {
    id: instance?.id || undefined,
    displayName: instance?.displayName || "",
    buildingName: instance?.buildingName || "",
    elevation: instance?.elevation || "",
    nameOfComponent: instance?.nameOfComponent || "",
    attachments: mapToDefaultAttachmentTypes(instance?.attachments, true),
    componentDisciplineId,
    componentFormId,
    componentTypeId,
    componentEnvironmentIds,
    redirectErrors: [],
    isComponentTypeIdEmpty: isComponentTypeIdEmpty || false,
    isComponentFormIdEmpty: isComponentFormIdEmpty || false,
    areEnvironmentIdsEmpty: false,
  };
};

export interface FieldsListType {
  name: FieldItem[];
  location: FieldItem[];
  type: FieldItem[];
  attachments: FieldItem[];
}

interface ItemType {
  id: number;
  name: string;
  isSecondary?: boolean;
}

const mapItemsToOptions = (items: ItemType[]): Option[] =>
  items.map((item) => ({ value: `${item.id}`, label: item.name, isSecondary: item.isSecondary }));

export const mapFieldsList = (
  disciplines: ItemType[],
  materials: ItemType[],
  types: ItemType[],
  environments: ItemType[],
): FieldsListType => ({
  name: [
    {
      type: FieldItemType.TEXT,
      name: "displayName",
      label: "Instance Name",
      wrapperClass: "instance_form_panels_field",
    },
  ],
  location: [
    {
      type: FieldItemType.TEXT,
      name: "buildingName",
      label: "Building Name",
      wrapperClass: "instance_form_panels_field",
    },
    {
      type: FieldItemType.TEXT,
      name: "elevation",
      label: "Enter elevation of component",
      wrapperClass: "instance_form_panels_field",
    },
  ],
  type: [
    {
      type: FieldItemType.TEXT,
      name: "nameOfComponent",
      label: "Name",
      wrapperClass: "instance_form_panels_field",
    },
    {
      type: FieldItemType.SELECT,
      name: "componentDisciplineId",
      options: mapItemsToOptions(disciplines),
      label: "Discipline",
      placeholder: "Select discipline",
      wrapperClass: "instance_form_panels_field",
    },
    {
      type: FieldItemType.MULTISELECT,
      name: "componentEnvironmentIds",
      options: mapItemsToOptions(environments),
      label: "Environment",
      placeholder: "Select environment",
      wrapperClass: "instance_form_panels_field",
    },
    {
      type: FieldItemType.SELECT,
      name: "componentFormId",
      options: mapItemsToOptions(materials),
      label: "Material",
      placeholder: "Select material",
      wrapperClass: "instance_form_panels_field",
    },
    {
      type: FieldItemType.SELECT,
      name: "componentTypeId",
      options: mapItemsToOptions(types),
      label: "Type",
      placeholder: "Select type",
      wrapperClass: "instance_form_panels_field",
    },
  ],
  attachments: [
    {
      type: FieldItemType.ATTACHMENT,
      name: "attachments",
      isFieldsList: true,
    },
  ],
});

export const isValuesChanged = (values: InstanceFormFields, initialValues: InstanceFormFields) => {
  const { redirectErrors, attachments, componentEnvironmentIds, ...primitiveValues } = values;

  const isPrimitiveValuesChanged = Object.keys(primitiveValues).some(
    (key) => primitiveValues[key] !== initialValues[key],
  );
  const isEnvironmentsChanged =
    componentEnvironmentIds.some(
      ({ value }) => !initialValues.componentEnvironmentIds.find(({ value: initialValue }) => initialValue === value),
    ) || componentEnvironmentIds?.length !== initialValues.componentEnvironmentIds?.length;

  const isPhotosChanged =
    attachments.some(
      ({ src, srcAnnotated, imageType }) =>
        !initialValues.attachments.find(
          ({ src: initialSrc, srcAnnotated: initialSrcAnnotated, imageType: initialImageType }) =>
            initialSrc === src && initialSrcAnnotated === srcAnnotated && initialImageType === imageType,
        ),
    ) || attachments?.length !== initialValues.photos?.length;

  return isPrimitiveValuesChanged || isEnvironmentsChanged || isPhotosChanged || !!redirectErrors?.length;
};
