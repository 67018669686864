import { all, fork } from "redux-saga/effects";

import { saga as authSaga } from "../containers/Auth/store";
import { saga as sharedSaga } from "../shared/store";
import { saga as usersSaga } from "../containers/Users/store";
import { saga as companiesPlantsSaga } from "../containers/CompaniesPlants/store";
import { saga as fieldNotesSaga } from "../containers/FieldNotes/store";
import { saga as instancesSaga } from "../containers/Instances/store";
import { saga as imagesSaga } from "../containers/Images/store";

const allSagas = [sharedSaga, authSaga, usersSaga, companiesPlantsSaga, fieldNotesSaga, instancesSaga, imagesSaga];

export default function* appSagas() {
  yield all(allSagas.map(fork));
}
