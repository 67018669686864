import React, { useCallback, useEffect, useState } from "react";
import { InputProps } from "../InputProps";
import "./index.scss";

interface SearchInputProps extends InputProps {
  value: string;
  onChangeSearch: (value: string) => void;
}

const SearchInput: React.FunctionComponent<SearchInputProps> = (props: SearchInputProps) => {
  const { className, onChangeSearch, value, ...inputProps } = props;
  const [inputValue, setInputValue] = useState(value || "");

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    onChangeSearch(event.target.value);
  };

  const clearInput = useCallback(() => {
    setInputValue("");
    onChangeSearch("");
  }, [setInputValue, onChangeSearch]);

  return (
    <>
      <input {...inputProps} className="search-input" onChange={onChangeInput} value={inputValue} />
      <i className="search-icon-block icon search" />
      {inputValue !== "" && <i className="search-icon-block icon cancel-grey" onClick={clearInput} />}
    </>
  );
};

export default SearchInput;
