export enum PERMISSION {
  COMPANY_VIEW = "Company_view",
  COMPANY_CREATE = "Company_create",
  COMPANY_EDIT = "Company_edit",
  COMPANY_DELETE = "Company_delete",

  PLANT_VIEW = "Plant_view",
  PLANT_CREATE = "Plant_create",
  PLANT_EDIT = "Plant_edit",
  PLANT_DELETE = "Plant_delete",

  FIELDNOTE_VIEW = "FieldNote_view",
  FIELDNOTE_CREATE = "FieldNote_create",
  FIELDNOTE_EDIT = "FieldNote_edit",
  FIELDNOTE_DELETE = "FieldNote_delete",

  INSTANCE_VIEW = "Instance_view",
  INSTANCE_CREATE = "Instance_create",
  INSTANCE_EDIT = "Instance_edit",
  INSTANCE_DELETE = "Instance_delete",

  CONDITION_NOTE_VIEW = "ConditionNote_view",
  CONDITION_NOTE_CREATE = "ConditionNote_create",
  CONDITION_NOTE_EDIT = "ConditionNote_edit",
  CONDITION_NOTE_DELETE = "ConditionNote_delete",

  DOCUMENT_VIEW = "Document_view",
  DOCUMENT_CREATE = "Document_create",
  DOCUMENT_EDIT = "Document_edit",
  DOCUMENT_DELETE = "Document_delete",

  COMMENT_VIEW = "Comment_view",
  COMMENT_CREATE = "Comment_create",
  COMMENT_EDIT = "Comment_edit",
  COMMENT_DELETE = "Comment_delete",

  USER_VIEW = "User_view",
  USER_CREATE = "User_create",
  USER_EDIT = "User_edit",
  USER_DELETE = "User_delete",

  TAG_MEMBER = "Tag_member",
  MANAGE_ROLE = "Manage_role",

  REVIEW_SECTION_VIEW = "Review_Section_View",
}
