import config from "../../config";

export default {
  AUTH: {
    LOGIN: `${config.baseApiUrl}auth/login`,
    EPRI_LOGIN: `${config.baseApiUrl}auth/epri-login`,
    EPRI_LOGIN_STATUS: `${config.baseApiUrl}auth/status`,
    REFRESH_TOKEN: `${config.baseApiUrl}auth/refresh-token`,
    FORGOT_PASSWORD: `${config.baseApiUrl}auth/forgotPassword`,
    SET_PASSWORD: `${config.baseApiUrl}auth/setPassword`,
  },
  COMPANY: {
    COMPANIES_PLANTS_LIST: `${config.baseApiUrl}companies-plants`,
    COMPANIES: `${config.baseApiUrl}companies`,
    GET_COMPANY: (id: string) => `${config.baseApiUrl}companies/${id}`,
    UPDATE_COMPANY: (id: string) => `${config.baseApiUrl}companies/${id}`,
    DELETE_COMPANY: (id: string) => `${config.baseApiUrl}companies/${id}`,
    RESTORE_COMPANY: (id: string) => `${config.baseApiUrl}restore/companies/${id}`,
    CREATE_COMPANY: `${config.baseApiUrl}companies/`,

    GET_PLANT: (id: number) => `${config.baseApiUrl}plants/${id}`,
    UPDATE_PLANT: (id: string) => `${config.baseApiUrl}plants/${id}`,
    DELETE_PLANT: (id: string) => `${config.baseApiUrl}plants/${id}`,
    RESTORE_PLANT: (id: string) => `${config.baseApiUrl}restore/plants/${id}`,
    CREATE_PLANT: `${config.baseApiUrl}plants/`,
    GET_PLANT_DOCUMENTS: (plantId: number) => `${config.baseApiUrl}plants/${plantId}/documents`,
    DELETE_PLANT_DOCUMENT: (plantId: number, documentId: number) =>
      `${config.baseApiUrl}plants/${plantId}/documents/${documentId}`,
    UPDATE_PLANT_DOCUMENT: (plantId: number, documentId: number) =>
      `${config.baseApiUrl}plants/${plantId}/documents/${documentId}`,
    CREATE_PLANT_DOCUMENT: (plantId: number) => `${config.baseApiUrl}plants/${plantId}/documents`,
  },
  INSTANCES: {
    GET_INSTANCES: `${config.baseApiUrl}instances`,
    GET_INSTANCE: (id: number) => `${config.baseApiUrl}instances/${id}`,
    GET_INSTANCE_PDF: (id: number) => `${config.baseApiUrl}instances-pdf/${id}`,
    GET_INSTANCES_BY_FIELD_NOTE: (fieldNoteId: number) => `${config.baseApiUrl}instances-by-field-note/${fieldNoteId}`,
    CREATE_INSTANCE: `${config.baseApiUrl}instance-fields`,
    UPDATE_INSTANCE: `${config.baseApiUrl}instance-fields`,
    DELETE_INSTANCE: (id: number) => `${config.baseApiUrl}instances/${id}`,
    UPDATE_INSTANCE_CONDITION_OPTIONS: (id: number) => `${config.baseApiUrl}instances/${id}/condition-options`,
    DELETE_INSTANCE_CONDITION_OPTIONS: (id: number) => `${config.baseApiUrl}instances/${id}/condition-options`,
    GET_INSTANCE_COMMENTS: `${config.baseApiUrl}comments-images-voices`,
    GET_INSTANCE_COLLABORATORS: (instanceId: number) => `${config.baseApiUrl}instances/${instanceId}/tag`,
    ADD_INSTANCE_COLLABORATOR: (instanceId: number) => `${config.baseApiUrl}instances/${instanceId}/tag`,
    DELETE_INSTANCE_COLLABORATOR: (instanceId: number, collaboratorId: number) =>
      `${config.baseApiUrl}instances/${instanceId}/tag/${collaboratorId}`,
    ADD_FIELD_GUIDE_TO_INSTANCE: (instanceId: number) =>
      `${config.baseApiUrl}instances/${instanceId}/field-guide-reference`,
    DELETE_FIELD_GUIDE_FROM_INSTANCE: (instanceId: number, referenceId: number) =>
      `${config.baseApiUrl}instances/${instanceId}/field-guide-reference/${referenceId}`,
  },
  IMAGES: {
    GET_IMAGES: `${config.baseApiUrl}images`,
  },
  VIDEOS: {
    CREATE_VIDEO_FILES: `${config.baseApiUrl}videos/upload`,
    DELETE_VIDEO_FILE: (videoId: number) => `${config.baseApiUrl}videos/${videoId}`,
  },
  CONDITION_OPTIONS: {
    GET_ALL_CONDITION_OPTIONS: `${config.baseApiUrl}condition-options`,
    GET_DEGRADATIONS_OPTIONS: `${config.baseApiUrl}condition-options/degradations`,
    GET_DISCIPLINES_OPTIONS: `${config.baseApiUrl}condition-options/disciplines`,
    GET_ENVIRONMENTS_OPTIONS: `${config.baseApiUrl}condition-options/environments`,
    GET_MATERIALS_OPTIONS: `${config.baseApiUrl}condition-options/materials`,
    GET_QUESTIONS_OPTIONS: `${config.baseApiUrl}condition-options/questions`,
    GET_MATERIAL_TYPES_OPTIONS: `${config.baseApiUrl}condition-options/materialTypes`,
    GET_REDIRECT_OPTIONS: `${config.baseApiUrl}condition-options/redirects`,
  },
  PROFILES: {
    GET_PLANT_PROFILES: (plantId: number) => `${config.baseApiUrl}profiles-by-plant/${plantId}`,
    GET_PROFILE_FIELD_GUIDES: `${config.baseApiUrl}profile-field-guides`,
    SET_FAVORITE_FIELD_GUIDE: (fieldGuideDocumentId: number) =>
      `${config.baseApiUrl}favorite-field-guide/${fieldGuideDocumentId}`,
  },
  FILES: {
    CREATE_FILE: `${config.baseApiUrl}image`,
    CREATE_IMAGE_FILE: `${config.baseApiUrl}image-base64`,
    DELETE_IMAGE_FILE: (imageId: number) => `${config.baseApiUrl}images/${imageId}`,
    GET_FILE: `${config.baseApiUrl}file`,
  },
  VOICE_NOTE_FOR_IMAGE: {
    SAVE: (imageId: number) => `${config.baseApiUrl}images/${imageId}/upload-voice-note`,
  },

  IMAGES_CHUNKED: {
    SAVE: `${config.baseApiUrl}images-chunked`,
    DELETE: `${config.baseApiUrl}images-chunked`,
  },
  COMMENTS: {
    CREATE_COMMENT: `${config.baseApiUrl}comments/upload`,
    DELETE_COMMENT: (commentId: number) => `${config.baseApiUrl}comments/${commentId}`,
  },
  FIELD_NOTE: {
    GET_FIELD_NOTES_LIST: `${config.baseApiUrl}field-notes`,
    GET_FIELD_NOTE: (id: number) => `${config.baseApiUrl}field-notes/${id}`,
    GET_FIELD_NOTE_PDF: (id: number) => `${config.baseApiUrl}field-notes-pdf/${id}`,
    UPDATE_FIELD_NOTE: (id: string) => `${config.baseApiUrl}field-notes/${id}`,
    DELETE_FIELD_NOTE: (id: number) => `${config.baseApiUrl}field-notes/${id}`,
    CREATE_FIELD_NOTE: `${config.baseApiUrl}field-notes/`,
  },
  USER: {
    GET_USERS_LIST: `${config.baseApiUrl}profiles`,
    GET_USER_DETAILS: (id: string) => `${config.baseApiUrl}profiles/${id}`,
    MARK_TUTOTIAL_COMPLETED: () => `${config.baseApiUrl}tutorial-completed`,
    UPDATE_USER_ROLES: (id: string) => `${config.baseApiUrl}profiles/${id}/roles`,
    GET_USER_GUIDE: `${config.baseApiUrl}user-guide`,
  },
  ITEMS: {
    GET_IMAGE_TYPES: `${config.baseApiUrl}image-types`,
    GET_REVIEW_STATUSES: `${config.baseApiUrl}review-statuses`,
    REVIEW_IMAGE: `${config.baseApiUrl}images-review`,
    VALIDATE_IMAGE_POSITION: `${config.baseApiUrl}validate-image-position`,
  },
  ROLES: {
    GET_ROLES: `${config.baseApiUrl}roles`,
  },
  NOTIFICATION_HISTORY: {
    GET_NOTIFICATION_HISTORY_LIST: `${config.baseApiUrl}notification-history`,
    MARK_ALL_NOTIFICATIONS_AS_READ: `${config.baseApiUrl}notification-history`,
    MARK_ONE_NOTIFICATION_AS_READ: (id: number) => `${config.baseApiUrl}notification-history/${id}`,
  },
};

export const APP_ROOT_ID = "root";

export const MODAL_ROOT_ID = "modal-root";
