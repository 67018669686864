import React, { useState, useEffect, useMemo } from "react";
import classnames from "classnames";
import { NotificationHistory } from "../../../../shared/models";
import { NotificationItem } from "../NotificationItem";

import classNames from "./notifications.module.scss";

interface NotificationsProps {
  opened: boolean;
  notificationHistory: NotificationHistory[];
  onMarkAllAsRead: Function;
  onMarkAsRead: Function;
}

const Notifications: React.FunctionComponent<NotificationsProps> = (props) => {
  const { opened, onMarkAllAsRead, notificationHistory, onMarkAsRead } = props;
  const [canMarkAllHistoryAsRead, setMarkAllHistoryAsRead] = useState(false);

  const markAllAsRead = () => {
    canMarkAllHistoryAsRead && onMarkAllAsRead();
  };

  const markAsReadItem = (notificationHistory: NotificationHistory) => {
    onMarkAsRead(notificationHistory);
  };

  useEffect(() => {
    setMarkAllHistoryAsRead(!!notificationHistory.find((n) => n.isActive));
  }, [notificationHistory]);

  const unreadCount = useMemo(() => {
    return notificationHistory.filter(({ isViewed }) => !isViewed).length;
  }, [notificationHistory]);

  return opened ? (
    <div className={classNames.notificationWrapper}>
      <div className={classNames.topButtons}>
        <div className={classNames.unread}>{unreadCount} unread</div>
        <div
          className={classnames(classNames.readBtn, { [classNames.empty]: !canMarkAllHistoryAsRead })}
          onClick={markAllAsRead}
        >
          <img
            src={canMarkAllHistoryAsRead ? "/icons/general/blue-check.svg" : "/icons/general/gray-check.svg"}
            alt="read all"
          />
          mark all as read
        </div>
      </div>
      <div
        className={classnames(classNames.notificationsList, {
          [classNames.emptyNotificationsImage]: !notificationHistory.length,
        })}
      >
        {notificationHistory.map((n, index) => (
          <NotificationItem key={index} notificationHistory={n} onItemClick={markAsReadItem} />
        ))}
        {!notificationHistory.length && <img src="images/dashboard/no-notifications.svg" alt="no ew notifications" />}
      </div>
    </div>
  ) : null;
};
export default Notifications;
