import React, { useState } from "react";
import classnames from "classnames";

import "./addButton.scss";
import { ButtonActionItem } from "../../interfaces";

interface AddButtonProps {
  items?: ButtonActionItem[];
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const AddButton = (props: AddButtonProps) => {
  const { items, onClick } = props;

  const [isOpened, setIsOpened] = useState(false);

  const toggleAddButton = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (onClick) {
      return onClick(event);
    }

    setIsOpened(!isOpened);
  };

  const handleCloseAddButton = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsOpened(false);
  };

  const handleItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    handleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  ) => {
    handleClick(event);
    setIsOpened(false);
  };

  return (
    <div className="add_button">
      {isOpened && (
        <>
          <div className="backdrop" onClick={handleCloseAddButton} />
          <div className="add_button_items">
            {items?.map((item) => (
              <div key={item.label} className="add_button_items_item" onClick={(e) => handleItemClick(e, item.onClick)}>
                <div className="add_button_items_item_label">{item.label}</div>
                <img src={item.icon} className="add_button_items_item_icon" alt={item.label} />
              </div>
            ))}
          </div>
        </>
      )}
      <div className="add_button_wrapper">
        <div className={classnames("circle_button", { opened: isOpened })} onClick={toggleAddButton} />
      </div>
    </div>
  );
};

export default AddButton;
