import React, { useCallback } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { FieldNote } from "../../models";
import { ROUTE_PATHS } from "../../routes";
import { FieldNoteCard } from "../";
import "./index.scss";
import { AppLoader } from "../AppLoader";

interface FieldNoteCardListProps {
  fieldNotes: FieldNote[];
  search: string;
  isLoading: boolean;
}

const FieldNoteCardList: React.FunctionComponent<FieldNoteCardListProps> = (props) => {
  const { fieldNotes, search, isLoading } = props;
  const history = useHistory();

  const openFieldNote = useCallback(
    (fieldNote: FieldNote) => {
      if (fieldNote.id) {
        history.push(
          generatePath(ROUTE_PATHS.INSTANCES_DASHBOARD, {
            plantId: String(fieldNote.plantId),
            fieldNoteId: fieldNote.id,
          }),
        );
      }
    },
    [history],
  );

  return (
    <>
      {isLoading ? (
        <AppLoader />
      ) : (
        fieldNotes.map((fieldNote: FieldNote, fieldNoteIndex: number) => (
          <FieldNoteCard fieldNote={fieldNote} key={fieldNoteIndex} onClick={() => openFieldNote(fieldNote)} />
        ))
      )}
      {fieldNotes.length === 0 &&
        (!search.length ? <div className="field-notes-list-empty" /> : <div className="field-notes-not-found" />)}
    </>
  );
};

export default FieldNoteCardList;
