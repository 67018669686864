import React, { useState } from "react";
import { AccessControl, Modal, RemoveModalContent } from "../../../../../../shared/components";
import { PERMISSION } from "../../../../../../shared/constants";
import { ConditionOptionsWithConcern, FieldGuideReference, Instance, Plant } from "../../../../../../shared/models";
import { InstanceReferences } from "../../../InstanceViewContainer/components/InstanceReferences";
import { actions } from "../../../../store";
import { useDispatch } from "react-redux";
import { setFieldGuide } from "../../../../../../shared/store/actions";

interface ConditionNotesListProps {
  degradations: [string, ConditionOptionsWithConcern[]][] | undefined;
  isActual: boolean;
  plant: Plant | null;
  setConcernToDelete: (value: number | undefined) => void;
  redirectToConditionNoteConcern: (value: number) => void;
  instance?: Instance;
  createReferenceHandler: (degradationId: number) => void;
  openReferenceHandler: (reference: FieldGuideReference, degId?: number) => void;
}

const ConditionNotesList: React.FunctionComponent<ConditionNotesListProps> = (props) => {
  const {
    createReferenceHandler,
    openReferenceHandler,
    degradations,
    isActual,
    plant,
    setConcernToDelete,
    redirectToConditionNoteConcern,
    instance,
  } = props;

  const dispatch = useDispatch();
  const [fieldGuideToDelete, setFieldGuideToDelete] = useState<number | undefined>(undefined);

  const deleteFieldGuide = (referenceId?: number) => {
    if (referenceId) {
      setFieldGuideToDelete(undefined);
      dispatch(
        actions.deleteFieldGuideReferenceFromInstance.request({ referenceId, instanceId: Number(instance?.id) }),
      );
    }
  };

  const createReference = (degradationId: number) => {
    dispatch(setFieldGuide(undefined));
    createReferenceHandler(degradationId);
  };

  return (
    <>
      {degradations?.map(([environmentName, degradations]) => (
        <div key={environmentName} className="conditions_item">
          <div className="conditions_item_title">
            <div className="conditions_item_title_text">{environmentName}</div>
            {!isActual && <div className="conditions_item_title_label">Not Actual</div>}
          </div>
          {degradations.map((degradation) => {
            const isDeleteShown = !degradation.isMetal && degradation.isAConcern;

            return (
              <div
                key={degradation.id}
                className="conditions_item_note"
                onClick={() => !isDeleteShown && redirectToConditionNoteConcern(Number(degradation.id))}
              >
                <div>
                  <img
                    src={
                      degradation.isAConcern
                        ? "/icons/general/green_circle_cross.svg"
                        : "/icons/general/red_circle_cross.svg"
                    }
                    alt="is concern"
                  />
                  {degradation.name}
                </div>
                <div className="instances-field-guide-section">
                  <InstanceReferences
                    plant={plant}
                    fieldGuideReferences={instance?.fieldGuideReferences || []}
                    openReference={openReferenceHandler}
                    deleteReference={setFieldGuideToDelete}
                    showIcon={false}
                    degradationId={degradation.id ? Number(degradation.id) : null}
                  />
                  <div
                    className="instances-field-guide-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      createReference(Number(degradation.id));
                    }}
                  >
                    Field Guide Reference
                  </div>
                </div>
                {isDeleteShown ? (
                  <div>
                    <AccessControl permissions={[PERMISSION.CONDITION_NOTE_DELETE]} plant={plant} instance={instance}>
                      <div
                        className="instances-remove-button"
                        onClick={() => setConcernToDelete(degradation.conditionOptionId)}
                      >
                        remove
                      </div>
                    </AccessControl>
                  </div>
                ) : (
                  <div
                    className="conditions_item_note_arrow"
                    onClick={() => redirectToConditionNoteConcern(Number(degradation.id))}
                  >
                    <img src="/icons/general/right-arrow.svg" alt="location" />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ))}
      <Modal isShowing={fieldGuideToDelete !== undefined} onClose={() => setFieldGuideToDelete(undefined)} boxPadding>
        <RemoveModalContent
          heading="Remove Reference"
          content="Are you sure you want to remove Reference?"
          cancelText="Cancel"
          removeText="Remove"
          onClose={() => setFieldGuideToDelete(undefined)}
          onDelete={() => deleteFieldGuide(fieldGuideToDelete)}
          isSubmitType
        />
      </Modal>
    </>
  );
};

export default ConditionNotesList;
