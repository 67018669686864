import { API, METHODS } from "../constants";
import { ConditionOptionsRequestParams, FieldGuideSortFilterOptions } from "../interfaces";
import { request } from "../utils";

export default {
  getUserDetails: (profileId: string) => request(METHODS.GET, API.USER.GET_USER_DETAILS(profileId))(),
  getFieldGuides: () => request(METHODS.GET, API.PROFILES.GET_PROFILE_FIELD_GUIDES)(),
  getProfileFieldGuides: (params: FieldGuideSortFilterOptions) =>
    request(METHODS.GET, API.PROFILES.GET_PROFILE_FIELD_GUIDES)({ params }),
  getUserGuide: (device: string = "web") => request(METHODS.GET, API.USER.GET_USER_GUIDE)({ params: { device } }),

  getFile: (key: string) =>
    request(
      METHODS.GET,
      API.FILES.GET_FILE,
    )({
      params: {
        key,
      },
    }),
  getImageTypes: () => request(METHODS.GET, API.ITEMS.GET_IMAGE_TYPES)(),
  getReviewStatuses: () => request(METHODS.GET, API.ITEMS.GET_REVIEW_STATUSES)(),

  getConditionOptions: (params: ConditionOptionsRequestParams) =>
    request(
      METHODS.GET,
      API.CONDITION_OPTIONS.GET_ALL_CONDITION_OPTIONS,
    )({
      params: {
        filter: params,
      },
    }),

  getDegradationsOptions: (params: ConditionOptionsRequestParams) =>
    request(
      METHODS.GET,
      API.CONDITION_OPTIONS.GET_DEGRADATIONS_OPTIONS,
    )({
      params: {
        filter: params,
      },
    }),

  getDisciplinesOptions: (params: ConditionOptionsRequestParams) =>
    request(
      METHODS.GET,
      API.CONDITION_OPTIONS.GET_DISCIPLINES_OPTIONS,
    )({
      params: {
        filter: params,
      },
    }),

  getEnvironmentsOptions: (params: ConditionOptionsRequestParams) =>
    request(
      METHODS.GET,
      API.CONDITION_OPTIONS.GET_ENVIRONMENTS_OPTIONS,
    )({
      params: {
        filter: params,
      },
    }),

  getMaterialsOptions: (params: ConditionOptionsRequestParams) =>
    request(
      METHODS.GET,
      API.CONDITION_OPTIONS.GET_MATERIALS_OPTIONS,
    )({
      params: {
        filter: params,
      },
    }),

  getQuestionsOptions: (params: ConditionOptionsRequestParams) =>
    request(
      METHODS.GET,
      API.CONDITION_OPTIONS.GET_QUESTIONS_OPTIONS,
    )({
      params: {
        filter: params,
      },
    }),

  getTypesOptions: (params: ConditionOptionsRequestParams) =>
    request(
      METHODS.GET,
      API.CONDITION_OPTIONS.GET_MATERIAL_TYPES_OPTIONS,
    )({
      params: {
        filter: params,
      },
    }),

  getRedirectOptions: (params: ConditionOptionsRequestParams) =>
    request(
      METHODS.GET,
      API.CONDITION_OPTIONS.GET_REDIRECT_OPTIONS,
    )({
      params: {
        filter: params,
      },
    }),

  getConditions: (params: ConditionOptionsRequestParams) =>
    request(
      METHODS.GET,
      API.CONDITION_OPTIONS.GET_ALL_CONDITION_OPTIONS,
    )({
      params: {
        filter: params,
      },
    }),

  getNotificationHistoryList: () => request(METHODS.GET, API.NOTIFICATION_HISTORY.GET_NOTIFICATION_HISTORY_LIST)(),
  getRolesList: () => request(METHODS.GET, API.ROLES.GET_ROLES)(),
  markAllNotificationsAsRead: () => request(METHODS.PUT, API.NOTIFICATION_HISTORY.MARK_ALL_NOTIFICATIONS_AS_READ)(),
  markOneNotificationAsRead: (id: number) =>
    request(METHODS.PUT, API.NOTIFICATION_HISTORY.MARK_ONE_NOTIFICATION_AS_READ(id))(),
  setFavoriteFieldGuide: (companyId: number, fieldGuideDocumentId: number, isFavorite: boolean) =>
    request(METHODS.POST, API.PROFILES.SET_FAVORITE_FIELD_GUIDE(fieldGuideDocumentId))({ companyId, isFavorite }),
};
