import produce from "immer";
import { ActionTypes } from "./constants";
import { Reducer } from "redux";
import { Profile } from "../../../shared/models";

export interface UsersState {
  users: Profile[];
}

const initialState: UsersState = {
  users: [],
};

const reducer: Reducer<UsersState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_USERS_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.users = action.payload;
      });
    }

    case ActionTypes.UPDATE_USER_ROLES_SUCCESS: {
      return produce(state, (nextState) => {
        const { profileRoles, id } = action.payload.user;
        nextState.users = nextState.users.map((u) => (u.id === id ? { ...u, profileRoles } : u));
      });
    }

    default:
      return state;
  }
};
export default reducer;
