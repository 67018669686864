import { Configuration } from "./shared/interfaces";
const {
  REACT_APP_BASE_API_URL = "http://localhost:4000/api/v1/",
  REACT_APP_SOCKET_URL = "http://localhost:4000/",
} = process.env;

const config: Configuration = {
  baseApiUrl: REACT_APP_BASE_API_URL,
  socketUrl: REACT_APP_SOCKET_URL,
};

export default config;
