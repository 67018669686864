import React from "react";
import classNames from "./aboutUs.module.scss";

const AboutUs = () => {
  return (
    <div className={classNames.aboutUsWrapper}>
      <div className={classNames.header}>About Digital Assistant</div>
      <div className={classNames.body}>
        <div className={classNames.article}>
          <div className={classNames.title}>Software:</div>
          <div className={classNames.content}>
            Cognitive System and Technology Assistant for Resource Referencing (Digital Assistant) Version v1.0
          </div>
        </div>

        <div className={classNames.article}>
          <div className={classNames.title}>Description:</div>
          <div className={classNames.content}>
            Digital Assistant provides a web and mobile application to assist nuclear power plant staff 1) capture,
            document and appropriately tag findings related to component aging, 2) collaborate in assessment and
            document resolution activity, and 3) reference relevant information.
          </div>
          <div className={classNames.title}>Supported Platforms:</div>
          <div className={classNames.content}>
            Browsers: Chrome, Edge, Firefox, Safari
            <br />
            Mobile OS: iOS 13 and above
          </div>
        </div>

        <div className={classNames.row}>
          <div className={`${classNames.articleWrap} ${classNames.medium}`}>
            <div className={classNames.article}>
              <div className={classNames.title}>Developed For:</div>
              <img src="icons/general/logo-blue.svg" alt="epri" />
              <div className={classNames.content}>
                Electric Power Research Institute (EPRI) <br />
                3420 Hillview Ave.
                <br />
                Palo Alto. CA 94304
              </div>
            </div>
          </div>

          <div className={`${classNames.articleWrap} ${classNames.medium}`}>
            <div className={classNames.article}>
              <div className={classNames.title}>Support:</div>
              <div className={classNames.content}>
                EPRI Customer Assistance Center <br />
                Phone: <br />
                1-800-313-3774 <br />
                Email:
                <br />
                <a className={classNames.link} href="mailto: askepri@epri.com">
                  askepri@epri.com
                </a>
              </div>
            </div>
          </div>
          <div className={`${classNames.articleWrap} ${classNames.small}`}>
            <div className={classNames.article}>
              <div className={classNames.title}>Developed By:</div>
              <img src="images/menu/hedgehog.svg" alt="hedgehog" />

              <div className={classNames.content}>
                Hedgehog Lab
                <br />
                50 Milk St. Floor 15
                <br />
                Boston, MA 02109
              </div>
            </div>
          </div>
          <div className={`${classNames.articleWrap} ${classNames.large}`}>
            <div className={classNames.article}>
              <div className={classNames.title}>Ordering information:</div>
              <div className={classNames.content}>
                The embodiment of this Program and supporting
                <br /> materials may be ordered from:
                <br />
                Electric Power Software Center (EPSC)
                <br />
                1300 W. W.T. Harris Blvd.
                <br />
                Charlotte. NC 28262
                <br />
                Phone:
                <br />
                1-800-313-3774
                <br />
                Email:
                <br />
                <a className={classNames.link} href="mailto: askepri@epri.com">
                  askepri@epri.com
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className={classNames.article}>
          <div className={classNames.title}>Copyright:</div>
          <div className={classNames.content}>
            {`Copyright© ${new Date().getFullYear()} Electric Power Research Institute. Inc.`} <br />
            EPRI reserves all rights in the Program as delivered. The Program or any portion thereof may not be
            reproduced in any form whatsoever except as provided by license
            <br />
            without the written consent of EPRI. A license under EPRI&apos;s rights in the Program may be available
            directly from EPRI.
          </div>
        </div>

        <div className={classNames.article}>
          <div className={classNames.title}>Disclaimer:</div>
          <div className={classNames.content}>
            This notice may not be removed from the program by any user thereof. <br />
            Neither EPRI, any member of EPRI. Nor any person or organization acting on behalf of them: <br />
            1. Makes any warranty or representation whatsoever. Express or implied. Including any warranty of
            merchantability or fitness of any purpose with respect to the program; or <br />
            2. Assumes any liability whatsoever with respect to any use of the program or any portion thereof or with
            respect to any damages which may result from such use. <br />
            Restricted rights legend: use, duplication. Or disclosure by the united states federal government is subject
            to restriction as set forth in paragraph (g) (3) (i). <br />
            With the exception of paragraph (g) (3) (i) (b) (s), of the rights in technical data and computer software
            clause in far 52.227-14, alternate iii.
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
