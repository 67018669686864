import React, { useEffect, useMemo, useRef, useState } from "react";
import { Stage, Layer, Image, Line, Rect } from "react-konva";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { Modal } from "../../../components";
import { actions as sharedActions, selectors as sharedSelectors } from "../../../store";

import "./ImageAnnotate.scss";
import { ATTACHMENT_TYPES } from "../../../constants";
import { AudioUpload, AudioUploadValue } from "..";
import { VoiceNote } from "../../../models";
import { useBinaryUrl, useKonva, useKonvaImage } from "../../../hooks";

interface ImageAnnotateProps {
  uid: string;
  src: string;
  header: string;
  show: boolean;
  onClose: Function;
  onCancel: Function;
  onSave: Function;
  maxWidth: number;
  maxHeight: number;
  imageTypeId: ATTACHMENT_TYPES;
  voiceNote?: VoiceNote;
}

export const ImageAnnotate: React.FunctionComponent<ImageAnnotateProps> = (props) => {
  const { src, uid, maxHeight, maxWidth, imageTypeId, voiceNote } = props;
  // eslint-disable-next-line
  const stageRef = useRef<any>();
  const dispatch = useDispatch();

  const imageTypes = useSelector(sharedSelectors.getImageTypes());

  const [image, setImage] = useState<HTMLImageElement>();
  const [origImageSize, setOrigImageSize] = useState({ w: 0, h: 0 });
  const [voiceNotes, setVoiceNotes] = useState<AudioUploadValue[]>([]);

  const { binary, errorSrc, handleLoad, handleError } = useBinaryUrl({
    binaryType: "image",
    isLazy: false,
    isVisible: false,
    uid,
  });
  const {
    Tool,
    activeTool,
    setActiveTool,
    drawColor,
    linePoints,
    isCropping,
    cropPoints,
    imageCrop,
    onMouseDown,
    onMouseUp,
    onMouseMove,
    onRevert,
    resizeKeepAspect,
    setOrigRatio,
    setRatio,
    croppedImageSize,
  } = useKonva({ maxWidth, maxHeight, origImageSize });

  useEffect(() => {
    if (voiceNote) {
      setVoiceNotes([{ id: voiceNote.id, src: voiceNote.key }]);
    }
  }, [voiceNote]);

  useEffect(() => {
    if (!imageTypes) {
      dispatch(sharedActions.getImageTypes.request());
    }
  }, [dispatch, imageTypes]);

  useKonvaImage({
    src,
    binary,
    errorSrc,
    resizeKeepAspect,
    handleLoad,
    handleError,
    setRatio,
    setOrigRatio,
    setImage,
    setOrigImageSize,
  });

  const imageDescription = useMemo(() => {
    if (!imageTypes) {
      return "";
    }

    const imageType = imageTypes.find((imageType) => imageType.id === Number(imageTypeId + 1));
    return imageType?.description || "";
  }, [imageTypeId, imageTypes]);

  const handleVoiceNoteSelect = (name: string, values: AudioUploadValue[]) => {
    const selectedFile = values[0]?.file;
    setVoiceNotes(selectedFile ? [{ file: selectedFile }] : []);
  };

  return (
    <Modal isShowing={props.show} onClose={props.onClose} boxPadding={false}>
      <div className="annotate-modal-content-wrapper">
        <div className="modal-heading">{props.header}</div>
        <div className="modal-subHeading">{imageDescription}</div>
        <div className="modal-content--canvas">
          <div className="centeredBox">
            <div>
              <Stage
                className="crosshair"
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onMouseMove={onMouseMove}
                ref={stageRef}
                width={croppedImageSize.w ? croppedImageSize.w : origImageSize.w}
                height={croppedImageSize.h ? croppedImageSize.h : origImageSize.h}
              >
                <Layer>
                  <Image
                    image={image}
                    width={croppedImageSize.w ? croppedImageSize.w : origImageSize.w}
                    height={croppedImageSize.h ? croppedImageSize.h : origImageSize.h}
                    crop={imageCrop}
                  />

                  {linePoints.map((points: number[], key: number) => {
                    return <Line points={points} fill={drawColor} stroke={drawColor} key={key} />;
                  })}

                  {isCropping && (
                    <Rect
                      x={cropPoints.x}
                      y={cropPoints.y}
                      width={cropPoints.w}
                      height={cropPoints.h}
                      fill={"black"}
                      opacity={0.3}
                    />
                  )}
                </Layer>
              </Stage>
            </div>
          </div>
        </div>
        <div className="modal-actions">
          <span className="annotate-wrapper">
            <div className="annotate-tools">
              <div className="annotate-crop" onClick={() => setActiveTool(Tool.CROP)}>
                <span className={classNames("tool-icon", { active: activeTool === Tool.CROP })} /> Crop
              </div>
              <div className="annotate-mark-up" onClick={() => setActiveTool(Tool.DRAW)}>
                <span className={classNames("tool-icon", { active: activeTool === Tool.DRAW })} /> Mark-up
              </div>
            </div>
          </span>
          <div className="voice-note">
            <AudioUpload
              name="voiceNotes"
              setFieldValues={handleVoiceNoteSelect}
              values={voiceNotes}
              showUploadIcon={!voiceNotes.length}
            />
          </div>
          <button className="btn blue" onClick={() => props.onCancel()}>
            Cancel
          </button>
          <button className="btn orange" onClick={onRevert}>
            Revert
          </button>
          <button
            className="btn white-orange"
            type="submit"
            onClick={() => {
              const imageBase64: string = stageRef.current.toDataURL();
              const isNewVoiceNote = voiceNotes[0]?.file && !voiceNotes[0]?.id;
              const isNewImage = imageBase64.length > 10;
              const voiceNote = voiceNotes[0];
              if (isNewImage || isNewVoiceNote) {
                props.onSave(isNewImage, isNewVoiceNote, imageBase64, voiceNote);
              }
            }}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};
