import React from "react";

import { PlantCard } from "../PlantCard";
import { CompaniesPlants } from "../../../../../../shared/models";
import { Plant } from "../../../../../../shared/models";

import "./index.scss";
import { AccessControl } from "../../../../../../shared/components";
import { PERMISSION } from "../../../../../../shared/constants";

interface CompanyContainerInterface {
  company: CompaniesPlants;
  onDelete: () => void;
  onEdit: () => void;
  onRestore: () => void;
  onClick: (plant: Plant) => void;
}

const CompanyContainer = (props: CompanyContainerInterface) => {
  const { company, onDelete, onEdit, onRestore, onClick } = props;

  return (
    <div className="company-container">
      <div className="company-header">
        <div className="company-title">
          <div className="company-name">{company.displayName}</div>
          {!company.isActive && (
            <div className="company-archived-wrapper">
              <div className="company-archived-label">Archived</div>
            </div>
          )}
        </div>
        {company.isActive ? (
          <AccessControl permissions={[PERMISSION.COMPANY_EDIT, PERMISSION.COMPANY_DELETE]}>
            <div className="company-actions">
              <div className="company-actions-wrapper">
                <AccessControl permissions={[PERMISSION.COMPANY_EDIT]}>
                  <div className="company-actions-edit" title="Edit Company" onClick={onEdit}>
                    Edit
                  </div>
                </AccessControl>
                <div className="company-actions-divider" />
                <AccessControl permissions={[PERMISSION.COMPANY_DELETE]}>
                  <div className="company-actions-remove" title="Remove Company" onClick={onDelete}>
                    Remove
                  </div>
                </AccessControl>
              </div>
            </div>
          </AccessControl>
        ) : (
          <AccessControl permissions={[PERMISSION.COMPANY_DELETE]}>
            <div className="company-restore-button" title="Restore Company" onClick={onRestore}>
              RESTORE
            </div>
          </AccessControl>
        )}
      </div>
      <div className="company-body">
        {company.plants?.map((plant) => (
          <div key={plant.id} className="company-plant-wrapper" onClick={() => onClick(plant)}>
            <PlantCard plant={plant} />
          </div>
        ))}
        {company.plants?.length === 0 && (
          <div className="company-without-plants">There are no Plants in this Company yet</div>
        )}
      </div>
    </div>
  );
};

export default CompanyContainer;
