import { Attachment, Instance, InstanceResponse } from "../models";

export const mapInstance = (instance: InstanceResponse): Instance => {
  const attachments: Attachment[] = [];
  instance?.images?.forEach((image) => attachments.push({ ...image, type: "image" }));
  instance?.videos?.forEach((image) => attachments.push({ ...image, type: "video" }));
  return {
    ...instance,
    attachments,
  };
};
