import styled from "styled-components";
import { Theme } from "../../interfaces";

export const Button = styled.button`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-radius: 25px;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  box-shadow: 0px 22px 34px rgba(0, 0, 0, 0.1);
  color: white;
  font-weight: bold;
  background: #5d81f4;
  fontfamily: ${(props: Theme) => (props.theme ? props.theme.globalFontFamily : "Source Sans Pro")};
  border: 2px solid #5d81f4;

  &.add {
    background: ${(props: Theme) => (props.theme ? props.theme.greenColor : "#38C975")};
    border: 2px solid ${(props: Theme) => (props.theme ? props.theme.greenColor : "#38C975")};
  }

  &:disabled {
    background: #bcc1d5;
    cursor: not-allowed;
    border-color: #bcc1d5;
  }
`;
