import React from "react";
import classNames from "./tutorialNavigation.module.scss";

/**
 * Navigate between tutorial slides
 */
interface TutorialNavigationProps {
  showNext?: boolean;
  showPrevious?: boolean;
  showFinish?: boolean;
  onNext: () => void;
  onPrevious: () => void;
  onFinish: () => void;
}

const TutorialNavigation: React.FunctionComponent<TutorialNavigationProps> = (props) => {
  const { onFinish, onNext, onPrevious, showFinish = true, showNext = true, showPrevious = true } = props;

  return (
    <div className={classNames.tutorialNavigationWrapper}>
      {showPrevious && (
        <button className={`${classNames.link} ${classNames.left}`} onClick={onPrevious}>
          <span>
            <img alt="previous" src="/images/tutorial/nav-left.svg" />
          </span>
          <span>Prev</span>
        </button>
      )}
      {showNext && (
        <button className={`${classNames.link} ${classNames.right}`} onClick={onNext}>
          <span>Next</span>
          <span>
            <img alt="next" src="/images/tutorial/nav-right.svg" />
          </span>
        </button>
      )}
      {showFinish && (
        <button className={`${classNames.link} ${classNames.right}`} onClick={onFinish}>
          <span>Finish</span>
          <span>
            <img alt="finish" src="/images/tutorial/nav-right.svg" />
          </span>
        </button>
      )}
    </div>
  );
};

export default TutorialNavigation;
