export enum ATTACHMENT_TYPES {
  STRAIGHT_ON,
  LEFT_SIDE,
  RIGHT_SIDE,
  ZOOMED_OUT,
  COMPONENT_TAG,
  ATTACHMENT,
}

export const ATTACHMENT_TYPES_MAP: { [key: string]: { id: number; label: string } } = {
  [ATTACHMENT_TYPES.STRAIGHT_ON]: { id: 1, label: "Straight On" },
  [ATTACHMENT_TYPES.LEFT_SIDE]: { id: 2, label: "Left Side" },
  [ATTACHMENT_TYPES.RIGHT_SIDE]: { id: 3, label: "Right Side" },
  [ATTACHMENT_TYPES.ZOOMED_OUT]: { id: 4, label: "Zoomed Out" },
  [ATTACHMENT_TYPES.COMPONENT_TAG]: { id: 5, label: "Component Tag" },
  [ATTACHMENT_TYPES.ATTACHMENT]: { id: 6, label: "Attachment" },
};

export const getImageTypeById = (imageTypeId: number): string | undefined => {
  const imageType = Object.entries(ATTACHMENT_TYPES_MAP).find(([key, value]) => value.id === imageTypeId);

  return imageType?.[0];
};
