export enum ActionTypes {
  GET_FIELD_NOTES = "@@DASHBOARD/GET_FIELD_NOTES",
  GET_FIELD_NOTES_SUCCESS = "@@DASHBOARD/GET_FIELD_NOTES_SUCCESS",
  GET_FIELD_NOTES_FAILURE = "@@DASHBOARD/GET_FIELD_NOTES_FAILURE",

  GET_FIELD_NOTE = "@@DASHBOARD/GET_FIELD_NOTE",
  GET_FIELD_NOTE_SUCCESS = "@@DASHBOARD/GET_FIELD_NOTE_SUCCESS",
  GET_FIELD_NOTE_FAILURE = "@@DASHBOARD/GET_FIELD_NOTE_FAILURE",

  GET_FIELD_NOTE_PDF = "@@DASHBOARD/GET_FIELD_NOTE_PDF",
  GET_FIELD_NOTE_PDF_SUCCESS = "@@DASHBOARD/GET_FIELD_NOTE_PDF_SUCCESS",
  GET_FIELD_NOTE_PDF_FAILURE = "@@DASHBOARD/GET_FIELD_NOTE_PDF_FAILURE",

  CLEAN_FIELD_NOTE_PDF = "@@DASHBOARD/CLEAN_FIELD_NOTE_PDF",

  CREATE_FIELD_NOTE = "@@DASHBOARD/CREATE_FIELD_NOTE",
  CREATE_FIELD_NOTE_SUCCESS = "@@DASHBOARD/CREATE_FIELD_NOTE_SUCCESS",
  CREATE_FIELD_NOTE_FAILURE = "@@DASHBOARD/CREATE_FIELD_NOTE_FAILURE",

  UPDATE_FIELD_NOTE = "@@DASHBOARD/UPDATE_FIELD_NOTE",
  UPDATE_FIELD_NOTE_SUCCESS = "@@DASHBOARD/UPDATE_FIELD_NOTE_SUCCESS",
  UPDATE_FIELD_NOTE_FAILURE = "@@DASHBOARD/UPDATE_FIELD_NOTE_FAILURE",

  DELETE_FIELD_NOTE = "@@DASHBOARD/DELETE_FIELD_NOTE",
  DELETE_FIELD_NOTE_SUCCESS = "@@DASHBOARD/DELETE_FIELD_NOTE_SUCCESS",
  DELETE_FIELD_NOTE_FAILURE = "@@DASHBOARD/DELETE_FIELD_NOTE_FAILURE",
}
