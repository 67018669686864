import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { match } from "react-router";

import { actions } from "../../store";
import "./index.scss";

interface EPRILoginStatusContainerProps {
  match: match<{ uid: string }>;
}

const EPRILoginStatusContainer = (props: EPRILoginStatusContainerProps) => {
  let uid = props.match.params.uid;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.epriLoginStatus.request({ uid }));
  }, [dispatch, uid]);

  return (
    <div className="epri-start">
      <div className="epri-logo" />
    </div>
  );
};

export default EPRILoginStatusContainer;
