import { createSelector } from "reselect";

import { RootReducer } from "../../../shared/interfaces";
import { Plant } from "../../../shared/models";

const selectFieldNotes = (state: RootReducer) => state.fieldNotes;

export const getFieldNotes = () => createSelector(selectFieldNotes, (state) => state.fieldNotes);
export const getCurrentFieldNote = () => createSelector(selectFieldNotes, (state) => state.currentFieldNote);
export const getFieldNotesByPlant = (plant: Plant | null) =>
  createSelector(selectFieldNotes, (state) =>
    state.fieldNotes.filter((fieldNote) => !!plant && fieldNote.plantId === plant.id),
  );
export const getFieldNotePDF = () => createSelector(selectFieldNotes, (state) => state.pdf);
