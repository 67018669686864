import { Option } from "../../interfaces";
import { StylesConfig } from "react-select";

export enum FieldItemType {
  TEXT = "text",
  PASSWORD = "password",
  CHECKBOX = "checkbox",
  SELECT = "select",
  MULTISELECT = "multiselect",
  ATTACHMENT = "attachment",
  TEXTAREA = "textarea",
  SIMPLE_IMAGE = "simple_image",
  AUDIO = "audio",
  DROP_UPLOAD_FILE = "drop_upload_file",
}

export interface AdditionalFieldItemOpts {
  isFieldsList?: boolean;
  index?: number;
  disabled?: boolean;
  helpText?: string;
}

export type FieldItem = (
  | TextFieldItem
  | PasswordFieldItem
  | CheckBoxFieldItem
  | SelectFieldItem
  | MultiSelectFieldItem
  | AttachmentSelectFieldItem
  | TextAreaFieldItem
  | SimpleImageFieldItem
  | SimpleAudioFieldItem
  | DropUploadFileFieldItem
) &
  AdditionalFieldItemOpts;

export interface TextFieldItem {
  type: FieldItemType.TEXT;
  name: string;
  label: string;
  disabled?: boolean;
  placeholder?: string;
  wrapperClass?: string;
}

export interface PasswordFieldItem {
  type: FieldItemType.PASSWORD;
  name: string;
  label: string;
  isShowPasswordIcon: boolean;
  disabled?: boolean;
  placeholder?: string;
  wrapperClass?: string;
}

export interface CheckBoxFieldItem {
  type: FieldItemType.CHECKBOX;
  name: string;
  label: string;
  wrapperClass?: string;
  inputClass?: string;
  parentPrefix?: string;
  disabled?: boolean;
  handleClick?: () => void;
}

export interface SelectFieldItem {
  type: FieldItemType.SELECT;
  name: string;
  label: string;
  options: Option[];
  disabled?: boolean;
  placeholder?: string;
  wrapperClass?: string;
  styles?: StylesConfig;
  helpText?: string;
  isSearchable?: boolean;
  tooltip?: string;
  tooltipLabel?: string;
  relatedFields?: Record<string, number | string[]>;
}

export interface MultiSelectFieldItem {
  type: FieldItemType.MULTISELECT;
  name: string;
  label: string;
  options: Option[];
  placeholder?: string;
  wrapperClass?: string;
  styles?: StylesConfig;
}

export interface AttachmentSelectFieldItem {
  type: FieldItemType.ATTACHMENT;
  name: string;
  index?: number;
}

export interface TextAreaFieldItem {
  type: FieldItemType.TEXTAREA;
  name: string;
  maxLength: number;
  placeholder: string;
  label: string;
  wrapperClass?: string;
  inputClass?: string;
}

export interface SimpleImageFieldItem {
  type: FieldItemType.SIMPLE_IMAGE;
  name: string;
}

export interface SimpleAudioFieldItem {
  type: FieldItemType.AUDIO;
  name: string;
}

export interface DropUploadFileFieldItem {
  type: FieldItemType.DROP_UPLOAD_FILE;
  name: string;
  maxSize?: number;
}
