import { User, Profile } from "../models";

export default {
  getAccessToken: () => {
    try {
      return localStorage.getItem("accessToken");
    } catch (err) {
      return false;
    }
  },

  getRefreshToken: () => {
    try {
      return localStorage.getItem("refreshToken");
    } catch (err) {
      return false;
    }
  },

  getProfile: () => {
    const profile = localStorage.getItem("profile");
    return profile ? JSON.parse(profile) : null;
  },

  setAccessToken: (token: string) => {
    if (token) localStorage.setItem("accessToken", token);
  },

  setRefreshToken: (token: string) => {
    if (token) localStorage.setItem("refreshToken", token);
  },

  setProfile: (profile: Profile) => {
    if (profile) localStorage.setItem("profile", JSON.stringify(profile));
  },

  setIsLicenseAgreed: (isAgreed: boolean) => {
    localStorage.setItem("isLicenseAgreed", JSON.stringify(isAgreed));
  },

  getIsLicenseAgreed: () => {
    const isAgreed = localStorage.getItem("isLicenseAgreed");
    return isAgreed ? JSON.parse(isAgreed) : null;
  },

  setTutorialCompletionDate: (date: Date) => {
    const profileString = localStorage.getItem("profile");
    const profile = profileString ? JSON.parse(profileString) : null;
    if (profile) {
      profile.tutorialCompletionDate = date;
      localStorage.setItem("profile", JSON.stringify(profile));
    }
  },

  setUser: (user: User) => {
    if (user) localStorage.setItem("user", JSON.stringify(user));
  },

  getUser: () => {
    const user = localStorage.getItem("user");

    return user ? JSON.parse(user) : null;
  },

  removeAccessToken: () => {
    localStorage.removeItem("accessToken");
  },

  removeRefreshToken: () => {
    localStorage.removeItem("refreshToken");
  },

  removeAll: () => {
    localStorage.clear();
  },
};
