import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, generatePath } from "react-router-dom";
import { Formik } from "formik";
import classnames from "classnames";

import { ExpandPanel, InstanceHeader } from "../../../../shared/components";
import { ROUTE_PATHS } from "../../../../shared/routes";
import { RouteComponentProps } from "../../../../shared/interfaces";
import {
  ComponentPropertyType,
  ConditionOptionsMap,
  ConditionOption,
  ConditionOptionsWithConcern,
  QuestionsWithDegradation,
} from "../../../../shared/models";
import { FormGenerator } from "../../../../shared/formComponents";
import { useConditionOptions } from "../../../../shared/hooks";
import { notEmpty } from "../../../../shared/utils";

import { actions, selectors } from "../../store";
import { selectors as fieldNotesSelectors, actions as fieldNotesActions } from "../../../FieldNotes/store";
import { InstancesConditionNoteQuestionsWrapper } from "./components/InstancesConditionNoteQuestionsWrapper";
import { mapFieldsList, prepareFormValues, InstanceConditionNoteFormFields, FieldsListType } from "./formHelpers";
import "./conditionNoteForm.scss";

interface InstancesConditionNoteFormContainerProps extends RouteComponentProps<{ id: number; fieldNoteId: number }> {}

const InstancesConditionNoteFormContainer: React.FunctionComponent<InstancesConditionNoteFormContainerProps> = (
  props,
) => {
  const {
    match: {
      params: { id: instanceId, fieldNoteId },
    },
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const { getConditionOptions, getInstanceOptionsByType, getInstanceOptionByType } = useConditionOptions();

  const instance = useSelector(selectors.getInstance());
  const currentFieldNote = useSelector(fieldNotesSelectors.getCurrentFieldNote());

  const [initialValues, setInitialValues] = useState<InstanceConditionNoteFormFields>(prepareFormValues());
  const [fieldsList, setFieldsList] = useState<FieldsListType | undefined>(undefined);
  const [isMetal, setIsMetal] = useState(false);
  const [degradationsEnvironments, setDegradationsEnvironments] = useState<
    Map<string, ConditionOptionsWithConcern[]> | undefined
  >(undefined);
  const [conditionOptionsMap, setConditionOptionsMap] = useState<Map<number, ConditionOptionsMap>>(new Map());
  const [selectedQuestions, setSelectedQuestions] = useState<QuestionsWithDegradation[] | undefined>(undefined);

  const redirectToConditionNoteConcern = useCallback(
    (degradationId: number) => {
      if (currentFieldNote) {
        history.push(
          generatePath(ROUTE_PATHS.CONDITION_NOTE_CONCERN, {
            id: instanceId,
            fieldNoteId: String(currentFieldNote.id),
            plantId: String(currentFieldNote.plantId),
            degradationId,
          }),
        );
      }
    },
    [currentFieldNote, history, instanceId],
  );

  useEffect(() => {
    if (fieldNoteId) {
      dispatch(fieldNotesActions.getFieldNote.request(fieldNoteId));
    }
  }, [dispatch, fieldNoteId]);

  useEffect(() => {
    if (!instance) {
      dispatch(actions.getInstance.request(instanceId));
    }
  }, [instanceId, dispatch, instance]);

  useEffect(() => {
    if (degradationsEnvironments) {
      setInitialValues(prepareFormValues(degradationsEnvironments));
      setFieldsList(mapFieldsList(degradationsEnvironments, redirectToConditionNoteConcern, isMetal));
    }
  }, [degradationsEnvironments, redirectToConditionNoteConcern, isMetal]);

  useEffect(() => {
    (async () => {
      if (instance?.instanceComponentProperties) {
        const material = getInstanceOptionByType(instance.instanceComponentProperties, ComponentPropertyType.material);
        const type = getInstanceOptionByType(instance.instanceComponentProperties, ComponentPropertyType.materialType);
        const discipline = getInstanceOptionByType(
          instance.instanceComponentProperties,
          ComponentPropertyType.discipline,
        );
        const environments = getInstanceOptionsByType(
          instance.instanceComponentProperties,
          ComponentPropertyType.environment,
        );

        const conditionOptions = await getConditionOptions({
          disciplines: [...[discipline].filter(notEmpty)],
          materials: [...[material].filter(notEmpty)],
          materialTypes: [...[type].filter(notEmpty)],
          environments: [...environments.filter(notEmpty)],
        });

        const degradationsEnvironmentsMap = new Map<string, ConditionOptionsWithConcern[]>();
        const conditionOptionsMap = new Map<number, ConditionOptionsMap>();

        if (
          instance.instanceComponentProperties.find(({ type }) => type === ComponentPropertyType.discipline)?.name ===
          "Metal"
        ) {
          setIsMetal(true);
        }

        conditionOptions.forEach((conditionOption) => {
          if (conditionOption.environment?.name) {
            const savedDegradation = instance.instanceConditionOptions?.find(
              ({ conditionOption: { id } }) => id === conditionOption.id,
            );

            const savedDegradations = degradationsEnvironmentsMap.get(conditionOption.environment.name) || [];
            if (!savedDegradations.some(({ id }) => id === conditionOption.degradation?.id))
              degradationsEnvironmentsMap.set(conditionOption.environment.name, [
                ...savedDegradations,
                ...(conditionOption.degradation
                  ? [
                      {
                        ...conditionOption.degradation,
                        conditionOptionId: conditionOption.id,
                        isAConcern: !!savedDegradation,
                        createdBy: savedDegradation?.createdBy,
                      },
                    ]
                  : []),
              ]);
          }
          conditionOptionsMap.set(conditionOption.id, conditionOption);
        });

        setDegradationsEnvironments(degradationsEnvironmentsMap);
        setConditionOptionsMap(conditionOptionsMap);
      }
    })();
  }, [instance, getConditionOptions, getInstanceOptionByType, getInstanceOptionsByType]);

  const redirectToInstance = () => {
    if (currentFieldNote) {
      history.push(
        generatePath(ROUTE_PATHS.INSTANCE_VIEW, {
          id: instanceId,
          fieldNoteId: String(currentFieldNote.id),
          plantId: String(currentFieldNote.plantId),
        }),
      );
    }
  };

  const handleSubmitQuestions = (conditionOptionsToAdd: { conditionOptionId: number; isAConcern: boolean }[]) => {
    dispatch(
      actions.addInstanceConditionOptions.request({
        conditionOptions: conditionOptionsToAdd,
        callback: redirectToInstance,
        instanceId,
      }),
    );
  };

  const prepareQuestions = (values: InstanceConditionNoteFormFields) => {
    const selectedDegradations = Object.entries(values.degradations)
      .filter(([, { value, isDefaultSelect }]) => value && !isDefaultSelect)
      .map(([composedId, degradation]) => [composedId.split("_")[0], degradation]);

    if (!selectedDegradations.length) {
      return;
    }

    const selectedDegradationsQuestions: QuestionsWithDegradation[] = selectedDegradations
      .map(([conditionOptionId]) => {
        let degradation: ConditionOption | undefined = undefined;
        let environment: ConditionOption | undefined = undefined;
        conditionOptionsMap.forEach((conditionOptions) => {
          if (conditionOptions.id === Number(conditionOptionId)) {
            degradation = conditionOptions.degradation;
            environment = conditionOptions.environment;
            return;
          }
        });
        if (degradation) {
          const questions: ConditionOptionsWithConcern[] = [];

          conditionOptionsMap.forEach((conditionOption) => {
            if (
              conditionOption.degradationId === Number(degradation?.id) &&
              conditionOption.environmentId === Number(environment?.id)
            ) {
              if (conditionOption.question) {
                questions.push({
                  id: `${conditionOptionId}_${conditionOption.question.id}`,
                  name: conditionOption.question.name,
                  isAConcern: undefined,
                  conditionOptionId: Number(conditionOption.id),
                });
              }
            }
          });

          return {
            conditionOptionId: Number(conditionOptionId),
            degradation: degradation as ConditionOption,
            questions,
          };
        }

        return undefined;
      })
      .filter(notEmpty);

    if (!isMetal) {
      const degradationsValues = selectedDegradationsQuestions.map(({ conditionOptionId }) => ({
        conditionOptionId,
        isAConcern: true,
      }));
      handleSubmitQuestions(degradationsValues);
    }

    setSelectedQuestions(
      selectedDegradationsQuestions.filter(
        (conditionsOptions) => !!conditionsOptions?.questions?.length && !!conditionsOptions.degradation,
      ),
    );
  };

  const removeQuestions = () => setSelectedQuestions(undefined);

  const isValuesSelected = (values: InstanceConditionNoteFormFields) => {
    return Object.values(values.degradations).some(({ value, isDefaultSelect }) => {
      return !!value && !isDefaultSelect;
    });
  };

  return (
    <Formik onSubmit={prepareQuestions} initialValues={initialValues} enableReinitialize>
      {(formikProps) => {
        return (
          <div className="instances-checked-concern">
            <InstanceHeader
              headerLabel={!selectedQuestions ? "Add Condition Note" : "Checking Concern"}
              onBackClick={!!selectedQuestions ? removeQuestions : redirectToInstance}
              backLabel={instance?.displayName}
              rightButtons={
                <>
                  {!selectedQuestions && (
                    <div
                      className={classnames("instances-save-button", {
                        disabled: !isValuesSelected(formikProps.values),
                      })}
                      onClick={formikProps.submitForm}
                    >
                      {isMetal ? "check concerns" : "add to instance"}
                    </div>
                  )}
                </>
              }
            />

            {!selectedQuestions ? (
              fieldsList?.map(([name, fields], idx) => {
                return (
                  <div key={name} className="expand-wrapper">
                    <ExpandPanel title={name}>
                      <FormGenerator formikProps={formikProps} key={`${name}_${idx}`} fields={fields} />
                    </ExpandPanel>
                  </div>
                );
              })
            ) : (
              <InstancesConditionNoteQuestionsWrapper
                questionsWithDegradation={selectedQuestions}
                handleSubmit={handleSubmitQuestions}
              />
            )}
          </div>
        );
      }}
    </Formik>
  );
};

export default InstancesConditionNoteFormContainer;
