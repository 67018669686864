import React, { useMemo, useState, useEffect, useCallback } from "react";

import { Document, Plant } from "../../../../../../shared/models";
import { formatDate } from "../../../../../../shared/utils";
import "./index.scss";
import { ActionMenu, AccessControl } from "../../../../../../shared/components";
import { ActionItemMenu } from "../../../../../../shared/components/ActionMenu/ActionMenu";
import { PERMISSION } from "../../../../../../shared/constants";
import { checkPermissions } from "../../../../../../shared/utils/ACL";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "../../../../../../shared/store/index";

interface DocumentCardProps {
  documentFile: Document;
  plant: Plant | null;
  onEdit: () => void;
  onRemove: () => void;
}

const DocumentCard: React.FunctionComponent<DocumentCardProps> = (props) => {
  const dispatch = useDispatch();
  const { documentFile, onEdit, onRemove, plant } = props;
  const [actionList, setActionList] = useState<ActionItemMenu[]>([]);

  const formattedDate = useMemo(() => {
    return documentFile.createdAt ? formatDate(new Date(documentFile.createdAt)) : "";
  }, [documentFile.createdAt]);

  const doc = useSelector(selectors.getFile(documentFile.key));

  useEffect(() => {
    if (doc) {
      const url = window.URL.createObjectURL(new Blob([doc], { type: "application/octet-stream" }));
      const a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      a.href = url;
      a.download = `${documentFile.displayName}.${documentFile.fileType}`;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }, [doc, documentFile]);

  useEffect(() => {
    const actions = [];
    if (checkPermissions([PERMISSION.DOCUMENT_EDIT], { plant })) {
      actions.push({ label: "EDIT", handler: onEdit });
    }

    if (checkPermissions([PERMISSION.DOCUMENT_DELETE])) {
      actions.push({ label: "REMOVE", handler: onRemove, isAlert: true });
    }

    setActionList(actions);
  }, [onEdit, onRemove, plant]);

  const openFile = useCallback(
    (key) => {
      dispatch(actions.getFile.request(key));
    },
    [dispatch],
  );

  return (
    <div className="document-container">
      <AccessControl permissions={[PERMISSION.DOCUMENT_EDIT, PERMISSION.DOCUMENT_DELETE]} plant={plant}>
        <ActionMenu actions={actionList} />
      </AccessControl>
      <div className="document-content" onClick={() => openFile(documentFile.key)}>
        <div className="header-document-container">
          <span>{documentFile.displayName}</span>
        </div>
        <div className="footer-document-container">
          <div className="created-at-block">{`Added: ${formattedDate}`}</div>
          <div className="created-by-block">{`by ${documentFile?.profile?.firstName} ${documentFile?.profile?.lastName}`}</div>
        </div>
      </div>
    </div>
  );
};

export default DocumentCard;
