import { Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";

import { Overlay } from "../";
import { FORM_ERROR_MESSAGES } from "../../constants";
import { FieldItem, FieldItemType, FormGenerator } from "../../formComponents";
import { Option } from "../../interfaces";
import { FieldNote, Plant } from "../../models";
import { prepareSelectData } from "../../utils";

import "./index.scss";

const validationSchema = () =>
  Yup.object().shape({
    displayName: Yup.string()
      .required(`Field Note Name ${FORM_ERROR_MESSAGES.REQUIRED}`)
      .max(50, `Field Note Name ${FORM_ERROR_MESSAGES.LONG}`),
    plantId: Yup.number().required(`Plant ${FORM_ERROR_MESSAGES.REQUIRED}`),
  });

const generateFormFields = (options?: Option[]): FieldItem[] => [
  {
    type: FieldItemType.SELECT,
    name: "plantId",
    label: "Plant",
    placeholder: "Select Plant",
    wrapperClass: "form-element-wrapper",
    options: options || [],
  },
  {
    type: FieldItemType.TEXT,
    name: "displayName",
    label: "Field Note Name",
    wrapperClass: "form-element-wrapper",
  },
];

interface EditFieldNoteModalProps {
  plants: Plant[];
  currentFieldNote: null | FieldNote;
  currentPlant: null | Plant;
  showHideUnsavedChangeModal: Function;
  submitFieldNote: Function;
}

interface FieldNoteFormValues {
  displayName: string;
  plantId?: string;
}

const prepareFieldNoteData = (data?: FieldNote): FieldNoteFormValues => {
  return {
    displayName: data?.displayName || "",
    plantId: data?.plantId?.toString() || undefined,
  };
};

const EditFieldNoteModal: React.FunctionComponent<EditFieldNoteModalProps> = (props) => {
  const { showHideUnsavedChangeModal, currentFieldNote, currentPlant, plants } = props;

  const [formValues, setFormValues] = useState<FieldNoteFormValues>(prepareFieldNoteData());
  const [fieldsList, setFieldsList] = useState(generateFormFields());
  const [validation, setValidation] = useState(validationSchema());

  useEffect(() => {
    if (currentFieldNote) {
      setFormValues(prepareFieldNoteData(currentFieldNote));
    } else if (currentPlant) {
      setFormValues({
        displayName: "",
        plantId: currentPlant.isActive ? currentPlant.id?.toString() : undefined,
      });
    }
    setValidation(validationSchema());
  }, [currentFieldNote, currentPlant, plants]);

  useEffect(() => {
    setFieldsList(generateFormFields(prepareSelectData(plants)));
  }, [plants]);

  return (
    <Formik
      validationSchema={validation}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        props.submitFieldNote(values);
      }}
      initialValues={formValues}
      enableReinitialize
      validateOnChange
      validateOnBlur={false}
    >
      {(formikProps) => (
        <Overlay onClose={() => showHideUnsavedChangeModal(false)}>
          <div className="edit-field-note-wrapper">
            <div className="edit-field-note-header">
              <div className="heading">{currentFieldNote ? "Edit Field Note" : "New Field Note"}</div>
            </div>
            <div className="edit-field-note-content">
              <FormGenerator formikProps={formikProps} fields={fieldsList} />
            </div>
            <div className="edit-field-note-actions">
              <div className="edit-field-note-button-panel">
                <button
                  className="action-button action-button-cancel"
                  onClick={() => showHideUnsavedChangeModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="action-button action-button-save"
                  disabled={!formikProps.dirty}
                  onClick={formikProps.submitForm}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Overlay>
      )}
    </Formik>
  );
};

export default EditFieldNoteModal;
