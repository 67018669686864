export const formatDate = (date: Date) => {
  // ex: August 12, 2020
  const options = { year: "numeric", month: "long", day: "numeric" };

  return date.toLocaleDateString("en-US", options);
};

export const formatDateWithTime = (date: Date) => {
  // ex: 08/20/2020
  const dateFormatter = new Intl.DateTimeFormat("en-us", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  // ex: 18:52
  const timeFormatter = new Intl.DateTimeFormat("en-us", {
    minute: "numeric",
    hour: "numeric",
    hour12: false,
  });

  // ex: 11:36, 08/20/2020
  return `${timeFormatter.format(date)}, ${dateFormatter.format(date)}  `;
};

export const getUSFormattedDate = (date: Date | string | undefined | null) => {
  // ex: 20/08/2020
  return date ? new Intl.DateTimeFormat("en-GB").format(new Date(date)) : "-";
};

export const formatDateAm = (date: Date) => {
  //Sep 05, 09:00 pm
  const options = { month: "short", day: "numeric", hour: "2-digit", minute: "2-digit" };

  return date.toLocaleDateString("en-US", options);
};

export const getUTCDate = (date: Date | [Date, Date] | null): Date | null | [Date, Date] => {
  if (Array.isArray(date)) {
    const [start, end] = date;
    if (start instanceof Date && end instanceof Date) {
      return [getSimpleUTCDate(start), getSimpleUTCDate(end)] as [Date, Date];
    }
    return null;
  } else {
    return getSimpleUTCDate(date);
  }
};

export const getSimpleUTCDate = (date: Date | null): Date | null => {
  if (date instanceof Date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  } else {
    return null;
  }
};
