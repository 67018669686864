import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actions as sharedActions, selectors as sharedSelectors } from "../../store";
import { AppLoader } from "../../components/AppLoader";
import useDebounce from "../DebounceHook";

//eslint-disable-next-line
type AnyFunction = (params?: any) => { type: string };
interface ActionTypes {
  request: AnyFunction;
  success: AnyFunction;
  failure: AnyFunction;
}

interface UseLoaderProps {
  name: string;
  actionTypes: ActionTypes[];
}

const getTypes = (actionTypes: ActionTypes[], types: Array<keyof ActionTypes>) => {
  return actionTypes.map((actions) => types.map((type) => actions[type] && actions[type]().type)).flat();
};

const useLoader = ({ name, actionTypes }: UseLoaderProps) => {
  const dispatch = useDispatch();
  const isLoading = !!useSelector(sharedSelectors.getLoadingSections(name));
  const isLoadingDebounced = useDebounce(isLoading, 750);

  useEffect(() => {
    if (name && actionTypes) {
      const loadingType = {
        name,
        startActions: getTypes(actionTypes, ["request"]),
        stopActions: getTypes(actionTypes, ["success", "failure"]),
      };

      dispatch(sharedActions.addLoadingType(loadingType));
    }

    return () => {
      dispatch(sharedActions.removeLoadingType(name));
    };
    // eslint-disable-next-line
  }, [name, dispatch]);

  return { isLoading, AppLoader, isLoadingDebounced: isLoading || isLoadingDebounced };
};

export default useLoader;
