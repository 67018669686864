import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";
import { CheckboxWrapper } from "./CheckboxWrapper";
import { InputProps } from "../InputProps";

export const CheckboxInput: React.FunctionComponent<InputProps> = (props: InputProps) => {
  const { className, disabled, handleClick, ...inputProps } = props;
  return (
    <CheckboxWrapper className={className}>
      <label className="no-select" onClick={handleClick}>
        <span className="heading">{props.label}</span>
        <input
          type="checkbox"
          {...inputProps}
          disabled={disabled}
          onChange={inputProps.onChange}
          checked={!!props.value}
        />
        <span className={classnames("checkmark", { disabled: disabled })} />
      </label>
    </CheckboxWrapper>
  );
};
CheckboxInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
};

CheckboxInput.defaultProps = {
  label: "",
  type: "checkbox",
  name: "",
};
