import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory } from "react-router";

import { actions as sharedActions, selectors as sharedSelectors } from "../../store";
import { ROUTE_PATHS } from "../../routes";
import { AppLoader } from "..";

// Core viewer
import { Viewer, Worker } from "@react-pdf-viewer/core";
// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { MoreActionsPopover, ToolbarProps, ToolbarSlot } from "@react-pdf-viewer/toolbar";
import {
  highlightPlugin,
  RenderHighlightsProps,
  RenderHighlightTargetProps,
  HighlightArea,
} from "@react-pdf-viewer/highlight";
import { RenderCurrentPageLabelProps } from "@react-pdf-viewer/page-navigation";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import "./pdfReader.scss";
import classnames from "classnames";
import { FieldGuideReferenceArea } from "../../models/FieldGuideReferenceArea";
import { setSelectedPdfAreas } from "../../../containers/Instances/store/actions";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { OnHighlightKeyword } from "@react-pdf-viewer/search";

interface PdfReaderProps {
  instanceName?: string;
  currentPage?: number | undefined;
  highlightAreas?: HighlightArea[] | undefined;
  uid?: string;
  enableReferences?: boolean;
  createReferenceFromInstance?: (data: { page?: number; areas?: FieldGuideReferenceArea[] }) => void;
  closeModalHandler?: () => void;
}

export default function PdfReader(props: PdfReaderProps) {
  const {
    currentPage,
    createReferenceFromInstance,
    closeModalHandler,
    instanceName,
    highlightAreas,
    uid,
    enableReferences = true,
  } = props;
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  const [binaryGuideUrl, setBinaryGuideUrl] = useState<string | null>(null);

  const fieldGuideUid = useSelector(sharedSelectors.getFieldGuide())?.key;

  const uidToRequest = uid || fieldGuideUid;

  const binaryGuide = useSelector(sharedSelectors.getFile(uidToRequest));

  useEffect(() => {
    if (!uid && !fieldGuideUid && !binaryGuideUrl) {
      dispatch(sharedActions.getFieldGuides.request());
    }
  }, [dispatch, fieldGuideUid, binaryGuideUrl, uid]);

  useEffect(() => {
    if (uidToRequest && !binaryGuide) {
      dispatch(sharedActions.getFile.request(uidToRequest));
    }
  }, [dispatch, uidToRequest, binaryGuide]);

  useEffect(() => {
    if (binaryGuide) {
      setBinaryGuideUrl(URL.createObjectURL(binaryGuide));
    }
  }, [binaryGuide]);

  useEffect(
    () => () => {
      binaryGuideUrl && URL.revokeObjectURL(binaryGuideUrl);
    },
    [binaryGuideUrl],
  );

  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          CurrentPageInput,
          CurrentPageLabel,
          EnterFullScreen,
          GoToFirstPage,
          GoToLastPage,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Print,
          ShowSearchPopover,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;
        return (
          <div className="pdfReader_toolbar">
            <div className="pdfReader_toolbar_block">
              {closeModalHandler && (
                <div className="pdfReader_toolbar_redirect" onClick={closeModalHandler}>
                  <img src="/icons/general/left-arrow.svg" alt="close" />
                  {instanceName}
                </div>
              )}
              {/* <div className="pdfReader_toolbar_button">{toolbarSlot.toggleSidebarButton}</div> */}
              <div className="pdfReader_toolbar_button">
                <div style={{ padding: "0px 2px" }}>
                  <ShowSearchPopover />
                </div>
              </div>
              <div className="pdfReader_toolbar_button">
                <div className="pdfReader_toolbar_button_hidden">
                  <GoToPreviousPage></GoToPreviousPage>
                </div>
                <img src="/icons/pdfTooltip/previousPage.svg" alt="previous page" />
              </div>
              <div className="viewer-toolbar-current-page-input">
                <CurrentPageInput></CurrentPageInput>
              </div>
              <div className="pdfReader_toolbar_button">
                / <NumberOfPages></NumberOfPages>
              </div>
              <div className="pdfReader_toolbar_button">
                <div className="pdfReader_toolbar_button_hidden">
                  <GoToNextPage></GoToNextPage>
                </div>
                <img src="/icons/pdfTooltip/nextPage.svg" alt="next page" />
              </div>
            </div>
            <div className="pdfReader_toolbar_block">
              <div className="pdfReader_toolbar_button">
                <div className="pdfReader_toolbar_button_hidden">
                  <ZoomOut></ZoomOut>
                </div>
                <img src="/icons/pdfTooltip/zoomOut.svg" alt="zoom out" />
              </div>
              <div>
                <Zoom></Zoom>
              </div>
              <div className="pdfReader_toolbar_button">
                <div className="pdfReader_toolbar_button_hidden">
                  <ZoomIn></ZoomIn>
                </div>
                <img src="/icons/pdfTooltip/zoomIn.svg" alt="zoom in" />
              </div>
            </div>
            <div className="pdfReader_toolbar_block">
              <div className="pdfReader_toolbar_button">
                <div className="pdfReader_toolbar_button_hidden">
                  <EnterFullScreen></EnterFullScreen>
                </div>
                <img src="/icons/pdfTooltip/fullScreen.svg" alt="full screen" />
              </div>
              <div className="pdfReader_toolbar_button">
                <div className="pdfReader_toolbar_button_hidden">
                  <GoToLastPage></GoToLastPage>
                </div>
                <img src="/icons/pdfTooltip/lastPage.svg" alt="last page" />
              </div>
              <div className="pdfReader_toolbar_button">
                <div className="pdfReader_toolbar_button_hidden">
                  <GoToFirstPage></GoToFirstPage>
                </div>
                <img src="/icons/pdfTooltip/firstPage.svg" alt="first page" />
              </div>
              <div className="pdfReader_toolbar_button">
                <div className="pdfReader_toolbar_button_hidden">
                  <Print></Print>
                </div>
                <img src="/icons/pdfTooltip/print.svg" alt="print" />
              </div>
              <div style={{ padding: "0px 2px" }}>
                <MoreActionsPopover toolbarSlot={slots} />
              </div>
              {enableReferences && (
                <CurrentPageLabel>
                  {(props: RenderCurrentPageLabelProps) => (
                    <div
                      className="pdfReader_toolbar_reference"
                      onClick={() => copyPageHandler(Number(props.currentPage))}
                    >
                      Create reference
                    </div>
                  )}
                </CurrentPageLabel>
              )}
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    toolbarPlugin: {
      searchPlugin: {
        onHighlightKeyword: (props: OnHighlightKeyword) => {
          props.highlightEle.style.backgroundColor = "yellow";
        },
      },
    },
  });

  const renderHighlightTarget = (highlightTargetProps: RenderHighlightTargetProps) => {
    return (
      <div
        style={{
          background: "#eee",
          display: "flex",
          position: "absolute",
          left: `${highlightTargetProps.selectionRegion.left}%`,
          top: `${highlightTargetProps.selectionRegion.top + highlightTargetProps.selectionRegion.height}%`,
          transform: "translate(0, 8px)",
          borderRadius: "50px",
        }}
      >
        <div
          className="pdfReader_toolbar_reference_inline"
          onClick={() => {
            highlightTargetProps.toggle();
            copyAreaHandler(highlightTargetProps?.highlightAreas);
          }}
        >
          Create reference
        </div>
      </div>
    );
  };

  const renderHighlights = (props: RenderHighlightsProps) => (
    <div>
      {highlightAreas &&
        highlightAreas
          .filter((area: HighlightArea) => area.pageIndex === props.pageIndex)
          .map((highlightArea, index) => (
            <div
              key={index}
              style={{
                ...{ background: "yellow", opacity: 0.3 },
                ...props.getCssProperties(highlightArea, props.rotation),
              }}
            />
          ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget: enableReferences ? renderHighlightTarget : undefined,
    renderHighlights,
  });
  const { jumpToHighlightArea } = highlightPluginInstance;

  const displayHighlightedFragment = useCallback(
    (firstHighlightedRow: HighlightArea | undefined) => {
      if (firstHighlightedRow) {
        jumpToHighlightArea(firstHighlightedRow);
      }
    },
    [jumpToHighlightArea],
  );

  const copyAreaHandler = useCallback(
    (highlightAreas: HighlightArea[]) => {
      dispatch(setSelectedPdfAreas(highlightAreas));
      if (createReferenceFromInstance && highlightAreas && highlightAreas.length) {
        createReferenceFromInstance({ page: highlightAreas[0].pageIndex, areas: highlightAreas });
        return;
      }

      history.push(generatePath(ROUTE_PATHS.FIELD_GUIDE_REFERENCE, { id: highlightAreas[0]?.pageIndex }));
    },
    [createReferenceFromInstance, history, dispatch],
  );

  const copyPageHandler = (page: number) => {
    dispatch(setSelectedPdfAreas(null));
    if (createReferenceFromInstance) {
      createReferenceFromInstance({ page });
      return;
    }

    history.push(generatePath(ROUTE_PATHS.FIELD_GUIDE_REFERENCE, { id: page }));
  };

  const handleStopLoading = () => {
    setIsLoading(false);
    if (highlightAreas && highlightAreas.length) {
      displayHighlightedFragment(highlightAreas[0]);
    }
  };

  return (
    <div className="pdfReader">
      {isLoading && <AppLoader />}
      {/* workerUrl's version should be same as "pdfjs-dist" package in .json file 
        for now it is 2.5.207
      */}
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
        {binaryGuideUrl ? (
          <div
            className={classnames("viewerWrapper", {
              hidden: isLoading,
            })}
          >
            <Viewer
              fileUrl={binaryGuideUrl}
              initialPage={currentPage || 0}
              plugins={[defaultLayoutPluginInstance, highlightPluginInstance]}
              onDocumentLoad={handleStopLoading}
            />
          </div>
        ) : null}
      </Worker>
    </div>
  );
}
