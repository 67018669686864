import { useEffect, useRef } from "react";
import { FormikProps } from "formik";
import { Option } from "../../../interfaces";
import { isArray } from "lodash";

// eslint-disable-next-line
const isEqualValues = (previousValue: any, newValue: any, skipInitUpdate?: boolean, dirty?: boolean): boolean => {
  if (isArray(previousValue) && isArray(newValue)) {
    if (previousValue && newValue && newValue.length === 0 && previousValue.length === 0) {
      return false;
    }
  }

  if (skipInitUpdate && !dirty) {
    return false;
  }
  return previousValue !== newValue;
};

const FormikChangeHandler = <T,>(props: {
  formikProps: FormikProps<T>;
  subscribeProps: Array<keyof T>;
  handleChange: (
    changedValueName: keyof T,
    values: T,
    formikHelpers: {
      setFieldValue: (field: string, value?: string | Option[]) => void;
      // eslint-disable-next-line
      setValues: (values: { [key: string]: any }, shouldValidate?: boolean | undefined) => void;
    },
  ) => void;
  skipInitUpdate?: boolean;
  children: JSX.Element;
}) => {
  const { children, formikProps, subscribeProps, handleChange, skipInitUpdate } = props;
  const ref = useRef<T>();
  useEffect(() => {
    const changedValueName =
      ref.current !== undefined
        ? subscribeProps.find((subscribeProp) => {
            return isEqualValues(
              ref.current?.[subscribeProp],
              formikProps.values[subscribeProp],
              skipInitUpdate,
              formikProps.dirty,
            );
          })
        : undefined;

    if (changedValueName) {
      handleChange(changedValueName, formikProps.values, {
        setFieldValue: formikProps.setFieldValue,
        setValues: formikProps.setValues as (
          // eslint-disable-next-line
          values: { [key: string]: any },
          shouldValidate?: boolean | undefined,
        ) => void,
      });
    }

    ref.current = formikProps.values;
  }, [formikProps, subscribeProps, handleChange, skipInitUpdate]);

  return children;
};

export default FormikChangeHandler;
