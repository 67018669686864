import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, generatePath } from "react-router-dom";

import {
  AddButton,
  InstanceHeader,
  Modal,
  RemoveModalContent,
  AccessControl,
  PdfReader,
} from "../../../../shared/components";
import { ROUTE_PATHS } from "../../../../shared/routes";
import { selectors as plantsSelectors } from "../../../CompaniesPlants/store";
import { selectors as fieldNotesSelectors, actions as fieldNotesActions } from "../../../FieldNotes/store";
import { RouteComponentProps } from "../../../../shared/interfaces";
import { actions, selectors } from "../../store";
import "./index.scss";
import { PERMISSION } from "../../../../shared/constants";
import { ConditionOptionsWithConcern, FieldGuideReference } from "../../../../shared/models";
import { ConditionNotesList } from "./components";
import { FieldGuideReferenceArea } from "../../../../shared/models/FieldGuideReferenceArea";
import { HighlightArea } from "@react-pdf-viewer/highlight";
import { getFieldGuide } from "../../../../shared/store/selectors";
import { CompanyFieldGuides } from "../FieldGuideSelectContainer/components/CompanyFieldGuides";

interface InstancesConditionNotesContainerProps extends RouteComponentProps<{ id: number; fieldNoteId: number }> {}

const InstancesConditionNotesContainer: React.FunctionComponent<InstancesConditionNotesContainerProps> = (props) => {
  const {
    match: {
      params: { id: instanceId, fieldNoteId },
    },
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const instance = useSelector(selectors.getInstance());
  const plant = useSelector(plantsSelectors.getCurrentPlant());
  const currentFieldNote = useSelector(fieldNotesSelectors.getCurrentFieldNote());
  const fieldGuide = useSelector(getFieldGuide());

  const [concernToDelete, setConcernToDelete] = useState<number | undefined>(undefined);
  const [degradations, setDegradations] = useState<[string, ConditionOptionsWithConcern[]][] | undefined>(undefined);
  const [notActualDegradations, setNotActualDegradations] = useState<
    [string, ConditionOptionsWithConcern[]][] | undefined
  >(undefined);
  const [showFieldGuide, setShowFieldGuide] = useState(false);
  const [degradationId, setDegradationId] = useState<number | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState<number | undefined>();
  const [highlightAreas, setHighlightAreas] = useState<HighlightArea[] | undefined>();

  const toggleFieldGuideDisplayState = () => {
    setShowFieldGuide(!showFieldGuide);
  };

  const redirectToInstance = useCallback(() => {
    if (currentFieldNote) {
      history.push(
        generatePath(ROUTE_PATHS.INSTANCE_VIEW, {
          id: instanceId,
          fieldNoteId: String(currentFieldNote.id),
          plantId: String(currentFieldNote.plantId),
        }),
      );
    }
  }, [history, currentFieldNote, instanceId]);

  useEffect(() => {
    if (!instance) {
      dispatch(actions.getInstance.request(instanceId));
    }
  }, [instanceId, dispatch, instance]);

  useEffect(() => {
    if (fieldNoteId) {
      dispatch(fieldNotesActions.getFieldNote.request(fieldNoteId));
    }
  }, [dispatch, fieldNoteId]);

  useEffect(() => {
    if (instance?.instanceConditionOptions) {
      const degradationsMap = new Map<string, ConditionOptionsWithConcern[]>();
      const notActualDegradationsMap = new Map<string, ConditionOptionsWithConcern[]>();

      instance.instanceConditionOptions.forEach(({ conditionOption, isActual, isAConcern, isActive }) => {
        if (!isActive) {
          return;
        }

        if (isActual) {
          if (conditionOption.degradation && conditionOption.environment) {
            const savedDegradations = degradationsMap.get(conditionOption.environment.name) || [];
            const isMetal = conditionOption.discipline?.name === "Metal";

            const alreadySavedDegradation = savedDegradations.find(({ id }) => id === conditionOption.degradation?.id);

            if (!alreadySavedDegradation) {
              degradationsMap.set(conditionOption.environment.name, [
                ...savedDegradations,
                ...(conditionOption.degradation
                  ? [{ ...conditionOption.degradation, isAConcern, isMetal, conditionOptionId: conditionOption.id }]
                  : []),
              ]);
            } else {
              degradationsMap.set(
                conditionOption.environment.name,
                savedDegradations.map((degradation) => {
                  if (degradation.id === alreadySavedDegradation.id) {
                    return { ...degradation, isAConcern: alreadySavedDegradation.isAConcern || isAConcern };
                  }
                  return degradation;
                }),
              );
            }
          }
        } else {
          if (conditionOption.degradation && conditionOption.environment) {
            const savedDegradations = notActualDegradationsMap.get(conditionOption.environment.name) || [];
            const isMetal = conditionOption.discipline?.name === "Metal";

            notActualDegradationsMap.set(conditionOption.environment.name, [
              ...savedDegradations,
              ...(conditionOption.degradation
                ? [{ ...conditionOption.degradation, isAConcern, isMetal, conditionOptionId: conditionOption.id }]
                : []),
            ]);
          }
        }
      });

      const degradationsEnvironment: [string, ConditionOptionsWithConcern[]][] = [];
      degradationsMap.forEach((degradations, environment) => {
        degradationsEnvironment.push([environment, degradations]);
      });
      const notActualDegradationsEnvironment: [string, ConditionOptionsWithConcern[]][] = [];
      notActualDegradationsMap.forEach((degradations, environment) => {
        notActualDegradationsEnvironment.push([environment, degradations]);
      });

      if (!degradationsEnvironment.length && !notActualDegradationsEnvironment.length) {
        redirectToInstance();
      }
      setDegradations(degradationsEnvironment);
      setNotActualDegradations(notActualDegradationsEnvironment);
    }
  }, [instance, redirectToInstance]);

  const redirectToConditionNoteForm = () => {
    if (currentFieldNote) {
      history.push(
        generatePath(ROUTE_PATHS.CONDITION_NOTES_FORM, {
          id: instanceId,
          fieldNoteId: String(currentFieldNote.id),
          plantId: String(currentFieldNote.plantId),
        }),
      );
    }
  };

  const redirectToConditionNoteConcern = (degradationId: number) => {
    if (currentFieldNote) {
      history.push(
        generatePath(ROUTE_PATHS.CONDITION_NOTE_CONCERN, {
          id: instanceId,
          fieldNoteId: String(currentFieldNote.id),
          plantId: String(currentFieldNote.plantId),
          degradationId,
        }),
      );
    }
  };

  const handleRemoveOption = () => {
    if (concernToDelete !== undefined) {
      dispatch(
        actions.removeInstanceConditionOptions.request({
          instanceId,
          conditionOptions: [{ conditionOptionId: concernToDelete }],
        }),
      );
      setConcernToDelete(undefined);
    }
  };

  const createFieldGuideReferenceHandler = (degrId: number) => {
    setDegradationId(degrId);
    toggleFieldGuideDisplayState();
  };

  const openFieldGuideReferenceHandler = (reference: FieldGuideReference, degrId?: number) => {
    if (degrId) {
      setDegradationId(degrId);
    }
    if (reference.page) {
      setCurrentPage(reference.page);
    }
    if (reference.fieldGuideReferenceAreas) {
      setHighlightAreas(reference.fieldGuideReferenceAreas);
    }
    toggleFieldGuideDisplayState();
  };

  const handleCopyPage = (data: { page?: number; areas?: FieldGuideReferenceArea[] }) => {
    const payload = {
      fieldGuideDocumentId: Number(fieldGuide?.id),
      instanceId,
      page: data.page,
      degradationId,
      areas: data.areas,
      callback: toggleFieldGuideDisplayState,
    };
    dispatch(actions.addFieldGuideReferenceToInstance.request(payload));
  };

  return (
    <>
      {!showFieldGuide ? (
        <div className="instances-condition-notes">
          <InstanceHeader
            headerLabel="Instance Condition Notes"
            onBackClick={redirectToInstance}
            backLabel={instance?.displayName}
            rightButtons={<></>}
          />
          <div className="conditions">
            <ConditionNotesList
              degradations={degradations}
              isActual
              plant={plant}
              setConcernToDelete={setConcernToDelete}
              redirectToConditionNoteConcern={redirectToConditionNoteConcern}
              instance={instance}
              createReferenceHandler={createFieldGuideReferenceHandler}
              openReferenceHandler={openFieldGuideReferenceHandler}
            />
            <ConditionNotesList
              degradations={notActualDegradations}
              isActual={false}
              plant={plant}
              setConcernToDelete={setConcernToDelete}
              redirectToConditionNoteConcern={redirectToConditionNoteConcern}
              instance={instance}
              createReferenceHandler={createFieldGuideReferenceHandler}
              openReferenceHandler={openFieldGuideReferenceHandler}
            />
          </div>
          <AccessControl permissions={[PERMISSION.CONDITION_NOTE_CREATE]} plant={plant} instance={instance}>
            <AddButton onClick={redirectToConditionNoteForm} />
          </AccessControl>
          <Modal isShowing={concernToDelete !== undefined} onClose={() => setConcernToDelete(undefined)} boxPadding>
            <RemoveModalContent
              heading="Remove concern"
              content="Are you sure you want to remove concern?"
              cancelText="Cancel"
              removeText="Remove"
              onClose={() => setConcernToDelete(undefined)}
              onDelete={handleRemoveOption}
            />
          </Modal>
        </div>
      ) : (
        <div className="field-guide-select-container">
          {fieldGuide ? (
            <PdfReader
              closeModalHandler={toggleFieldGuideDisplayState}
              currentPage={currentPage}
              highlightAreas={highlightAreas}
              createReferenceFromInstance={handleCopyPage}
              instanceName={instance?.displayName}
            />
          ) : (
            plant?.companyId && <CompanyFieldGuides companyId={plant.companyId} />
          )}
        </div>
      )}
    </>
  );
};

export default InstancesConditionNotesContainer;
