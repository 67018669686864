export enum ActionTypes {
  UPDATE_IMAGE = "@@DASHBOARD/UPDATE_IMAGE",
  UPDATE_IMAGE_SUCCESS = "@@DASHBOARD/UPDATE_IMAGE_SUCCESS",
  UPDATE_IMAGE_FAILURE = "@@DASHBOARD/UPDATE_IMAGE_FAILURE",

  DELETE_IMAGE = "@@DASHBOARD/DELETE_IMAGE",
  DELETE_IMAGE_SUCCESS = "@@DASHBOARD/DELETE_IMAGE_SUCCESS",
  DELETE_IMAGE_FAILURE = "@@DASHBOARD/DELETE_IMAGE_FAILURE",

  DELETE_VIDEO = "@@DASHBOARD/DELETE_VIDEO",
  DELETE_VIDEO_SUCCESS = "@@DASHBOARD/DELETE_VIDEO_SUCCESS",
  DELETE_VIDEO_FAILURE = "@@DASHBOARD/DELETE_VIDEO_FAILURE",

  GET_INSTANCE = "@@DASHBOARD/GET_INSTANCE",
  GET_INSTANCE_SUCCESS = "@@DASHBOARD/GET_INSTANCE_SUCCESS",
  GET_INSTANCE_FAILURE = "@@DASHBOARD/GET_INSTANCE_FAILURE",

  GET_INSTANCE_PDF = "@@DASHBOARD/GET_INSTANCE_PDF",
  GET_INSTANCE_PDF_SUCCESS = "@@DASHBOARD/GET_INSTANCE_PDF_SUCCESS",
  GET_INSTANCE_PDF_FAILURE = "@@DASHBOARD/GET_INSTANCE_PDF_FAILURE",

  CLEAN_INSTANCE_PDF = "@@DASHBOARD/CLEAN_INSTANCE_PDF",
  SET_SELECTED_PDF_AREAS = "@@DASHBOARD/SET_SELECTED_PDF_AREAS",

  GET_INSTANCES = "@@DASHBOARD/GET_INSTANCES",
  GET_INSTANCES_SUCCESS = "@@DASHBOARD/GET_INSTANCES_SUCCESS",
  GET_INSTANCES_FAILURE = "@@DASHBOARD/GET_INSTANCES_FAILURE",

  GET_INSTANCES_BY_FIELD_NOTE = "@@DASHBOARD/GET_INSTANCES_BY_FIELD_NOTE",
  GET_INSTANCES_BY_FIELD_NOTE_SUCCESS = "@@DASHBOARD/GET_INSTANCES_BY_FIELD_NOTE_SUCCESS",
  GET_INSTANCES_BY_FIELD_NOTE_FAILURE = "@@DASHBOARD/GET_INSTANCES_BY_FIELD_NOTE_FAILURE",

  CREATE_INSTANCE = "@@DASHBOARD/CREATE_INSTANCE",
  CREATE_INSTANCE_SUCCESS = "@@DASHBOARD/CREATE_INSTANCE_SUCCESS",
  CREATE_INSTANCE_FAILURE = "@@DASHBOARD/CREATE_INSTANCE_FAILURE",

  UPDATE_INSTANCE = "@@DASHBOARD/UPDATE_INSTANCE",
  UPDATE_INSTANCE_SUCCESS = "@@DASHBOARD/UPDATE_INSTANCE_SUCCESS",
  UPDATE_INSTANCE_FAILURE = "@@DASHBOARD/UPDATE_INSTANCE_FAILURE",

  ADD_INSTANCE_CONDITION_OPT = "@@DASHBOARD/ADD_INSTANCE_CONDITION_OPT",
  ADD_INSTANCE_CONDITION_OPT_SUCCESS = "@@DASHBOARD/ADD_INSTANCE_CONDITION_OPT_SUCCESS",
  ADD_INSTANCE_CONDITION_OPT_FAILURE = "@@DASHBOARD/ADD_INSTANCE_CONDITION_OPT_FAILURE",

  UPDATE_INSTANCE_CONDITION_OPT = "@@DASHBOARD/UPDATE_INSTANCE_CONDITION_OPT",
  UPDATE_INSTANCE_CONDITION_OPT_SUCCESS = "@@DASHBOARD/UPDATE_INSTANCE_CONDITION_OPT_SUCCESS",
  UPDATE_INSTANCE_CONDITION_OPT_FAILURE = "@@DASHBOARD/UPDATE_INSTANCE_CONDITION_OPT_FAILURE",

  REMOVE_INSTANCE_CONDITION_OPT = "@@DASHBOARD/REMOVE_INSTANCE_CONDITION_OPT",
  REMOVE_INSTANCE_CONDITION_OPT_SUCCESS = "@@DASHBOARD/REMOVE_INSTANCE_CONDITION_OPT_SUCCESS",
  REMOVE_INSTANCE_CONDITION_OPT_FAILURE = "@@DASHBOARD/REMOVE_INSTANCE_CONDITION_OPT_FAILURE",

  DELETE_INSTANCE = "@@DASHBOARD/DELETE_INSTANCE",
  DELETE_INSTANCE_SUCCESS = "@@DASHBOARD/DELETE_INSTANCE_SUCCESS",
  DELETE_INSTANCE_FAILURE = "@@DASHBOARD/DELETE_INSTANCE_FAILURE",

  GET_PLANT_PROFILES = "@@DASHBOARD/GET_PLANT_PROFILES",
  GET_PLANT_PROFILES_SUCCESS = "@@DASHBOARD/GET_PLANT_PROFILES_SUCCESS",
  GET_PLANT_PROFILES_FAILURE = "@@DASHBOARD/GET_PLANT_PROFILES_FAILURE",

  GET_INSTANCE_COLLABORATORS = "@@DASHBOARD/GET_INSTANCE_COLLABORATORS",
  GET_INSTANCE_COLLABORATORS_SUCCESS = "@@DASHBOARD/GET_INSTANCE_COLLABORATORS_SUCCESS",
  GET_INSTANCE_COLLABORATORS_FAILURE = "@@DASHBOARD/GET_INSTANCE_COLLABORATORS_FAILURE",

  ADD_INSTANCE_COLLABORATORS = "@@DASHBOARD/ADD_INSTANCE_COLLABORATORS",
  ADD_INSTANCE_COLLABORATORS_SUCCESS = "@@DASHBOARD/ADD_INSTANCE_COLLABORATORS_SUCCESS",
  ADD_INSTANCE_COLLABORATORS_FAILURE = "@@DASHBOARD/ADD_INSTANCE_COLLABORATORS_FAILURE",

  DELETE_INSTANCE_COLLABORATOR = "@@DASHBOARD/DELETE_INSTANCE_COLLABORATOR",
  DELETE_INSTANCE_COLLABORATOR_SUCCESS = "@@DASHBOARD/DELETE_INSTANCE_COLLABORATOR_SUCCESS",
  DELETE_INSTANCE_COLLABORATOR_FAILURE = "@@DASHBOARD/DELETE_INSTANCE_COLLABORATOR_FAILURE",

  GET_INSTANCE_COMMENTS = "@@DASHBOARD/GET_INSTANCE_COMMENTS",
  GET_INSTANCE_COMMENTS_SUCCESS = "@@DASHBOARD/GET_INSTANCE_COMMENTS_SUCCESS",
  GET_INSTANCE_COMMENTS_FAILURE = "@@DASHBOARD/GET_INSTANCE_COMMENTS_FAILURE",

  CREATE_COMMENT = "@@DASHBOARD/CREATE_COMMENT",
  CREATE_COMMENT_SUCCESS = "@@DASHBOARD/CREATE_COMMENT_SUCCESS",
  CREATE_COMMENT_FAILURE = "@@DASHBOARD/CREATE_COMMENT_FAILURE",

  UPDATE_COMMENT = "@@DASHBOARD/UPDATE_COMMENT",
  UPDATE_COMMENT_SUCCESS = "@@DASHBOARD/UPDATE_COMMENT_SUCCESS",
  UPDATE_COMMENT_FAILURE = "@@DASHBOARD/UPDATE_COMMENT_FAILURE",

  DELETE_COMMENT = "@@DASHBOARD/DELETE_COMMENT",
  DELETE_COMMENT_SUCCESS = "@@DASHBOARD/DELETE_COMMENT_SUCCESS",
  DELETE_COMMENT_FAILURE = "@@DASHBOARD/DELETE_COMMENT_FAILURE",

  FIELD_GUIDE_REFERENCE_ADD = "@@DASHBOARD/FIELD_GUIDE_REFERENCE_ADD",
  FIELD_GUIDE_REFERENCE_ADD_SUCCESS = "@@DASHBOARD/FIELD_GUIDE_REFERENCE_ADD_SUCCESS",
  FIELD_GUIDE_REFERENCE_ADD_FAILURE = "@@DASHBOARD/FIELD_GUIDE_REFERENCE_ADD_FAILURE",

  FIELD_GUIDE_REFERENCE_DELETE = "@@DASHBOARD/FIELD_GUIDE_REFERENCE_DELETE",
  FIELD_GUIDE_REFERENCE_DELETE_SUCCESS = "@@DASHBOARD/FIELD_GUIDE_REFERENCE_DELETE_SUCCESS",
  FIELD_GUIDE_REFERENCE_DELETE_FAILURE = "@@DASHBOARD/FIELD_GUIDE_REFERENCE_DELETE_FAILURE",

  SAVE_VOICE_NOTE_FOR_IMAGE = "@@DASHBOARD/SAVE_VOICE_NOTE_FOR_IMAGE",
  SAVE_VOICE_NOTE_FOR_IMAGE_SUCCESS = "@@DASHBOARD/SAVE_VOICE_NOTE_FOR_IMAGE_SUCCESS",
  SAVE_VOICE_NOTE_FOR_IMAGE_FAILURE = "@@DASHBOARD/SAVE_VOICE_NOTE_FOR_IMAGE_FAILURE",
}
