import React from "react";
import { useDispatch } from "react-redux";
import { generatePath, useHistory } from "react-router";
import { ROUTE_PATHS } from "../../../../shared/routes";
import { tokenHandler } from "../../../../shared/utils";
import classNames from "./license.module.scss";
import { actions } from "../../../Auth/store";
import { checkIsTutorialCompleted } from "../../../../shared/utils/ACL";

const LicenceAgreement = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleAccept = () => {
    tokenHandler.setIsLicenseAgreed(true);
    history.push(generatePath(checkIsTutorialCompleted() ? ROUTE_PATHS.COMPANIES_DASHBOARD : ROUTE_PATHS.TUTORIAL));
  };

  const handleReject = () => {
    dispatch(actions.logout.request());
  };

  return (
    <div className={classNames.licenseWrapper}>
      <div className={classNames.header}>EPRI License Agreement</div>
      <div className={classNames.body}>
        <div className={`${classNames.article} ${classNames.bordered}`}>
          <div className={classNames.title}>Digital Assistant Version 1.0</div>
          <div className={classNames.content}>
            Electric Power Research Institute (EPRI) <br />
            3420 Hillview Ave. <br />
            Palo Alto, CA 94304
          </div>
          <div className={classNames.subContent}>
            {`Copyright © ${new Date().getFullYear()} Electric Power Research Institute, Inc. All rights reserved.`}
          </div>
        </div>

        <div className={classNames.article}>
          <div className={classNames.content}>
            As a user of this EPRI Beta software, you accept and acknowledge that:
          </div>
        </div>

        <div className={classNames.article}>
          <div className={classNames.content}>
            * This software is a Beta version which may have problems that could potentially harm your system.
          </div>
        </div>
        <div className={classNames.article}>
          <div className={classNames.content}>
            * To satisfy the terms and conditions of the Master License Agreement or Beta License Agreement between
            <br />
            EPRI and your company, you understand what to do with this Beta product after the Beta review period
            <br />
            has expired.
          </div>
        </div>

        <div className={classNames.article}>
          <div className={classNames.content}>
            * Reproduction or distribution of this Beta software is in violation of the terms and conditions of the
            <br />
            Master License Agreement or Beta License Agreement currently in place between EPRI and your company.
          </div>
        </div>

        <div className={classNames.article}>
          <div className={classNames.content}>
            * Your company&apos;s funding will determine if you have the rights to the final production release of this{" "}
            <br />
            product.
          </div>
        </div>

        <div className={classNames.article}>
          <div className={classNames.content}>
            * EPRI will evaluate all tester suggestions and recommendations, but does not guarantee they will be <br />
            incorporated into the final production product.
          </div>
        </div>

        <div className={classNames.article}>
          <div className={classNames.content}>
            * As a Beta evaluator, you agree to provide feedback as a condition of obtaining the preproduction <br />
            software
          </div>
        </div>

        <div className={classNames.buttons}>
          <div className={`${classNames.button} ${classNames.emptyButton}`} onClick={handleReject}>
            Reject
          </div>
          <div className={`${classNames.button} ${classNames.fullButton}`} onClick={handleAccept}>
            Accept
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenceAgreement;
