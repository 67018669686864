import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { ConnectedRouter } from "connected-react-router";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";
import history from "./shared/utils/history";
import configureStore from "./store";
import { APP_ROOT_ID } from "./shared/constants/api";

import "./styles/index.scss";

const { store } = configureStore(history);

if (process.env.REACT_APP_ENV === "dev") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, { trackHooks: true });
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div className="app-wrapper">
        <App />
      </div>
    </ConnectedRouter>
  </Provider>,
  document.getElementById(APP_ROOT_ID),
);

serviceWorker.register();
