import React, { useEffect, useMemo } from "react";
import { generatePath, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { ExpandListPanel, InstanceHeader } from "../../../../shared/components";
import { ROUTE_PATHS } from "../../../../shared/routes";
import { RouteComponentProps } from "../../../../shared/interfaces";

import {
  actions as companiesPlantsActions,
  selectors as companiesPlantsSelectors,
} from "../../../CompaniesPlants/store";
import { actions as fieldNotesActions, selectors as fieldNotesSelectors } from "../../../FieldNotes/store";
import { actions, selectors } from "../../../Instances/store";

import classnames from "./fieldGuideReference.module.scss";
import { useLoader } from "../../../../shared/hooks";
import { LOADERS_NAMES } from "../../../../shared/constants";
import { getFieldGuide } from "../../../../shared/store/selectors";

interface InstancesMap {
  instanceName: string;
  instanceId: number;
}

interface FieldNotesMap {
  fieldNoteName: string;
  fieldNoteId: number;
  instances?: InstancesMap[];
}

interface PlantsMap {
  plantName: string;
  plantId: number;
  fieldNotes?: FieldNotesMap[];
}

interface ComposedMap {
  companyName: string;
  companyId: number;
  plants?: PlantsMap[];
}

interface FieldGuideReferenceContainerProps extends RouteComponentProps<{ id: number }> {}

const FieldGuideReferenceContainer: React.FunctionComponent<FieldGuideReferenceContainerProps> = (props) => {
  const {
    match: {
      params: { id: page },
    },
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoading, AppLoader } = useLoader({
    name: LOADERS_NAMES.FIELD_GUIDE_REFERENCE_LOADER,
    actionTypes: [companiesPlantsActions.getCompaniesPlants],
  });

  const fieldGuide = useSelector(getFieldGuide());
  const companies = useSelector(companiesPlantsSelectors.getCompaniesPlantsByFieldGuide(Number(fieldGuide?.id)));
  const fieldNotes = useSelector(fieldNotesSelectors.getFieldNotes());
  const instances = useSelector(selectors.getInstances());
  const areas = useSelector(selectors.getPdfAreas());

  useEffect(() => {
    if (fieldGuide) {
      dispatch(companiesPlantsActions.getCompaniesPlants.request({ showFieldGuides: true }));
      dispatch(fieldNotesActions.getFieldNotes.request({}));
      dispatch(actions.getInstances.request({ showEditable: true }));
    }
  }, [dispatch, fieldGuide]);

  const composedMap = useMemo(() => {
    const composedMap: ComposedMap[] = [];

    if (!companies.length || !fieldNotes.length || !instances.length) {
      return [];
    }

    const instancesMap = new Map<number, InstancesMap[]>();
    instances.forEach((instance) => {
      const instancesByFieldNote = instancesMap.get(instance.fieldNoteId) || [];
      instancesMap.set(instance.fieldNoteId, [
        ...instancesByFieldNote,
        { instanceName: instance.displayName, instanceId: instance.id },
      ]);
    });

    const fieldNotesMap = new Map<number, FieldNotesMap[]>();
    fieldNotes.forEach((fieldNote) => {
      const fieldNotesByPlant = fieldNotesMap.get(fieldNote.plantId) || [];

      if (instancesMap.get(fieldNote.id)) {
        fieldNotesMap.set(fieldNote.plantId, [
          ...fieldNotesByPlant,
          {
            fieldNoteName: fieldNote.displayName,
            fieldNoteId: fieldNote.id,
            instances: instancesMap.get(fieldNote.id),
          },
        ]);
      }
    });

    const plantsMap = new Map<number, PlantsMap[]>();
    companies.forEach(({ plants }) => {
      plants?.forEach((plant) => {
        if (fieldNotesMap.get(plant.id)) {
          const plantsByCompany = plantsMap.get(plant.companyId) || [];
          plantsMap.set(plant.companyId, [
            ...plantsByCompany,
            { plantName: plant.displayName, plantId: plant.id, fieldNotes: fieldNotesMap.get(plant.id) },
          ]);
        }
      });
    });

    companies.forEach((company) => {
      if (plantsMap.get(company.id)) {
        composedMap.push({
          companyName: company.displayName,
          companyId: company.id,
          plants: plantsMap.get(company.id),
        });
      }
    });

    return composedMap;
  }, [companies, fieldNotes, instances]);

  const handleRedirectToFieldGuide = () => {
    history.push(generatePath(ROUTE_PATHS.FIELD_GUIDES));
  };

  const handleCreateReference = (instanceId: number, fieldNoteId: number, plantId: number) => {
    dispatch(
      actions.addFieldGuideReferenceToInstance.request({
        fieldGuideDocumentId: Number(fieldGuide?.id),
        areas,
        instanceId,
        page,
        callback: () => history.push(generatePath(ROUTE_PATHS.INSTANCE_VIEW, { fieldNoteId, id: instanceId, plantId })),
      }),
    );
  };

  return (
    <div className={classnames.fieldGuideReference}>
      <InstanceHeader
        backLabel="Field Guide"
        headerLabel={`Select Field Note for Page ${Number(page) + 1}`}
        onBackClick={handleRedirectToFieldGuide}
      />
      <p className={classnames.question}>Where do you want to add page reference to?</p>

      {isLoading ? (
        <AppLoader />
      ) : (
        composedMap.map(({ companyId, companyName, plants }) => (
          <div key={companyId} className={classnames.companyBlock}>
            <div className={classnames.companyName}>{companyName}</div>
            {plants?.map(({ plantId, plantName, fieldNotes }) => (
              <ExpandListPanel key={plantId} title={plantName} isDefaultOpen={false} isHeadingItem>
                {fieldNotes?.map(({ fieldNoteId, fieldNoteName, instances }) => (
                  <ExpandListPanel key={fieldNoteId} title={fieldNoteName} isDefaultOpen={false} level={1}>
                    {instances?.map(({ instanceName, instanceId }) => (
                      <div
                        key={instanceId}
                        className={classnames.instanceWrapper}
                        onClick={() => handleCreateReference(instanceId, fieldNoteId, plantId)}
                      >
                        <div className={classnames.instanceName}>{instanceName}</div>
                        <div className={classnames.bottomLine} />
                      </div>
                    ))}
                  </ExpandListPanel>
                ))}
              </ExpandListPanel>
            ))}
          </div>
        ))
      )}
    </div>
  );
};

export default FieldGuideReferenceContainer;
