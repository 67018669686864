import React, { CSSProperties } from "react";
import classnames from "classnames";
import { DateRangeInput, SearchInput, Select } from "../../../../shared/formComponents/common";
import { IDateRange } from "../../../../shared/interfaces";
import { ORDER_TYPES } from "../../../../shared/constants";

interface FieldNoteHeaderProps {
  plantsForSelect: {
    label: string;
    value: string;
  }[];
  fieldNotesLength?: number;
  plantSelectHandler: (option: { value: string }) => void;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
  debouncedSearch: string;
  sortOrder: ORDER_TYPES;
  sortOrderLabel: string;
  toggleSortOrder: () => void;
  startDate: Date | null;
  endDate: Date | null;
  setDateRange: ({ start_date, end_date }: IDateRange) => void;
  showMine: boolean;
  toggleShowMine: () => void;
}

const FieldNoteHeader: React.FunctionComponent<FieldNoteHeaderProps> = (props) => {
  const {
    plantsForSelect,
    fieldNotesLength,
    plantSelectHandler,
    setSearchString,
    debouncedSearch,
    startDate,
    endDate,
    setDateRange,
    sortOrder,
    toggleSortOrder,
    sortOrderLabel,
    showMine,
    toggleShowMine,
  } = props;

  return (
    <div className="field-notes-header">
      <div className="field-notes-title">{fieldNotesLength} Field Notes</div>
      <div className="filter-right-block">
        <div className="filter plant-list-block">
          <Select
            options={plantsForSelect}
            className="select"
            components={{
              IndicatorSeparator: () => null,
            }}
            onChange={(option) =>
              plantSelectHandler(
                option as {
                  value: string;
                },
              )
            }
            placeholder={<div>All Plants</div>}
            isClearable
            styles={{
              placeholder: (styles: CSSProperties) => ({
                ...styles,
                color: "$global-placeholder-color",
              }),
            }}
          />
        </div>
        <div className="filter search-block">
          <SearchInput onChangeSearch={setSearchString} placeholder="Search" value={debouncedSearch} />
        </div>
        <div className="filter date-range-block">
          <DateRangeInput startDateValue={startDate} endDateValue={endDate} onChange={setDateRange} />
        </div>
        <div className="filter sort-order-block no-select showMine">
          <div onClick={toggleShowMine}>{showMine ? "Show All" : "Show Mine"}</div>
        </div>
        <div className="filter sort-order-block no-select">
          <div onClick={toggleSortOrder}>
            {sortOrderLabel}
            <span className={classnames("icon sort-order", { "v-flip": sortOrder === ORDER_TYPES.DESC })} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FieldNoteHeader;
