import React, { useEffect, useState, useCallback } from "react";
import { Redirect, Route, RouteProps, withRouter } from "react-router";
import { ROUTE_PATHS } from "../../shared/routes";
import { RouteComponentProps } from "../../shared/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { checkRoles, checkPermissions, checkIsTutorialCompleted } from "../../shared/utils/ACL";
import { actions, selectors } from "../../containers/CompaniesPlants/store";

interface PrivateRouteProps extends RouteProps {
  authenticated: boolean;
  permissions?: string[];
  roles?: string[];
}

const PrivateRoute: React.FunctionComponent<PrivateRouteProps & RouteComponentProps> = (
  props: PrivateRouteProps & RouteComponentProps,
) => {
  const dispatch = useDispatch();

  const { component: Component, authenticated, permissions, roles, ...rest } = props;
  // eslint-disable-next-line
  const [plantId, setPlantId] = useState<number | null>(Number((props as any).computedMatch.params.plantId));
  const plant = useSelector(selectors.getCurrentPlant());

  const canLoadComponent = useCallback(() => !plantId || (plant && plant.id === plantId), [plantId, plant]);

  useEffect(() => {
    if (plantId) {
      dispatch(actions.getPlant.request(plantId));

      return () => setPlantId(null);
    }
  }, [dispatch, plantId]);

  return canLoadComponent() ? (
    <Route
      {...rest}
      render={(props) => {
        if (
          authenticated &&
          checkRoles(roles || []) &&
          checkPermissions(permissions || [], { plant: plantId ? plant : null }) &&
          Component
        ) {
          if (rest.path !== ROUTE_PATHS.TUTORIAL && rest.path !== ROUTE_PATHS.ROOT && !checkIsTutorialCompleted()) {
            return (
              <Redirect
                to={{
                  pathname: ROUTE_PATHS.TUTORIAL,
                  state: { from: props.location },
                }}
              />
            );
          }
          return <Component {...props} />;
        }

        return (
          <Redirect
            to={{
              pathname: authenticated ? ROUTE_PATHS.COMPANIES_DASHBOARD : ROUTE_PATHS.AUTH_LOGIN,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  ) : null;
};

export default withRouter(PrivateRoute);
