import { ImageFileExtensions } from "../constants";
import { FileType } from "../models";

export const isValidExtension = (fileName: string, extensions: string[] = ImageFileExtensions): boolean => {
  const fileExtension = getFileExtension(fileName);

  return extensions.includes(fileExtension);
};

export const getFileExtension = (fileName: string): FileType => {
  const dotIndex = fileName.lastIndexOf(".") + 1;
  const fileExtension = fileName.substr(dotIndex, fileName.length).toLowerCase() as FileType;

  return fileExtension;
};

export const blobToBase64 = (blob: Blob): Promise<string | undefined> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result?.toString());
    reader.readAsDataURL(blob);
  });
};

export const blobToBase64andDrawThumbnail = (blob: Blob): Promise<string | undefined> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = (e) => {
      const root = document.getElementById("root");
      root?.insertAdjacentHTML(
        "afterend",
        `
        <div style="display:none">
          <video id="videoId" muted autoplay loop controls>
            <source src="${e?.target?.result?.toString()}" type="video/mp4" />
          </video>
          <canvas id="canvasId"></canvas>
        </div>
      `,
      );
      setTimeout(() => {
        resolve(drawThumbnail());
      }, 1000);
    };
    reader.readAsDataURL(blob);
  });
};

const drawThumbnail = () => {
  const video = document.getElementById("videoId") as CanvasImageSource & { pause: Function; remove: Function };
  const canvas = document.getElementById("canvasId") as HTMLCanvasElement;
  const context = canvas.getContext("2d");
  context?.drawImage(video, 0, 0, canvas.width, canvas.height);

  const dataURL = canvas.toDataURL();
  video.pause();
  canvas.remove();
  video.remove();
  return dataURL;
};
