import React from "react";
import { useDispatch } from "react-redux";

import { LoginForm } from "./components/LoginForm";
import { AuthShape } from "../../interfaces";
import { login } from "../../store/actions";

const LoginContainer = () => {
  const dispatch = useDispatch();

  const handleSubmit = (values: AuthShape) => {
    dispatch(login.request(values));
  };

  return (
    <section className="login-container">
      <div className="login-container-title" />
      <div className="login-content">
        <LoginForm submitHandler={handleSubmit} />
      </div>
    </section>
  );
};

export default LoginContainer;
