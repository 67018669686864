import { connectRouter } from "connected-react-router";
import { History } from "history";
import { AnyAction, CombinedState, combineReducers } from "redux";

import { RootReducer } from "../shared/interfaces";

import { reducer as AuthReducer } from "../containers/Auth/store";
import { reducer as SharedReducer } from "../shared/store";
import { reducer as usersReducer } from "../containers/Users/store";
import { reducer as companiesPlantsReducer } from "../containers/CompaniesPlants/store";
import { reducer as fieldNotesReducer } from "../containers/FieldNotes/store";
import { reducer as instancesReducer } from "../containers/Instances/store";
import { reducer as imagesReducer } from "../containers/Images/store";

type Reducer = CombinedState<RootReducer>;

export default (history: History) => {
  const appReducer = combineReducers({
    auth: AuthReducer,
    shared: SharedReducer,
    users: usersReducer,
    companiesPlants: companiesPlantsReducer,
    fieldNotes: fieldNotesReducer,
    instances: instancesReducer,
    images: imagesReducer,
    router: connectRouter(history),
  });

  return (state: Reducer | undefined, action: AnyAction) => {
    return appReducer(state, action);
  };
};
