import {
  Profile,
  ProfileRole,
  Plant,
  FieldNote,
  Instance,
  InstanceComment,
  FieldGuideReference,
  ConditionOptionsWithConcern,
  InstanceCollaborator,
} from "../models";
import { tokenHandler } from "../utils";
import { PERMISSION } from "../constants";

interface PermissionData {
  plant?: Plant | null;
  fieldNote?: FieldNote | null;
  instance?: Instance | null;
  comment?: InstanceComment | null;
  fieldGuideReference?: FieldGuideReference | null;
  conditionOption?: ConditionOptionsWithConcern | null;
}
const STANDARD_USER_ROLE = 3;
const OWN_ROLES = [STANDARD_USER_ROLE];

const getProfileRoles = (): ProfileRole[] => {
  const profile: Profile = tokenHandler.getProfile();
  if (!profile || !profile.profileRoles) {
    return [];
  }

  return profile.profileRoles;
};

function checkIsOwnCreatedValue<T extends { createdBy?: string }>(
  value: T,
  profileId: number,
  roleId: number,
): boolean {
  if (!OWN_ROLES.includes(roleId)) {
    return true;
  }

  return Number(value.createdBy) === profileId;
}

export const hasAnyNonStandardUserRole = () => {
  return getProfileRoles().some((pr) => pr.roleId !== STANDARD_USER_ROLE);
};

const checkIsGlobalAdmin = (profileRoles: ProfileRole[]) => {
  return profileRoles.some((pr) => !pr.plantId && !pr.companyId);
};

export const checkIsOneOfCollaborators = (collaborators: InstanceCollaborator[]) =>
  collaborators?.some((collaborator) => collaborator.profileId === tokenHandler?.getProfile()?.id);

export const checkIsLicenseAgreed = () => {
  return !!tokenHandler.getIsLicenseAgreed();
};

export const checkIsTutorialCompleted = () => {
  return !!tokenHandler.getProfile()?.tutorialCompletionDate;
};

export const checkRoles = (roles: string[]): boolean => {
  return !roles.length || getProfileRoles().some((pr) => roles.includes(String(pr.role?.name)));
};

export const checkPermissions = (permissions: string[] | PERMISSION[], data?: PermissionData): boolean => {
  const { plant, fieldNote, instance, comment, fieldGuideReference, conditionOption } = data || {};

  const profileRoles = getProfileRoles();
  if (!profileRoles.length) {
    return !permissions.length;
  }

  const isGlobalAdmin = checkIsGlobalAdmin(profileRoles);
  if (isGlobalAdmin) {
    return true;
  }

  if (plant) {
    const profileByPlantIdRole = profileRoles.find(
      (pr) =>
        Number(pr.plantId) === Number(plant.id) || // explicitly specified plant
        (plant.companyId === pr.companyId && !pr.plantId) || // any plant at explicitly specified company
        !pr.companyId, // any plant at any company
    );
    if (profileByPlantIdRole) {
      const hasPermission = permissions.length
        ? permissions.some((p) => profileByPlantIdRole.role.permissions.find((pObj) => pObj.name === p))
        : true;

      if (!hasPermission) {
        return false;
      }

      if (fieldNote) {
        return checkIsOwnCreatedValue(fieldNote, profileByPlantIdRole.profileId, profileByPlantIdRole.roleId);
      }

      if (comment) {
        return checkIsOwnCreatedValue(comment, profileByPlantIdRole.profileId, profileByPlantIdRole.roleId);
      }

      if (instance) {
        return checkIsOwnCreatedValue(instance, profileByPlantIdRole.profileId, profileByPlantIdRole.roleId);
      }

      if (fieldGuideReference) {
        return checkIsOwnCreatedValue(fieldGuideReference, profileByPlantIdRole.profileId, profileByPlantIdRole.roleId);
      }

      if (conditionOption) {
        return checkIsOwnCreatedValue(conditionOption, profileByPlantIdRole.profileId, profileByPlantIdRole.roleId);
      }

      return hasPermission;
    }

    const accessInAnotherPlants = profileRoles.filter((pr) => pr.companyId === plant.companyId && pr.plantId);
    if (!accessInAnotherPlants.length) {
      const profileByCompanyIdRole = profileRoles.find(
        (pr) => Number(pr.companyId) === Number(plant.companyId) && !pr.plantId,
      );
      if (profileByCompanyIdRole) {
        return permissions.length
          ? permissions.some((p) => profileByCompanyIdRole.role.permissions.find((pObj) => pObj.name === p))
          : true;
      }
    }

    return false;
  }

  if (!plant && permissions.length) {
    return !!profileRoles.find((pr) => permissions.some((p) => pr.role.permissions.find((pObj) => pObj.name === p)));
  }

  return true;
};
