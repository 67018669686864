import { useEffect } from "react";

export function useKonvaImage(props: {
  src: string;
  binary: string | undefined;
  errorSrc: string | undefined;
  resizeKeepAspect: Function;
  handleLoad: () => void;
  handleError: () => void;
  setImage: Function;
  setOrigImageSize: Function;
  setRatio: Function;
  setOrigRatio: Function;
}) {
  const {
    src,
    binary,
    errorSrc,
    resizeKeepAspect,
    handleLoad,
    handleError,
    setRatio,
    setOrigRatio,
    setImage,
    setOrigImageSize,
  } = props;

  useEffect(() => {
    const img = document.createElement("img");
    const handleImageLoad = () => {
      // size of image
      const { newWidth, newHeight, newRatio } = resizeKeepAspect(img.width, img.height);
      img.width = newWidth;
      img.height = newHeight;
      setImage(img);
      setOrigRatio(newRatio);
      setRatio(newRatio);

      setOrigImageSize({ w: newWidth, h: newHeight });
    };
    img.addEventListener("load", handleImageLoad);
    img.addEventListener("load", handleLoad);
    if (handleError) {
      img.addEventListener("error", handleError);
    }

    img.crossOrigin = "Anonymous";
    if (src) {
      img.src = src || "";
    } else if (binary) {
      img.src = errorSrc || binary;
    }
    return () => {
      img.removeEventListener("load", handleImageLoad);
      img.removeEventListener("load", handleLoad);
      img.removeEventListener("error", handleError);
    };
  }, [
    src,
    binary,
    errorSrc,
    resizeKeepAspect,
    handleLoad,
    handleError,
    setRatio,
    setOrigRatio,
    setImage,
    setOrigImageSize,
  ]);
}
