import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  AddButton,
  EditFieldNoteModal,
  EditPlantSideout,
  NavigationPanel,
  FieldNoteCardList,
  Modal,
  RemoveModalContent,
  AccessControl,
} from "../../../../shared/components";
import { FieldNote, Plant, Document } from "../../../../shared/models";
import { useLoader, useModal, useSortFilter } from "../../../../shared/hooks";
import { ROUTE_PATHS } from "../../../../shared/routes";
import { RouteComponentProps, ButtonActionItem, SortFilterOptions } from "../../../../shared/interfaces";
import { LOADERS_NAMES, ModalTypes, ORDER_TYPES, PERMISSION } from "../../../../shared/constants";

import { actions, selectors } from "../../store";
import { selectors as fieldNotesSelectors, actions as fieldNotesActions } from "../../../FieldNotes/store";
import { selectors as instancesSelectors, actions as instancesActions } from "../../../Instances/store";
import { actions as sharedActions } from "../../../../shared/store";

import "./index.scss";
import {
  PlantDetailsFilters,
  PlantDetailsHeader,
  DocumentCardList,
  EditDocumentModal,
  CollaboratorCardList,
} from "./components";
import { UpdateDocumentShape, AddDocumentShape } from "../../interfaces";
import { checkPermissions } from "../../../../shared/utils/ACL";

enum Panels {
  FieldNotes = "Field Notes",
  Documents = "Documents",
  Collaborators = "Collaborators",
}

enum ModalEntities {
  document = "Document",
  plant = "Plant",
  fieldNote = "FieldNote",
}

const PANELS_WITH_DATE_RANGE = [Panels.FieldNotes];

interface PlantDetailsContainerProps extends RouteComponentProps<{ plantId: number }> {}

const PlantDetailsContainer: React.FunctionComponent<PlantDetailsContainerProps> = (props) => {
  const {
    match: {
      params: { plantId },
    },
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading: isFieldNotesLoading } = useLoader({
    name: LOADERS_NAMES.FIELD_NOTES_LOADER,
    actionTypes: [fieldNotesActions.getFieldNotes],
  });
  const { isLoading: isDocumentsLoading } = useLoader({
    name: LOADERS_NAMES.DOCUMENTS_LOADER,
    actionTypes: [actions.updatePlantDocument, actions.getPlantDocuments, actions.createPlantDocument],
  });
  const { isLoading: isProfilesLoading } = useLoader({
    name: LOADERS_NAMES.PROFILE_LOADER,
    actionTypes: [instancesActions.getPlantProfiles],
  });

  const { isLoading: isFileLoading, AppLoader } = useLoader({
    name: LOADERS_NAMES.FILE_LOADER,
    actionTypes: [sharedActions.getFile],
  });

  const { isShowing, onOpen, onClose } = useModal(false);
  const {
    requestOptions,
    searchString,
    endDate,
    startDate,
    sortOrder,
    sortOrderLabel,
    showMine,
    utils: { setSearchString, toggleSortOrder, setDateRange, setSortOrder, toggleShowMine },
  } = useSortFilter({ showMineInitial: false });

  const currentPlant = useSelector(selectors.getCurrentPlant());
  const documents = useSelector(selectors.getCurrentPlantDocuments());
  const fieldNotes = useSelector(fieldNotesSelectors.getFieldNotesByPlant(currentPlant));
  const collaborators = useSelector(instancesSelectors.getProfiles());
  const companies = useSelector(selectors.getCompaniesPlants());
  const plants: Plant[] = useSelector(selectors.getPlants());
  const [showPlantEdit, setPlantEdit] = useState(false);

  const [modalType, setModalType] = useState<{ type: string; payload?: Document } | null>(null);
  const [modalEntity, setModalEntity] = useState("");
  const [showFieldNoteEdit, setFieldNoteEdit] = useState(false);

  const [currentPanel, setCurrentPanel] = useState<string>(Panels.FieldNotes);
  const [currentDocument, setCurrentDocument] = useState<Document | null>(null);
  const [isShowingDateRange, setShowDateRange] = useState<boolean>(false);
  const [showDocumentEdit, setShowDocumentEdit] = useState(false);
  const [itemLength, setItemLength] = useState<number>(0);
  const [plantActionList, setPlantActionList] = useState<ButtonActionItem[]>([]);

  useEffect(() => {
    return () => {
      dispatch(actions.getPlantDocuments.success([]));
    };
  }, [dispatch, plantId]);

  useEffect(() => {
    setShowDateRange(PANELS_WITH_DATE_RANGE.includes(currentPanel as Panels));
    if (plantId) {
      switch (currentPanel) {
        case Panels.FieldNotes:
          dispatch(
            fieldNotesActions.getFieldNotes.request({
              ...requestOptions,
              plantId: String(plantId),
            } as SortFilterOptions),
          );
          break;
        case Panels.Documents:
          const { startDate, endDate, showMine, ...filteredRequestOptions } = requestOptions;
          dispatch(actions.getPlantDocuments.request({ plantId, filter: filteredRequestOptions }));
          break;
        default: {
          const { startDate, endDate, showMine, ...filteredRequestOptions } = requestOptions;
          dispatch(instancesActions.getPlantProfiles.request({ plantId, filter: filteredRequestOptions }));
        }
      }
    }
  }, [dispatch, requestOptions, currentPanel, plantId, currentPlant]);

  useEffect(() => {
    switch (currentPanel) {
      case Panels.FieldNotes:
        setItemLength(fieldNotes.length);
        break;
      case Panels.Documents:
        setItemLength(documents.length);
        break;
      default:
        setItemLength(collaborators.length);
    }
  }, [currentPanel, documents, fieldNotes, collaborators]);

  const openDocument = useCallback((document: Document | null) => {
    setShowDocumentEdit(true);
    setCurrentDocument(document);
  }, []);

  const openFieldNote = useCallback(() => {
    setFieldNoteEdit(true);
  }, []);

  useEffect(() => {
    const actions = [];
    if (checkPermissions([PERMISSION.DOCUMENT_CREATE])) {
      actions.push({
        label: "Document",
        icon: "/images/dashboard/document-button.svg",
        onClick: () => openDocument(null),
      });
    }

    if (checkPermissions([PERMISSION.FIELDNOTE_CREATE])) {
      actions.push({
        label: "Field Note",
        icon: "/images/dashboard/field-note-button.svg",
        onClick: openFieldNote,
      });
    }

    setPlantActionList(actions);
  }, [currentPanel, openFieldNote, openDocument]);

  const onPlantEdit = useCallback(() => setPlantEdit(true), [setPlantEdit]);

  const onPlantRestore = useCallback(() => {
    setModalType({ type: ModalTypes.restore });
    setModalEntity(ModalEntities.plant);
    onOpen();
  }, [onOpen]);

  const openCompaniesPlants = useCallback(() => {
    history.push(ROUTE_PATHS.COMPANIES_DASHBOARD);
  }, [history]);

  const closePlantModalHandler = useCallback(() => {
    onClose();
    setPlantEdit(false);
  }, [onClose]);

  const deletePlant = useCallback(() => {
    if (currentPlant?.id) {
      dispatch(actions.deletePlant.request(currentPlant.id));
    }
  }, [currentPlant, dispatch]);

  const restorePlant = useCallback(() => {
    if (currentPlant && currentPlant.id) {
      dispatch(actions.restorePlant.request(currentPlant.id));
    }
  }, [currentPlant, dispatch]);

  const submitPlant = useCallback(
    (plant: Partial<Plant>) => {
      if (currentPlant) {
        dispatch(actions.updatePlant.request({ ...plant, id: currentPlant.id }));
      } else {
        dispatch(actions.createPlant.request(plant));
      }
      closePlantModalHandler();
    },
    [currentPlant, closePlantModalHandler, dispatch],
  );

  const closeFieldNoteModalHandler = useCallback(() => {
    onClose();
    setFieldNoteEdit(false);
  }, [setFieldNoteEdit, onClose]);

  const submitFieldNote = useCallback(
    (fieldNote: Partial<FieldNote>) => {
      dispatch(fieldNotesActions.createFieldNote.request(fieldNote));
      setCurrentPanel(Panels.FieldNotes);
      closeFieldNoteModalHandler();
    },
    [closeFieldNoteModalHandler, dispatch],
  );

  const closeDocumentModalHandler = useCallback(() => {
    setShowDocumentEdit(false);
    setCurrentDocument(null);
  }, [setShowDocumentEdit, setCurrentDocument]);

  const submitDocument = useCallback(
    (data: AddDocumentShape | UpdateDocumentShape, documentId: number | null) => {
      if (documentId) {
        dispatch(actions.updatePlantDocument.request({ plantId, documentId, data: { displayName: data.displayName } }));
      } else {
        dispatch(actions.createPlantDocument.request({ plantId, data: data as AddDocumentShape }));
      }
      setCurrentPanel(Panels.Documents);
      closeDocumentModalHandler();
    },
    [closeDocumentModalHandler, plantId, dispatch],
  );

  const deleteDocument = useCallback(
    (document) => {
      dispatch(actions.deletePlantDocument.request({ plantId, documentId: document.id }));
    },
    [dispatch, plantId],
  );

  const showHideUnsavedChangeModal = useCallback(
    (dirty: boolean, modalEntity: string) => {
      if (dirty) {
        setModalEntity(modalEntity);
        onOpen();
      } else {
        switch (modalEntity) {
          case ModalEntities.document:
            closeDocumentModalHandler();
            break;
          case ModalEntities.fieldNote:
            closeFieldNoteModalHandler();
            break;
          case ModalEntities.plant:
            closePlantModalHandler();
            break;
        }
      }
    },
    [closeDocumentModalHandler, closeFieldNoteModalHandler, closePlantModalHandler, onOpen],
  );

  const onRemove = useCallback(
    (type: string, entity: string, payload?: Document) => {
      setModalType({ type, payload });
      setModalEntity(entity);
      onOpen();
    },
    [onOpen],
  );

  const renderModal = useCallback(() => {
    let onDelete,
      heading,
      content,
      removeText,
      cancelText,
      onCloseModal,
      isSubmitType = false;
    // TODO REFACTOR THIS and other 'renderModal' modals....

    onCloseModal = () => {
      onClose();
      setModalType(null);
      setModalEntity("");
    };

    switch (modalType?.type) {
      case ModalTypes.delete: {
        removeText = "Remove";
        cancelText = "Cancel";
        content = `Are you sure you want to remove the ${modalEntity}?`;
        heading = `Remove ${modalEntity}`;
        onDelete = () => {
          switch (modalEntity) {
            case ModalEntities.plant: {
              deletePlant();
              break;
            }
            case ModalEntities.document: {
              deleteDocument(modalType.payload);
              break;
            }
          }
          closePlantModalHandler();
        };
        break;
      }

      case ModalTypes.restore: {
        heading = `Restore ${modalEntity}`;
        content = `Are you sure you want to restore this ${modalEntity}?`;
        removeText = "Restore";
        cancelText = "Cancel";
        isSubmitType = true;
        onDelete = () => {
          if (modalEntity === ModalEntities.plant) {
            restorePlant();
            closePlantModalHandler();
          }
        };
        break;
      }

      default: {
        removeText = "LEAVE";
        content = `Do you want to save the changes made to the ${modalEntity}`;
        heading = "Save Changes";
        cancelText = "Leave";
        removeText = "Save";
        onDelete = () => {
          onClose();
          setModalType(null);
          setModalEntity("");
        };
        onCloseModal = () => {
          if (modalEntity === ModalEntities.plant) {
            closePlantModalHandler();
          }
        };
      }
    }

    return (
      <RemoveModalContent
        heading={heading}
        content={content}
        cancelText={cancelText}
        removeText={removeText}
        isSubmitType={isSubmitType}
        onClose={onCloseModal}
        onDelete={onDelete}
      />
    );
  }, [modalType, modalEntity, closePlantModalHandler, deletePlant, restorePlant, onClose, deleteDocument]);

  const handleChangePanel = useCallback(
    (panel: string) => {
      if (panel === Panels.Collaborators) {
        setSortOrder(ORDER_TYPES.ASC);
      } else {
        setSortOrder(ORDER_TYPES.DESC);
      }
      if (searchString !== "") {
        setSearchString("");
      }

      setCurrentPanel(panel);
    },
    [setSortOrder, setSearchString, searchString],
  );

  const panelsList = useMemo(() => [Panels.FieldNotes, Panels.Documents, Panels.Collaborators], []);

  return (
    <div className="plant-details-container">
      <Modal isShowing={isShowing} onClose={onClose} boxPadding>
        {renderModal()}
      </Modal>
      <PlantDetailsHeader
        openCompaniesPlants={openCompaniesPlants}
        onPlantEdit={onPlantEdit}
        onPlantRestore={onPlantRestore}
        currentPlant={currentPlant}
      />
      {isFileLoading && <AppLoader text="File is processing" />}
      <NavigationPanel
        panels={panelsList}
        className="plant-details-subheader"
        onChangePanel={handleChangePanel}
        rightComponent={
          <PlantDetailsFilters
            itemLength={itemLength}
            isNameSort={currentPanel === Panels.Collaborators}
            isShowMineSort={currentPanel === Panels.FieldNotes}
            collectionName={currentPanel}
            setSearchString={setSearchString}
            searchString={searchString}
            sortOrder={sortOrder}
            sortOrderLabel={sortOrderLabel}
            toggleSortOrder={toggleSortOrder}
            startDate={startDate}
            endDate={endDate}
            setDateRange={setDateRange}
            isShowingDateRange={isShowingDateRange}
            showMine={!!showMine}
            toggleShowMine={toggleShowMine}
          />
        }
        immediatePanel={currentPanel}
      >
        <FieldNoteCardList fieldNotes={fieldNotes} search={searchString} isLoading={isFieldNotesLoading} />
        <DocumentCardList
          documents={documents}
          plant={currentPlant}
          search={searchString}
          onRemoveDocument={(document) => onRemove(ModalTypes.delete, ModalEntities.document, document)}
          onEditDocument={openDocument}
          isLoading={isDocumentsLoading}
        />
        <CollaboratorCardList collaborators={collaborators} search={searchString} isLoading={isProfilesLoading} />
      </NavigationPanel>
      <AccessControl permissions={[PERMISSION.FIELDNOTE_CREATE, PERMISSION.DOCUMENT_CREATE]} plant={currentPlant}>
        {currentPlant && currentPlant.isActive && <AddButton items={plantActionList} />}
      </AccessControl>
      {showPlantEdit ? (
        <EditPlantSideout
          companies={companies}
          currentPlant={currentPlant}
          showHideUnsavedChangeModal={(dirty: boolean) => showHideUnsavedChangeModal(dirty, ModalEntities.plant)}
          submitPlant={submitPlant}
          onPlantRemove={(type) => onRemove(type, ModalEntities.plant)}
        />
      ) : null}
      {showFieldNoteEdit ? (
        <div className="central-overlay">
          <EditFieldNoteModal
            currentFieldNote={null}
            currentPlant={currentPlant}
            plants={plants}
            showHideUnsavedChangeModal={(dirty: boolean) => showHideUnsavedChangeModal(dirty, ModalEntities.fieldNote)}
            submitFieldNote={submitFieldNote}
          />
        </div>
      ) : null}
      {showDocumentEdit ? (
        <div className="central-overlay">
          <EditDocumentModal
            showHideUnsavedChangeModal={(dirty: boolean) => showHideUnsavedChangeModal(dirty, ModalEntities.document)}
            submitDocument={submitDocument}
            document={currentDocument}
          />
        </div>
      ) : null}
    </div>
  );
};

export default PlantDetailsContainer;
