import produce from "immer";
import { Reducer } from "redux";

import { CompaniesPlants, Company, Plant, Document } from "../../../shared/models";
import { SharedActionTypes } from "../../../shared/store/constants";
import { ActionTypes } from "./constants";

export interface CompaniesPlantsState {
  companiesPlants: CompaniesPlants[];
  currentCompany: Company | null;
  currentPlant: Plant | null;
  currentPlantDocuments: Document[];
}

const initialState: CompaniesPlantsState = {
  companiesPlants: [],
  currentCompany: null,
  currentPlantDocuments: [],
  currentPlant: null,
};

const reducer: Reducer<CompaniesPlantsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_COMPANY_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.currentCompany = action.payload;
      });
    }
    case ActionTypes.GET_COMPANIES_PLANTS: {
      return produce(state, (nextState) => {
        nextState.companiesPlants = [];
      });
    }
    case ActionTypes.GET_COMPANIES_PLANTS_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.companiesPlants = action.payload;
      });
    }
    case ActionTypes.GET_COMPANIES: {
      return produce(state, (nextState) => {
        nextState.companiesPlants = [];
      });
    }
    case ActionTypes.GET_COMPANIES_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.companiesPlants = action.payload;
      });
    }
    case ActionTypes.CREATE_COMPANY_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.companiesPlants = [...nextState.companiesPlants, { ...action.payload, plants: [] }].sort((a, b) =>
          a.displayName < b.displayName ? -1 : 1,
        );
      });
    }
    case ActionTypes.UPDATE_COMPANY_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.companiesPlants = nextState.companiesPlants
          .map((company) => (company.id === action.payload.id ? { ...company, ...action.payload } : company))
          .sort((a, b) => (a.displayName < b.displayName ? -1 : 1));
      });
    }
    case ActionTypes.DELETE_COMPANY_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.companiesPlants = nextState.companiesPlants.map((company) => {
          if (company.id === action.payload.id) {
            let plants = company.plants || [];
            plants = plants.map((plant) => ({ ...plant, isActive: false }));
            return {
              ...company,
              isActive: false,
              plants,
            };
          }
          return company;
        });
        nextState.currentCompany = null;
      });
    }

    case ActionTypes.RESTORE_COMPANY_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.companiesPlants = nextState.companiesPlants.map((company) => {
          if (company.id === action.payload.id) {
            let plants = company.plants || [];
            plants = plants.map((plant) => ({ ...plant, isActive: true }));
            return {
              ...company,
              isActive: true,
              plants,
            };
          }
          return company;
        });
        nextState.currentCompany = null;
      });
    }

    case ActionTypes.CREATE_PLANT_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.companiesPlants = nextState.companiesPlants.map((company) => {
          if (company.id === action.payload.companyId) {
            let plants = company.plants || [];
            plants = [
              ...plants,
              {
                ...action.payload,
                notesCount: 0,
                collaboratorsCount: 0,
                documentsCount: 0,
              },
            ].sort((a, b) => (a.displayName < b.displayName ? -1 : 1));
            return {
              ...company,
              plants,
            };
          }
          return company;
        });
      });
    }
    case ActionTypes.UPDATE_PLANT_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.currentPlant = action.payload;
        nextState.companiesPlants = nextState.companiesPlants.map((company) => {
          if (company.id === action.payload.companyId) {
            let plants = (company.plants || [])
              .map((plant) => (plant.id === action.payload.id ? { ...plant, ...action.payload } : plant))
              .sort((a, b) => (a.displayName < b.displayName ? -1 : 1));
            return { ...company, plants };
          }
          return company;
        });
      });
    }
    case ActionTypes.DELETE_PLANT_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.currentPlant = action.payload;
        nextState.companiesPlants = nextState.companiesPlants.map((company) => {
          if (company.id === action.payload.companyId) {
            let plants = (company.plants || []).map((plant) =>
              plant.id === action.payload.id ? { ...plant, isActive: false } : plant,
            );
            return { ...company, plants };
          }
          return company;
        });
      });
    }

    case ActionTypes.RESTORE_PLANT_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.currentPlant = action.payload;
        nextState.companiesPlants = nextState.companiesPlants.map((company) => {
          if (company.id === action.payload.companyId) {
            let plants = (company.plants || []).map((plant) =>
              plant.id === action.payload.id ? { ...plant, isActive: true } : plant,
            );
            return { ...company, plants };
          }
          return company;
        });
      });
    }

    case ActionTypes.GET_PLANT_SUCCESS:
      const plant: Plant = action.payload;
      return produce(state, (nextState) => {
        nextState.currentPlant = plant;
      });

    case ActionTypes.GET_PLANT_DOCUMENTS_SUCCESS: {
      const documents: Document[] = action.payload;
      return produce(state, (nextState) => {
        nextState.currentPlantDocuments = documents;
      });
    }

    case ActionTypes.DELETE_PLANT_DOCUMENT_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.currentPlantDocuments = nextState.currentPlantDocuments.filter(
          (d) => d.id !== Number(action.payload.id),
        );
      });
    }

    case ActionTypes.CREATE_PLANT_DOCUMENT_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.currentPlantDocuments = [action.payload.document, ...nextState.currentPlantDocuments];
      });
    }

    case ActionTypes.UPDATE_PLANT_DOCUMENT_SUCCESS: {
      return produce(state, (nextState) => {
        const document = action.payload.document;
        const documents = [...nextState.currentPlantDocuments];
        const documentIndex = documents.findIndex((d) => d.id === document.id);
        if (documentIndex !== -1) {
          documents[documentIndex] = document;
        }
        nextState.currentPlantDocuments = documents;
      });
    }

    case SharedActionTypes.SET_FAVORITE_FIELD_GUIDE_SUCCESS:
      return produce(state, (nextState) => {
        nextState.companiesPlants = nextState.companiesPlants.map((company) =>
          action.payload.companyId === company.id
            ? {
                ...company,
                ...(company.fieldGuideDocuments
                  ? {
                      fieldGuideDocuments: [
                        ...company.fieldGuideDocuments.map((fieldGuideDocument) =>
                          fieldGuideDocument.id === action.payload.fieldGuideDocumentId
                            ? { ...fieldGuideDocument, isFavorite: action.payload.isFavorite }
                            : fieldGuideDocument,
                        ),
                      ],
                    }
                  : {}),
              }
            : company,
        );
      });

    default:
      return state;
  }
};
export default reducer;
