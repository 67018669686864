import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory } from "react-router";

import { EditPlantSideout, Modal, RemoveModalContent, AccessControl, AddButton } from "../../../../shared/components";
import { SearchInput } from "../../../../shared/formComponents/common";
import { Company, CompanyRequest, Plant } from "../../../../shared/models";
import { useLoader, useModal, useSortFilter } from "../../../../shared/hooks";
import { ROUTE_PATHS } from "../../../../shared/routes";

import { CompanyCard } from "./components/CompanyCard";
import { EditCompany } from "./components/EditCompany";
import { ArchivedSelect } from "./components/ArchivedSelect";

import { actions, selectors } from "../../store";

import "./index.scss";
import { LOADERS_NAMES, PERMISSION } from "../../../../shared/constants";
import { checkPermissions } from "../../../../shared/utils/ACL";

const CompaniesContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isShowing, onOpen, onClose } = useModal(false);
  const {
    debouncedSearch,
    utils: { setSearchString },
    requestOptions,
  } = useSortFilter({});

  const { isLoading, AppLoader } = useLoader({
    name: LOADERS_NAMES.COMPANIES_PLANTS_LOADER,
    actionTypes: [actions.getCompaniesPlants],
  });

  const companies = useSelector(selectors.getCompaniesPlants());

  const [modalType, setModalType] = useState("");
  const [modalEntity, setModalEntity] = useState("");
  const [showCompanyEdit, setCompanyEdit] = useState(false);
  const [editedCompany, setEditedCompany] = useState<Company | null>(null);
  const [showPlantEdit, setPlantEdit] = useState(false);
  const [editedPlant, setEditedPlant] = useState<Plant | null>(null);
  const [filterOptions, setFilterOptions] = useState<{ showInactive?: boolean; showActive?: boolean }>({
    showActive: true,
  });

  useEffect(() => {
    const { sortOrder, ...searchValues } = requestOptions;
    dispatch(actions.getCompaniesPlants.request({ ...filterOptions, ...searchValues }));
  }, [dispatch, requestOptions, filterOptions]);

  const openCompany = useCallback(() => {
    setCompanyEdit(true);
  }, []);

  const onCompanyEdit = useCallback(
    (company: Company) => {
      const companyFromList = companies.find((c) => c.id === company.id);

      if (companyFromList) {
        setEditedCompany(companyFromList);
        setCompanyEdit(true);
      }
    },
    [companies, setCompanyEdit, setEditedCompany],
  );

  const closeCompanyModalHandler = useCallback(() => {
    onClose();
    setCompanyEdit(false);
    setEditedCompany(null);
  }, [setCompanyEdit, onClose, setEditedCompany]);

  const showHideUnsavedCompanyChangeModal = useCallback(
    (dirty: boolean) => {
      if (dirty) {
        setModalType("");
        setModalEntity("Company");
        onOpen();
      } else {
        closeCompanyModalHandler();
      }
    },
    [closeCompanyModalHandler, onOpen],
  );

  const submitCompany = useCallback(
    (company: CompanyRequest) => {
      if (editedCompany) {
        dispatch(actions.updateCompany.request({ ...company, id: editedCompany.id }));
      } else {
        dispatch(actions.createCompany.request(company));
      }
      closeCompanyModalHandler();
    },
    [editedCompany, closeCompanyModalHandler, dispatch],
  );

  const onRestore = useCallback(
    (company: Company) => {
      setModalType("restore");
      setModalEntity("Company");
      setEditedCompany(company);
      onOpen();
    },
    [onOpen],
  );

  const onCompanyDelete = useCallback(
    (company: Company) => {
      setModalType("delete");
      setModalEntity("Company");
      setEditedCompany(company);
      onOpen();
    },
    [onOpen],
  );

  const onCompanyRemove = useCallback(
    (type: string) => {
      setModalType(type);
      setModalEntity("Company");
      onOpen();
    },
    [onOpen],
  );

  const deleteCompany = useCallback(() => {
    if (editedCompany?.id) {
      dispatch(actions.deleteCompany.request(editedCompany.id));
    }
  }, [editedCompany, dispatch]);

  const restoreCompany = useCallback(() => {
    if (editedCompany?.id) {
      dispatch(actions.restoreCompany.request(editedCompany.id));
    }
  }, [editedCompany, dispatch]);

  const restorePlant = useCallback(() => {
    if (editedPlant?.id) {
      dispatch(actions.restorePlant.request(editedPlant.id));
    }
  }, [editedPlant, dispatch]);

  const openPlant = useCallback(() => {
    setPlantEdit(true);
  }, []);

  const onPlantDetails = useCallback(
    (plant) => {
      history.push(generatePath(ROUTE_PATHS.PLANT_DETAILS_DASHBOARD, { plantId: plant.id }));
    },
    [history],
  );

  const closePlantModalHandler = useCallback(() => {
    onClose();
    setPlantEdit(false);
    setEditedPlant(null);
  }, [setPlantEdit, onClose, setEditedPlant]);

  const showHideUnsavedPlantChangeModal = useCallback(
    (dirty: boolean) => {
      if (dirty) {
        setModalEntity("Plant");
        onOpen();
      } else {
        closePlantModalHandler();
      }
    },
    [closePlantModalHandler, onOpen],
  );

  const submitPlant = useCallback(
    (plant: Partial<Plant>) => {
      if (editedPlant) {
        dispatch(actions.updatePlant.request({ ...plant, id: editedPlant.id }));
      } else {
        dispatch(actions.createPlant.request(plant));
      }
      closePlantModalHandler();
    },
    [editedPlant, closePlantModalHandler, dispatch],
  );

  const onPlantRemove = useCallback(
    (type: string) => {
      setModalType(type);
      setModalEntity("Plant");
      onOpen();
    },
    [onOpen],
  );

  const deletePlant = useCallback(() => {
    if (editedPlant?.id) {
      dispatch(actions.deletePlant.request(editedPlant.id));
    }
  }, [editedPlant, dispatch]);

  const renderModal = useCallback(() => {
    // TODO REFACTOR THIS and other 'renderModal' modals....
    let onDelete,
      onCloseModal,
      heading,
      content,
      removeText,
      cancelText,
      isSubmitType = false;

    onCloseModal = () => {
      onClose();
      setModalType("");
      setModalEntity("");
    };

    switch (modalType) {
      case "delete": {
        heading = `Remove ${modalEntity}`;
        content = `Are you sure you want to remove the ${modalEntity}?`;
        removeText = "Remove";
        cancelText = "Cancel";
        onDelete = () => {
          if (modalEntity === "Company") {
            deleteCompany();
            closeCompanyModalHandler();
          } else if (modalEntity === "Plant") {
            deletePlant();
            closePlantModalHandler();
          }
        };
        break;
      }

      case "restore": {
        heading = `Restore ${modalEntity}`;
        content = `Are you sure you want to restore this ${modalEntity}?`;
        removeText = "Restore";
        cancelText = "Cancel";
        isSubmitType = true;
        onDelete = () => {
          if (modalEntity === "Company") {
            restoreCompany();
            closeCompanyModalHandler();
          } else if (modalEntity === "Plant") {
            restorePlant();
            closePlantModalHandler();
          }
        };
        break;
      }

      default: {
        removeText = "LEAVE";
        content = `Do you want to save the changes made to the ${modalEntity}`;
        heading = "Save Changes";
        cancelText = "Leave";
        removeText = "Save";
        onDelete = () => {
          onClose();
          setModalType("");
          setModalEntity("");
        };

        onCloseModal = () => {
          if (modalEntity === "Company") {
            closeCompanyModalHandler();
          } else if (modalEntity === "Plant") {
            closePlantModalHandler();
          }
        };
      }
    }

    return (
      <RemoveModalContent
        heading={heading}
        content={content}
        cancelText={cancelText}
        removeText={removeText}
        isSubmitType={isSubmitType}
        onClose={onCloseModal}
        onDelete={onDelete}
      />
    );
  }, [
    modalType,
    modalEntity,
    closeCompanyModalHandler,
    closePlantModalHandler,
    deleteCompany,
    deletePlant,
    onClose,
    restoreCompany,
    restorePlant,
  ]);

  return (
    <div className="companies-list-container">
      <div className="companies-list-header">
        <div className="companies-list-title">Companies & Plants</div>
        <div className="search-block">
          <SearchInput onChangeSearch={setSearchString} placeholder="Search" value={debouncedSearch} />
        </div>
        <ArchivedSelect handleFilterChanged={setFilterOptions} />
      </div>
      <div className="companies-list">
        {isLoading ? (
          <AppLoader />
        ) : (
          <>
            {companies.map((company) => (
              <div key={company.id} title="See Plant Details">
                <CompanyCard
                  company={company}
                  onDelete={() => onCompanyDelete(company)}
                  onEdit={() => onCompanyEdit(company)}
                  onRestore={() => onRestore(company)}
                  onClick={(plant) => onPlantDetails(plant)}
                />
                <div className="companies-list-divider" />
              </div>
            ))}
            {!companies.length && (
              <div
                className={classnames({
                  "companies-list-empty": !debouncedSearch.length,
                  "companies-not-found": !!debouncedSearch,
                })}
              />
            )}
          </>
        )}
      </div>

      <AccessControl permissions={[PERMISSION.COMPANY_EDIT, PERMISSION.PLANT_CREATE]}>
        <AddButton
          items={[
            ...(checkPermissions([PERMISSION.COMPANY_CREATE])
              ? [{ icon: "/images/dashboard/company-button.svg", label: "Company", onClick: openCompany }]
              : []),
            ...(checkPermissions([PERMISSION.PLANT_CREATE])
              ? [{ icon: "/images/dashboard/plant-button.svg", label: "Plant", onClick: openPlant }]
              : []),
          ]}
        />
      </AccessControl>

      <Modal isShowing={isShowing} onClose={onClose} boxPadding>
        {renderModal()}
      </Modal>
      {showCompanyEdit && (
        <EditCompany
          currentCompany={editedCompany}
          showHideUnsavedChangeModal={showHideUnsavedCompanyChangeModal}
          submitCompany={submitCompany}
          onCompanyRemove={onCompanyRemove}
        />
      )}
      {showPlantEdit && (
        <EditPlantSideout
          companies={companies}
          currentPlant={editedPlant}
          showHideUnsavedChangeModal={showHideUnsavedPlantChangeModal}
          submitPlant={submitPlant}
          onPlantRemove={onPlantRemove}
        />
      )}
    </div>
  );
};

export default CompaniesContainer;
