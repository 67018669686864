import React from "react";
import classnames from "classnames";
import { DateRangeInput, SearchInput } from "../../../../../../shared/formComponents/common";
import { IDateRange } from "../../../../../../shared/interfaces";
import { ORDER_TYPES } from "../../../../../../shared/constants";

interface PlantDetailsFiltersProps {
  itemLength?: number;
  collectionName: string;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
  searchString: string;
  sortOrder: string;
  sortOrderLabel: string;
  toggleSortOrder: () => void;
  startDate: Date | null;
  endDate: Date | null;
  setDateRange: ({ start_date, end_date }: IDateRange) => void;
  isShowingDateRange: boolean;
  isNameSort: boolean;
  isShowMineSort: boolean;
  showMine: boolean;
  toggleShowMine: () => void;
}

const PlantDetailsFilters: React.FunctionComponent<PlantDetailsFiltersProps> = (props) => {
  const {
    itemLength,
    collectionName,
    setSearchString,
    searchString,
    sortOrder,
    sortOrderLabel,
    toggleSortOrder,
    startDate,
    endDate,
    setDateRange,
    isShowingDateRange,
    isNameSort,
    isShowMineSort,
    showMine,
    toggleShowMine,
  } = props;

  let sortLabel = "";
  if (!isNameSort) {
    sortLabel = sortOrderLabel;
  } else {
    if (sortOrder === ORDER_TYPES.DESC) {
      sortLabel = "Z-A";
    } else {
      sortLabel = "A-Z";
    }
  }

  return (
    <div className="plant-details-filters">
      <div className="plant-details-title">
        {itemLength} {collectionName}
      </div>
      <div className="filter-right-block">
        <div className="filter search-block">
          <SearchInput onChangeSearch={setSearchString} placeholder="Search" value={searchString} />
        </div>
        {isShowingDateRange && (
          <div className="filter date-range-block">
            <DateRangeInput startDateValue={startDate} endDateValue={endDate} onChange={setDateRange} />
          </div>
        )}
        {isShowMineSort && (
          <div className="filter sort-order-block no-select showMine" onClick={toggleShowMine}>
            {showMine ? "Show All" : "Show Mine"}
          </div>
        )}
        <div className="filter sort-order-block no-select">
          <div onClick={toggleSortOrder}>
            {sortLabel}
            <span className={classnames("icon sort-order", { "v-flip": sortOrder === ORDER_TYPES.DESC })} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlantDetailsFilters;
