import styled from "styled-components";

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  box-sizing: border-box;
  label {
    order:2
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    cursor:pointer;
  }
  input {
     order: 1;
     position: absolute;
     opacity: 0;
     cursor: pointer;
     height: 0;
     width: 0;
  }
  .checkmark {
     position: absolute;
     height: 16px;
     width: 16px;
     background-color: #eee;
     margin-left: auto;
     margin-right: auto;
     left: 0;
     right: 0;
  }
   
  input ~ .checkmark {
    border: 1px solid #219cff;
    border-radius: 3px;
    box-sizing: border-box;
    background: transparent;
    right: unset;
  }


  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }


  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  input:checked ~ .checkmark {
    background-color: #219cff;
  }

  input:checked ~ .disabled {
    background-color: #ECECEC;
    border: 1px solid #ECECEC;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 4px;
    top: 1px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &.left {
    width: 90%;
    margin-right: 5%;
  }

  &.right {
    width: 90%;
    margin-left: 5%;
  }
}
`;
