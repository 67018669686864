import React from "react";
import { ToastProps } from "react-toast-notifications";

import "./index.scss";

const Toast = (props: ToastProps) => {
  const { appearance, children, onDismiss } = props;

  return (
    <div className={`notification ${appearance}`}>
      <div className={`notification-img ${appearance}`} />
      <div className="notification-message">
        <div className="notification-message-header">
          <div className={`notification-message-header-title ${appearance}`}>
            {appearance === "success" ? "Success!" : "Error!"}
          </div>
          <div className="notification-message-header-close" onClick={() => onDismiss()}>
            <img src="/icons/general/white-cancel.svg" alt="close notification" />
          </div>
        </div>
        <div className={`notification-message-text ${appearance}`}>{children}</div>
      </div>
    </div>
  );
};

export default Toast;
