import PropTypes from "prop-types";
import React, { useState } from "react";
import { Field } from "formik";

import { InputProps } from "../InputProps";
import { InputWrapper } from "./InputWrapper";
import "./index.scss";

const Input: React.FunctionComponent<InputProps> = (props: InputProps) => {
  const { className, isShowPasswordIcon, isRequiredField, ...inputProps } = props;
  const [isShowPassword, setShowPassword] = useState(false);
  return (
    <InputWrapper className={className}>
      <label className={props.disabled ? "disabled" : ""}>
        {props.label} {isRequiredField && <span className="required-field">*</span>}
      </label>
      <Field {...{ ...inputProps, type: isShowPassword ? "text" : props.type }} />
      {isShowPasswordIcon && (
        <i
          className={`password-icon-block icon ${isShowPassword ? "password-hide" : "password-show"}`}
          onClick={() => setShowPassword(!isShowPassword)}
        />
      )}
    </InputWrapper>
  );
};
Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
};

Input.defaultProps = {
  label: "",
  type: "text",
  name: "",
};

export default Input;
