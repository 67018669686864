import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { EditFieldNoteModal, FieldNoteCardList } from "../../../../shared/components";
import { FieldNote, Plant } from "../../../../shared/models";
import { useLoader, useModal, useSortFilter } from "../../../../shared/hooks";
import { Modal, RemoveModalContent } from "../../../../shared/components";
import { FieldNoteHeader } from "../../components";

import { actions, selectors } from "../../store";
import {
  actions as companiesPlantsActions,
  selectors as companiesPlantsSelectors,
} from "../../../CompaniesPlants/store";

import "./index.scss";
import { LOADERS_NAMES } from "../../../../shared/constants";

const FieldNotesContainer = () => {
  const dispatch = useDispatch();

  const {
    requestOptions,
    debouncedSearch,
    startDate,
    endDate,
    sortOrder,
    sortOrderLabel,
    showMine = true,
    utils: { setSearchString, toggleSortOrder, setDateRange, setSearchPlantId, toggleShowMine },
  } = useSortFilter({ showMineInitial: false });

  const { isLoading } = useLoader({
    name: LOADERS_NAMES.FIELD_NOTES_CONTAINER_LOADER,
    actionTypes: [actions.getFieldNotes],
  });

  const { isShowing, onOpen, onClose } = useModal(false);

  const fieldNotes: FieldNote[] = useSelector(selectors.getFieldNotes());
  const plants: Plant[] = useSelector(companiesPlantsSelectors.getPlants());

  const [isShowingAddMenu, setShowMenu] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalEntity, setModalEntity] = useState("");
  const [showFieldNoteEdit, setFieldNoteEdit] = useState(false);
  const [editedFieldNote, setEditedFieldNote] = useState<FieldNote | null>(null);

  const plantsForSelect = useMemo(() => {
    const filteredPlants: { label: string; value: string }[] = [];

    plants.forEach(({ displayName, id }) => {
      if (displayName && id) {
        filteredPlants.push({ label: displayName, value: id.toString() });
      }
    });
    return filteredPlants;
  }, [plants]);

  useEffect(() => {
    dispatch(actions.getFieldNotes.request(requestOptions));
  }, [dispatch, requestOptions]);

  useEffect(() => {
    dispatch(companiesPlantsActions.getCompaniesPlants.request({}));
  }, [dispatch]);

  const plantSelectHandler = (option: { value: string }) => {
    if (option && option.value) {
      setSearchPlantId(option.value.toString());
    } else {
      setSearchPlantId(undefined);
    }
  };

  const openFieldNote = useCallback(() => {
    setFieldNoteEdit(true);
  }, []);

  const closeFieldNoteModalHandler = useCallback(() => {
    onClose();
    setFieldNoteEdit(false);
    setEditedFieldNote(null);
    setShowMenu(false);
  }, [setFieldNoteEdit, onClose, setEditedFieldNote]);

  const showHideUnsavedFieldNoteChangeModal = useCallback(
    (dirty: boolean) => {
      if (dirty) {
        setModalEntity("FieldNote");
        onOpen();
      } else {
        closeFieldNoteModalHandler();
      }
    },
    [closeFieldNoteModalHandler, onOpen],
  );

  const submitFieldNote = useCallback(
    (fieldNote: Partial<FieldNote>) => {
      if (editedFieldNote) {
        dispatch(actions.updateFieldNote.request({ ...fieldNote, id: editedFieldNote.id }));
      } else {
        dispatch(actions.createFieldNote.request(fieldNote));
      }
      closeFieldNoteModalHandler();
    },
    [editedFieldNote, closeFieldNoteModalHandler, dispatch],
  );

  const renderModal = useCallback(() => {
    let onDelete, heading, content, removeText;
    switch (modalType) {
      default: {
        removeText = "LEAVE";
        heading = "Leave without changes";
        content = "If you leave now your changes will be lost";
        onDelete = () => {
          if (modalEntity === "FieldNote") {
            closeFieldNoteModalHandler();
          }
        };
      }
    }

    return (
      <RemoveModalContent
        heading={heading}
        content={content}
        cancelText={modalType === "delete" ? "Cancel" : "Stay"}
        removeText={removeText}
        onClose={() => {
          onClose();
          setModalType("");
          setModalEntity("");
        }}
        onDelete={onDelete}
      />
    );
  }, [modalType, modalEntity, closeFieldNoteModalHandler, onClose]);

  const toggleShowMenu = useCallback(() => {
    setShowMenu(!isShowingAddMenu);
    openFieldNote();
  }, [isShowingAddMenu, openFieldNote]);

  return (
    <div className="field-notes-container">
      <FieldNoteHeader
        plantsForSelect={plantsForSelect}
        fieldNotesLength={fieldNotes.length}
        plantSelectHandler={plantSelectHandler}
        setSearchString={setSearchString}
        debouncedSearch={debouncedSearch}
        startDate={startDate}
        endDate={endDate}
        setDateRange={setDateRange}
        sortOrder={sortOrder}
        sortOrderLabel={sortOrderLabel}
        toggleSortOrder={toggleSortOrder}
        showMine={showMine}
        toggleShowMine={toggleShowMine}
      />

      <div className="field-notes-body">
        <FieldNoteCardList fieldNotes={fieldNotes} search={debouncedSearch} isLoading={isLoading} />
      </div>
      <div className={`add-button-container${isShowingAddMenu ? " expanded" : ""}`}>
        {isShowingAddMenu ? (
          <>
            <div className="bottom-row">
              <div className="cross-button" onClick={toggleShowMenu} />
            </div>
          </>
        ) : (
          <div className="bottom-row">
            <div className="add-button" onClick={toggleShowMenu} />
          </div>
        )}
      </div>
      <Modal isShowing={isShowing} onClose={onClose} boxPadding>
        {renderModal()}
      </Modal>
      {showFieldNoteEdit ? (
        <EditFieldNoteModal
          currentFieldNote={editedFieldNote}
          currentPlant={null}
          plants={plants}
          showHideUnsavedChangeModal={showHideUnsavedFieldNoteChangeModal}
          submitFieldNote={submitFieldNote}
        />
      ) : null}
    </div>
  );
};

export default FieldNotesContainer;
