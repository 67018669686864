const AUTH_ROUTES = {
  AUTH: "/auth/",
  AUTH_EPRI_LOGIN: "/auth/epri-login",
  AUTH_LOGIN: "/auth/login",
  AUTH_STATUS: "/auth/status/:uid",
  AUTH_LOGIN_OLD: "/auth/login/old",
  AUTH_REFRESH_TOKEN: "/auth/refresh-token",
};

const FIELD_NOTES_ROUTES = {
  FIELD_NOTES_DASHBOARD: "/field-notes",
  INSTANCES_DASHBOARD: "/plants/:plantId/field-notes/:fieldNoteId/instances",
  INSTANCE_VIEW: "/plants/:plantId/field-notes/:fieldNoteId/instances/:id",
  CONDITION_NOTES_FORM: "/plants/:plantId/field-notes/:fieldNoteId/instances/:id/concern",
  CREATE_EDIT_INSTANCE: "/plants/:plantId/field-notes/:fieldNoteId/instance/:id?",
  CREATE_INSTANCE: "/plants/:plantId/field-notes/:fieldNoteId/instance",
  EDIT_INSTANCE: "/plants/:plantId/field-notes/:fieldNoteId/instance/:id",
  CONDITION_NOTES: "/plants/:plantId/field-notes/:fieldNoteId/instances/:id/concerns",
  CONDITION_NOTE_CONCERN:
    "/plants/:plantId/field-notes/:fieldNoteId/instances/:id/concerns/:degradationId/condition-notes",
};

const FIELD_GUIDE_ROUTES = {
  FIELD_GUIDES: "/field-guides",
  FIELD_GUIDE_REFERENCE: "/field-guide/:id/reference",
};

const USERS_ROUTES = {
  USERS_LIST: "/users",
};

const IMAGE_REVIEW_ROUTES = {
  IMAGE_REVIEWS_DASHBOARD: "/image-reviews",
};

export const ROUTE_PATHS = {
  ROOT: "/",
  ...AUTH_ROUTES,
  DASHBOARD: "/",
  ...USERS_ROUTES,
  COMPANIES_DASHBOARD: "/companies",
  PLANT_DETAILS_DASHBOARD: "/plants/:plantId",
  ...FIELD_NOTES_ROUTES,
  ...FIELD_GUIDE_ROUTES,
  ...IMAGE_REVIEW_ROUTES,
  PUSH_DASHBOARD: "/push", // mock page
  ABOUT: "/about",
  LICENSE_AGREEMENT: "/licence",
  TUTORIAL: "/tutorial",
  USER_GUIDE: "/user-guide",
};
