export const LOADERS_NAMES = {
  PDF_LOADER: "PDF_LOADER",
  FILE_LOADER: "FILE_LOADER",
  COMPANIES_PLANTS_LOADER: "COMPANIES_PLANTS_LOADER",
  COMPANIES_LOADER: "COMPANIES_LOADER",
  FIELD_NOTES_LOADER: "FIELD_NOTES_LOADER",
  DOCUMENTS_LOADER: "DOCUMENTS_LOADER",
  PROFILE_LOADER: "PROFILE_LOADER",
  FIELD_NOTES_CONTAINER_LOADER: "FIELD_NOTES_CONTAINER_LOADER",
  FIELD_GUIDE_REFERENCE_LOADER: "FIELD_GUIDE_REFERENCE_LOADER",
  INSTANCE_CREATE_EDIT_LOADER: "INSTANCE_CREATE_EDIT_LOADER",
  INSTANCES_LOADER: "INSTANCES_LOADER",
  INSTANCE_COMMENTS_LOADER: "INSTANCE_COMMENTS_LOADER",
  INSTANCE_PHOTO_LOADER: "INSTANCE_PHOTO_LOADER",
  USERS_LOADER: "USERS_LOADER",
};
