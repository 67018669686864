import React, { useMemo } from "react";
import { generatePath, useHistory } from "react-router";
import { ROUTE_PATHS } from "../../routes";
import { selectors } from "../../../shared/store";

import "./profilePopover.scss";
import { useSelector } from "react-redux";
import { tokenHandler } from "../../utils";
import { ProfileRole } from "../../models";

interface ProfilePopoverProps {
  setIsLogoutOpened: (isOpened: boolean) => void;
  closeProfilePopover: () => void;
}

const ProfilePopover = (props: ProfilePopoverProps) => {
  const { setIsLogoutOpened, closeProfilePopover } = props;

  const history = useHistory();

  const userInfo = useSelector(selectors.getUser());
  const userRole = useMemo(() => {
    return tokenHandler
      .getProfile()
      ?.profileRoles.filter((profileRole: ProfileRole) => profileRole?.isActive)
      .map((profileRole: ProfileRole) => profileRole?.role?.name)
      .join(", ");
  }, []);

  const handleRedirectToAbout = () => {
    history.push(generatePath(ROUTE_PATHS.ABOUT));
    closeProfilePopover();
  };

  return (
    <div className="profilePopover">
      <div className="header">
        <div className="header_image">
          <img src="/icons/general/profile_image.svg" alt="user logo" />
        </div>
        <div className="header_title">
          <div className="header_name">{`${userInfo?.firstName} ${userInfo?.lastName}`}</div>
          <div className="header_role">{userRole}</div>
        </div>
      </div>
      <div className="profile_body">
        <div className="profile_block">
          <div className="profile_email">
            <img src="/icons/general/blue-letter.svg" alt="email" />
            {userInfo?.email}
          </div>
        </div>
        <div className="profile_button" onClick={handleRedirectToAbout}>
          About Us
        </div>
        <div className="profile_logout" onClick={() => setIsLogoutOpened(true)}>
          <img src="/icons/general/logout.svg" alt="email" />
          Logout
        </div>
      </div>
    </div>
  );
};

export default ProfilePopover;
