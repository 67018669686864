import React, { useEffect, useRef } from "react";
import classnames from "classnames";
import ReactDOM from "react-dom";

import { useOutsideClick } from "../../hooks";
import { MODAL_ROOT_ID } from "../../constants/api";

import "./index.scss";

interface ModalProps {
  isShowing: boolean;
  isOverflowVisible?: boolean;
  boxPadding?: boolean;
  boxPaddingThick?: boolean;
  onClose: Function;
  children: React.ReactNode;
  className?: string;
}

const modalNode = document.getElementById(MODAL_ROOT_ID);

const Modal = (props: ModalProps) => {
  const wrapperRef = useRef(null);
  const { isShowing, onClose, children, boxPadding, boxPaddingThick, isOverflowVisible, className = "" } = props;
  const { isOutside, setOutsideValue } = useOutsideClick(wrapperRef);

  useEffect(() => {
    if (isOutside) {
      onClose();
      setOutsideValue();
    }
  }, [isOutside, onClose, setOutsideValue]);

  return modalNode && isShowing
    ? ReactDOM.createPortal(
        <>
          <div className="modal-overlay" />
          <div className="modal-wrapper">
            <div
              className={classnames("modal", className, {
                box: boxPadding,
                "box--24": boxPaddingThick,
                "overflow-visible": isOverflowVisible,
              })}
              ref={wrapperRef}
            >
              {children}
            </div>
          </div>
        </>,
        modalNode,
      )
    : null;
};

export default Modal;
