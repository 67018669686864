import React, { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath } from "react-router";
import classnames from "classnames";
import { isTomorrow } from "date-fns";

import { AppLoader, Modal } from "../../../../shared/components";
import { BinaryImage } from "../../../../shared/components/BinaryImage";
import { ImageCanvas } from "../../../../shared/formComponents/common/ImageCanvas";
import { Image } from "../../../../shared/models";
import { formatDate } from "../../../../shared/utils";
import { AudioUploadValue } from "../../../../shared/formComponents/common";
import { BinaryAudio } from "../../../../shared/components/BinaryAudio";
import { calculateAspectRatioFit } from "../../../../shared/utils/image";
import { ROUTE_PATHS, REVIEW_STATUS_ID } from "../../../../shared";
import { EAction } from "../../containers/ImageReviewContainer/ImageReviewContainer";
import "./index.scss";
interface ImageApproveProps {
  show: boolean;
  onClose: Function;
  onApprove: Function;
  onReject: Function;
  onSave: Function;
  onEdit: Function;
  onNext: Function;
  onPrevious: Function;
  image: Image;
  imageType: string;
  reviewStatus: string;
  fileToEdit: Image | undefined;
  plantId: number;
  showPrevious: boolean;
  showNext: boolean;
  validImagePosition: boolean;
  isUpdatingStatus: string | undefined;
}

const ImageApprove: React.FunctionComponent<ImageApproveProps> = (props) => {
  const {
    fileToEdit,
    show,
    onApprove,
    onReject,
    onClose,
    onSave,
    onEdit,
    onNext,
    onPrevious,
    image,
    imageType,
    reviewStatus,
    plantId,
    showPrevious,
    showNext,
    validImagePosition,
    isUpdatingStatus,
  } = props;

  const isAudio = Boolean(image?.voiceNote);
  const formattedDate = useMemo(() => {
    return image.createdAt ? formatDate(new Date(image.createdAt)) : "";
  }, [image.createdAt]);

  useEffect(() => {
    setImageLoading(true);
  }, [image]);

  const [imageLoading, setImageLoading] = useState(false);

  const [imageSize, setImageSize] = useState<{ width: number; height: number } | null>(null);
  const handleFileEdit = () => {
    onEdit({ ...image });
  };

  const redirectToInstanceView = useCallback(() => {
    const { instanceId, fieldNoteId } = image;
    if (instanceId ?? fieldNoteId) {
      window.open(
        generatePath(ROUTE_PATHS.INSTANCE_VIEW, {
          id: String(instanceId),
          fieldNoteId: String(fieldNoteId),
          plantId: String(plantId),
        }),
        "_blank",
      );
    }
  }, [image, plantId]);

  const handleFileSave = (
    isNewImage: boolean,
    isNewVoiceNote: boolean,
    imageBase64: string,
    voiceNote: AudioUploadValue,
  ) => {
    onSave(isNewImage, isNewVoiceNote, imageBase64, voiceNote, fileToEdit);
  };

  const handleAttachmentLoad = useCallback((event?: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (event) {
      const element = event.target as HTMLImageElement;
      setImageSize(calculateAspectRatioFit(element?.naturalWidth || 544, element?.naturalHeight || 440, 816, 600));
    }
    setImageLoading(false);
  }, []);

  const formattedExpiresDate = useMemo(() => {
    return image.expiresAt ? (
      <div
        className={classnames("image-approve-expired", {
          error: isTomorrow(new Date(image.expiresAt)),
        })}
      >
        Expiration Date: {formatDate(new Date(image.expiresAt))}
      </div>
    ) : null;
  }, [image.expiresAt]);

  const imageStyles = useMemo(
    () => ({ width: `${imageSize?.width || 544}px`, height: `${imageSize?.height || 440}px` }),
    [imageSize],
  );

  const eventListener = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (!imageLoading) {
        if ((key === "ArrowRight" || keyCode === 39) && showNext) onNext();
        if ((key === "ArrowLeft" || keyCode === 37) && showPrevious) onPrevious();
      }
    },
    [showNext, showPrevious, onNext, onPrevious, imageLoading],
  );

  useEffect(() => {
    if (!fileToEdit) {
      document.addEventListener("keydown", eventListener);
      return () => document.removeEventListener("keydown", eventListener);
    }
  }, [fileToEdit, eventListener]);

  return (
    <Modal
      isShowing={show}
      isOverflowVisible={true}
      onClose={() => {
        /* ignore outside click */
      }}
      boxPadding={false}
    >
      <div className={classnames("image-approve", { "with-audio": isAudio, edit: fileToEdit })}>
        {fileToEdit && image.key && (
          <ImageCanvas
            header={""}
            imageTypeId={Number(fileToEdit.instanceId)}
            uid={image.key}
            src={""}
            show={!!fileToEdit}
            onCancel={() => onEdit(undefined)}
            onClose={() => {}}
            onSave={handleFileSave}
            maxWidth={680}
            maxHeight={504}
            voiceNote={image.voiceNote || undefined}
          />
        )}
        {!fileToEdit && (
          <>
            <div className="button-close" onClick={() => onClose()} />
            {showPrevious ? <div className="image-approve-arrow left" onClick={() => onPrevious()}></div> : null}
            {showNext ? <div className="image-approve-arrow right" onClick={() => onNext()}></div> : null}
            <div className={classnames("image-approve-image", { loading: imageLoading })} style={imageStyles}>
              {imageLoading && <AppLoader />}
              <BinaryImage uid={image.key} alt="image" isLazy={true} onLoad={handleAttachmentLoad} />
              {image.imageTypeId === REVIEW_STATUS_ID.IMAGE_FROM_COMMENTS || !validImagePosition ? null : (
                <div className="button-edit" onClick={() => handleFileEdit()} />
              )}
            </div>
            <div className={classnames("image-approve-fields", { "with-audio": isAudio })}>
              <div className="image-approve-title">
                {imageType}
                <div className={`image-approve-status ${reviewStatus?.toLowerCase()}`}>{reviewStatus}</div>
              </div>
              {isAudio && (
                <div className="image-approve-audio">
                  <BinaryAudio uid={image?.voiceNote?.key} />
                </div>
              )}
              <div className="image-approve-footer">
                <div className="image-approve-instance-info">
                  <div className="image-approve-instance" onClick={redirectToInstanceView}>
                    {image.instanceName}
                  </div>
                  <div className="image-approve-created">
                    Created: {formattedDate} by {image.createdByName}
                  </div>
                  {formattedExpiresDate}
                </div>
                <div className="image-approve-button-panel">
                  {reviewStatus !== "Rejected" && (
                    <div
                      className={classnames("button-reject", { disabled: isUpdatingStatus })}
                      onClick={() => !isUpdatingStatus && onReject()}
                    >
                      Reject
                      {isUpdatingStatus === EAction.REJECT ? <div className="loader reject">Loading...</div> : null}
                    </div>
                  )}
                  {reviewStatus !== "Approved" && (
                    <div
                      className={classnames("button-approve", { disabled: isUpdatingStatus })}
                      onClick={() => !isUpdatingStatus && onApprove()}
                    >
                      Approve
                      {isUpdatingStatus === EAction.APPROVE ? <div className="loader success">Loading...</div> : null}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ImageApprove;
