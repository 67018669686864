import { createAsyncAction } from "typesafe-actions";

import { Error, SortFilterOptions } from "../../../shared/interfaces";
import { Profile } from "../../../shared/models";
import { EditUserShape } from "../interfaces";
import { ActionTypes } from "./constants";

export const getUsers = createAsyncAction(
  ActionTypes.GET_USERS,
  ActionTypes.GET_USERS_SUCCESS,
  ActionTypes.GET_USERS_FAILURE,
)<SortFilterOptions & { showEpriDetails: boolean }, Profile[], Error>();

export const updateUserRoles = createAsyncAction(
  ActionTypes.UPDATE_USER_ROLES,
  ActionTypes.UPDATE_USER_ROLES_SUCCESS,
  ActionTypes.UPDATE_USER_ROLES_FAILURE,
)<{ id: string } & EditUserShape, Profile, Error>();

export const markTutorialCompleted = createAsyncAction(
  ActionTypes.MARK_TUTORIAL_COMPLETED,
  ActionTypes.MARK_TUTORIAL_COMPLETED_SUCCESS,
  ActionTypes.MARK_TUTORIAL_COMPLETED_FAILURE,
)<void, Profile, Error>();
