import React from "react";
import { FieldGuideDocument } from "../../../../../../shared/models/FieldGuideDocument";
import classnames from "classnames";
import "./index.scss";

interface FieldGuideCardProps {
  fieldGuide: FieldGuideDocument;
  companyId: number;
  onStarClick: Function;
  onClick: Function;
}

const FieldGuideCard: React.FunctionComponent<FieldGuideCardProps> = (props) => {
  const { fieldGuide, onStarClick, onClick, companyId } = props;
  return (
    <div className="field-guide-card" onClick={() => onClick(fieldGuide)}>
      <div className="field-guide-card-title">{fieldGuide.displayName}</div>
      <div className="field-guide-card-number">{fieldGuide.number}</div>
      <div
        className={classnames("field-guide-card-star", { favorite: fieldGuide.isFavorite })}
        onClick={(e) => {
          e.stopPropagation();
          onStarClick(fieldGuide, companyId);
        }}
      ></div>
    </div>
  );
};

export default FieldGuideCard;
