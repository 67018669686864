import { FieldItem, FieldItemType } from "../../../../shared/formComponents";
import { ConditionOptionsWithConcern } from "../../../../shared/models";

export type InstanceConditionNoteFormFields = {
  degradations: {
    [key: string]: {
      value: boolean;
      isDefaultSelect?: boolean;
    };
  };
};

export const prepareFormValues = (
  degradationsMap?: Map<string, ConditionOptionsWithConcern[]>,
): InstanceConditionNoteFormFields => {
  if (!degradationsMap) {
    return { degradations: {} };
  }

  const initialValues: InstanceConditionNoteFormFields = { degradations: {} };

  degradationsMap.forEach((degradations) => {
    degradations.forEach((degradation) => {
      const isAConcern = !!degradation.isAConcern;
      const id = `${degradation.conditionOptionId}_${degradation.id}`;

      initialValues.degradations[id] = { value: isAConcern, isDefaultSelect: isAConcern };
    });
  });

  return initialValues;
};

export type FieldsListType = [string, FieldItem[]][];

const getFieldPattern = (
  { id, name, conditionOptionId, isAConcern }: ConditionOptionsWithConcern,
  handleRedirect: (optionId: number) => void,
  isMetal: boolean,
): FieldItem => ({
  type: FieldItemType.CHECKBOX,
  name: `${conditionOptionId}_${id}`,
  label: name,
  parentPrefix: "degradations",
  disabled: !!isAConcern,
  handleClick: () => {
    if (!!isAConcern && isMetal) {
      handleRedirect(Number(id));
    }
  },
});

export const mapFieldsList = (
  degradationsMap: Map<string, ConditionOptionsWithConcern[]>,
  handleRedirect: (optionId: number) => void,
  isMetal: boolean,
): FieldsListType => {
  const fieldsList: FieldsListType = [];

  degradationsMap.forEach((degradations, environment) => {
    fieldsList.push([
      environment,
      degradations.map((degradation) => getFieldPattern(degradation, handleRedirect, isMetal)),
    ]);
  });

  return fieldsList;
};
