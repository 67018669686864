import * as Yup from "yup";
import { AudioUploadValue, SimpleImagesUploadValue } from "../../../../../../shared/formComponents/common";
import { FieldItem, FieldItemType } from "../../../../../../shared/formComponents";
import { Image, InstanceComment, VoiceNote } from "../../../../../../shared/models";

const MAX_COMMENT_LENGTH = 1024;

export interface CommentFormFields {
  id?: number;
  comment: string;
  images: SimpleImagesUploadValue[];
  audios: AudioUploadValue[];
}

export const validationSchema = Yup.object<CommentFormFields>().shape({
  comment: Yup.string().max(MAX_COMMENT_LENGTH, "Your comment is too long"),
});

const getImageValues = (images?: Image[]): { src: string; file?: File; id?: number }[] =>
  images?.map((image) => ({ src: image?.key || "", id: image?.id || undefined, file: undefined })) || [];

const getAudioValues = (audios?: VoiceNote[]): { src: string; file?: File; id?: number }[] =>
  audios?.map((audio) => ({ src: audio?.key || "", id: audio?.id || undefined, file: undefined })) || [];

export const prepareFormValues = (comment?: InstanceComment): CommentFormFields => ({
  id: comment?.id || undefined,
  comment: comment?.comment || "",
  images: getImageValues(comment?.images),
  audios: getAudioValues(comment?.voiceNotes),
});

interface fieldsListType {
  comment: FieldItem[];
  image: FieldItem[];
  audio: FieldItem[];
}

export const generateFieldsList = (isEdit?: boolean): fieldsListType => ({
  comment: [
    {
      type: FieldItemType.TEXTAREA,
      name: "comment",
      maxLength: MAX_COMMENT_LENGTH,
      placeholder: "Start typing here",
      label: isEdit ? "Edit comment" : "Add comment",
      wrapperClass: "instanceComments_panel_form_field",
      inputClass: "instanceComments_panel_form_field_textarea",
    },
  ],
  image: [
    {
      type: FieldItemType.SIMPLE_IMAGE,
      name: "images",
    },
  ],
  audio: [
    {
      type: FieldItemType.AUDIO,
      name: "audios",
    },
  ],
});
