export const notEmpty = <TValue,>(value: TValue | null | undefined): value is TValue => {
  return value !== null && value !== undefined;
};

export const sortDates = (valueA: string, valueB: string): number => {
  return sortValues(new Date(valueB).getTime(), new Date(valueA).getTime());
};

export const sortValues = <TValue,>(valueA: TValue | null | undefined, valueB: TValue | null | undefined): number => {
  return valueA && valueB && valueA < valueB ? -1 : 1;
};

export const sortNumbers = (valueA: number | undefined, valueB: number | undefined): number => {
  return (valueA || 0) < (valueB || 0) ? -1 : 1;
};
