import { HttpStatusCodes } from "../constants";

const MESSAGES_OVERRIDES = [
  {
    code: HttpStatusCodes.FORBIDDEN,
    message: "Cannot sync with cloud",
    text: [
      "There is no content to be displayed.",
      "This could be because your utility does not have a signed Digital Assistant license agreement.",
      "If you have questions or believe this to be an error,",
      "please contact your NMETT or EPRI Technical Advisor.",
    ].join(" "),
  },
];

export const getErrorMessage = (code: number, message: string): string => {
  const override = MESSAGES_OVERRIDES.find(
    (messageOverride) => code === messageOverride.code && message === messageOverride.message,
  );

  if (override) {
    return override.text;
  }

  return message;
};
