import React from "react";
import { Plant } from "../../../../../../shared/models";
import { AccessControl } from "../../../../../../shared/components";
import { PERMISSION } from "../../../../../../shared/constants";

interface PlantDetailsHeaderProps {
  openCompaniesPlants: () => void;
  onPlantEdit: () => void;
  onPlantRestore: () => void;
  currentPlant: Plant | null;
}

const PlantDetailsHeader: React.FunctionComponent<PlantDetailsHeaderProps> = (props) => {
  const { openCompaniesPlants, onPlantEdit, onPlantRestore, currentPlant } = props;

  return (
    <div className="plant-details-header">
      <div className="plant-details-back-name" onClick={openCompaniesPlants}>
        <div className="plant-details-back" />
        <div className="plant-details-back-label">Companies &amp; Plants</div>
      </div>
      <div className="plant-details-plant-name">{currentPlant?.displayName}</div>
      <div className="plant-details-right-buttons">
        <AccessControl permissions={[PERMISSION.PLANT_EDIT]} plant={currentPlant}>
          {currentPlant && currentPlant.isActive ? (
            <div className="plant-details-edit-button" onClick={() => onPlantEdit()}>
              EDIT PLANT
            </div>
          ) : (
            <div className="plant-details-edit-button" onClick={() => onPlantRestore()}>
              RESTORE PLANT
            </div>
          )}
        </AccessControl>
      </div>
    </div>
  );
};

export default PlantDetailsHeader;
