import React from "react";
import classnames from "classnames";
import { components, MultiValueProps, OptionProps, PlaceholderProps } from "react-select";

import { MultiSelectItem } from "../../../../interfaces/MultiSelectItem";

// doc is here: https://react-select.com/components#components

export const MultiValueContainer: React.FunctionComponent<MultiValueProps<MultiSelectItem>> = (props) => {
  const {
    data: { isSecondary },
  } = props;

  return <div className={classnames("multiValueContainer", { isSecondary: !!isSecondary })}>{props.children}</div>;
};

export const MultiValueLabel: React.FunctionComponent<MultiValueProps<MultiSelectItem>> = (props) => {
  const {
    data: { label },
  } = props;

  return <div className="multiValueLabel">{label}</div>;
};

export const MultiValueRemove: React.FunctionComponent<MultiValueProps<MultiSelectItem>> = (props) => (
  <div className="multiValueRemove">
    <components.MultiValueRemove {...props}>
      <img src="/icons/general/white-cancel.svg" alt="remove_icon" />
    </components.MultiValueRemove>
  </div>
);

export const Option: React.FunctionComponent<OptionProps<MultiSelectItem>> = (props) => (
  <div className="multiValueOption">
    <components.Option {...props} />
  </div>
);

export const Placeholder: React.FunctionComponent<PlaceholderProps<MultiSelectItem>> = (props) => (
  <div className="multiValuePlaceholder">{props.children}</div>
);

export const IndicatorSeparator: React.FunctionComponent = () => null;
export const ClearIndicator: React.FunctionComponent = () => null;
