import { createAsyncAction } from "typesafe-actions";
import { AuthShape, SetPasswordInterface } from "../interfaces";

import { Error } from "../../../shared/interfaces";
import { AuthActionTypes } from "./constants";
import { EpriLogin, EpriLoginStatus } from "../interfaces/EpriLogin";

export const login = createAsyncAction(
  AuthActionTypes.LOGIN,
  AuthActionTypes.LOGIN_SUCCESS,
  AuthActionTypes.LOGIN_FAILURE,
)<AuthShape, undefined, Error>();

export const epriLogin = createAsyncAction(
  AuthActionTypes.EPRI_LOGIN,
  AuthActionTypes.EPRI_LOGIN_SUCCESS,
  AuthActionTypes.EPRI_LOGIN_FAILURE,
)<EpriLogin, undefined, Error>();

export const epriLoginStatus = createAsyncAction(
  AuthActionTypes.EPRI_LOGIN_STATUS,
  AuthActionTypes.EPRI_LOGIN_STATUS_SUCCESS,
  AuthActionTypes.EPRI_LOGIN_STATUS_FAILURE,
)<EpriLoginStatus, undefined, Error>();

export const requestPassword = createAsyncAction(
  AuthActionTypes.REQUEST_PASSWORD,
  AuthActionTypes.REQUEST_PASSWORD_SUCCESS,
  AuthActionTypes.REQUEST_PASSWORD_FAILURE,
)<AuthShape, undefined, Error>();

export const submitPassword = createAsyncAction(
  AuthActionTypes.SUBMIT_PASSWORD,
  AuthActionTypes.SUBMIT_PASSWORD_SUCCESS,
  AuthActionTypes.SUBMIT_PASSWORD_FAILURE,
)<SetPasswordInterface, undefined, Error>();

export const logout = createAsyncAction(
  AuthActionTypes.LOGOUT,
  AuthActionTypes.LOGOUT_SUCCESS,
  AuthActionTypes.LOGOUT_FAILURE,
)<undefined, undefined, Error>();
