import React, { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";

import { AudioUploadValue } from "./interfaces";
import { Audio } from "../../../../shared/components/Audio";

import { BinaryAudio } from "../../../components/BinaryAudio";
import { isValidExtension } from "../../../utils";
import { AudiFileExtensions } from "../../../constants";
import { showNotification } from "../../../store/actions";

import "./audioUpload.scss";

interface AudioUploadProps {
  values: AudioUploadValue[];
  name: string;
  setFieldValues: (field: string, values: AudioUploadValue[]) => void;
  showUploadIcon?: boolean;
}

const emptyValue: AudioUploadValue = { src: "", file: undefined };

export const AudioUpload: React.FunctionComponent<AudioUploadProps> = (props) => {
  const { values, name, setFieldValues, showUploadIcon = true } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);

  const dispatch = useDispatch();

  const getFileUrl = useCallback(
    (value: AudioUploadValue) => (value.src ? value.src : value.file ? URL.createObjectURL(value.file) : undefined),
    [],
  );

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    const rawFile = files && files[0];
    const newValue = { ...emptyValue, file: rawFile ?? undefined };
    const fileName = rawFile?.name;
    if (fileName && isValidExtension(fileName, AudiFileExtensions)) {
      setFieldValues(name, [...(values || []), newValue]);
    } else {
      dispatch(
        showNotification({
          message: `Please choose the file with the allowed extension: ${AudiFileExtensions.join(", ")}`,
          appearance: "error",
        }),
      );
    }
    e.target.value = "";
  };

  const handleAudioSelectClick = () => {
    inputRef?.current?.click();
  };

  const handleRemoveAudio = (indexToRemove: number) => {
    setFieldValues(name, [...(values?.filter((_, index) => index !== indexToRemove) || [])]);
  };

  return (
    <>
      {showUploadIcon && (
        <img
          className="audioUpload_btn"
          src="/icons/general/speaker.svg"
          onClick={handleAudioSelectClick}
          alt="add audio file"
        />
      )}
      <div className="audioUpload">
        {values?.map((value, index) => {
          const url = getFileUrl(value);
          return (
            url && (
              <div key={index} className="audioUpload_container">
                {url?.startsWith("blob") ? <Audio src={url} /> : <BinaryAudio uid={url} />}
                <div className="audioUpload_container_deleteBtn" onClick={() => handleRemoveAudio(index)} />
              </div>
            )
          );
        })}

        <input type="file" accept="audio/mp3" name={name} onChange={handleFileSelect} ref={inputRef} />
      </div>
    </>
  );
};
