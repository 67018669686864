import React from "react";

import { Modal } from "../Modal";

import classNames from "./videoModal.module.scss";

interface VideoModalProps {
  show: boolean;
  onClose: () => void;
  src?: string;
}

const VideoModal: React.FC<VideoModalProps> = ({ src, show, onClose }) => {
  return (
    <Modal isShowing={show} onClose={onClose}>
      <div className={classNames.video_modal}>
        <div className={classNames.close_btn} onClick={onClose} />
        <video controls src={src} autoPlay controlsList="nodownload noremoteplayback" disablePictureInPicture />
      </div>
    </Modal>
  );
};

export default VideoModal;
