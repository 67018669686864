import { useCallback, useState } from "react";
import { ConditionOptionsRequestParams } from "../../interfaces";
import { ConditionOptionsMap, ConditionOption, InstanceComponentProperties, ComponentPropertyType } from "../../models";
import { api } from "../../store";
import { notEmpty } from "../../utils";

interface ConditionOptionsHook {
  getTypes: (params: ConditionOptionsRequestParams) => Promise<ConditionOption[]>;
  getRedirects: (params: ConditionOptionsRequestParams) => Promise<ConditionOption[]>;
  getDisciplines: (params: ConditionOptionsRequestParams) => Promise<ConditionOption[]>;
  getMaterials: (params: ConditionOptionsRequestParams) => Promise<ConditionOption[]>;
  getEnvironments: (params: ConditionOptionsRequestParams, disableCache?: boolean) => Promise<ConditionOption[]>;
  getConditionOptions: (params: ConditionOptionsRequestParams) => Promise<ConditionOptionsMap[]>;
  getInstanceOptionsByType: (
    instanceComponentProperties: InstanceComponentProperties[],
    type: ComponentPropertyType,
  ) => string[];
  getInstanceOptionByType: (
    instanceComponentProperties: InstanceComponentProperties[],
    type: ComponentPropertyType,
  ) => string | undefined;

  setEnvironmentsIsAdditionalState: (
    params: ConditionOptionsRequestParams,
  ) => Promise<(ConditionOption & { isSecondary?: boolean })[]>;

  typesOptions: ConditionOption[];
  disciplinesOptions: ConditionOption[];
  materialsOptions: ConditionOption[];
  environmentsOptions: ConditionOption[];
  redirectsOptions: ConditionOption[];
}

const useConditionOptions = (): ConditionOptionsHook => {
  const [typesOptions, setTypesOptions] = useState<ConditionOption[]>([]);
  const [redirectsOptions, setRedirectsOptions] = useState<ConditionOption[]>([]);
  const [disciplinesOptions, setDisciplinesOptions] = useState<ConditionOption[]>([]);
  const [materialsOptions, setMaterialsOptions] = useState<ConditionOption[]>([]);
  const [environmentsOptions, setEnvironmentsOptions] = useState<ConditionOption[]>([]);

  const getInstanceOptionsByType = useCallback(
    (instanceComponentProperties: InstanceComponentProperties[], type: ComponentPropertyType): string[] => {
      return instanceComponentProperties.filter((option) => option.type === type).map(({ value }) => value.toString());
    },
    [],
  );

  const getInstanceOptionByType = useCallback(
    (instanceComponentProperties: InstanceComponentProperties[], type: ComponentPropertyType): string | undefined => {
      return instanceComponentProperties.find((option) => option.type === type)?.value.toString();
    },
    [],
  );

  const getConditionOptions = useCallback(async (params: ConditionOptionsRequestParams) => {
    const result: ConditionOptionsMap[] = await api.getConditions(params);
    return result;
  }, []);

  const getTypes = useCallback(async (params: ConditionOptionsRequestParams) => {
    const result: ConditionOption[] = await api.getTypesOptions(params);
    setTypesOptions(result);

    return result;
  }, []);

  const getRedirects = useCallback(async (params: ConditionOptionsRequestParams) => {
    const conditionOptions: ConditionOptionsMap[] = await api.getConditions(params);

    const filteredConditionOptions = !params.materialTypes?.length
      ? conditionOptions.filter((option) => option.materialTypeId === null)
      : conditionOptions;

    const redirectOptions = filteredConditionOptions.map((option) => option.redirect).filter(notEmpty);

    setRedirectsOptions(redirectOptions);
    return redirectOptions;
  }, []);

  const getDisciplines = useCallback(async (params: ConditionOptionsRequestParams) => {
    const result: ConditionOption[] = await api.getDisciplinesOptions(params);
    setDisciplinesOptions(result);

    return result;
  }, []);

  const getMaterials = useCallback(async (params: ConditionOptionsRequestParams) => {
    const result: ConditionOption[] = await api.getMaterialsOptions(params);
    setMaterialsOptions(result);

    return result;
  }, []);

  const getEnvironments = useCallback(async (params: ConditionOptionsRequestParams, disableCache?: boolean) => {
    const result: ConditionOption[] = await api.getEnvironmentsOptions(params);
    if (!disableCache) {
      setEnvironmentsOptions(result);
    }

    return result;
  }, []);

  const setEnvironmentsIsAdditionalState = useCallback(
    async (params: ConditionOptionsRequestParams) => {
      const result: ConditionOption[] = await api.getEnvironmentsOptions(params);

      const environmentsOptionsWithIsAdditional = result.map((environmentsOption) => {
        if (!environmentsOptions.some(({ id }) => id === environmentsOption.id)) {
          return { ...environmentsOption, isSecondary: true };
        }
        return { ...environmentsOption, isSecondary: false };
      });
      setEnvironmentsOptions(environmentsOptionsWithIsAdditional);

      return environmentsOptionsWithIsAdditional;
    },
    [environmentsOptions],
  );

  return {
    typesOptions,
    redirectsOptions,
    disciplinesOptions,
    materialsOptions,
    environmentsOptions,
    getEnvironments,
    getTypes,
    getDisciplines,
    getMaterials,
    getRedirects,
    getConditionOptions,
    getInstanceOptionsByType,
    getInstanceOptionByType,
    setEnvironmentsIsAdditionalState,
  };
};

export default useConditionOptions;
