import { routerMiddleware } from "connected-react-router";
import { applyMiddleware, compose, createStore, Dispatch, Middleware } from "redux";
import { History } from "history";
import history from "../shared/utils/history";
import { composeWithDevTools } from "redux-devtools-extension";
import freeze from "redux-freeze";
import createSagaMiddleware from "redux-saga";

import { showNotification, clearNotification } from "../shared/store/actions";
import tokenHandler from "./../shared/utils/tokenHandler";

import reducer from "./reducer";
import appSagas from "./saga";
import { ROUTE_PATHS } from "../shared/routes";
import { HttpStatusCodes } from "../shared/constants";
import { getErrorMessage } from "../shared/utils/getErrorMessage";

const sagaMiddleware = createSagaMiddleware();
let middleware: Middleware[];
let composer: Function;

if (process.env.NODE_ENV === "development") {
  middleware = [freeze, sagaMiddleware];
  composer = composeWithDevTools({ trace: true, traceLimit: 25 });
} else {
  middleware = [sagaMiddleware];
  composer = compose;
}

const errorHandlerMiddleware: Middleware = () => (next: Dispatch) => (action) => {
  if (action.type.includes("FAILURE")) {
    next(
      showNotification({
        message: getErrorMessage(action.payload.code, action.payload.error || action.payload.message),
        appearance: "error",
      }),
    );

    if (action.payload && action.payload.code === HttpStatusCodes.UNAUTHORIZED) {
      tokenHandler.removeAll();
    }

    if (action.payload && action.payload.code === HttpStatusCodes.FORBIDDEN) {
      history.push(ROUTE_PATHS.COMPANIES_DASHBOARD);
    }
  }

  if (action.type.endsWith("LOGIN")) {
    next(clearNotification());
  }

  if (action.type.includes("SUCCESS") && action.payload && action.payload.message) {
    next(
      showNotification({
        message: action.payload.message,
        appearance: "success",
      }),
    );
  }

  return next(action);
};

export default function configureStore(history: History) {
  const store = createStore(
    reducer(history),
    undefined,
    composer(applyMiddleware(...middleware, routerMiddleware(history), errorHandlerMiddleware)),
  );

  sagaMiddleware.run(appSagas);

  if (module.hot) {
    module.hot.accept(() => store.replaceReducer(reducer(history)));
  }

  return { store };
}
