import React, { useCallback, useState, useEffect } from "react";
import { useDropzone, FileRejection, FileError } from "react-dropzone";
import "./index.scss";

export interface DropFileItem {
  base64: string | null;
  name: string;
  size: number;
}

interface DropFileZoneProps {
  file: DropFileItem | null;
  maxSize?: number;
  onUploadFile: (file: DropFileItem | null) => void;
  onRejectedFile: (fileError: FileError | null) => void;
}

const DropFileZone: React.FunctionComponent<DropFileZoneProps> = (props: DropFileZoneProps) => {
  const { file, onUploadFile, maxSize, onRejectedFile } = props;
  const [uploadedFileName, setUploadedFileName] = useState<string>();

  useEffect(() => {
    if (file) {
      setUploadedFileName(file.name);
    }
  }, [file]);

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      if (fileRejections.length) {
        const fileRejection: FileRejection = fileRejections[0];
        onRejectedFile(fileRejection.errors.length ? fileRejection.errors[0] : null);
      }
    },
    [onRejectedFile],
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length) {
        const file = acceptedFiles[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          onUploadFile({ name: file.name, base64: String(reader.result), size: file.size });
          onRejectedFile(null);
          setUploadedFileName(file.name || "unknown-file");
        };
      }
    },
    [setUploadedFileName, onUploadFile, onRejectedFile],
  );

  const onClearFile = useCallback(() => {
    setUploadedFileName("");
    onUploadFile(null);
    onRejectedFile(null);
  }, [onUploadFile, onRejectedFile]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, onDropRejected, maxSize, multiple: false });

  return (
    <div className="drop-container">
      {!uploadedFileName && (
        <div className="drop-zone" {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="drop-file-icon">
            <img src="/icons/general/drop_file.svg" alt="drop file" />
          </div>
          <div className="drop-file-text">
            <p>
              Select file and drop here
              <br /> or <span className="browse">browse</span>
            </p>
          </div>
        </div>
      )}
      <div className="drop-footer">
        {uploadedFileName && (
          <div className="selected-file-block">
            <div className="file-name">
              <span>{uploadedFileName}</span>
            </div>
            <div className="remove-file">
              <div className="icon remove-circle" onClick={() => onClearFile()} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropFileZone;
