import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import api from "./api";
import { actions } from "./";
import { RequestWithCallback } from "../../../shared/interfaces";

function* getFieldNotesList({ payload }: AnyAction) {
  try {
    const fieldNotes = yield call(api.getFieldNotesList, payload);
    yield put(actions.getFieldNotes.success(fieldNotes));
  } catch (error) {
    yield put(actions.getFieldNotes.failure(error));
  }
}

function* getFieldNote({ payload: fieldNoteId }: { payload: number }) {
  try {
    const fieldNote = yield call(api.getFieldNote, fieldNoteId);

    yield put(actions.getFieldNote.success(fieldNote));
  } catch (error) {
    yield put(actions.getFieldNote.failure(error));
  } finally {
  }
}

function* getFieldNotePDF({ payload: fieldNoteId }: { payload: number }) {
  try {
    const pdf = yield call(api.getFieldNotePDF, fieldNoteId);

    yield put(actions.getFieldNotePDF.success(pdf));
  } catch (error) {
    yield put(actions.getFieldNotePDF.failure(error));
  } finally {
  }
}

function* updateFieldNote({ payload }: AnyAction) {
  try {
    const fieldNote = yield call(api.updateFieldNote, payload);

    yield put(actions.updateFieldNote.success({ ...fieldNote, message: "The Field Note was successfully updated" }));
  } catch (error) {
    yield put(actions.updateFieldNote.failure(error));
  }
}

function* createFieldNote({ payload }: AnyAction) {
  try {
    const fieldNote = yield call(api.createFieldNote, payload);

    yield put(actions.createFieldNote.success({ ...fieldNote, message: "The Field Note was successfully created" }));
  } catch (error) {
    yield put(actions.createFieldNote.failure(error));
  }
}

function* deleteFieldNote({ payload }: { payload: RequestWithCallback<{ fieldNoteId: number }> }) {
  try {
    const { callback, fieldNoteId } = payload;

    const fieldNote = yield call(api.deleteFieldNote, fieldNoteId);

    yield put(actions.deleteFieldNote.success({ ...fieldNote, message: "The Field Note was successfully deleted" }));

    callback?.();
  } catch (error) {
    yield put(actions.deleteFieldNote.failure(error));
  }
}

function* dashboardSaga() {
  yield takeLatest(actions.getFieldNotes.request, getFieldNotesList);
  yield takeLatest(actions.getFieldNote.request, getFieldNote);
  yield takeLatest(actions.getFieldNotePDF.request, getFieldNotePDF);
  yield takeLatest(actions.createFieldNote.request, createFieldNote);
  yield takeLatest(actions.deleteFieldNote.request, deleteFieldNote);
  yield takeLatest(actions.updateFieldNote.request, updateFieldNote);
}

export default dashboardSaga;
