import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory } from "react-router";
import classnames from "classnames";

import {
  BooleanAnswers,
  InstanceHeader,
  Modal,
  RemoveModalContent,
  AccessControl,
} from "../../../../shared/components";
import { ConditionOptionsWithConcern } from "../../../../shared/models";
import { RouteComponentProps } from "../../../../shared/interfaces";
import { ROUTE_PATHS } from "../../../../shared/routes";
import { selectors as fieldNotesSelectors, actions as fieldNotesActions } from "../../../FieldNotes/store";
import { selectors as selectorsPlants } from "../../../CompaniesPlants/store";
import { actions, selectors } from "../../store";

import "./instancesConditionNoteQuestions.scss";
import { PERMISSION } from "../../../../shared/constants";
import { notEmpty } from "../../../../shared/utils";

interface InstancesConcernContainerProps
  extends RouteComponentProps<{ id: number; degradationId: number; fieldNoteId: number }> {}

const InstancesConcernContainer: React.FunctionComponent<InstancesConcernContainerProps> = (props) => {
  const {
    match: {
      params: { id: instanceId, degradationId, fieldNoteId },
    },
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const instance = useSelector(selectors.getInstance());
  const plant = useSelector(selectorsPlants.getCurrentPlant());
  const currentFieldNote = useSelector(fieldNotesSelectors.getCurrentFieldNote());

  const [degradation, setDegradation] = useState<ConditionOptionsWithConcern | undefined>(undefined);
  const [questions, setQuestions] = useState<ConditionOptionsWithConcern[]>([]);
  const [isRemoveConcern, setIsRemoveConcern] = useState(false);
  const [isConcernComposed, setIsConcernComposed] = useState(false);

  const redirectToInstanceView = useCallback(() => {
    if (currentFieldNote) {
      history.push(
        generatePath(ROUTE_PATHS.CONDITION_NOTES, {
          id: instanceId,
          fieldNoteId: String(currentFieldNote.id),
          plantId: String(currentFieldNote.plantId),
        }),
      );
    }
  }, [currentFieldNote, instanceId, history]);

  useEffect(() => {
    if (fieldNoteId) {
      dispatch(fieldNotesActions.getFieldNote.request(fieldNoteId));
    }
  }, [dispatch, fieldNoteId]);

  useEffect(() => {
    if (!instance) {
      dispatch(actions.getInstance.request(instanceId));
    }
  }, [instanceId, dispatch, instance]);

  useEffect(() => {
    if (instance?.instanceConditionOptions) {
      const savedConditionNote = instance.instanceConditionOptions.filter(
        ({ conditionOption: { degradation } }) => (degradation && degradation?.id) === Number(degradationId),
      );

      const degradationList = savedConditionNote.flatMap((note) =>
        note.conditionOption.degradation && note.conditionOption.degradation.id === Number(degradationId)
          ? [note.conditionOption.degradation]
          : [],
      );

      const degradation = degradationList.find((d) => d.id === Number(degradationId));

      if (!degradation && !savedConditionNote.length) {
        redirectToInstanceView();
        return;
      }

      setDegradation(degradation);

      let isConcernComposed = false;

      const questions = savedConditionNote
        .map(({ conditionOption: { question }, isAConcern, conditionOptionRelationId, createdBy }) => {
          if (question) {
            if (isAConcern) {
              isConcernComposed = true;
            }
            return {
              ...question,
              isAConcern,
              conditionOptionId: conditionOptionRelationId,
              createdBy,
            };
          }

          return undefined;
        })
        .filter(notEmpty);

      setIsConcernComposed(isConcernComposed);
      setQuestions(questions);
    }
  }, [degradationId, instance, redirectToInstanceView]);

  const handleRemoveOption = () => {
    if (questions.length) {
      dispatch(
        actions.removeInstanceConditionOptions.request({
          instanceId,
          conditionOptions: questions
            .map((question) => {
              return question.conditionOptionId ? { conditionOptionId: question.conditionOptionId } : undefined;
            })
            .filter(notEmpty),
        }),
      );
    }
    setIsRemoveConcern(false);
  };

  return (
    <div className="instances-checking-concern">
      <InstanceHeader
        headerLabel="Checking Concern"
        onBackClick={redirectToInstanceView}
        backLabel={instance?.displayName}
      />

      <div className="questions">
        <div className="questions_item">
          <div className="questions_item_title">Observations</div>
          <div className="questions_item_wrapper">
            {questions?.map((question, index) => (
              <BooleanAnswers
                key={question.id}
                questionNumber={index + 1}
                text={question.name}
                initialValue={question.isAConcern}
              />
            ))}
          </div>
        </div>

        {degradation && (
          <div className="questions_item">
            <div className="questions_item_title">Possible Outcome</div>
            <div className="questions_item_wrapper">
              <div
                className={classnames("questions_item_note inline", {
                  reject: !isConcernComposed,
                  accept: isConcernComposed,
                })}
              >
                <div>
                  <img
                    src={
                      isConcernComposed
                        ? "/icons/general/green_circle_cross.svg"
                        : "/icons/general/red_circle_cross.svg"
                    }
                    alt="concern status"
                  />
                  {degradation.name}
                </div>
                <div>
                  <AccessControl
                    permissions={[PERMISSION.CONDITION_NOTE_DELETE]}
                    plant={plant}
                    conditionOption={degradation}
                  >
                    <div className="instances-edit-button" onClick={() => setIsRemoveConcern(true)}>
                      Remove from instance
                    </div>
                  </AccessControl>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal isShowing={isRemoveConcern} onClose={() => setIsRemoveConcern(false)} boxPadding>
        <RemoveModalContent
          heading="Remove concern"
          content="Are you sure you want to remove concern?"
          cancelText="Cancel"
          removeText="Remove"
          onClose={() => setIsRemoveConcern(false)}
          onDelete={handleRemoveOption}
        />
      </Modal>
    </div>
  );
};

export default InstancesConcernContainer;
