import React, { useRef, useState } from "react";
import { VideoModal } from "../VideoModal";

import "./video.scss";

interface VideoProps {
  src?: string;
  onLoad?: (event?: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
}

const Video: React.FunctionComponent<VideoProps> = (props) => {
  const { src, onLoad } = props;

  const [showModal, setShowModal] = useState(false);

  const videoRef = useRef<HTMLVideoElement | null>(null);

  const loaded = (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    onLoad?.(event);
  };

  return (
    <div className="video">
      <div className="video-play-btn" />
      <video
        onClick={() => setShowModal(true)}
        ref={videoRef}
        src={src}
        controlsList="nodownload noremoteplayback"
        onLoadedData={loaded}
      />
      <VideoModal src={src} show={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
};

export default Video;
