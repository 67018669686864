import React, { useRef, useState, useMemo, useCallback } from "react";
import { FieldArrayRenderProps } from "formik";
import { useDispatch } from "react-redux";
import { getThumbnails } from "video-metadata-thumbnails";

import { ATTACHMENT_TYPES, ATTACHMENT_TYPES_MAP, ImageFileExtensions, VideoFileExtensions } from "../../../constants";
import { AttachmentUploadValue } from "./interfaces";
import { RemoveModalContent } from "../../../components/RemoveModalContent";
import { Modal } from "../../../components/Modal";
import { ModalContent } from "../../../components/ModalContent";
import { ImageAnnotate } from "../ImageAnnotate";
import { blobToBase64andDrawThumbnail, getFileExtension, isValidExtension } from "../../../utils";
import { setUpdateLayout, showNotification } from "../../../store/actions";
import { BinaryImage } from "../../../components/BinaryImage";
import { useAttachment } from "../../../hooks";
import { AttachmentActions, BinaryVideo, Video } from "../../../components";
import { FileType } from "../../../models";

import "./attachmentsUpload.scss";

interface AttachmentsUploadProps {
  value: AttachmentUploadValue;
  name: string;
  index?: number;
  setFieldValue: (field: string, value: AttachmentUploadValue) => void;
  formHelpers?: FieldArrayRenderProps;
}

const getType = (fileType: FileType): "image" | "video" | undefined => {
  if (!fileType) {
    return;
  }
  if (ImageFileExtensions.includes(fileType)) {
    return "image";
  }
  if (VideoFileExtensions.includes(fileType)) {
    return "video";
  }
};

const emptyValue = { uid: "", imageType: ATTACHMENT_TYPES.ATTACHMENT };

export const AttachmentsUpload: React.FunctionComponent<AttachmentsUploadProps> = (props) => {
  const { value, name, setFieldValue, index, formHelpers } = props;

  const [isEditingImage, setIsEditingImage] = useState(false);
  const [isRemoveOpened, setIsRemoveOpened] = useState(false);
  const [srcAnnotated, setSrcAnnotated] = useState<string | undefined>(undefined);

  const dispatch = useDispatch();
  const label = useMemo(() => {
    return `
    ${index !== undefined && index < 5 ? "Photo " : ""}
    ${index !== undefined ? index + 1 : ""} - ${ATTACHMENT_TYPES_MAP[value.imageType].label}`;
  }, [index, value]);

  const { isAttachment, allowedExtensions, allowedFileTypes, attachmentPlaceholder, isImage } = useAttachment(value);

  const inputRef = useRef<HTMLInputElement>(null);

  const attachmentIndex = index || 0;

  const handleFileSelect = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      const rawFile = files && files[0];
      const reader = new FileReader();
      const fileName = rawFile?.name;
      let thumbnail: string | undefined;
      if (fileName && isValidExtension(fileName, allowedExtensions)) {
        const fileType = getFileExtension(fileName);
        const type = getType(fileType);
        if (rawFile) {
          if (type === "video") {
            thumbnail = await blobToBase64andDrawThumbnail(rawFile);
          }
          reader.onloadend = (e) => {
            const file = e?.target?.result?.toString();
            if (file && type) {
              setFieldValue(name, {
                ...emptyValue,
                src: file,
                file: rawFile,
                imageType: value.imageType,
                fileType,
                type,
                positionIndex: attachmentIndex,
                thumbnail,
              });
              if (isAttachment && !isEditingImage) {
                formHelpers?.push(emptyValue);
              }
            }
          };

          reader.readAsDataURL(rawFile);
        }
      } else {
        dispatch(
          showNotification({
            message: `Please choose the file with the allowed extension: ${allowedExtensions.join(", ")}`,
            appearance: "error",
          }),
        );
        e.target.value = "";
      }
    },
    [
      dispatch,
      setFieldValue,
      formHelpers,
      isEditingImage,
      name,
      value,
      isAttachment,
      allowedExtensions,
      attachmentIndex,
    ],
  );

  const handleFileUpload = (isEdit?: boolean) => {
    inputRef?.current?.click();
    setIsEditingImage(!!isEdit);
  };

  const handleFileDelete = useCallback(() => {
    setIsRemoveOpened(false);
    setFieldValue(name, { ...emptyValue, imageType: value.imageType, type: "image" });

    if (isAttachment && index) {
      formHelpers?.remove(index);
    }
  }, [formHelpers, isAttachment, index, name, value, setFieldValue]);

  const handleFileSave = (imgBase64: string) => {
    setIsEditingImage(false);
    if (value.parentId || value.srcAnnotated) {
      if (value.srcAnnotated) {
        setFieldValue(name, {
          ...value,
          srcAnnotated: imgBase64,
        });
      } else {
        setFieldValue(name, {
          ...value,
          src: imgBase64,
        });
      }
    } else {
      setSrcAnnotated(imgBase64);
    }
  };

  const handleSaveOnlyAnnotated = () => {
    setFieldValue(name, {
      ...value,
      src: srcAnnotated,
    });
    setSrcAnnotated(undefined);
  };

  const handleSaveBoth = () => {
    setFieldValue(name, {
      ...value,
      srcAnnotated: srcAnnotated,
    });
    setSrcAnnotated(undefined);
  };

  const handleAttachmentLoad = useCallback(
    (event?: React.SyntheticEvent<HTMLImageElement | HTMLVideoElement, Event>) => {
      const element = event?.target;
      if (element instanceof HTMLVideoElement && element.duration > 90) {
        dispatch(
          showNotification({
            message: "The video has to be 90 secs max. Please edit or reshoot.",
            appearance: "error",
          }),
        );
        setTimeout(handleFileDelete, 500);
      }
      dispatch(setUpdateLayout(true));
    },
    [dispatch, handleFileDelete],
  );

  const videoUrl = useMemo(() => (value.file ? URL.createObjectURL(value.file) : undefined), [value]);

  return (
    <div className="attachments_upload">
      <div className="attachments_upload_label">{label}</div>
      <div
        className="attachments_upload_container"
        onClick={!value.src && !value.srcAnnotated && !value.key ? () => handleFileUpload() : () => {}}
      >
        {value.key ? (
          isImage ? (
            <BinaryImage uid={value.key} alt="upload photos" onLoad={handleAttachmentLoad} />
          ) : (
            <BinaryVideo uid={value.key} onLoad={handleAttachmentLoad} />
          )
        ) : value.src || value.srcAnnotated ? (
          <>
            {!isImage ? (
              <Video src={videoUrl} onLoad={handleAttachmentLoad} />
            ) : (
              <img
                className="loaded"
                src={value.srcAnnotated || value.src}
                alt="upload photos"
                onLoad={handleAttachmentLoad}
              />
            )}
          </>
        ) : (
          <img
            src={attachmentPlaceholder}
            className="attachments_upload_default"
            alt="upload"
            onLoad={handleAttachmentLoad}
          />
        )}
        {(value.key || value.src || value.srcAnnotated) && (
          <AttachmentActions
            className="actions"
            actions={!isImage ? ["delete"] : undefined}
            onDelete={() => setIsRemoveOpened(true)}
            onEdit={() => setIsEditingImage(true)}
          />
        )}
        {value?.voiceNote && (
          <div className="attachments_upload_container_audio">
            <img src="/icons/general/speaker.svg" alt="has audio file" />
          </div>
        )}
        <input type="file" accept={allowedFileTypes} name={name} onChange={handleFileSelect} ref={inputRef} />
      </div>

      {isEditingImage && (
        <ImageAnnotate
          header={label}
          imageTypeId={value.imageType}
          uid={value.key || ""}
          src={value.srcAnnotated || value.src || ""}
          show={isEditingImage}
          onCancel={() => setIsEditingImage(false)}
          onClose={() => setIsEditingImage(false)}
          onSave={handleFileSave}
          maxWidth={680}
          maxHeight={520}
        />
      )}

      <Modal isShowing={isRemoveOpened} onClose={() => setIsRemoveOpened(false)} boxPadding>
        <RemoveModalContent
          heading="Remove Attachment"
          content="Are you sure you want to delete the Attachment?"
          cancelText="Cancel"
          removeText="Remove"
          onClose={() => setIsRemoveOpened(false)}
          onDelete={handleFileDelete}
          isSubmitType
        />
      </Modal>

      <Modal isShowing={!!srcAnnotated} onClose={() => setSrcAnnotated(undefined)} boxPaddingThick>
        <ModalContent
          heading="Save Photo"
          content="Would you like to save the original photo?"
          minorActionText="Annotated only"
          mainActionText="Save both"
          onMinorAction={handleSaveOnlyAnnotated}
          onMainAction={handleSaveBoth}
        />
      </Modal>
    </div>
  );
};
