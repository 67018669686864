import { createAsyncAction } from "typesafe-actions";
import { SortFilterOptionsWithInactive, SortFilterOptions } from "../../../shared/interfaces";

import { CompaniesPlants, Company, CompanyRequest, Plant, Document } from "../../../shared/models";
import { ActionTypes } from "./constants";
import { UpdateDocumentShape, AddDocumentShape } from "../interfaces";

export const getCompaniesPlants = createAsyncAction(
  ActionTypes.GET_COMPANIES_PLANTS,
  ActionTypes.GET_COMPANIES_PLANTS_SUCCESS,
  ActionTypes.GET_COMPANIES_PLANTS_FAILURE,
)<SortFilterOptionsWithInactive, CompaniesPlants[], Error>();

export const getCompanies = createAsyncAction(
  ActionTypes.GET_COMPANIES,
  ActionTypes.GET_COMPANIES_SUCCESS,
  ActionTypes.GET_COMPANIES_FAILURE,
)<SortFilterOptionsWithInactive, Company[], Error>();

export const getCompany = createAsyncAction(
  ActionTypes.GET_COMPANY,
  ActionTypes.GET_COMPANY_SUCCESS,
  ActionTypes.GET_COMPANY_FAILURE,
)<number, Company, Error>();

export const updateCompany = createAsyncAction(
  ActionTypes.UPDATE_COMPANY,
  ActionTypes.UPDATE_COMPANY_SUCCESS,
  ActionTypes.UPDATE_COMPANY_FAILURE,
)<CompanyRequest, { company: Company; message: string }, Error>();

export const createCompany = createAsyncAction(
  ActionTypes.CREATE_COMPANY,
  ActionTypes.CREATE_COMPANY_SUCCESS,
  ActionTypes.CREATE_COMPANY_FAILURE,
)<CompanyRequest, { company: Company; message: string }, Error>();

export const deleteCompany = createAsyncAction(
  ActionTypes.DELETE_COMPANY,
  ActionTypes.DELETE_COMPANY_SUCCESS,
  ActionTypes.DELETE_COMPANY_FAILURE,
)<number, { company: Company; message: string }, Error>();

export const restoreCompany = createAsyncAction(
  ActionTypes.RESTORE_COMPANY,
  ActionTypes.RESTORE_COMPANY_SUCCESS,
  ActionTypes.RESTORE_COMPANY_FAILURE,
)<number, { company: Company; message: string }, Error>();

export const getPlant = createAsyncAction(
  ActionTypes.GET_PLANT,
  ActionTypes.GET_PLANT_SUCCESS,
  ActionTypes.GET_PLANT_FAILURE,
)<number, Plant, Error>();

export const updatePlant = createAsyncAction(
  ActionTypes.UPDATE_PLANT,
  ActionTypes.UPDATE_PLANT_SUCCESS,
  ActionTypes.UPDATE_PLANT_FAILURE,
)<Partial<Plant>, { company: Plant; message: string }, Error>();

export const createPlant = createAsyncAction(
  ActionTypes.CREATE_PLANT,
  ActionTypes.CREATE_PLANT_SUCCESS,
  ActionTypes.CREATE_PLANT_FAILURE,
)<Partial<Plant>, { company: Plant; message: string }, Error>();

export const deletePlant = createAsyncAction(
  ActionTypes.DELETE_PLANT,
  ActionTypes.DELETE_PLANT_SUCCESS,
  ActionTypes.DELETE_PLANT_FAILURE,
)<number, { company: Plant; message: string }, Error>();

export const restorePlant = createAsyncAction(
  ActionTypes.RESTORE_PLANT,
  ActionTypes.RESTORE_PLANT_SUCCESS,
  ActionTypes.RESTORE_PLANT_FAILURE,
)<number, { company: Plant; message: string }, Error>();

export const getPlantDocuments = createAsyncAction(
  ActionTypes.GET_PLANT_DOCUMENTS,
  ActionTypes.GET_PLANT_DOCUMENTS_SUCCESS,
  ActionTypes.GET_PLANT_DOCUMENTS_FAILURE,
)<{ plantId: number; filter: SortFilterOptions }, Document[], Error>();

export const deletePlantDocument = createAsyncAction(
  ActionTypes.DELETE_PLANT_DOCUMENT,
  ActionTypes.DELETE_PLANT_DOCUMENT_SUCCESS,
  ActionTypes.DELETE_PLANT_DOCUMENT_FAILURE,
)<{ plantId: number; documentId: number }, { message: string; documentId: number }, Error>();

export const createPlantDocument = createAsyncAction(
  ActionTypes.CREATE_PLANT_DOCUMENT,
  ActionTypes.CREATE_PLANT_DOCUMENT_SUCCESS,
  ActionTypes.CREATE_PLANT_DOCUMENT_FAILURE,
)<{ plantId: number; data: AddDocumentShape }, { message: string; document: Document }, Error>();

export const updatePlantDocument = createAsyncAction(
  ActionTypes.UPDATE_PLANT_DOCUMENT,
  ActionTypes.UPDATE_PLANT_DOCUMENT_SUCCESS,
  ActionTypes.UPDATE_PLANT_DOCUMENT_FAILURE,
)<{ plantId: number; documentId: number; data: UpdateDocumentShape }, { message: string; document: Document }, Error>();
