import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "../store";
import { usePrevious } from "./UsePreviousHook";

export function useBinaryUrl(props: {
  binaryType: string;
  isVisible: boolean;
  uid: string | undefined;
  isLazy: boolean;
  errorImg?: string;
  onLoad?: Function | undefined;
}) {
  const { binaryType, isVisible, uid, isLazy, errorImg = "/icons/general/no-image.png", onLoad } = props;

  const dispatch = useDispatch();

  const previousUid = usePrevious(uid);

  const [errorSrc, setErrorSrc] = useState<string | undefined>(undefined);
  const [binary, setBinary] = useState<string | undefined>(undefined);
  const [loaded, setLoaded] = useState(false);
  const binaryFile = useSelector(selectors.getFile(uid));
  const startLoading = useCallback((uid: string | undefined) => uid && dispatch(actions.getFile.request(uid)), [
    dispatch,
  ]);

  useEffect(() => {
    // Lazy component request
    if (isLazy && isVisible && (!binary?.length || uid !== previousUid)) {
      startLoading(uid);
    }
  }, [startLoading, uid, previousUid, binary, isVisible, isLazy]);

  useEffect(() => {
    // Non-lazy component request
    if (!isLazy && !binary?.length && uid !== previousUid) {
      startLoading(uid);
    }
  }, [startLoading, uid, previousUid, binary, isLazy]);

  const handleLoad = useCallback(
    (e?: React.SyntheticEvent<HTMLImageElement, Event>) => {
      if (binary?.length && uid) {
        dispatch(actions.cleanFile(uid));
        onLoad && onLoad(e);
        setLoaded(true);
      }
    },
    [binary, dispatch, onLoad, uid],
  );

  const handleError = useCallback(() => {
    setErrorSrc(errorImg);
  }, [errorImg]);

  useEffect(() => {
    if (binaryFile) {
      setBinary(URL.createObjectURL(new Blob([binaryFile], { type: binaryType })) || "");
    }
  }, [binaryFile, binaryType]);

  return { binary, errorSrc, loaded, handleLoad, handleError };
}
