import React, { useState, useEffect, useMemo } from "react";
import classnames from "classnames";

import "./addMembersModal.scss";
import { Modal } from "../Modal";
import { useDebounce } from "../../hooks";
import { Profile } from "../../models";
import { SearchInput } from "../../formComponents/common";
import { DEBOUNCE_OPTIONS } from "../../constants";

interface AddMembersModalProps {
  membersProfiles: Profile[];
  onClose: () => void;
  onSubmit: (selectedMembersIds: number[]) => void;
}

const AddMembersModal = (props: AddMembersModalProps) => {
  const { onClose, membersProfiles, onSubmit } = props;

  const [selectedMembersMap, setSelectedMembersMap] = useState<{ [key: number]: boolean }>({});
  const [search, setSearch] = useState("");
  const debouncedSearch: string = useDebounce(search, DEBOUNCE_OPTIONS.delay);

  const filteredMembersProfiles = useMemo(() => {
    return membersProfiles.filter(({ firstName, lastName }) => {
      const fullName = `${firstName} ${lastName}`.toLowerCase();
      const reversedFullName = `${lastName} ${firstName}`.toLowerCase();
      const lowerSearch = debouncedSearch.toLowerCase();

      return fullName.includes(lowerSearch) || reversedFullName.includes(lowerSearch);
    });
  }, [membersProfiles, debouncedSearch]);

  useEffect(() => {
    const membersMap: { [key: number]: boolean } = [];
    filteredMembersProfiles.forEach((member) => {
      membersMap[member.id] = false;
    });

    setSelectedMembersMap(membersMap);
  }, [filteredMembersProfiles]);

  const selectedMembersDetails: {
    membersCount: number;
    label?: string;
    firstMemberLogo?: string;
    secondMemberLogo?: string;
  } = useMemo(() => {
    const selectedMembersIds = Object.entries(selectedMembersMap)
      .filter(([id, isSelected]) => isSelected)
      .map(([id, isSelected]) => +id);

    if (!selectedMembersIds.length) {
      return { membersCount: 0 };
    }

    const firstMember = filteredMembersProfiles.find((member) => member.id === selectedMembersIds[0]);
    const secondMember = filteredMembersProfiles.find((member) => member.id === selectedMembersIds[1]);
    const label =
      selectedMembersIds.length === 1
        ? `${firstMember?.firstName} ${firstMember?.lastName}`
        : `${selectedMembersIds.length} Collaborators`;

    return {
      label,
      // collaborators in DB have wrong images, fix and unCommit here!
      //firstMemberLogo: !!firstMember ? firstMember.imageUrl || "/icons/menu/profile_image.svg" : "",
      //secondMemberLogo: !!secondMember ? secondMember.imageUrl || "/icons/menu/profile_image.svg" : "",
      firstMemberLogo: !!firstMember ? "/icons/general/profile_image.svg" : "",
      secondMemberLogo: !!secondMember ? "/icons/general/profile_image.svg" : "",
      membersCount: selectedMembersIds.length - 2,
    };
  }, [selectedMembersMap, filteredMembersProfiles]);

  const handleSelectMember = (id: number) => {
    setSelectedMembersMap({ ...selectedMembersMap, [id]: !selectedMembersMap[id] });
  };

  const handleAddCollaborators = () => {
    const selectedMembersIds = Object.entries(selectedMembersMap)
      .filter(([id, isSelected]) => isSelected)
      .map(([id, isSelected]) => +id);

    onSubmit(selectedMembersIds);
  };

  return (
    <Modal isShowing onClose={onClose} boxPadding={false}>
      <div className="add_members_modal">
        <div className="add_members_modal_header">
          <div className="add_members_modal_header_title">Add Collaborators</div>
          <div className="add_members_modal_header_search">
            <SearchInput onChangeSearch={setSearch} placeholder="Search" value={debouncedSearch} />
          </div>
        </div>
        <div className="add_members_modal_body">
          {filteredMembersProfiles.map((member) => (
            <div
              key={member.id}
              className="add_members_modal_body_member"
              onClick={() => handleSelectMember(member.id)}
            >
              <div className="member_photo">
                <img
                  className={classnames({ selected: selectedMembersMap[member.id] })}
                  src="/icons/general/profile_image.svg"
                  alt="member"
                />
              </div>
              <div className="member_name">{`${member.firstName} ${member.lastName}`}</div>
            </div>
          ))}
          {!filteredMembersProfiles.length && <div className="no-matches-found" />}
        </div>
        <div className="add_members_modal_footer">
          <div className="add_members_modal_footer_members">
            {selectedMembersDetails.label && (
              <>
                <div
                  className={classnames(
                    "add_members_modal_footer_members_images",
                    `count_${selectedMembersDetails.membersCount + 1}`,
                  )}
                >
                  {!!selectedMembersDetails.firstMemberLogo && (
                    <div className="image_wrapper">
                      <img src={selectedMembersDetails.firstMemberLogo} alt="selected member" />
                    </div>
                  )}
                  {!!selectedMembersDetails.secondMemberLogo && (
                    <div className="image_wrapper">
                      <img src={selectedMembersDetails.secondMemberLogo} alt="selected member" />
                    </div>
                  )}
                  {selectedMembersDetails.membersCount > 0 && (
                    <div className="image_wrapper">
                      <span>+{selectedMembersDetails.membersCount}</span>
                    </div>
                  )}
                </div>
                <div className="add_members_modal_footer_members_label">{selectedMembersDetails.label}</div>
              </>
            )}
          </div>
          <div className="add_members_modal_footer_buttons">
            <div className="modal_button-cancel" onClick={onClose}>
              Cancel
            </div>
            <div className="modal_button-add" onClick={handleAddCollaborators}>
              Add
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddMembersModal;
