import { AnyAction, Dispatch } from "redux";
import * as SocketIOClient from "socket.io-client";
import config from "../../config";
import { NotificationHistory } from "../models";
import { addNewNotificationHistory, getNotificationHistoryList } from "../store/actions";

export const initSocket = (profileId: number | undefined, dispatch: Dispatch<AnyAction>) => {
  const socket = SocketIOClient.connect(config.socketUrl, {
    secure: true,
    transports: ["websocket", "polling"],
  });

  socket.on("connect", () => {
    // eslint-disable-next-line
    console.log("[socket] connect");
    if (profileId) socket.emit("join", String(profileId));
  });

  socket.on("disconnect", () => {
    // eslint-disable-next-line
    console.log("[socket] disconnect");
  });

  socket.on("new-notification", (payload: NotificationHistory) => {
    dispatch(addNewNotificationHistory(payload));
  });

  socket.on("remove-notifications", () => {
    dispatch(getNotificationHistoryList.request());
  });
};
