import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";

import { LoginContainer } from "..";
import { ROUTE_PATHS } from "../../../../shared/routes";
import { tokenHandler } from "../../../../shared/utils";
import { authenticated } from "../../store/selectors";
import { EPRILoginContainer } from "../EPRILoginContainer";
import { EPRILoginStatusContainer } from "../EPRILoginStatusContainer";
import "./index.scss";

const redirectHandler = (dispatch: Function) => {
  return dispatch(push(ROUTE_PATHS.COMPANIES_DASHBOARD));
};

const AuthContainer = () => {
  const isAuthorized = useSelector(authenticated());

  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthorized) {
      const token = tokenHandler.getAccessToken();

      if (token) {
        redirectHandler(dispatch);
      }
    }
  }, [isAuthorized, dispatch]);

  return (
    <section className="auth-container">
      <div className="auth-container-content">
        <Switch>
          <Route path={ROUTE_PATHS.AUTH_LOGIN_OLD} exact component={LoginContainer} />
          <Route path={ROUTE_PATHS.AUTH_LOGIN} component={EPRILoginContainer} />
          <Route path={ROUTE_PATHS.AUTH_STATUS} component={EPRILoginStatusContainer} />
          <Redirect to={ROUTE_PATHS.AUTH_LOGIN} />
        </Switch>
      </div>
    </section>
  );
};

export default AuthContainer;
