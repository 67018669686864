import React from "react";
import "./errorMessage.scss";

interface ErrorMessageProps {
  isTouched?: boolean;
  error?: string;
}

export const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = (props) => {
  const { isTouched, error } = props;

  return isTouched && error ? <div className="error-message">{error}</div> : null;
};
