import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MasonryInstance } from "../interfaces/Image";
import { setUpdateLayout } from "../store/actions";
import { getUpdateLayoutStatus } from "../store/selectors";
import useElementSize from "./UseElementSize";
import { usePrevious } from "./UsePreviousHook";

let timeout: number | undefined;

const useMasonryLayout = (container: HTMLDivElement | null, masonryInstance: MasonryInstance | null) => {
  const shouldUpdate = useSelector(getUpdateLayoutStatus());

  const dispatch = useDispatch();

  const updateLayout = useCallback(() => {
    dispatch(setUpdateLayout(true));
  }, [dispatch]);

  useEffect(() => {
    if (shouldUpdate) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        masonryInstance?.masonry?.layout?.();
        dispatch(setUpdateLayout(false));
      }, 500);
    }
  }, [shouldUpdate, masonryInstance, dispatch]);

  useEffect(
    () => () => {
      clearTimeout(timeout);
      timeout = undefined;
      dispatch(setUpdateLayout(false));
    },
    [dispatch],
  );

  const { width } = useElementSize(container, 300);
  const previousContainerWidth = usePrevious(width);

  useEffect(() => {
    if (width !== previousContainerWidth) {
      updateLayout();
    }
  }, [width, previousContainerWidth, updateLayout]);

  return [updateLayout];
};

export default useMasonryLayout;
