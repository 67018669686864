import { useCallback, useState } from "react";
import Konva from "konva";

enum Tool {
  DRAW = "draw",
  CROP = "crop",
}

const DEFAULT_COLOR = "red";

export function useKonva(props: {
  maxWidth: number;
  maxHeight: number;
  origImageSize: {
    w: number;
    h: number;
  };
}) {
  const { maxWidth, maxHeight, origImageSize } = props;
  const [isDrawing, setIsDrawing] = useState(false);
  const [croppedImageSize, setCroppedImageSize] = useState({ w: 0, h: 0 });
  const [activeTool, setActiveTool] = useState<string>(Tool.DRAW);
  const [isCropping, setIsCropping] = useState(false);
  const [cropPoints, setCropPoints] = useState({ x: 0, y: 0, w: 0, h: 0 });
  const [imageCrop, setImageCrop] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const [origRatio, setOrigRatio] = useState(1);
  const [ratio, setRatio] = useState(1);
  const [linePoints, setLinePoints] = useState<number[][]>([[]]);
  const [drawColor] = useState(DEFAULT_COLOR);

  const resizeKeepAspect = useCallback(
    (imgWidth: number, imgHeight: number): { newWidth: number; newHeight: number; newRatio: number } => {
      let newRatio;
      let newWidth;
      let newHeight;
      const ratioW = imgWidth / maxWidth;
      const ratioH = imgHeight / maxHeight;

      if (ratioW > ratioH) {
        newRatio = ratioW;
        newHeight = imgHeight / ratioW;
        newWidth = maxWidth;
      } else {
        newRatio = ratioH;
        newWidth = imgWidth / ratioH;
        newHeight = maxHeight;
      }
      return { newWidth, newHeight, newRatio };
    },
    [maxWidth, maxHeight],
  );

  const onMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
    if (e.evt.buttons !== 1) {
      return;
    }

    const { x, y } = e.target.getStage()?.getPointerPosition() as Konva.Vector2d;

    if (activeTool === Tool.DRAW) {
      setIsDrawing(true);
      setLinePoints([...linePoints, [x, y]]);
    }

    if (activeTool === Tool.CROP) {
      setIsCropping(true);
      setCropPoints({ x: x, y: y, w: 0, h: 0 });
    }
  };

  const onMouseUp = (e: Konva.KonvaEventObject<MouseEvent>) => {
    if (activeTool === Tool.DRAW && isDrawing) {
      setIsDrawing(false);
    }

    if (activeTool === Tool.CROP && isCropping) {
      const { x, y } = e.target.getStage()?.getPointerPosition() as Konva.Vector2d;

      // reset drawings
      setLinePoints([[]]);

      setIsCropping(false);
      setCropPoints({ x: 0, y: 0, w: 0, h: 0 });

      const cropSettings = {
        x: cropPoints.x * ratio,
        y: cropPoints.y * ratio,
        width: (x - cropPoints.x) * ratio,
        height: (y - cropPoints.y) * ratio,
      };
      setImageCrop(cropSettings);
      const { newWidth, newHeight, newRatio } = resizeKeepAspect(
        (x - cropPoints.x) * ratio,
        (y - cropPoints.y) * ratio,
      );
      setCroppedImageSize({ w: newWidth, h: newHeight });
      setRatio(newRatio);
    }
  };

  const onMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
    if (activeTool === Tool.DRAW && isDrawing) {
      const { x, y } = e.target.getStage()?.getPointerPosition() as Konva.Vector2d;

      const temp = [...linePoints];
      const last = temp.pop() || [];
      setLinePoints([...temp, [...last, ...[x, y]]]);

      return;
    }

    if (activeTool === Tool.CROP && isCropping) {
      const { x, y } = e.target.getStage()?.getPointerPosition() as Konva.Vector2d;
      setCropPoints({ x: cropPoints.x, y: cropPoints.y, w: x - cropPoints.x, h: y - cropPoints.y });
    }
  };

  const onRevert = () => {
    setLinePoints([[]]);
    setCropPoints({ x: 0, y: 0, w: 0, h: 0 });
    setImageCrop({ x: 0, y: 0, width: 0, height: 0 });
    setCroppedImageSize(origImageSize);
    setRatio(origRatio);
  };

  return {
    Tool,
    activeTool,
    setActiveTool,
    drawColor,
    linePoints,
    isCropping,
    cropPoints,
    imageCrop,
    resizeKeepAspect,
    onMouseUp,
    onMouseDown,
    onMouseMove,
    onRevert,
    setRatio,
    setOrigRatio,
    croppedImageSize,
  };
}
