import { useMemo } from "react";
import { ATTACHMENT_TYPES, ImageFileExtensions, VideoFileExtensions } from "../constants";
import { AttachmentUploadValue } from "../formComponents/common";

export const useAttachment = (attachment: AttachmentUploadValue) => {
  const isAttachment = useMemo(() => attachment.imageType === ATTACHMENT_TYPES.ATTACHMENT, [attachment]);
  const allowedExtensions = useMemo(
    () => (isAttachment ? [...ImageFileExtensions, ...VideoFileExtensions] : ImageFileExtensions),
    [isAttachment],
  );

  const allowedFileTypes = useMemo(() => allowedExtensions.map((ext) => `.${ext}`).join(), [allowedExtensions]);

  const attachmentPlaceholder = useMemo(() => `/icons/general/${isAttachment ? "media" : "image"}_empty_add.svg`, [
    isAttachment,
  ]);

  const isImage = useMemo(() => attachment.type === "image", [attachment]);

  return { isAttachment, allowedExtensions, allowedFileTypes, attachmentPlaceholder, isImage };
};
