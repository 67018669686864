import React, { useMemo } from "react";
import classnames from "classnames";

import { BooleanAnswers } from "../../../../../../shared/components";
import { ConditionOptionsWithConcern } from "../../../../../../shared/models";
import "./instancesConditionNoteQuestions.scss";

interface InstancesCheckingConcernProps {
  questions: ConditionOptionsWithConcern[];
  nextStep: (isAConcern: boolean) => void;
  handleChangeQuestion: (question: ConditionOptionsWithConcern) => void;
  isLastStep: boolean;
  degradationLabel: string;
}

export const InstancesConditionNoteQuestions = (props: InstancesCheckingConcernProps) => {
  const { questions, handleChangeQuestion, isLastStep, nextStep, degradationLabel } = props;

  const handleChangeValue = (question: ConditionOptionsWithConcern) => (value: boolean) => {
    handleChangeQuestion({ ...question, isAConcern: value });
  };

  const isAConcern = useMemo(() => {
    if (questions.every(({ isAConcern }) => isAConcern === false)) {
      return false;
    }

    const selectedAnswer = questions.find(({ isAConcern }) => isAConcern === true);
    return selectedAnswer ? true : undefined;
  }, [questions]);

  return (
    <div className="questions">
      <div className="questions_item">
        <div className="questions_item_title">Observations</div>
        <div className="questions_item_wrapper">
          {questions.map((question, index) => (
            <BooleanAnswers
              key={question.id}
              questionNumber={index + 1}
              text={question.name}
              handleChangeValue={handleChangeValue(question)}
            />
          ))}
        </div>
      </div>

      {isAConcern !== undefined ? (
        <div className="questions_item">
          <div className="questions_item_title">Possible Outcome</div>
          <div className="questions_item_wrapper">
            <div className={classnames("questions_item_note inline", { reject: !isAConcern, accept: isAConcern })}>
              <div>
                <img
                  src={isAConcern ? "/icons/general/green_circle_cross.svg" : "/icons/general/red_circle_cross.svg"}
                  alt="concern status"
                />
                {degradationLabel}
              </div>
              <div>
                <div className="instances-edit-button" onClick={() => nextStep(!!isAConcern)}>
                  {isLastStep ? "Go to instance" : "next step"}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
