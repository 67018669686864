import React from "react";
import classnames from "classnames";
import { FormikValues } from "formik";

import { TextareaWrapper } from "./TextareaWrapper";
import "./index.scss";

interface TextareaProps extends React.HTMLProps<HTMLTextAreaElement> {
  className?: string;
  label?: string;
  setFieldValue?: Function;
  values?: FormikValues;
  maxLength?: number;
}

const Textarea: React.FunctionComponent<TextareaProps> = (props: TextareaProps) => {
  const { className, maxLength, ...inputProps } = props;

  return (
    <TextareaWrapper className={className}>
      <div className="textArea_details">
        <label className={classnames("textArea_details_label", { disabled: props.disabled })}>{props.label}</label>
        {maxLength && (
          <div className="textArea_details_length">{`${inputProps.value?.toString()?.length || 0}/${maxLength}`}</div>
        )}
      </div>
      <textarea {...inputProps} />
    </TextareaWrapper>
  );
};
export default Textarea;
