import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actions, selectors } from "../../store";
import { AppLoader } from "../AppLoader";
import { VideoModal } from "../VideoModal";

import "./binaryVideo.scss";

interface VideoProps {
  uid?: string;
  onLoad?: (event?: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
}

const BinaryVideo: React.FunctionComponent<VideoProps> = (props) => {
  const { uid, onLoad } = props;

  const [showModal, setShowModal] = useState(false);
  const [fileUrl, setFileUrl] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  const binaryVideo = useSelector(selectors.getFile(uid));

  const dispatch = useDispatch();

  useEffect(() => {
    if (uid) {
      dispatch(actions.getFile.request(uid));
    }
  }, [dispatch, uid]);
  useEffect(() => {
    if (binaryVideo) {
      setFileUrl(URL.createObjectURL(new Blob([binaryVideo], { type: "video/mp4" })));
      setIsLoading(false);
    }
  }, [binaryVideo]);

  useEffect(
    () => () => {
      fileUrl && URL.revokeObjectURL(fileUrl);
      uid && dispatch(actions.cleanFile(uid));
    },
    [fileUrl, uid, dispatch],
  );
  const loaded = (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    if (uid) {
      dispatch(actions.cleanFile(uid));
    }
    onLoad?.(event);
  };

  return (
    <div className="binary-video">
      {isLoading ? (
        <AppLoader />
      ) : (
        <>
          <div className="binary-video-play-btn" />
          <video onClick={() => setShowModal(true)} controlsList="nodownload noremoteplayback" onLoadedData={loaded}>
            <source src={fileUrl} type="video/mp4" />
            <source src={fileUrl} type="video/quicktime" />
          </video>
          <VideoModal src={fileUrl} show={showModal} onClose={() => setShowModal(false)} />
        </>
      )}
    </div>
  );
};

export default BinaryVideo;
