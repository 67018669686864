import produce from "immer";
import { Reducer } from "redux";
import { SharedStateType } from "../interfaces";
import { ImageType } from "../models/ImageTypes";
import { SharedActionTypes } from "./constants";

const initialState: SharedStateType = {
  notification: null,
  user: null,
  loadingTypes: [],
  loadingSections: [],
  files: {},
  notificationHistory: [],
  roles: [],
  imageTypes: undefined,
  reviewStatuses: undefined,
  fieldGuide: undefined,
  fieldGuides: [],
  userGuide: undefined,
  shouldUpdateLayout: false,
};

const reducer: Reducer<SharedStateType> = (state: SharedStateType = initialState, action) => {
  switch (action.type) {
    case SharedActionTypes.ADD_LOADING_TYPE:
      return produce(state, (nextState) => {
        nextState.loadingTypes = [...state.loadingTypes, action.payload];
      });
    case SharedActionTypes.REMOVE_LOADING_TYPE:
      return produce(state, (nextState) => {
        nextState.loadingTypes = state.loadingTypes.filter(({ name }) => name !== action.payload);
      });
    case SharedActionTypes.ADD_LOADING_SECTION:
      return produce(state, (nextState) => {
        if (!state.loadingSections.includes(action.payload)) {
          nextState.loadingSections = [...state.loadingSections, action.payload];
        }
      });
    case SharedActionTypes.REMOVE_LOADING_SECTION:
      return produce(state, (nextState) => {
        const indexOfLoadingSection = state.loadingSections.indexOf(action.payload);

        nextState.loadingSections = state.loadingSections.filter((a, index) => index !== indexOfLoadingSection);
      });

    case SharedActionTypes.SHOW_NOTIFICATION: {
      return produce(state, (nextState) => {
        nextState.notification = action.payload;
      });
    }
    case SharedActionTypes.CLEAR_NOTIFICATION: {
      return produce(state, (nextState) => {
        nextState.notification = null;
      });
    }
    case SharedActionTypes.GET_FILE_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.files = { ...nextState.files, ...action.payload };
      });
    }
    case SharedActionTypes.CLEAN_FILE: {
      return produce(state, (nextState) => {
        delete nextState.files[action.payload];
      });
    }
    case SharedActionTypes.GET_USER_SUCCESS:
      return produce(state, (nextState) => {
        nextState.user = action.payload;
      });

    case SharedActionTypes.GET_IMAGE_TYPES_SUCCESS:
      const imageTypes: ImageType[] = action.payload;
      return produce(state, (nextState) => {
        nextState.imageTypes = imageTypes;
      });

    case SharedActionTypes.GET_REVIEW_STATUSES_SUCCESS:
      const reviewStatuses: ImageType[] = action.payload;
      return produce(state, (nextState) => {
        nextState.reviewStatuses = reviewStatuses;
      });

    case SharedActionTypes.GET_FIELD_GUIDES_SUCCESS:
      return produce(state, (nextState) => {
        nextState.fieldGuide = action.payload[0];
      });
    case SharedActionTypes.GET_PROFILE_FIELD_GUIDES_SUCCESS:
      return produce(state, (nextState) => {
        nextState.fieldGuides = action.payload;
      });
    case SharedActionTypes.GET_USER_GUIDE_SUCCESS:
      return produce(state, (nextState) => {
        nextState.userGuide = action.payload;
      });
    case SharedActionTypes.GET_ROLES_SUCCESS:
      return produce(state, (nextState) => {
        nextState.roles = action.payload;
      });
    case SharedActionTypes.GET_NOTIFICATION_HISTORY_LIST_SUCCESS:
      return produce(state, (nextState) => {
        nextState.notificationHistory = action.payload;
      });
    case SharedActionTypes.MARK_ALL_NOTIFICATIONS_HISTORY_AS_READ_SUCCESS:
      return produce(state, (nextState) => {
        nextState.notificationHistory = action.payload;
      });
    case SharedActionTypes.MARK_ONE_NOTIFICATION_HISTORY_AS_READ_SUCCESS:
      return produce(state, (nextState) => {
        const notificationHistory = action.payload;
        nextState.notificationHistory = nextState.notificationHistory.map((n) => {
          if (n.id === notificationHistory.id) {
            n = { ...notificationHistory };
          }

          return n;
        });
      });

    case SharedActionTypes.SET_FIELD_GUIDE: {
      return produce(state, (nextState) => {
        nextState.fieldGuide = action.payload;
      });
    }

    case SharedActionTypes.ADD_NEW_NOTIFICATION_HISTORY:
      return produce(state, (nextState) => {
        const notificationHistory = action.payload;
        if (notificationHistory) {
          nextState.notificationHistory = [notificationHistory, ...nextState.notificationHistory];
        }
      });

    case SharedActionTypes.SET_UPDATE_LAYOUT:
      return produce(state, (nextState) => {
        nextState.shouldUpdateLayout = action.payload;
      });
    default:
      return state;
  }
};
export default reducer;
