import React, { useMemo, useState } from "react";
import classnames from "classnames";

import "./expandPanel.scss";

interface ExpandPanelProps {
  isDefaultOpen?: boolean;
  title: string;
  primaryText?: string;
  secondaryText?: string;
  children: React.ReactNode;
  isGlobalOpened?: boolean;
}

const ExpandPanel: React.FunctionComponent<ExpandPanelProps> = (props) => {
  const { isDefaultOpen = true, title, primaryText, secondaryText, children, isGlobalOpened } = props;

  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  const isComposedOpened = useMemo(() => {
    return isGlobalOpened || isOpen;
  }, [isGlobalOpened, isOpen]);

  const toggleCollapse = () => {
    if (!isGlobalOpened) {
      setIsOpen(!isOpen);
    }
  };

  const openCollapse = () => {
    !isOpen && setIsOpen(true);
  };

  return (
    <div className={classnames("expand_panel", { collapsed: !isComposedOpened })} onClick={openCollapse}>
      <div className="expand_panel_header">
        <div className="expand_panel_header_body">{title}</div>
        {!isGlobalOpened && (
          <div
            className={classnames("expand_panel_header_arrow", { collapsed: !isComposedOpened })}
            onClick={toggleCollapse}
          />
        )}
      </div>
      <div className={classnames("expand_panel_content", { open: isComposedOpened })}>
        <div className="expand_panel_text">
          <div className="expand_panel_text_primary">{primaryText}</div>
          <div className="expand_panel_text_secondary">{secondaryText}</div>
        </div>
        <div className="expand_panel_body">{children}</div>
      </div>
    </div>
  );
};

export default ExpandPanel;
