import React, { useEffect, useState, useCallback, useRef } from "react";
import classnames from "classnames";

import "./navigationPanel.scss";

/** usage:
 *
 * <NavigationPanel panels={["panel1", "panel2"]}>
 *     <div/>   // => panel1
 *     <div/>   // => panel2
 * </NavigationPanel>
 */

interface NavigationPanelProps {
  panels: string[];
  immediatePanel?: string;
  onChangePanel?: (panel: string) => void;
  children: React.ReactNode[];
  rightComponent?: React.ReactNode;
  className?: string;
}

const NavigationPanel: React.FunctionComponent<NavigationPanelProps> = (props) => {
  const { panels, children, className, immediatePanel, rightComponent, onChangePanel } = props;

  const [selectedIndex, setSelectedIndex] = useState(0);
  const ref = useRef<string>();

  useEffect(() => {
    if (immediatePanel && immediatePanel !== ref.current) {
      const panelIndex = panels.indexOf(immediatePanel);

      if (panelIndex !== -1) {
        onChangePanel && onChangePanel(panels[panelIndex]);
        setSelectedIndex(panelIndex);
      }
    }
    ref.current = immediatePanel;
  }, [immediatePanel, panels, onChangePanel]);

  const changePanel = useCallback(
    (panelIndex) => {
      setSelectedIndex(panelIndex);
      onChangePanel && onChangePanel(panels[panelIndex]);
    },
    [setSelectedIndex, onChangePanel, panels],
  );

  return (
    <>
      <div className="navigation">
        <div className="navigation_panel">
          {panels.map((panel, index) => (
            <div className="navigation_panel_item" key={index}>
              <div
                className={classnames("navigation_panel_item_name", { selected: selectedIndex === index })}
                onClick={() => changePanel(index)}
              >
                {panel}
              </div>
              <div className={classnames("navigation_panel_item_underline", { selected: selectedIndex === index })} />
            </div>
          ))}
        </div>
        {rightComponent}
      </div>
      <div className={className}>{children[selectedIndex]}</div>
    </>
  );
};

export default NavigationPanel;
