import React from "react";
import CreatableSelect from "react-select/creatable";
import { ValueType } from "react-select";
import classnames from "classnames";
import { isArray } from "lodash";

import { MultiSelectItem } from "../../../interfaces/MultiSelectItem";
import {
  ClearIndicator,
  IndicatorSeparator,
  MultiValueContainer,
  MultiValueLabel,
  MultiValueRemove,
  Option,
  Placeholder,
} from "./PartialComponents/partialComponents";

import "./multiSelect.scss";

interface MultiSelectProps {
  name: string;
  placeholder: React.ReactNode;

  label?: string;
  required?: boolean;
  className?: string;
  errorText?: string;
  isDisabled: boolean;

  values: MultiSelectItem[];
  options: MultiSelectItem[];

  onInputChange?(value: string): void;
  setFieldValue: (field: string, value: MultiSelectItem[]) => void;
}

// to debug menu's css use menuIsOpen={true} prop
export const MultiSelect: React.FunctionComponent<MultiSelectProps> = (props) => {
  const {
    values,
    options,
    setFieldValue,
    name,
    onInputChange,
    label,
    required,
    placeholder,
    className,
    isDisabled,
    errorText,
  } = props;

  const handleChange = (value: ValueType<MultiSelectItem>) => {
    if (!value) {
      return setFieldValue(name, []);
    }

    const valuesArray = !isArray(value) ? [value] : value;

    // bug in current react-select version https://github.com/JedWatson/react-select/issues/3988
    const wrongValue = valuesArray.find(({ label }: MultiSelectItem) => typeof label !== "string");
    if (wrongValue) {
      return handleAddNewOption(wrongValue.value);
    }

    const values = valuesArray.map(({ label, value, isSecondary }: MultiSelectItem) => ({
      label,
      value,
      isSecondary,
    }));

    setFieldValue(name, values);
  };

  const handleInputChange = (newValue: string) => {
    onInputChange && onInputChange(newValue);
  };

  const handleAddNewOption = (value: string) => {
    const newOption = {
      value,
      label: value,
    };

    handleChange([...values, newOption]);
  };

  return (
    <div className={classnames("multiSelect_wrapper", className, { disabled: isDisabled })}>
      {label && <p className="multiSelect_label">{`${label} ${required ? "*" : ""}`}</p>}
      <CreatableSelect
        isDisabled={isDisabled}
        isMulti
        className="multiSelect"
        classNamePrefix="multiSelect"
        name={name}
        placeholder={placeholder}
        options={options}
        value={values}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onCreateOption={handleAddNewOption}
        isValidNewOption={() => false}
        formatCreateLabel={(newOption) => (
          <div className="multiValueNoOption">
            <p className="multiValueNoOption-title">{`Can't find your ${name} in list?`}</p>
            <div className="multiValueNoOption-add">{`add this ${name}`}</div>
          </div>
        )}
        components={{
          MultiValueContainer,
          MultiValueLabel,
          MultiValueRemove,
          IndicatorSeparator,
          ClearIndicator,
          Option,
          Placeholder,
        }}
      />
      {errorText && <div className="error">{errorText}</div>}
    </div>
  );
};
