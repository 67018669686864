import { API, METHODS } from "../../../shared/constants";
import { AuthShape, SetPasswordInterface } from "../interfaces";

import { request } from "../../../shared/utils";
import { EpriLogin, EpriLoginStatus } from "../interfaces/EpriLogin";

export default {
  login: (payload: AuthShape) => request(METHODS.POST, API.AUTH.LOGIN)(payload),
  epriLogin: (payload: EpriLogin) => request(METHODS.GET, API.AUTH.EPRI_LOGIN)({ params: payload }),
  epriLoginStatus: (payload: EpriLoginStatus) => request(METHODS.GET, API.AUTH.EPRI_LOGIN_STATUS)({ params: payload }),
  refreshToken: (payload: { refreshToken: string }) => request(METHODS.POST, API.AUTH.REFRESH_TOKEN),
  forgotPassword: (payload: AuthShape) => request(METHODS.POST, API.AUTH.FORGOT_PASSWORD)(payload),
  submitPassword: (payload: SetPasswordInterface) => request(METHODS.POST, API.AUTH.SET_PASSWORD)(payload),
};
