export interface ConditionOption {
  id: number;
  name: string;
}
export interface ConditionOptionsMap {
  id: number;
  disciplineId?: number;
  materialId?: number;
  materialTypeId?: number;
  environmentId?: number;
  degradationId?: number;
  questionId?: number;
  redirectId?: number;
  discipline?: ConditionOption;
  material?: ConditionOption;
  materialType?: ConditionOption;
  environment?: ConditionOption;
  degradation?: ConditionOption;
  question?: ConditionOption;
  redirect?: ConditionOption;
}

export enum ComponentPropertyType {
  discipline = "discipline",
  material = "material",
  environment = "environment",
  degradation = "degradation",
  question = "question",
  materialType = "materialType",
}

export interface ComponentPropertyRequest {
  type: ComponentPropertyType;
  value: number;
}

export type QuestionsWithDegradation = {
  questions: ConditionOptionsWithConcern[];
  degradation: ConditionOptionsWithConcern;
  conditionOptionId: number;
};

export interface ConditionOptionsWithConcern {
  isAConcern?: boolean;
  conditionOptionId?: number;
  isMetal?: boolean;
  createdBy?: string;
  id: string | number;
  name: string;
}

export interface InstanceConditionOptions {
  id: number;
  instanceId: number;
  conditionOptionRelationId: number;
  isAConcern: boolean;
  isActual: boolean;
  isActive: boolean;
  createdAt: string;
  createdBy: string;
  updatedAt?: string;
  updatedBy?: string;
  conditionOption: ConditionOptionsMap;
}

export interface InstanceComponentProperties {
  id: number;
  type: ComponentPropertyType;
  value: number;
  name: string;
  instanceId: number;
  createdAt: string;
  updatedAt?: string;
  createdBy: string;
  updatedBy?: string;
  isActive: boolean;
}

export interface InstanceConditionOptionsRequest {
  conditionOptionId: number;
  isAConcern: boolean;
}

export interface InstanceConditionOptionsUpdateRequest {
  instanceId: number;
  conditionOptions: InstanceConditionOptionsRequest[];
}

export interface InstanceConditionOptionsDeleteRequest {
  instanceId: number;
  conditionOptions: { conditionOptionId: number }[];
}
