import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { differenceInDays } from "date-fns";

import { ImageApprove, ImageReviewHeader } from "../../components";
import { actions, selectors } from "../../store";
import { ImageCard } from "../../components/ImageCard";
import { RouteComponentProps } from "../../../../shared/interfaces";
import { Plant } from "../../../../shared/models";
import { useSortFilter } from "../../../../shared/hooks";
import { actions as sharedActions, selectors as sharedSelectors } from "../../../../shared/store";
import {
  actions as companiesPlantsActions,
  selectors as companiesPlantsSelectors,
} from "../../../CompaniesPlants/store";
import { actions as instanceActions } from "../../../Instances/store";
import { Modal, ModalContent, RemoveModalContent } from "../../../../shared/components";
import { AudioUploadValue, ImagesUploadValue } from "../../../../shared/formComponents/common";
import { Image } from "../../../../shared/models";
import MasonryLayout from "../../../../shared/components/MasonryLayout/MasonryLayout";
import { getFieldNotes as getAllFieldNotes } from "../../../FieldNotes/store/selectors";
import { getFieldNotes } from "../../../FieldNotes/store/actions";
import queryString from "query-string";
import { REVIEW_STATUS_TITLE, REVIEW_STATUS_ID, ORDER_TYPES } from "../../../../shared/constants";

import "./index.scss";

export enum EAction {
  APPROVE = "approve",
  REJECT = "reject",
}

interface PlantDetailsContainerProps extends RouteComponentProps<{ plantId: number }> {}

const ImageReviewContainer: React.FunctionComponent<PlantDetailsContainerProps> = (props) => {
  const [reviewStatusValue, setReviewStatusValue] = useState<{ label: string; value: string } | undefined>({
    label: REVIEW_STATUS_TITLE.PENDING,
    value: REVIEW_STATUS_ID.PENDING.toString(),
  });
  const dispatch = useDispatch();
  const {
    requestOptions,
    debouncedSearch,
    startDate,
    endDate,
    sortOrder,
    sortOrderLabel,
    utils: { setSearchString, toggleSortOrder, setDateRange, setSearchPlantId, setSearchReviewStatusId, incrementPage },
  } = useSortFilter({
    showMineInitial: false,
    page: 0,
    pageSize: 25,
    sortOrder: ORDER_TYPES.ASC,
    searchReviewStatusId: REVIEW_STATUS_ID.PENDING.toString() as string,
  });

  const images: Image[] = useSelector(selectors.getImages());
  const plants: Plant[] = useSelector(companiesPlantsSelectors.getPlants());
  const imageTypes = useSelector(sharedSelectors.getImageTypes());
  const reviewStatuses = useSelector(sharedSelectors.getReviewStatuses());
  const fieldNotes = useSelector(getAllFieldNotes());

  const [selectedImageIds, setSelectedImageIds] = useState<number[]>([]);
  const [imageToApprove, setImageToApprove] = useState<Image | undefined>(undefined);
  const [validImagePosition, setImagePositionStatus] = useState<boolean>(true);
  const [rejectConfirmation, setRejectConfirmation] = useState<boolean>(false);
  const [fileToSave, setFileToSave] = useState<ImagesUploadValue | undefined>(undefined);
  const [fileToEdit, setFileToEdit] = useState<Image | undefined>(undefined);
  const [voiceNote, setVoiceNote] = useState<AudioUploadValue | undefined>(undefined);
  const [isNewImage, setIsNewImage] = useState<boolean>(false);
  const [isNewVoiceNote, setIsNewVoiceNote] = useState<boolean>(false);
  const [plantId, setPlantId] = useState<number | null>(null);
  const [isUpdatingStatus, setUpdatingStatus] = useState<EAction.APPROVE | EAction.REJECT | undefined>();
  const count = useSelector(selectors.getImagesCount());

  useEffect(() => {
    let { status = null } = queryString.parse(props.location.search);
    if (status) {
      const selectedStatus = reviewStatuses?.find((s) => s.id === Number(status));
      if (selectedStatus) {
        setReviewStatusValue({ label: selectedStatus.title, value: selectedStatus.id.toString() });
      }
    }
  }, [reviewStatuses, props.location.search, setSearchReviewStatusId]);

  useEffect(() => {
    setSearchReviewStatusId(reviewStatusValue ? reviewStatusValue.value : undefined);
  }, [reviewStatusValue, setSearchReviewStatusId]);

  useEffect(() => {
    dispatch(actions.getImages.request({ ...requestOptions, showCommentImages: true }));
    setSelectedImageIds([]);
  }, [dispatch, requestOptions]);

  useEffect(() => {
    dispatch(companiesPlantsActions.getCompaniesPlants.request({}));
  }, [dispatch]);

  useEffect(() => {
    if (!imageTypes) {
      dispatch(sharedActions.getImageTypes.request());
    }
  }, [dispatch, imageTypes]);

  useEffect(() => {
    if (!reviewStatuses) {
      dispatch(sharedActions.getReviewStatuses.request());
    }
  }, [dispatch, reviewStatuses]);

  useEffect(() => {
    dispatch(getFieldNotes.request({}));
  }, [dispatch]);

  useEffect(() => {
    if (imageToApprove) {
      const fieldNote = fieldNotes.find(({ id }) => id === imageToApprove.fieldNoteId);
      fieldNote && setPlantId(fieldNote.plantId);
    } else {
      setPlantId(null);
    }
  }, [imageToApprove, fieldNotes]);

  const loadMore = useCallback(
    (element) => {
      const clientHeight = element.clientHeight;
      const scrollPosition = element.scrollHeight - element.scrollTop;

      if (scrollPosition === clientHeight && element.clientHeight !== element.scrollHeight) {
        if (
          count >= (requestOptions.page + 1) * requestOptions.pageSize ||
          count >= (requestOptions.page + 1) * requestOptions.pageSize - requestOptions.pageSize
        ) {
          incrementPage();
        }
      }
    },
    [incrementPage, count, requestOptions.page, requestOptions.pageSize],
  );

  useEffect(() => {
    const element = document.getElementById("dashboard-content");
    if (element) {
      const id = setInterval(() => loadMore(element), 1000);
      return () => clearInterval(id);
    }
  }, [loadMore]);

  const plantsForSelect = useMemo(() => {
    const filteredPlants: { label: string; value: string }[] = [];

    plants.forEach(({ displayName, id }) => {
      if (displayName && id) {
        filteredPlants.push({ label: displayName, value: String(id) });
      }
    });
    return filteredPlants;
  }, [plants]);

  const { approvedStatusId, rejectedStatusId } = useMemo(
    (): { approvedStatusId: number | undefined; rejectedStatusId: number | undefined } => ({
      approvedStatusId: reviewStatuses?.find((status) => status.title === "Approved")?.id,
      rejectedStatusId: reviewStatuses?.find((status) => status.title === "Rejected")?.id,
    }),
    [reviewStatuses],
  );

  const plantSelectHandler = (option: { value: string }) => {
    if (option && option.value) {
      setSearchPlantId(String(option.value));
    } else {
      setSearchPlantId(undefined);
    }
  };

  const imageStatusSelectHandler = (option: { value: string }) => {
    if (option && option.value) {
      setReviewStatusValue(option as { value: string; label: string });
    } else {
      setReviewStatusValue(undefined);
    }
  };

  const imageSelectHandler = useCallback(
    (image: Image, isSelected: boolean) => {
      if (isSelected) {
        setSelectedImageIds([...selectedImageIds, image.id]);
      } else {
        setSelectedImageIds([...selectedImageIds.filter((id) => id !== image.id)]);
      }
    },
    [selectedImageIds, setSelectedImageIds],
  );

  const unselectAllHandler = () => {
    setSelectedImageIds([]);
  };

  const selectAllHandler = () => {
    setSelectedImageIds([...images.map((image) => image.id)]);
  };

  const reviewSelectedHandler = (reviewStatusId: number) => {
    switch (reviewStatusId) {
      case rejectedStatusId: {
        setRejectConfirmation(true);
        break;
      }
      case approvedStatusId: {
        reviewImages(reviewStatusId, selectedImageIds);
        break;
      }
    }
  };

  const reviewImages = (reviewStatusId: number, imageIds: number[]) => {
    if (reviewStatusId === rejectedStatusId) {
      setUpdatingStatus(EAction.REJECT);
      setRejectConfirmation(false);
    }
    dispatch(
      actions.reviewImage.request({
        imageIds,
        reviewStatusId,
        cb: () => {
          setUpdatingStatus(undefined);
          setSelectedImageIds([]);
        },
      }),
    );
  };

  const reviewHandler = (image: Image, reviewStatusId: number | undefined, cb?: () => void) => {
    if (reviewStatusId) {
      dispatch(actions.reviewImage.request({ imageIds: [image.id], reviewStatusId, cb }));
    }
  };

  const approveHandler = (image: Image) => {
    setUpdatingStatus(EAction.APPROVE);
    reviewHandler(image, approvedStatusId, () => {
      nextHandler(image);
      setUpdatingStatus(undefined);
      setSelectedImageIds([]);
    });
  };

  const rejectHandler = (image: Image) => {
    setUpdatingStatus(EAction.REJECT);
    reviewHandler(image, rejectedStatusId, () => {
      nextHandler(image);
      setUpdatingStatus(undefined);
      setSelectedImageIds([]);
    });
    setRejectConfirmation(false);
  };

  const imageUpdatedHandler = () => {
    dispatch(actions.getImages.request({ ...requestOptions, showCommentImages: true }));
  };

  const cleareImagesRequest = () => {
    dispatch(actions.clearImages());
  };

  const saveHandler = (
    isNewImageFlag: boolean,
    isNewVoiceNoteFlag: boolean,
    imageBase64: string,
    voiceNoteData: AudioUploadValue,
    fileToEdit: Image,
  ) => {
    const { instanceId, imageTypeId, fieldNoteId, id, positionIndex } = fileToEdit;
    const imageTypeData = Number(imageTypes?.findIndex((imageType) => imageType.id === imageTypeId));
    if (fileToEdit?.instanceId) {
      if (fileToEdit.parentId) {
        // Once before we have already chosen Save Both, so now just save a child (annotated) file
        dispatch(
          instanceActions.updateImage.request({
            instanceId: Number(instanceId),
            fieldNoteId: Number(fieldNoteId),
            image: { id, srcAnnotated: imageBase64, imageType: imageTypeData, positionIndex },
            voiceNote: voiceNoteData,
            isNewImage: isNewImageFlag,
            isNewVoiceNote: isNewVoiceNoteFlag,
            callback: imageUpdatedHandler,
          }),
        );
        setFileToEdit(undefined);
        setFileToSave(undefined);
        setImageToApprove(undefined);
      } else {
        setVoiceNote(voiceNoteData);
        setIsNewImage(isNewImageFlag);
        setIsNewVoiceNote(isNewVoiceNoteFlag);
        setFileToSave({
          id: fileToEdit.id,
          src: imageBase64,
          imageType: imageTypeData,
          positionIndex: fileToEdit.positionIndex,
        });
      }
    }
  };

  const editHandler = (image: Image) => {
    setFileToEdit(image);
  };

  const handleSaveOnlyAnnotated = () => {
    // We are saving annotated file instead of original file. As a result we will have 1 file without parentId
    if (fileToSave && fileToEdit?.instanceId) {
      dispatch(
        instanceActions.updateImage.request({
          instanceId: Number(fileToEdit.instanceId),
          fieldNoteId: Number(fileToEdit?.fieldNoteId),
          image: {
            id: fileToSave.id,
            src: fileToSave.src,
            imageType: fileToSave.imageType,
            positionIndex: fileToSave.positionIndex,
          },
          voiceNote,
          isNewImage,
          isNewVoiceNote,
          callback: imageUpdatedHandler,
        }),
      );
    }

    setFileToSave(undefined);
    setIsNewImage(false);
    setIsNewVoiceNote(false);
    setFileToEdit(undefined);
    setImageToApprove(undefined);
  };

  const handleSaveBoth = () => {
    if (fileToSave && fileToEdit?.instanceId) {
      dispatch(
        instanceActions.updateImage.request({
          instanceId: Number(fileToEdit.instanceId),
          fieldNoteId: Number(fileToEdit?.fieldNoteId),
          image: {
            id: fileToSave.id,
            srcAnnotated: fileToSave.src,
            imageType: fileToSave.imageType,
            positionIndex: fileToSave.positionIndex,
          },
          voiceNote,
          isNewImage,
          isNewVoiceNote,
          callback: imageUpdatedHandler,
        }),
      );
      setFileToEdit(undefined);
    }

    setFileToSave(undefined);
    setIsNewImage(false);
    setIsNewVoiceNote(false);
    setImageToApprove(undefined);
  };

  const getImageTypeLabel = useCallback(
    (image: Image) => {
      const prefix = `Photo ${Number(image.imageTypeId)} - `;
      return `${prefix}${imageTypes?.find((imageType) => imageType.id === image.imageTypeId)?.title || ""}`;
    },
    [imageTypes],
  );

  const getReviewStatusLabel = useCallback(
    (image: Image) => {
      return reviewStatuses?.find((status) => status.id === image.reviewStatusId)?.title || "Pending";
    },
    [reviewStatuses],
  );

  const getCurrentImageIndex = (imageId: number) => images.findIndex((image) => image.id === imageId);

  const previousHandler = (currentImage: Image) => {
    const currentIndex = getCurrentImageIndex(currentImage.id);
    setImageToApprove(currentIndex === 0 ? undefined : images[currentIndex - 1]);
  };

  const nextHandler = (currentImage: Image) => {
    const currentIndex = getCurrentImageIndex(currentImage.id);
    setImageToApprove(currentIndex === images.length - 1 ? undefined : images[currentIndex + 1]);
  };

  const removeModalContent = useMemo(() => {
    const msg = "Are you sure you want to reject image";

    if (selectedImageIds && selectedImageIds.length) {
      return `${msg}s. The rejected images will be permanently deleted according to the company settings.`;
    }

    if (imageToApprove) {
      const defaultMsg = `${msg}. The rejected image will be permanently deleted according to the company settings.`;

      if (!imageToApprove.expiresAt) return defaultMsg;

      const diff = differenceInDays(new Date(imageToApprove.expiresAt || new Date()), new Date());
      if (!diff) return defaultMsg;

      return `${msg}. The rejected image will be permanently deleted in ${diff} day${diff > 1 ? "s" : ""}.`;
    }

    return `${msg}.`;
  }, [imageToApprove, selectedImageIds]);

  const validateImageAndSetToApprove = useCallback(
    (image: Image) => {
      setImageToApprove(image);
      const { positionIndex, id, instanceId } = image;
      dispatch(
        actions.validateImagePosition.request({
          imageId: id,
          positionIndex,
          instanceId,
          cb: setImagePositionStatus,
        } as {
          imageId: number | string;
          instanceId: number;
          positionIndex: number;
          cb: (value: boolean) => void;
        }),
      );
    },
    [dispatch, setImagePositionStatus],
  );

  const renderImages = useMemo(() => {
    if (images && images.length) {
      if (!reviewStatusValue) {
        return (
          <MasonryLayout className="image-review-body" layoutOptions={{ columnWidth: 322 }}>
            {images?.map((image: Image) => (
              <ImageCard
                key={image.id}
                image={image}
                selected={selectedImageIds.includes(image.id)}
                imageSelectHandler={imageSelectHandler}
                showCheckbox={selectedImageIds.length > 0}
                imageType={getImageTypeLabel(image)}
                reviewStatus={getReviewStatusLabel(image)}
                onClick={() => validateImageAndSetToApprove(image)}
              />
            ))}
          </MasonryLayout>
        );
      } else {
        return (
          <MasonryLayout className="image-review-body" layoutOptions={{ columnWidth: 322 }}>
            {images.flatMap((image) =>
              image.reviewStatusId === Number(reviewStatusValue.value) ||
              (image.reviewStatusId === null && reviewStatusValue.value === REVIEW_STATUS_ID.PENDING.toString())
                ? [
                    <ImageCard
                      key={image.id}
                      image={image}
                      selected={selectedImageIds.includes(image.id)}
                      imageSelectHandler={imageSelectHandler}
                      showCheckbox={selectedImageIds.length > 0}
                      imageType={getImageTypeLabel(image)}
                      reviewStatus={getReviewStatusLabel(image)}
                      onClick={() => validateImageAndSetToApprove(image)}
                    />,
                  ]
                : [],
            )}
          </MasonryLayout>
        );
      }
    } else {
      return (
        <div className="images-empty-state">
          <img src="/images/dashboard/no-images.svg" alt="No images to display" />
          <p>No images to display</p>
        </div>
      );
    }
  }, [
    images,
    reviewStatusValue,
    getImageTypeLabel,
    getReviewStatusLabel,
    imageSelectHandler,
    selectedImageIds,
    validateImageAndSetToApprove,
  ]);

  const imageReviewLength = useMemo(() => {
    if (images && images.length) {
      if (!reviewStatusValue) {
        return count;
      } else {
        const totalImages = images.flatMap((image) =>
          image.reviewStatusId === Number(reviewStatusValue.value) ||
          (image.reviewStatusId === null && reviewStatusValue.value === REVIEW_STATUS_ID.PENDING.toString())
            ? [image]
            : [],
        ).length;

        let loadedImagesCount = (requestOptions.page + 1) * requestOptions.pageSize;
        const diff = loadedImagesCount - totalImages;

        const result = count - diff;
        return result < 0 ? totalImages : result;
      }
    } else {
      return count;
    }
  }, [images, reviewStatusValue, count, requestOptions.page, requestOptions.pageSize]);

  return (
    <div className="image-review-container">
      <ImageReviewHeader
        reviewStatuses={reviewStatuses?.map((status) => ({ label: status.title, value: String(status.id) })) || []}
        plantsForSelect={plantsForSelect}
        imageReviewLength={imageReviewLength}
        imageStatusSelectHandler={imageStatusSelectHandler}
        plantSelectHandler={plantSelectHandler}
        setSearchString={setSearchString}
        debouncedSearch={debouncedSearch}
        startDate={startDate}
        endDate={endDate}
        setDateRange={setDateRange}
        sortOrder={sortOrder}
        sortOrderLabel={sortOrderLabel}
        toggleSortOrder={toggleSortOrder}
        cleareImagesRequest={cleareImagesRequest}
        reviewStatusValue={reviewStatusValue}
      />
      <div className="image-review-selector">
        {imageReviewLength ? (
          <div className="cards-selected">
            {selectedImageIds.length} {selectedImageIds.length === 1 ? "image" : "images"} selected
          </div>
        ) : null}
        {imageReviewLength ? (
          <div className="buttons">
            {selectedImageIds.length > 0 && (
              <div className="unselect" onClick={unselectAllHandler}>
                Unselect All
              </div>
            )}
            <div className="select" onClick={selectAllHandler}>
              Select All
            </div>
            {selectedImageIds.length > 0 ? <div className="separator"></div> : null}
            {selectedImageIds.length > 0 &&
            ((reviewStatusValue &&
              [REVIEW_STATUS_ID.APPROVED, REVIEW_STATUS_ID.PENDING].includes(Number(reviewStatusValue.value))) ||
              !reviewStatusValue) ? (
              <div className="reject" onClick={() => rejectedStatusId && reviewSelectedHandler(rejectedStatusId)}>
                Reject Selected
              </div>
            ) : null}
            {selectedImageIds.length > 0 &&
            ((reviewStatusValue &&
              [REVIEW_STATUS_ID.REJECTED, REVIEW_STATUS_ID.PENDING].includes(Number(reviewStatusValue.value))) ||
              !reviewStatusValue) ? (
              <div className="approve" onClick={() => approvedStatusId && reviewSelectedHandler(approvedStatusId)}>
                Approve Selected
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      {renderImages}
      {imageToApprove && plantId !== null && (
        <div className="modal-wrapper">
          <ImageApprove
            image={imageToApprove}
            plantId={plantId}
            imageType={getImageTypeLabel(imageToApprove)}
            reviewStatus={getReviewStatusLabel(imageToApprove)}
            show={Boolean(imageToApprove)}
            onClose={() => setImageToApprove(undefined)}
            onApprove={() => approveHandler(imageToApprove)}
            onReject={() => setRejectConfirmation(true)}
            onSave={saveHandler}
            onEdit={editHandler}
            onPrevious={() => previousHandler(imageToApprove)}
            onNext={() => nextHandler(imageToApprove)}
            fileToEdit={fileToEdit}
            showPrevious={getCurrentImageIndex(imageToApprove.id) > 0}
            showNext={getCurrentImageIndex(imageToApprove.id) < images.length - 1}
            validImagePosition={validImagePosition}
            isUpdatingStatus={isUpdatingStatus}
          />
        </div>
      )}
      {(imageToApprove || selectedImageIds) && rejectConfirmation && (
        <Modal isShowing={true} onClose={() => setRejectConfirmation(false)} boxPadding>
          <div>
            <RemoveModalContent
              heading="Reject Images"
              content={removeModalContent}
              cancelText="Cancel"
              removeText="Reject"
              onClose={() => setRejectConfirmation(false)}
              onDelete={() =>
                imageToApprove
                  ? rejectHandler(imageToApprove)
                  : rejectedStatusId && reviewImages(rejectedStatusId, selectedImageIds)
              }
            />
          </div>
        </Modal>
      )}
      <Modal isShowing={!!fileToSave} onClose={() => setFileToSave(undefined)} boxPaddingThick>
        <ModalContent
          heading="Save Photo"
          content="Would you like to save the original photo?"
          minorActionText="Annotated only"
          mainActionText="Save both"
          onMinorAction={handleSaveOnlyAnnotated}
          onMainAction={handleSaveBoth}
        />
      </Modal>
    </div>
  );
};

export default ImageReviewContainer;
