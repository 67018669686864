export enum ActionTypes {
  GET_IMAGES = "@@DASHBOARD/GET_IMAGES",
  GET_IMAGES_SUCCESS = "@@DASHBOARD/GET_IMAGES_SUCCESS",
  GET_IMAGES_FAILURE = "@@DASHBOARD/GET_IMAGES_FAILURE",

  CLEAR_IMAGES = "@@DASHBOARD/CLEAR_IMAGES",

  REVIEW_IMAGE = "@@DASHBOARD/REVIEW_IMAGE",
  REVIEW_IMAGE_SUCCESS = "@@DASHBOARD/REVIEW_IMAGE_SUCCESS",
  REVIEW_IMAGE_FAILURE = "@@DASHBOARD/REVIEW_IMAGE_FAILURE",

  VALIDATE_IMAGE_POSITION_INDEX = "@@DASHBOARD/VALIDATE_IMAGE_POSITION_INDEX",
  VALIDATE_IMAGE_POSITION_INDEX_SUCCESS = "@@DASHBOARD/VALIDATE_IMAGE_POSITION_INDEX_SUCCESS",
  VALIDATE_IMAGE_POSITION_INDEX_FAILURE = "@@DASHBOARD/VALIDATE_IMAGE_POSITION_INDEX_FAILURE",
}
