import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import api from "./api";
import { actions } from "./";
import { Company, CompanyRequest, Plant } from "../../../shared/models";

function* getCompaniesPlantsList({ payload }: AnyAction) {
  try {
    const companiesAndPlants = yield call(api.getCompaniesPlantsList, payload);

    yield put(actions.getCompaniesPlants.success(companiesAndPlants));
  } catch (error) {
    yield put(actions.getCompaniesPlants.failure(error));
  }
}

function* getCompanies({ payload }: AnyAction) {
  try {
    const companies: Company[] = yield call(api.getCompanies, payload);

    yield put(actions.getCompanies.success(companies));
  } catch (error) {
    yield put(actions.getCompanies.failure(error));
  }
}

function* getCompany({ payload }: AnyAction) {
  try {
    const company = yield call(api.getCompany, payload);

    yield put(actions.getCompany.success(company));
  } catch (error) {
    yield put(actions.getCompany.failure(error));
  }
}

function* updateCompany({ payload }: { payload: CompanyRequest }) {
  try {
    const company = yield call(api.updateCompany, payload);

    yield put(
      actions.updateCompany.success({ ...company, message: "The Company information was successfully updated" }),
    );
  } catch (error) {
    yield put(actions.updateCompany.failure(error));
  }
}

function* restoreCompany({ payload }: AnyAction) {
  try {
    const company = yield call(api.restoreCompany, payload);

    yield put(
      actions.restoreCompany.success({
        ...company,
        message: "The Company and all of its Plants were successfully restored",
      }),
    );
  } catch (error) {
    yield put(actions.restoreCompany.failure(error));
  }
}

function* createCompany({ payload }: AnyAction) {
  try {
    const company = yield call(api.createCompany, payload);

    yield put(actions.createCompany.success({ ...company, message: "The Company was successfully created" }));
  } catch (error) {
    yield put(actions.createCompany.failure(error));
  }
}

function* deleteCompany({ payload }: AnyAction) {
  try {
    const company = yield call(api.deleteCompany, payload);

    yield put(actions.deleteCompany.success({ ...company, message: "The Company was successfully deleted" }));
  } catch (error) {
    yield put(actions.deleteCompany.failure(error));
  }
}

function* getPlant({ payload: plantId }: { payload: number }) {
  try {
    const plant: Plant = yield call(api.getPlant, plantId);

    yield put(actions.getPlant.success(plant));
  } catch (error) {
    yield put(actions.getPlant.failure(error));
  } finally {
  }
}

function* updatePlant({ payload }: AnyAction) {
  try {
    const plant = yield call(api.updatePlant, payload);

    yield put(actions.updatePlant.success({ ...plant, message: "The Plant was successfully updated" }));
  } catch (error) {
    yield put(actions.updatePlant.failure(error));
  }
}

function* createPlant({ payload }: AnyAction) {
  try {
    const plant = yield call(api.createPlant, payload);

    yield put(actions.createPlant.success({ ...plant, message: "The Plant was successfully created" }));
  } catch (error) {
    yield put(actions.createPlant.failure(error));
  }
}

function* deletePlant({ payload }: AnyAction) {
  try {
    const plant = yield call(api.deletePlant, payload);

    yield put(actions.deletePlant.success({ ...plant, message: "The Plant was successfully deleted" }));
  } catch (error) {
    yield put(actions.deletePlant.failure(error));
  }
}

function* restorePlant({ payload }: AnyAction) {
  try {
    const plant = yield call(api.restorePlant, payload);

    yield put(actions.restorePlant.success({ ...plant, message: "The Plant was successfully restored" }));
  } catch (error) {
    yield put(actions.restorePlant.failure(error));
  }
}

function* getPlantDocuments({ payload }: AnyAction) {
  try {
    const { plantId, filter } = payload;

    const documents = yield call(api.getPlantDocumentList, plantId, filter);

    yield put(actions.getPlantDocuments.success(documents));
  } catch (error) {
    yield put(actions.getPlantDocuments.failure(error));
  }
}

function* deletePlantDocument({ payload }: AnyAction) {
  try {
    const { plantId, documentId } = payload;

    const result = yield call(api.deletePlantDocument, plantId, documentId);

    yield put(actions.deletePlantDocument.success(result));
  } catch (error) {
    yield put(actions.deletePlantDocument.failure(error));
  }
}

function* createPlantDocument({ payload }: AnyAction) {
  try {
    const { plantId, data } = payload;

    const result = yield call(api.createPlantDocument, data, plantId);

    yield put(actions.createPlantDocument.success(result));
  } catch (error) {
    yield put(actions.createPlantDocument.failure(error));
  }
}

function* updatePlantDocument({ payload }: AnyAction) {
  try {
    const { plantId, documentId, data } = payload;

    const result = yield call(api.updatePlantDocument, data, plantId, documentId);

    yield put(actions.updatePlantDocument.success(result));
  } catch (error) {
    yield put(actions.updatePlantDocument.failure(error));
  }
}

function* companiesPlantsSaga() {
  yield takeLatest(actions.getCompaniesPlants.request, getCompaniesPlantsList);

  yield takeLatest(actions.getCompanies.request, getCompanies);
  yield takeLatest(actions.getCompany.request, getCompany);
  yield takeLatest(actions.createCompany.request, createCompany);
  yield takeLatest(actions.deleteCompany.request, deleteCompany);
  yield takeLatest(actions.updateCompany.request, updateCompany);
  yield takeLatest(actions.restoreCompany.request, restoreCompany);

  yield takeLatest(actions.getPlant.request, getPlant);
  yield takeLatest(actions.createPlant.request, createPlant);
  yield takeLatest(actions.deletePlant.request, deletePlant);
  yield takeLatest(actions.updatePlant.request, updatePlant);
  yield takeLatest(actions.restorePlant.request, restorePlant);

  yield takeLatest(actions.getPlantDocuments.request, getPlantDocuments);
  yield takeLatest(actions.deletePlantDocument.request, deletePlantDocument);
  yield takeLatest(actions.createPlantDocument.request, createPlantDocument);
  yield takeLatest(actions.updatePlantDocument.request, updatePlantDocument);
}

export default companiesPlantsSaga;
