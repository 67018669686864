import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";

import tokenHandler from "./tokenHandler";
import history from "./history";
import { ROUTE_PATHS } from "../../shared/routes";
import config from "../../config";

axios.defaults.headers["Accept"] = "application/json";
axios.defaults.headers["Content-Type"] = "application/json";

const axiosInstance: AxiosInstance = axios.create();

axiosInstance.interceptors.request.use(function (config) {
  const token = tokenHandler.getAccessToken();
  if (token) {
    config.headers.Authorization = token;
  }
  if (config.url?.includes("-pdf") || config.url?.includes("/file")) {
    config.responseType = "blob";
    config.headers["X-Timezone-Offset"] = new Date().getTimezoneOffset();
  }

  return config;
});

/* eslint-disable  @typescript-eslint/no-explicit-any */
const makeRequest = (instance: AxiosInstance & { [key: string]: any }) => (
  method: string,
  url: string,
  params: any,
) => {
  return instance[method](url, ...params);
};

axiosInstance.interceptors.response.use(
  (res: AxiosResponse) => {
    const response = res.data || res;

    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  },
  (error: AxiosError) => {
    const { response } = error || {};
    const { data } = response || {};
    const originalRequest = error?.config || {};

    if (originalRequest?.url?.endsWith(ROUTE_PATHS.AUTH_REFRESH_TOKEN)) {
      history.push(ROUTE_PATHS.AUTH_LOGIN);
      return Promise.reject(error);
    }

    if (response?.status === 401) {
      if (!originalRequest?.url?.endsWith(ROUTE_PATHS.AUTH_LOGIN)) {
        if (originalRequest.headers["X-Retrying"] !== "true") {
          originalRequest.headers["X-Retrying"] = "true";
          return axios
            .post(`${config.baseApiUrl}auth/refresh-token`, {
              refreshToken: tokenHandler.getRefreshToken(),
            })
            .then((res) => {
              if (res.status === 200) {
                const newToken = res.data.accessToken;
                if (newToken) {
                  tokenHandler.setAccessToken(newToken);
                }
                const retryRequest = {
                  ...originalRequest,
                  headers: { ...originalRequest.headers, Authorization: newToken },
                };
                return axios(retryRequest).then((res) => {
                  const response = res.data || res;

                  if (response.error) {
                    return Promise.reject(response.error);
                  }

                  return response;
                });
              }
            })
            .catch((err) => {
              tokenHandler.removeAll();
            });
        }
      }
    }

    if (data) {
      return Promise.reject(data);
    }
    return Promise.reject(error);
  },
);

/**
 * Axios wrapper
 *
 * @param  {string} method Method of the request
 * @param  {string} url url of the request
 *
 * @return {object} wrapped axios function that receives params
 */
export default (method: string, url: string) => (...params: any[]) => makeRequest(axiosInstance)(method, url, params);
