import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actions, selectors } from "../../store";
import { actions as actionsShared, selectors as selectorsShared } from "../../../../shared/store";
import {
  actions as actionsCompaniesPlants,
  selectors as selectorsCompaniesPlants,
} from "../../../CompaniesPlants/store";
import { Company, Profile, Role } from "../../../../shared/models";
import { useLoader } from "../../../../shared/hooks/LoaderHook";
import { UsersTable } from "../../components/UsersTable";
import UsersHeader from "../../components/UsersHeader";

import "./index.scss";
import { LOADERS_NAMES, PERMISSION, ORDER_TYPES } from "../../../../shared/constants";
import { EditUserSideout } from "../../components";
import { useModal, useSortFilter } from "../../../../shared/hooks";
import { Modal, RemoveModalContent } from "../../../../shared/components";
import { EditUserShape } from "../../interfaces";
import { checkPermissions } from "../../../../shared/utils/ACL";

const UsersContainer = () => {
  const [currentUser, setCurrentUser] = useState<Profile | null>(null);
  const [modalType, setModalType] = useState("");

  const dispatch = useDispatch();
  const { isShowing, onOpen, onClose } = useModal(false);

  const { isLoadingDebounced, AppLoader } = useLoader({
    name: LOADERS_NAMES.USERS_LOADER,
    actionTypes: [actions.getUsers],
  });

  const {
    sortOrder,
    searchCompanyId,
    searchPlantId,
    searchString,
    requestOptions,
    utils: { setSearchString, toggleSortOrder, setSearchPlantId, setSearchCompanyId },
  } = useSortFilter({ sortOrder: ORDER_TYPES.ASC });

  const users: Profile[] = useSelector(selectors.getUsers());
  const companies: Company[] = useSelector(selectorsCompaniesPlants.getCompaniesPlants());
  const roles: Role[] = useSelector(selectorsShared.getRoles());

  useEffect(() => {
    dispatch(actions.getUsers.request({ showEpriDetails: true, ...requestOptions }));
  }, [requestOptions, dispatch]);

  useEffect(() => {
    dispatch(actionsShared.getRoles.request());
    dispatch(actionsCompaniesPlants.getCompaniesPlants.request({ showActive: true }));
  }, [dispatch]);

  const editUser = useCallback(
    (user: Profile) => {
      if (checkPermissions([PERMISSION.USER_EDIT])) {
        setCurrentUser(user);
      }
    },
    [setCurrentUser],
  );

  const plantSelectHandler = (option: { value: string }) => {
    if (option && option.value) {
      setSearchPlantId(option.value.toString());
    } else {
      setSearchPlantId(undefined);
    }
  };

  const companySelectHandler = (option: { value: string }, clearPlant?: boolean) => {
    if (option && option.value) {
      setSearchCompanyId(option.value.toString());
    } else {
      setSearchCompanyId(undefined);
    }
    if (clearPlant) {
      setSearchPlantId(undefined);
    }
  };

  const closeEditUserModalHandler = useCallback(() => {
    setCurrentUser(null);
    setModalType("");
    onClose();
  }, [setCurrentUser, onClose]);

  const sumbitUser = useCallback(
    (id: number, payload: EditUserShape) => {
      closeEditUserModalHandler();
      dispatch(
        actions.updateUserRoles.request({
          id: String(id),
          profileRoles: payload.profileRoles.map((pr) => ({
            companyId: pr.companyId,
            roleId: Number(pr.roleId),
            plants: pr.plants.map((p) => ({ plantId: p.plantId, roleId: Number(p.roleId) || null })),
          })),
        }),
      );
    },
    [dispatch, closeEditUserModalHandler],
  );

  const showHideUnsavedUserChangeModal = useCallback(
    (dirty: boolean) => {
      if (dirty) {
        setModalType("Leave");
        onOpen();
      } else {
        closeEditUserModalHandler();
      }
    },
    [closeEditUserModalHandler, setModalType, onOpen],
  );

  const renderModal = useCallback(() => {
    switch (modalType) {
      default: {
        return (
          <RemoveModalContent
            heading="Save Changes"
            content="Do you want to save the changes made to the user"
            cancelText="Leave"
            removeText="Save"
            isSubmitType={true}
            onClose={closeEditUserModalHandler}
            onDelete={() => {
              setModalType("");
              onClose();
            }}
          />
        );
      }
    }
  }, [modalType, closeEditUserModalHandler, onClose]);

  return (
    <div className="users-list-container">
      <Modal isShowing={isShowing} onClose={onClose} boxPadding>
        {renderModal()}
      </Modal>
      <UsersHeader
        profiles={users}
        companies={companies}
        setSearchString={setSearchString}
        toggleSortOrder={toggleSortOrder}
        sortOrder={sortOrder}
        searchCompanyId={searchCompanyId}
        searchPlantId={searchPlantId}
        searchString={searchString}
        setSearchPlantId={plantSelectHandler}
        setSearchCompanyId={companySelectHandler}
      />
      <div className="users-list-body">
        {isLoadingDebounced && <AppLoader />}
        <UsersTable profiles={users} companies={companies} onEditItem={editUser} />
      </div>
      {currentUser && (
        <EditUserSideout
          roles={roles}
          user={currentUser}
          companies={companies}
          showHideUnsavedChangeModal={showHideUnsavedUserChangeModal}
          submitUser={sumbitUser}
        />
      )}
    </div>
  );
};

export default UsersContainer;
