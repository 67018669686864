import { createAction, createAsyncAction } from "typesafe-actions";
import { RequestWithCallback, SortFilterOptions } from "../../../shared/interfaces";

import { Plant, FieldNote } from "../../../shared/models";
import { ActionTypes } from "./constants";

export const getFieldNotes = createAsyncAction(
  ActionTypes.GET_FIELD_NOTES,
  ActionTypes.GET_FIELD_NOTES_SUCCESS,
  ActionTypes.GET_FIELD_NOTES_FAILURE,
)<SortFilterOptions, FieldNote[], Error>();

export const getFieldNote = createAsyncAction(
  ActionTypes.GET_FIELD_NOTE,
  ActionTypes.GET_FIELD_NOTE_SUCCESS,
  ActionTypes.GET_FIELD_NOTE_FAILURE,
)<number, FieldNote, Error>();

export const getFieldNotePDF = createAsyncAction(
  ActionTypes.GET_FIELD_NOTE_PDF,
  ActionTypes.GET_FIELD_NOTE_PDF_SUCCESS,
  ActionTypes.GET_FIELD_NOTE_PDF_FAILURE,
)<number, Blob, Error>();

export const updateFieldNote = createAsyncAction(
  ActionTypes.UPDATE_FIELD_NOTE,
  ActionTypes.UPDATE_FIELD_NOTE_SUCCESS,
  ActionTypes.UPDATE_FIELD_NOTE_FAILURE,
)<Partial<Plant>, { company: Plant; message: string }, Error>();

export const createFieldNote = createAsyncAction(
  ActionTypes.CREATE_FIELD_NOTE,
  ActionTypes.CREATE_FIELD_NOTE_SUCCESS,
  ActionTypes.CREATE_FIELD_NOTE_FAILURE,
)<Partial<Plant>, { company: Plant; message: string }, Error>();

export const deleteFieldNote = createAsyncAction(
  ActionTypes.DELETE_FIELD_NOTE,
  ActionTypes.DELETE_FIELD_NOTE_SUCCESS,
  ActionTypes.DELETE_FIELD_NOTE_FAILURE,
)<RequestWithCallback<{ fieldNoteId: number }>, { company: FieldNote; message: string }, Error>();

export const cleanFieldNotePdf = createAction(ActionTypes.CLEAN_FIELD_NOTE_PDF)<void>();
