import React, { useEffect } from "react";

import constants from "../../../../shared/constants/api";
import "./index.scss";

const EPRILoginContainer = () => {
  useEffect(() => {
    const uid = `wap_${Math.floor(1000000000 * Math.random())}`;
    window.location.href = constants.AUTH.EPRI_LOGIN + `?uid=${uid}_${window.location.origin}`;
  }, []);

  return (
    <div className="epri-start">
      <div className="epri-logo" />
      <div className="epri-copyright">
        <p>{`Copyright © ${new Date().getFullYear()}`}</p>
        <p>Electric Power Research Institute, Inc All rights reserved.</p>
      </div>
    </div>
  );
};

export default EPRILoginContainer;
