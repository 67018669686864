import React, { useEffect, useState } from "react";
import { checkPermissions } from "../../utils/ACL";
import { Plant, FieldNote, Instance, InstanceComment, ConditionOptionsWithConcern } from "../../models";
import { FieldGuideReference } from "../../models/FieldGuideReference";

interface AccessControlProps {
  permissions: string[];
  children: React.ReactNode;
  plant?: Plant | null;
  fieldNote?: FieldNote | null;
  instance?: Instance | null;
  comment?: InstanceComment | null;
  fieldGuideReference?: FieldGuideReference | null;
  conditionOption?: ConditionOptionsWithConcern;
}

const AccessControl = (props: AccessControlProps) => {
  const { permissions, plant, fieldNote, instance, comment, fieldGuideReference, conditionOption, children } = props;
  const [permitted, setPermitted] = useState(false);

  useEffect(() => {
    setPermitted(
      checkPermissions(permissions, { plant, fieldNote, instance, comment, fieldGuideReference, conditionOption }),
    );
  }, [permissions, plant, fieldNote, instance, comment, fieldGuideReference, conditionOption]);

  return permitted ? <>{children}</> : null;
};

export default AccessControl;
