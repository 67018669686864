import React, { useMemo, useRef } from "react";
import Masonry, { MasonryOptions } from "react-masonry-component";

import useMasonryLayout from "../../hooks/UseMasonryLayout";
import { MasonryInstance } from "../../interfaces/Image";

import classes from "./masonryLayout.module.scss";

export const defaultOptions: MasonryOptions = {
  transitionDuration: 0,
  fitWidth: true,
  columnWidth: 230,
  gutter: 16,
  horizontalOrder: true,
};

interface Props {
  className?: string;
  layoutOptions?: MasonryOptions;
}

const MasonryLayout: React.FC<Props> = ({ className, layoutOptions = {}, children }) => {
  const masonryRef = useRef<MasonryInstance | null>(null);

  const containerRef = useRef<HTMLDivElement | null>(null);

  useMasonryLayout(containerRef.current, masonryRef.current);

  const options = useMemo(() => {
    return {
      ...defaultOptions,
      ...layoutOptions,
    };
  }, [layoutOptions]);

  return (
    <div className={classes.masonry} ref={containerRef}>
      <Masonry
        className={className}
        options={options}
        ref={(i) => (masonryRef.current = (i as unknown) as MasonryInstance)}
      >
        {children}
      </Masonry>
    </div>
  );
};

export default MasonryLayout;
