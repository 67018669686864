import { action, createAction, createAsyncAction } from "typesafe-actions";
import { Error, FieldGuideSortFilterOptions, IBlobHash, LoadingType, Notification } from "../interfaces";
import { NotificationHistory, Role, User, UserGuideDocument } from "../models";
import { FieldGuideDocument } from "../models/FieldGuideDocument";
import { ImageType } from "../models/ImageTypes";
import { ReviewStatus } from "../models/ReviewStatus";
import { SharedActionTypes } from "./constants";

export const showNotification = createAction(SharedActionTypes.SHOW_NOTIFICATION)<Notification>();
export const clearNotification = createAction(SharedActionTypes.CLEAR_NOTIFICATION)();

export const getUserData = createAsyncAction(
  SharedActionTypes.GET_USER,
  SharedActionTypes.GET_USER_SUCCESS,
  SharedActionTypes.GET_USER_FAILURE,
)<string, User, Error>();

export const addLoadingType = (loadingType: LoadingType) => action(SharedActionTypes.ADD_LOADING_TYPE, loadingType);
export const removeLoadingType = (loadingTypeName: string) =>
  action(SharedActionTypes.REMOVE_LOADING_TYPE, loadingTypeName);

export const addLoadingSection = (sectionName: string) => action(SharedActionTypes.ADD_LOADING_SECTION, sectionName);
export const removeLoadingSection = (sectionName: string) =>
  action(SharedActionTypes.REMOVE_LOADING_SECTION, sectionName);

export const getImageTypes = createAsyncAction(
  SharedActionTypes.GET_IMAGE_TYPES,
  SharedActionTypes.GET_IMAGE_TYPES_SUCCESS,
  SharedActionTypes.GET_IMAGE_TYPES_FAILURE,
)<undefined, ImageType[], Error>();

export const getReviewStatuses = createAsyncAction(
  SharedActionTypes.GET_REVIEW_STATUSES,
  SharedActionTypes.GET_REVIEW_STATUSES_SUCCESS,
  SharedActionTypes.GET_REVIEW_STATUSES_FAILURE,
)<undefined, ReviewStatus[], Error>();

export const getFile = createAsyncAction(
  SharedActionTypes.GET_FILE,
  SharedActionTypes.GET_FILE_SUCCESS,
  SharedActionTypes.GET_USER_FAILURE,
)<string, IBlobHash, Error>();

export const getFieldGuides = createAsyncAction(
  SharedActionTypes.GET_FIELD_GUIDES,
  SharedActionTypes.GET_FIELD_GUIDES_SUCCESS,
  SharedActionTypes.GET_FIELD_GUIDES_FAILURE,
)<undefined, FieldGuideDocument[], Error>();

export const getProfileFieldGuides = createAsyncAction(
  SharedActionTypes.GET_PROFILE_FIELD_GUIDES,
  SharedActionTypes.GET_PROFILE_FIELD_GUIDES_SUCCESS,
  SharedActionTypes.GET_PROFILE_FIELD_GUIDES_FAILURE,
)<FieldGuideSortFilterOptions, FieldGuideDocument[], Error>();

export const setFavoriteFieldGuide = createAsyncAction(
  SharedActionTypes.SET_FAVORITE_FIELD_GUIDE,
  SharedActionTypes.SET_FAVORITE_FIELD_GUIDE_SUCCESS,
  SharedActionTypes.SET_FAVORITE_FIELD_GUIDE_FAILURE,
)<
  { companyId: number; fieldGuideDocumentId: number; isFavorite: boolean },
  { companyId: number; fieldGuideDocumentId: number; isFavorite: boolean },
  Error
>();

export const setFieldGuide = createAction(SharedActionTypes.SET_FIELD_GUIDE)<FieldGuideDocument | undefined>();

export const getNotificationHistoryList = createAsyncAction(
  SharedActionTypes.GET_NOTIFICATION_HISTORY_LIST,
  SharedActionTypes.GET_NOTIFICATION_HISTORY_LIST_SUCCESS,
  SharedActionTypes.GET_NOTIFICATION_HISTORY_LIST_FAILURE,
)<undefined, NotificationHistory[], Error>();

export const markAllNotificationsHistoryAsRead = createAsyncAction(
  SharedActionTypes.MARK_ALL_NOTIFICATIONS_HISTORY_AS_READ,
  SharedActionTypes.MARK_ALL_NOTIFICATIONS_HISTORY_AS_READ_SUCCESS,
  SharedActionTypes.MARK_ALL_NOTIFICATIONS_HISTORY_AS_READ_FAILURE,
)<undefined, NotificationHistory[], Error>();

export const markOneNotificationHistoryAsRead = createAsyncAction(
  SharedActionTypes.MARK_ONE_NOTIFICATION_HISTORY_AS_READ,
  SharedActionTypes.MARK_ONE_NOTIFICATION_HISTORY_AS_READ_SUCCESS,
  SharedActionTypes.MARK_ONE_NOTIFICATION_HISTORY_AS_READ_FAILURE,
)<number, NotificationHistory, Error>();

export const getRoles = createAsyncAction(
  SharedActionTypes.GET_ROLES,
  SharedActionTypes.GET_ROLES_SUCCESS,
  SharedActionTypes.GET_ROLES_FAILURE,
)<undefined, Role[], Error>();

export const getUserGuide = createAsyncAction(
  SharedActionTypes.GET_USER_GUIDE,
  SharedActionTypes.GET_USER_GUIDE_SUCCESS,
  SharedActionTypes.GET_USER_GUIDE_FAILURE,
)<undefined, UserGuideDocument, Error>();

export const addNewNotificationHistory = createAction(SharedActionTypes.ADD_NEW_NOTIFICATION_HISTORY)<
  NotificationHistory
>();

export const cleanFile = createAction(SharedActionTypes.CLEAN_FILE)<string>();

export const setUpdateLayout = createAction(SharedActionTypes.SET_UPDATE_LAYOUT)<boolean>();
