import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import classnames from "classnames";
import { useDispatch } from "react-redux";

import { InstanceComment, Profile, Plant } from "../../../../../../shared/models";
import { formatDateWithTime } from "../../../../../../shared/utils";
import { ActionMenu, Modal, RemoveModalContent, AccessControl, AppLoader } from "../../../../../../shared/components";

import { validationSchema, prepareFormValues, CommentFormFields, generateFieldsList } from ".";

import { FormGenerator } from "../../../../../../shared/formComponents";
import { PERMISSION } from "../../../../../../shared/constants";
import { checkPermissions } from "../../../../../../shared/utils/ACL";
import { BinaryImage } from "../../../../../../shared/components/BinaryImage";
import { BinaryAudio } from "../../../../../../shared/components/BinaryAudio";
import MasonryLayout from "../../../../../../shared/components/MasonryLayout/MasonryLayout";
import { setUpdateLayout } from "../../../../../../shared/store/actions";

import "./instanceComments.scss";

interface InstanceCommentsProps {
  comments?: InstanceComment[];
  plant: Plant | null;
  isAddComment: boolean;
  isLoading: boolean;
  handleAddComment: (values: CommentFormFields) => void;
  handleCancelCommenting: () => void;
  handleStartCommenting: () => void;
  handleDeleteComment: (commentId: number) => void;
}
const InstanceComments: React.FunctionComponent<InstanceCommentsProps> = (props) => {
  const {
    comments,
    plant,
    isAddComment,
    isLoading,
    handleAddComment,
    handleCancelCommenting,
    handleDeleteComment,
    handleStartCommenting,
  } = props;

  const [initialValues, setInitialValues] = useState(prepareFormValues());
  const [commentIdToDelete, setCommentIdToDelete] = useState<number | undefined>(undefined);
  const [commentIdToEdit, setCommentIdToEdit] = useState<number | undefined>(undefined);
  const [isLeavePopup, setIsLeavePopup] = useState(false);

  const dispatch = useDispatch();

  const generatedFieldsList = useMemo(() => {
    const comment = comments?.find(({ id }) => id === commentIdToEdit);
    return generateFieldsList(!!comment?.id);
  }, [comments, commentIdToEdit]);

  useEffect(() => {
    if (commentIdToEdit) {
      const comment = comments?.find(({ id }) => id === commentIdToEdit);
      setInitialValues(prepareFormValues(comment));
    } else {
      setInitialValues(prepareFormValues());
    }
  }, [commentIdToEdit, comments]);

  const commentsLengthLabel = useMemo(() => {
    if (comments?.length) {
      if (comments.length === 1) {
        return "1 comment";
      }
      return `${comments.length} comments`;
    }
    return "";
  }, [comments]);

  const getProfileName = (profile: Profile) => {
    return `${profile.firstName} ${profile.lastName}`;
  };

  const getDate = (createdAt: string) => {
    return formatDateWithTime(new Date(createdAt));
  };

  const handleSave = (values: CommentFormFields) => {
    handleAddComment(values);
    setCommentIdToEdit(undefined);
  };

  const handleDeleteCommentClick = () => {
    if (commentIdToDelete) {
      handleDeleteComment(commentIdToDelete);
    }
    setCommentIdToDelete(undefined);
  };

  const handleEditCommentClick = (commentId: number) => {
    handleStartCommenting();
    setCommentIdToEdit(commentId);
  };

  const handleLeaveCommentingClick = () => {
    handleCancelCommenting();
    setIsLeavePopup(false);
    setCommentIdToEdit(undefined);
  };

  const handleCancelCommentingClick = (values: CommentFormFields) => {
    if (!!values.comment || values.audios.length || values.audios.length) {
      setIsLeavePopup(true);
    } else {
      handleLeaveCommentingClick();
    }
  };

  const checkIsEmptyForm = (values: CommentFormFields) => {
    return !values.comment?.length && !values.images.length && !values.audios.length;
  };

  const handleLoadImage = useCallback(() => {
    dispatch(setUpdateLayout(true));
  }, [dispatch]);

  return (
    <div className={classnames("instanceComments", { empty: !commentsLengthLabel })}>
      {isAddComment && (
        <Formik
          validationSchema={validationSchema}
          onSubmit={handleSave}
          initialValues={initialValues}
          enableReinitialize
        >
          {(formikProps) => (
            <div className="instanceComments_panel">
              <FormGenerator
                className="instanceComments_panel_form"
                formikProps={formikProps}
                fields={generatedFieldsList.comment}
              />
              <FormGenerator
                className="instanceComments_panel_form"
                formikProps={formikProps}
                fields={generatedFieldsList.image}
              />
              <FormGenerator
                className="instanceComments_panel_form"
                formikProps={formikProps}
                fields={generatedFieldsList.audio}
              />
              <div className="instanceComments_panel_form_buttons">
                <div className="instanceComments_panel_form_buttons_left"></div>

                <div className="instanceComments_panel_form_buttons_right">
                  <button
                    className="instanceComments_panel_form_button cancel"
                    onClick={() => handleCancelCommentingClick(formikProps.values)}
                  >
                    Cancel
                  </button>
                  <button
                    className="instanceComments_panel_form_button save"
                    type="submit"
                    onClick={formikProps.submitForm}
                    disabled={checkIsEmptyForm(formikProps.values) || !formikProps.dirty}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </Formik>
      )}

      <div className="instanceComments_label">{commentsLengthLabel}</div>
      {isLoading ? (
        <AppLoader />
      ) : (
        comments?.map((comment) => (
          <div className="instanceComments_comment" key={comment.id}>
            <div className="instanceComments_comment_header">
              <div className="instanceComments_comment_header_left">
                <img src="/icons/general/member.svg" alt="profile" />
                <div className="instanceComments_comment_header_left_name">{getProfileName(comment.profile)}</div>
              </div>
              <div className="instanceComments_comment_header_right">
                <img src="/icons/general/date.svg" alt="date" />
                <div className="instanceComments_comment_header_right_date">
                  {getDate(comment.updatedAt || comment.createdAt)}
                </div>
                <AccessControl
                  permissions={[PERMISSION.COMMENT_EDIT, PERMISSION.COMMENT_DELETE]}
                  plant={plant}
                  comment={comment}
                >
                  <ActionMenu
                    actions={[
                      ...(checkPermissions([PERMISSION.COMMENT_EDIT], { plant, comment })
                        ? [{ label: "edit", handler: () => handleEditCommentClick(comment.id) }]
                        : []),
                      ...(checkPermissions([PERMISSION.COMMENT_DELETE], { plant, comment })
                        ? [{ label: "delete", handler: () => setCommentIdToDelete(comment.id), isAlert: true }]
                        : []),
                    ]}
                  />
                </AccessControl>
              </div>
            </div>
            <div className="instanceComments_comment_text">{comment.comment}</div>
            <MasonryLayout className="instanceComments_comment_images" layoutOptions={{ columnWidth: 100 }}>
              {comment?.images?.map((image, index) => (
                <div key={index} className="instanceComments_comment_images_image">
                  <BinaryImage uid={image.key} alt="comment" onLoad={handleLoadImage} />
                </div>
              ))}
            </MasonryLayout>
            {comment?.voiceNotes?.map((voiceNote, index) => (
              <div key={index} className="instanceComments_comment_audio">
                <BinaryAudio uid={voiceNote.key} />
              </div>
            ))}
          </div>
        ))
      )}
      <Modal isShowing={!!commentIdToDelete} onClose={() => setCommentIdToDelete(undefined)} boxPadding>
        <RemoveModalContent
          heading="Delete Comment"
          content="Are you sure you want to delete comment?"
          cancelText="Cancel"
          removeText="Delete"
          onClose={() => setCommentIdToDelete(undefined)}
          onDelete={handleDeleteCommentClick}
          isSubmitType
        />
      </Modal>

      <Modal isShowing={isLeavePopup} onClose={handleLeaveCommentingClick} boxPadding>
        <RemoveModalContent
          heading="Save Changes"
          content="Do you want to save the changes made to the Comment?"
          cancelText="Leave"
          removeText="Save"
          onClose={handleLeaveCommentingClick}
          onDelete={() => setIsLeavePopup(false)}
        />
      </Modal>
    </div>
  );
};

export default InstanceComments;
