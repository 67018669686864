import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router";
import { ThemeProvider } from "styled-components";
import { LastLocationProvider } from "react-router-last-location";
import "fontsource-mukta";

import { ROUTE_PATHS } from "../../shared/routes";
import { PrivateRoute } from "../../shared/routes";
import { AuthContainer } from "../Auth";
import { authenticated } from "../Auth/store/selectors";
import PrivateRoutes from "./PrivateRoutes";

import { mainTheme as theme } from "../../theme";
import { useLoader } from "../../shared/hooks";
import { actions as fieldNotesActions } from "../FieldNotes/store";
import { actions as instanceActions } from "../Instances/store";
import { LOADERS_NAMES } from "../../shared/constants";

const App = () => {
  const isAuthorized = useSelector(authenticated());

  const { isLoading, AppLoader } = useLoader({
    name: LOADERS_NAMES.PDF_LOADER,
    actionTypes: [fieldNotesActions.getFieldNotePDF, instanceActions.getInstancePDF],
  });

  return (
    <ThemeProvider theme={{ ...theme }}>
      <LastLocationProvider>
        {isLoading && <AppLoader text="File is processing" />}
        <div className={`App ${isLoading ? "loading" : ""}`}>
          <Switch>
            <Route path={ROUTE_PATHS.AUTH} component={AuthContainer} />
            <PrivateRoute path={ROUTE_PATHS.ROOT} component={PrivateRoutes} authenticated={isAuthorized} />
          </Switch>
        </div>
      </LastLocationProvider>
    </ThemeProvider>
  );
};

export default App;
