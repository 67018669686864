import React, { useState, useEffect } from "react";
import { Formik, Form, FieldArray, FormikProps } from "formik";

import "./index.scss";
import { Company, Profile, Role } from "../../../../shared/models";
import { CompanyPlants, UserCompanyPlantsAccess, EditUserShape, CompanyPlantsRoleShape } from "../../interfaces";
import { FieldItem, FieldItemType } from "../../../../shared/formComponents";
import { generateSelect } from "../../../../shared/formComponents/formRenderers/formRenderers";
import { Option } from "../../../../shared/interfaces";

interface EditUserFormProps {
  submitUser: Function;
  user: Profile;
  roles: Role[];
  companies: Company[];
  onChangeForm: Function;
  formRef: RefObject<FormikProps<EditUserShape>>;
}

const selectField: FieldItem = {
  type: FieldItemType.SELECT,
  name: "roleId",
  label: "",
  placeholder: "Select Role",
  wrapperClass: "form-element-wrapper",
  options: [],
};

const setRolesForCompany = (
  field: string,
  value: EditUserShape,
  companyIndex: number,
  formikProps: FormikProps<EditUserShape>,
) => {
  formikProps.setFieldValue(field, value);
  formikProps.values.profileRoles[companyIndex].plants.forEach((_, pIndex: number) => {
    formikProps.setFieldValue(`profileRoles.${companyIndex}.plants.${pIndex}.${selectField.name}`, value);
  });
};

const getInitFormValue = (
  companyPlants: CompanyPlants,
  userCompanyPlantsAccess: UserCompanyPlantsAccess,
): EditUserShape => {
  return Object.keys(userCompanyPlantsAccess).reduce(
    (result: EditUserShape, key) => {
      const company = companyPlants[Number(key)];
      const plantsAccess = userCompanyPlantsAccess[Number(key)];

      if (company && company.plants && plantsAccess.length) {
        const profileRole: CompanyPlantsRoleShape = {
          companyDisplayName: company.displayName,
          companyId: company.id,
          plants: [],
          roleId: String(plantsAccess[0].roleId),
        };

        const profileRoleWithoutPlantId = plantsAccess.find((pa) => !pa.plantId);

        profileRole.plants = company.plants.map((plant) => {
          const plantAccess = plantsAccess.find((pa) => pa.plantId === plant.id);
          return {
            plantId: plant.id,
            plantDisplayName: plant.displayName,
            roleId: plantAccess
              ? String(plantAccess.roleId)
              : profileRoleWithoutPlantId
              ? String(profileRoleWithoutPlantId.roleId)
              : "",
          };
        });
        result.profileRoles.push(profileRole);
      }

      return result;
    },
    { profileRoles: [] },
  );
};

const EditUserForm: React.FunctionComponent<EditUserFormProps> = (props) => {
  const { user, companies, formRef, onChangeForm, roles } = props;
  const [initFormValues, setInitFormValues] = useState<EditUserShape>({ profileRoles: [] });
  const [companyPlants, setcompanyPlants] = useState<CompanyPlants>({});
  const [roleOptions, setRoleOptions] = useState<Option[]>([]);
  const [userCompanyPlantsAccess, setUserCompanyPlantsAccess] = useState<UserCompanyPlantsAccess>({});

  useEffect(() => {
    setRoleOptions(roles.map((r) => ({ value: String(r.id), label: r.name })));
  }, [roles]);

  useEffect(() => {
    setcompanyPlants(
      companies.reduce((result: CompanyPlants, item) => {
        if (item.id) {
          result[Number(item.id)] = item;
        }

        return result;
      }, {}),
    );
  }, [companies]);

  useEffect(() => {
    setUserCompanyPlantsAccess(
      (user.profileRoles || []).reduce((result: UserCompanyPlantsAccess, item) => {
        if (item.companyId && companyPlants[item.companyId]) {
          result[item.companyId] = result[item.companyId] || [];
          result[item.companyId].push({ roleId: item.roleId, plantId: item.plantId });
        }
        return result;
      }, {}),
    );
  }, [user, companyPlants]);

  useEffect(() => {
    setInitFormValues(getInitFormValue(companyPlants, userCompanyPlantsAccess));
  }, [companyPlants, userCompanyPlantsAccess]);

  return (
    <Formik
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        props.submitUser(user.id, values);
      }}
      initialValues={initFormValues}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
      innerRef={formRef}
      validate={(values) => onChangeForm(values)}
    >
      {(formikProps) => (
        <Form onSubmit={formikProps.handleSubmit}>
          <FieldArray
            name="profileRoles"
            render={() => (
              <div>
                {formikProps.values.profileRoles.map((profileRole, index) => (
                  <div key={index}>
                    <div className="company-name-title">{profileRole.companyDisplayName}</div>
                    {generateSelect({
                      formikProps: {
                        ...formikProps,
                        values: { [`profileRoles.${index}.${selectField.name}`]: profileRole.roleId },
                        setFieldValue: (field, value) => setRolesForCompany(field, value, index, formikProps),
                      },
                      ...selectField,
                      name: `profileRoles.${index}.${selectField.name}`,
                      options: roleOptions,
                    })}
                    <FieldArray
                      name={`profileRoles.${index}.plants`}
                      render={() => (
                        <div>
                          {formikProps.values.profileRoles[index].plants.map((plant, plantIndex) => (
                            <div key={plantIndex}>
                              <div className="plant-name-title">{plant.plantDisplayName}</div>
                              {generateSelect({
                                formikProps: {
                                  ...formikProps,
                                  values: {
                                    [`profileRoles.${index}.plants.${plantIndex}.${selectField.name}`]: plant.roleId,
                                  },
                                },
                                ...selectField,
                                name: `profileRoles.${index}.plants.${plantIndex}.${selectField.name}`,
                                options: [{ value: "", label: "Not Available" }, ...roleOptions],
                              })}
                            </div>
                          ))}
                        </div>
                      )}
                    />
                  </div>
                ))}
              </div>
            )}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditUserForm;
