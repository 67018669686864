import styled from "styled-components";
import { InputProps } from "../InputProps";

export const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    display: flex;
    align-items: center;
  }
  input {
    width: 100%;
    height: 40px;
    padding: 10px;
    font-size: 16px;
    box-sizing: border-box;
    border-radius: 8px;
  }

  input:disabled {
    background: #f4f6fb;
    cursor: not-allowed;
  }

  label *:focus {
    outline: none;
  }

  .clear-search {
    width: 15px;
    position: absolute;
    top: 39px;
    right: 10px;
    border: none;
    background: #f4f6fb;
    font-weight: bold;
    cursor: pointer;
    color: darkgray;
    text-align: center;
    font-family: ${(props: InputProps) => (props.theme ? props.theme.globalFontFamily : "san-serif")};
  }

  &.search {
    margin-bottom: 0;
    input {
      font-size: 14px;
    }
  }

  &.left {
    width: 90%;
    margin-right: 5%;
  }

  &.right {
    width: 90%;
    margin-left: 5%;
  }

  .autocomplete-dropdown-container {
    position: absolute;
    width: 100%;
    z-index: 1;
    border-radius: 8px;
    background: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    max-height: 230px;
    overflow-x: auto;
    font-family: ${(props: InputProps) => (props.theme ? props.theme.globalFontFamily : "san-serif")};
    top: 72px;

    .hint_item {
      width: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      border: 0;
      padding: 7px;
      box-sizing: border-box;
      margin: 0;
      cursor: pointer;
      :hover {
        background: ${(props: InputProps) => (props.theme ? props.theme.selectHoverBG : "#5d81f4")};
        color: ${(props: InputProps) => (props.theme ? props.theme.darkColor : "black")};
      }
    }
  }
`;
