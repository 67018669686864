import React, { useEffect, useMemo, useRef, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import classnames from "classnames";

import { NotificationHistory, User } from "../../../../shared/models";
import { useOutsideClick } from "../../../../shared/hooks";
import { Notifications } from "../Notifications";
import { NavbarItemsMap } from "./constants";
import { Modal, ProfilePopover, RemoveModalContent } from "../../../../shared/components";
import { ROUTE_PATHS } from "../../../../shared/routes";
import "./index.scss";
import { hasAnyNonStandardUserRole, checkPermissions } from "../../../../shared/utils/ACL";

interface NavbarProps {
  user: User;
  notificationHistory: NotificationHistory[];
  onLogout: () => void;
  onMarkAllAsRead: Function;
  onMarkAsRead: Function;
  onCloseOpenNotification: Function;
}

const Navbar: React.FunctionComponent<NavbarProps> = (props) => {
  const { onLogout, onMarkAllAsRead, onMarkAsRead, notificationHistory, onCloseOpenNotification } = props;

  const notificationsRef = useRef(null);
  const profileRef = useRef(null);

  const {
    location: { pathname },
  } = useHistory();

  const [isNewNotification, setNewNotification] = useState(false);
  const [currentYear] = useState(new Date().getFullYear());
  const [isNotificationsOpened, setIsNotificationsOpened] = useState(false);
  const [isProfileOpened, setIsProfileOpened] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isLicensePage, setIsLicensePage] = useState(false);
  const [isTutorialPage, setIsTutorialPage] = useState(false);
  const [isLogoutOpened, setIsLogoutOpened] = useState(false);

  const { isOutside: isOutsideNotifications } = useOutsideClick(notificationsRef);
  const { isOutside: isOutsideProfile } = useOutsideClick(profileRef);

  useEffect(() => {
    setIsExpanded(true);
    if (pathname === ROUTE_PATHS.LICENSE_AGREEMENT) {
      setIsLicensePage(true);
    } else {
      if (isLicensePage) {
        setIsLicensePage(false);
      }
    }
  }, [pathname, isLicensePage]);

  useEffect(() => {
    if (pathname === ROUTE_PATHS.TUTORIAL) {
      setIsTutorialPage(true);
    } else {
      if (isTutorialPage) {
        setIsTutorialPage(false);
      }
    }
  }, [pathname, isTutorialPage]);

  useEffect(() => {
    setNewNotification(notificationHistory.some((n) => !n.isViewed));
  }, [notificationHistory]);

  useEffect(() => {
    if (isOutsideNotifications && isNotificationsOpened) {
      onCloseOpenNotification(false);
      setIsNotificationsOpened(false);
    }
  }, [isOutsideNotifications, isNotificationsOpened, onCloseOpenNotification]);

  useEffect(() => {
    if (isOutsideProfile && isProfileOpened && !isLogoutOpened) {
      setIsProfileOpened(false);
    }
  }, [isOutsideProfile, isProfileOpened, isLogoutOpened]);

  const filteredNavBarItems = useMemo(() => {
    return NavbarItemsMap.filter((navBarItem) => {
      if (navBarItem.checkPermissions !== undefined) {
        if (typeof navBarItem.checkPermissions === "boolean" && navBarItem.checkPermissions) {
          return hasAnyNonStandardUserRole();
        }

        if (Array.isArray(navBarItem.checkPermissions)) {
          return checkPermissions(navBarItem.checkPermissions);
        }
      }
      return navBarItem;
    });
  }, []);

  const closeOpenNotification = (isOpen: boolean) => {
    onCloseOpenNotification(isOpen);
  };

  const toggleNotificationPanel = () => {
    if (!isLicensePage) {
      closeOpenNotification(!isNotificationsOpened);
      setIsNotificationsOpened(!isNotificationsOpened);
    }
  };

  const toggleProfilePopover = () => {
    if (!isLicensePage) {
      setIsProfileOpened(!isProfileOpened);
    }
  };

  const closeProfilePopover = () => {
    setIsProfileOpened(false);
  };

  const toggleCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  const markAsReadNotification = (notificationHistory: NotificationHistory) => {
    closeOpenNotification(false);
    setIsNotificationsOpened(false);
    onMarkAsRead(notificationHistory);
  };

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (isLicensePage) {
      event.preventDefault();
    }
  };

  return (
    <div className={classnames("navbar", { expanded: isExpanded }, { disabled: isTutorialPage })}>
      <div className="collapse_button" onClick={toggleCollapse}>
        <img
          src={isExpanded ? "/icons/general/white-left-arrow.svg" : "/icons/general/gray-right-arrow.svg"}
          alt="collapse"
        />
      </div>
      <nav className="navbar-top">
        <div className="logo">
          {isExpanded ? (
            <img className="full_logo_img" src="/icons/general/logo.svg" alt="logo" />
          ) : (
            <img className="logo_img" src="/images/menu/menu-logo.svg" alt="logo" />
          )}
        </div>

        {filteredNavBarItems.map((item) => (
          <NavLink
            onClick={handleLinkClick}
            key={item.link}
            exact
            to={item.link}
            activeClassName="active"
            className="navbar-link"
          >
            <div className="active-bar" />
            <div className={classnames("navbar-button", { disabled: isLicensePage })}>
              <div className={classnames("navbar-icon")}>
                <img src={item.iconPath} alt={item.name} />
                <div className="tooltiptext">{item.name}</div>
              </div>
              <div className="title">{item.name}</div>
            </div>
          </NavLink>
        ))}
      </nav>

      <nav className="navbar-bottom">
        <NavLink
          onClick={handleLinkClick}
          exact
          to={ROUTE_PATHS.USER_GUIDE}
          activeClassName="active"
          className="navbar-link"
        >
          <div className="active-bar" />
          <div className={classnames("navbar-button", { disabled: isLicensePage })}>
            <div className={classnames("navbar-icon")}>
              <img src={"/images/menu/menu-user-guide.svg"} alt="User Guide" />
              <div className="tooltiptext">User Guide</div>
            </div>
            <div className="title">User Guide</div>
          </div>
        </NavLink>
        <div className="notificationsWrapper" ref={notificationsRef}>
          <div
            className={classnames("navbar-button", { active: isNotificationsOpened, disabled: isLicensePage })}
            onClick={toggleNotificationPanel}
          >
            <div className="navbar-icon">
              {isNewNotification && <div className="new-notification" />}
              <img src="/images/menu/menu-notification.svg" alt="notifications" />
              <div className="tooltiptext">Notifications</div>
            </div>
            <div className="title">Notifications</div>
          </div>

          <Notifications
            opened={isNotificationsOpened}
            onMarkAllAsRead={onMarkAllAsRead}
            onMarkAsRead={markAsReadNotification}
            notificationHistory={notificationHistory}
          />
        </div>

        <div className="profileWrapper" ref={profileRef}>
          <div
            className={classnames("navbar-button", { active: isProfileOpened, disabled: isLicensePage })}
            onClick={toggleProfilePopover}
          >
            <div className="navbar-icon">
              <img src="/icons/general/profile_image.svg" alt="notifications" />
              <div className="tooltiptext">Profile</div>
            </div>
            <div className="title">Profile</div>
          </div>
          {isProfileOpened && (
            <ProfilePopover setIsLogoutOpened={setIsLogoutOpened} closeProfilePopover={closeProfilePopover} />
          )}
        </div>
      </nav>
      <nav className="navbar-footer">
        <div>
          EPRI 3420 Hillview Avenue, Palo Alto, California 94304 <br />© Electric Power Research Institute, Inc, 2001-
          {currentYear} <br />
          All rights reserved <br />
          <p>
            <a title="Copyright Policy" href="https://www.epri.com/#/copyright">
              Copyright Policy
            </a>
            |
            <a title="Privacy Statement" href="https://www.epri.com/#/privacy_statement">
              Privacy Policy
            </a>
            |
            <a title="Terms of Use" href="https://www.epri.com/#/terms-of-use">
              Terms of Use
            </a>
            |
            <a title="EPRI.com" href="https://www.epri.com/">
              EPRI.com
            </a>
          </p>
        </div>
      </nav>
      <Modal isShowing={isLogoutOpened} onClose={() => setIsLogoutOpened(false)} boxPadding>
        <RemoveModalContent
          heading="Logout"
          content="Are you sure you want to logout this account?"
          cancelText="Cancel"
          removeText="Logout"
          onClose={() => setIsLogoutOpened(false)}
          onDelete={onLogout}
        />
      </Modal>
    </div>
  );
};

export default Navbar;
