import React from "react";

import { Company, Profile } from "../../../shared/models";

interface UsersTableProps {
  profiles: Profile[] | null;
  onEditItem: Function;
  companies: Company[];
}
export const UsersTable = (props: UsersTableProps) => {
  const { companies, profiles, onEditItem } = props;
  const plantNames: { [key: number]: string } = Object.fromEntries(
    companies
      .map((company) => company.plants)
      .flat()
      .map((plant) => [plant?.id, plant?.displayName]),
  );

  return profiles?.length ? (
    <table className="users-table">
      <thead>
        <tr>
          <th>User</th>
          <th>Title</th>
          <th>Company</th>
          <th>Plant Name</th>
          <th>Email</th>
          <th>Phone number</th>
        </tr>
      </thead>
      <tbody>
        {profiles.map((row) => {
          return (
            <tr key={row.id} onClick={() => onEditItem(row)}>
              <td>
                <img
                  src={row.imageUrl ? row.imageUrl : "/icons/general/profile_image.svg"}
                  alt="User"
                  className="user-list-profile-img"
                />
                {row.profileDetails?.fullName || `${row.firstName} ${row.lastName}`}
              </td>
              <td>{row.profileDetails?.title}</td>
              <td>{row.profileDetails?.companyName}</td>
              <td>{row.profileRoles?.map((role) => role.plantId && plantNames[role.plantId]).join(", ")}</td>
              <td>{row.profileDetails?.email || row.email}</td>
              <td>
                {row.profileDetails?.phone}
                <br></br>
                {row.profileDetails?.mobilePhone || ""}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : (
    <div className="no-matches-found"></div>
  );
};
