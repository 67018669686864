import { API, METHODS } from "../../../shared/constants";
import { request } from "../../../shared/utils";
import { AddFieldGuideReferencePayload, RequestWithQuery, SortFilterOptions } from "../../../shared/interfaces";
import {
  ImageCreateRequest,
  InstanceFormBaseValues,
  InstanceConditionOptionsUpdateRequest,
  InstanceConditionOptionsDeleteRequest,
  ChunkedImagesRequest,
  DeleteImagesRequest,
} from "../../../shared/models";

const instancesApi = {
  getInstances: (showEditable: boolean) =>
    request(METHODS.GET, API.INSTANCES.GET_INSTANCES)({ params: { showEditable } }),
  getInstance: (id: number) => request(METHODS.GET, API.INSTANCES.GET_INSTANCE(id))(),
  getInstancePDF: (id: number) => request(METHODS.GET, API.INSTANCES.GET_INSTANCE_PDF(id))(),
  getInstancesByFieldNote: (payload: RequestWithQuery<{ fieldNoteId: number }>) =>
    request(
      METHODS.GET,
      API.INSTANCES.GET_INSTANCES_BY_FIELD_NOTE(payload.fieldNoteId),
    )({ params: { search: payload.search, sortOrder: payload.sortOrder, showMine: payload.showMine } }),
  createInstance: (payload: InstanceFormBaseValues) => request(METHODS.POST, API.INSTANCES.CREATE_INSTANCE)(payload),
  updateInstance: (payload: InstanceFormBaseValues) => request(METHODS.PUT, API.INSTANCES.UPDATE_INSTANCE)(payload),
  deleteInstance: (id: number) => request(METHODS.DELETE, API.INSTANCES.DELETE_INSTANCE(id))(),
  addInstanceConditionOptions: ({ conditionOptions, instanceId }: InstanceConditionOptionsUpdateRequest) =>
    request(METHODS.POST, API.INSTANCES.UPDATE_INSTANCE_CONDITION_OPTIONS(instanceId))(conditionOptions),
  updateInstanceConditionOptions: ({ conditionOptions, instanceId }: InstanceConditionOptionsUpdateRequest) =>
    request(METHODS.PUT, API.INSTANCES.UPDATE_INSTANCE_CONDITION_OPTIONS(instanceId))(conditionOptions),
  deleteInstanceConditionOptions: ({ conditionOptions, instanceId }: InstanceConditionOptionsDeleteRequest) =>
    request(METHODS.DELETE, API.INSTANCES.DELETE_INSTANCE_CONDITION_OPTIONS(instanceId))({ data: conditionOptions }),
  getInstanceCommentsWithMedia: (instanceId: number) =>
    request(METHODS.GET, API.INSTANCES.GET_INSTANCE_COMMENTS)({ params: { instanceId } }),
  getInstanceCollaborators: (instanceId: number) =>
    request(METHODS.GET, API.INSTANCES.GET_INSTANCE_COLLABORATORS(instanceId))(),
  addInstanceCollaborator: ({ instanceId, profileId }: { instanceId: number; profileId: number }) =>
    request(METHODS.POST, API.INSTANCES.ADD_INSTANCE_COLLABORATOR(instanceId))({ id: profileId }),
  deleteInstanceCollaborator: ({ instanceId, profileId }: { instanceId: number; profileId: number }) =>
    request(METHODS.DELETE, API.INSTANCES.DELETE_INSTANCE_COLLABORATOR(instanceId, profileId))(),
};

const commentsApi = {
  createComment: (payload: FormData) => request(METHODS.POST, API.COMMENTS.CREATE_COMMENT)(payload),
  deleteComment: (commentId: number) => request(METHODS.DELETE, API.COMMENTS.DELETE_COMMENT(commentId))(),
};

const videosApi = {
  createVideos: (payload: FormData) => request(METHODS.POST, API.VIDEOS.CREATE_VIDEO_FILES)(payload),
  deleteVideo: (videoId: number) => request(METHODS.DELETE, API.VIDEOS.DELETE_VIDEO_FILE(videoId))(),
};

const filesApi = {
  createImage: (payload: ImageCreateRequest) => request(METHODS.POST, API.FILES.CREATE_IMAGE_FILE)(payload),
  deleteFile: (fileId: number) => request(METHODS.DELETE, API.FILES.DELETE_IMAGE_FILE(fileId))(),
  saveChunkedImages: (payload: ChunkedImagesRequest) => request(METHODS.POST, API.IMAGES_CHUNKED.SAVE)(payload),
  saveVoiceNoteForImage: (voiceNoteData: FormData, imageId: number) =>
    request(METHODS.POST, API.VOICE_NOTE_FOR_IMAGE.SAVE(imageId))(voiceNoteData),
  deleteChunkedImages: (payload: DeleteImagesRequest) =>
    request(METHODS.DELETE, API.IMAGES_CHUNKED.DELETE)({ data: payload }),
};

const profilesApi = {
  getPlantProfiles: (plantId: number, filter: SortFilterOptions) =>
    request(METHODS.GET, API.PROFILES.GET_PLANT_PROFILES(plantId))({ params: filter }),
};

const fieldNotesApi = {
  addFieldGuideReferenceToInstance: (payload: AddFieldGuideReferencePayload) =>
    request(METHODS.POST, API.INSTANCES.ADD_FIELD_GUIDE_TO_INSTANCE(payload.instanceId))(payload),
  deleteFieldGuideReferenceToInstance: (payload: { instanceId: number; referenceId: number }) =>
    request(
      METHODS.DELETE,
      API.INSTANCES.DELETE_FIELD_GUIDE_FROM_INSTANCE(payload.instanceId, payload.referenceId),
    )(payload),
};

export default {
  ...instancesApi,
  ...filesApi,
  ...profilesApi,
  ...commentsApi,
  ...fieldNotesApi,
  ...videosApi,
};
