import { createSelector } from "reselect";

import { RootReducer } from "../../../shared/interfaces";

const selectInstances = (state: RootReducer) => state.instances;

export const getInstances = () => createSelector(selectInstances, (state) => state.instances);
export const getInstance = () => createSelector(selectInstances, (state) => state.selectedInstance);
export const getInstanceComments = () => createSelector(selectInstances, (state) => state.comments);
export const getProfiles = () => createSelector(selectInstances, (state) => state.profiles);
export const getCollaborators = () => createSelector(selectInstances, (state) => state.collaborators);
export const getInstancePDF = () => createSelector(selectInstances, (state) => state.pdf);
export const getPdfAreas = () => createSelector(selectInstances, (state) => state.pdfAreas);
