import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { Overlay } from "../../../../../../shared/components";
import { Document } from "../../../../../../shared/models";
import { FORM_ERROR_MESSAGES } from "../../../../../../shared/constants";
import { FieldItem, FieldItemType, FormGenerator } from "../../../../../../shared/formComponents";
import * as Yup from "yup";
import "./index.scss";
import { DropFileItem } from "../../../../../../shared/components/DropFileZone/DropFileZone";

interface DocumentFormValues {
  displayName: string;
  file: DropFileItem | null;
}

interface EditDocumentModalProps {
  showHideUnsavedChangeModal: Function;
  submitDocument: Function;
  document: Document | null;
}

const generateValidationSchema = (document: Document | null) => {
  if (document) {
    return Yup.object().shape({
      displayName: Yup.string()
        .required(`Document Name ${FORM_ERROR_MESSAGES.REQUIRED}`)
        .max(50, `Document Name ${FORM_ERROR_MESSAGES.LONG}`),
    });
  }

  return Yup.object().shape({
    displayName: Yup.string()
      .required(`Document Name ${FORM_ERROR_MESSAGES.REQUIRED}`)
      .max(50, `Document Name ${FORM_ERROR_MESSAGES.LONG}`),
    file: Yup.object({
      name: Yup.string().required(),
    })
      .required(`Document ${FORM_ERROR_MESSAGES.REQUIRED}`)
      .nullable(),
  });
};

const generateFormFields = (document: Document | null): FieldItem[] => {
  const fields: FieldItem[] = [
    {
      type: FieldItemType.TEXT,
      name: "displayName",
      label: "Document Name",
      wrapperClass: "form-element-wrapper",
    },
  ];

  if (!document) {
    fields.push({
      type: FieldItemType.DROP_UPLOAD_FILE,
      name: "file",
      maxSize: 100000000,
    });
  }

  return fields;
};

const prepareDocumentData = (document: Document | null): DocumentFormValues => {
  return {
    displayName: document ? document.displayName || "" : "",
    file: null,
  };
};

const EditDocumentModal: React.FunctionComponent<EditDocumentModalProps> = (props: EditDocumentModalProps) => {
  const { showHideUnsavedChangeModal, document, submitDocument } = props;
  const [fieldsList, setFieldsList] = useState<FieldItem[]>([]);
  const [validation, setValidation] = useState(generateValidationSchema(null));
  const [formValues, setFormValues] = useState<DocumentFormValues>(prepareDocumentData(null));

  useEffect(() => {
    setFieldsList(generateFormFields(document));
    setValidation(generateValidationSchema(document));
    setFormValues(prepareDocumentData(document));
  }, [document]);

  return (
    <Formik
      validationSchema={validation}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        submitDocument(
          {
            displayName: values.displayName,
            file: values.file ? values.file.base64 : null,
          },
          document ? document.id : null,
        );
      }}
      initialValues={formValues}
      enableReinitialize
      validateOnChange
      validateOnBlur={false}
    >
      {(formikProps) => (
        <Overlay onClose={() => showHideUnsavedChangeModal(false)}>
          <div className="edit-document-wrapper">
            <div className="edit-document-header">
              <div className="heading">{document ? "Edit Document" : "Add Document"}</div>
            </div>
            <div className="edit-document-content">
              <FormGenerator formikProps={formikProps} fields={fieldsList} />
            </div>
            <div className="edit-document-actions">
              <div className="edit-document-button-panel">
                <button
                  className="action-button action-button-cancel"
                  onClick={() => showHideUnsavedChangeModal(false)}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="action-button action-button-save"
                  disabled={!formikProps.dirty}
                  onClick={formikProps.submitForm}
                >
                  {document ? "SAVE" : "ADD"}
                </button>
              </div>
            </div>
          </div>
        </Overlay>
      )}
    </Formik>
  );
};

export default EditDocumentModal;
