import { createAction, createAsyncAction } from "typesafe-actions";

import {
  InstanceFormValues,
  InstanceUpdateFormValues,
  Profile,
  Image,
  InstanceCollaborator,
  InstanceComment,
  InstanceCommentRequest,
  InstanceCommentUpdateRequest,
  InstanceConditionOptionsUpdateRequest,
  InstanceConditionOptionsDeleteRequest,
  UpdateImagePayload,
  VoiceNoteForImageRequest,
  InstanceResponse,
} from "../../../shared/models";
import {
  AddFieldGuideReferencePayload,
  RequestWithCallback,
  RequestWithMessage,
  RequestWithQuery,
  SortFilterOptions,
} from "../../../shared/interfaces";

import { ActionTypes } from "./constants";
import { HighlightArea } from "@react-pdf-viewer/highlight";

export const getInstance = createAsyncAction(
  ActionTypes.GET_INSTANCE,
  ActionTypes.GET_INSTANCE_SUCCESS,
  ActionTypes.GET_INSTANCE_FAILURE,
)<number, InstanceResponse | undefined, Error>();

export const getInstancePDF = createAsyncAction(
  ActionTypes.GET_INSTANCE_PDF,
  ActionTypes.GET_INSTANCE_PDF_SUCCESS,
  ActionTypes.GET_INSTANCE_PDF_FAILURE,
)<number, Blob, Error>();

export const getInstancesByFieldNote = createAsyncAction(
  ActionTypes.GET_INSTANCES_BY_FIELD_NOTE,
  ActionTypes.GET_INSTANCES_BY_FIELD_NOTE_SUCCESS,
  ActionTypes.GET_INSTANCES_BY_FIELD_NOTE_FAILURE,
)<RequestWithQuery<{ fieldNoteId: number }>, InstanceResponse[], Error>();

export const getInstances = createAsyncAction(
  ActionTypes.GET_INSTANCES,
  ActionTypes.GET_INSTANCES_SUCCESS,
  ActionTypes.GET_INSTANCES_FAILURE,
)<{ showEditable: boolean }, InstanceResponse[], Error>();

export const createInstance = createAsyncAction(
  ActionTypes.CREATE_INSTANCE,
  ActionTypes.CREATE_INSTANCE_SUCCESS,
  ActionTypes.CREATE_INSTANCE_FAILURE,
)<RequestWithCallback<InstanceFormValues>, InstanceResponse, Error>();

export const updateInstance = createAsyncAction(
  ActionTypes.UPDATE_INSTANCE,
  ActionTypes.UPDATE_INSTANCE_SUCCESS,
  ActionTypes.UPDATE_INSTANCE_FAILURE,
)<RequestWithCallback<InstanceUpdateFormValues>, InstanceResponse, Error>();

export const addInstanceConditionOptions = createAsyncAction(
  ActionTypes.ADD_INSTANCE_CONDITION_OPT,
  ActionTypes.ADD_INSTANCE_CONDITION_OPT_SUCCESS,
  ActionTypes.ADD_INSTANCE_CONDITION_OPT_FAILURE,
)<RequestWithCallback<InstanceConditionOptionsUpdateRequest>, InstanceResponse, Error>();

export const updateInstanceConditionOptions = createAsyncAction(
  ActionTypes.UPDATE_INSTANCE_CONDITION_OPT,
  ActionTypes.UPDATE_INSTANCE_CONDITION_OPT_SUCCESS,
  ActionTypes.UPDATE_INSTANCE_CONDITION_OPT_FAILURE,
)<RequestWithCallback<InstanceConditionOptionsUpdateRequest>, InstanceResponse, Error>();

export const removeInstanceConditionOptions = createAsyncAction(
  ActionTypes.REMOVE_INSTANCE_CONDITION_OPT,
  ActionTypes.REMOVE_INSTANCE_CONDITION_OPT_SUCCESS,
  ActionTypes.REMOVE_INSTANCE_CONDITION_OPT_FAILURE,
)<InstanceConditionOptionsDeleteRequest, InstanceResponse, Error>();

export const updateImage = createAsyncAction(
  ActionTypes.UPDATE_IMAGE,
  ActionTypes.UPDATE_IMAGE_SUCCESS,
  ActionTypes.UPDATE_IMAGE_FAILURE,
)<RequestWithCallback<UpdateImagePayload>, undefined, Error>();

export const saveVoiceNoteForImage = createAsyncAction(
  ActionTypes.SAVE_VOICE_NOTE_FOR_IMAGE,
  ActionTypes.SAVE_VOICE_NOTE_FOR_IMAGE_SUCCESS,
  ActionTypes.SAVE_VOICE_NOTE_FOR_IMAGE_FAILURE,
)<VoiceNoteForImageRequest, Image, Error>();

export const deleteImage = createAsyncAction(
  ActionTypes.DELETE_IMAGE,
  ActionTypes.DELETE_IMAGE_SUCCESS,
  ActionTypes.DELETE_IMAGE_FAILURE,
)<{ imageId: number; instanceId: number }, undefined, Error>();

export const deleteInstance = createAsyncAction(
  ActionTypes.DELETE_INSTANCE,
  ActionTypes.DELETE_INSTANCE_SUCCESS,
  ActionTypes.DELETE_INSTANCE_FAILURE,
)<RequestWithCallback<{ instanceId: number }>, RequestWithMessage<InstanceResponse>, Error>();

export const deleteVideo = createAsyncAction(
  ActionTypes.DELETE_VIDEO,
  ActionTypes.DELETE_VIDEO_SUCCESS,
  ActionTypes.DELETE_VIDEO_FAILURE,
)<{ videoId: number; instanceId: number }, undefined, Error>();

export const getPlantProfiles = createAsyncAction(
  ActionTypes.GET_PLANT_PROFILES,
  ActionTypes.GET_PLANT_PROFILES_SUCCESS,
  ActionTypes.GET_PLANT_PROFILES_FAILURE,
)<{ plantId: number; filter: SortFilterOptions }, Profile[], Error>();

export const getInstanceCollaborators = createAsyncAction(
  ActionTypes.GET_INSTANCE_COLLABORATORS,
  ActionTypes.GET_INSTANCE_COLLABORATORS_SUCCESS,
  ActionTypes.GET_INSTANCE_COLLABORATORS_FAILURE,
)<number, InstanceCollaborator[], Error>();

export const addInstanceCollaborators = createAsyncAction(
  ActionTypes.ADD_INSTANCE_COLLABORATORS,
  ActionTypes.ADD_INSTANCE_COLLABORATORS_SUCCESS,
  ActionTypes.ADD_INSTANCE_COLLABORATORS_FAILURE,
)<{ profileIds: number[]; instanceId: number }, undefined, Error>();

export const deleteInstanceCollaborator = createAsyncAction(
  ActionTypes.DELETE_INSTANCE_COLLABORATOR,
  ActionTypes.DELETE_INSTANCE_COLLABORATOR_SUCCESS,
  ActionTypes.DELETE_INSTANCE_COLLABORATOR_FAILURE,
)<{ profileId: number; instanceId: number }, number, Error>();

export const getInstanceComments = createAsyncAction(
  ActionTypes.GET_INSTANCE_COMMENTS,
  ActionTypes.GET_INSTANCE_COMMENTS_SUCCESS,
  ActionTypes.GET_INSTANCE_COMMENTS_FAILURE,
)<{ instanceId: number }, InstanceComment[], Error>();

export const createComment = createAsyncAction(
  ActionTypes.CREATE_COMMENT,
  ActionTypes.CREATE_COMMENT_SUCCESS,
  ActionTypes.CREATE_COMMENT_FAILURE,
)<InstanceCommentRequest, InstanceComment, Error>();

export const updateComment = createAsyncAction(
  ActionTypes.UPDATE_COMMENT,
  ActionTypes.UPDATE_COMMENT_SUCCESS,
  ActionTypes.UPDATE_COMMENT_FAILURE,
)<InstanceCommentUpdateRequest, InstanceComment, Error>();

export const deleteComment = createAsyncAction(
  ActionTypes.DELETE_COMMENT,
  ActionTypes.DELETE_COMMENT_SUCCESS,
  ActionTypes.DELETE_COMMENT_FAILURE,
)<{ commentId: number }, number, Error>();

export const addFieldGuideReferenceToInstance = createAsyncAction(
  ActionTypes.FIELD_GUIDE_REFERENCE_ADD,
  ActionTypes.FIELD_GUIDE_REFERENCE_ADD_SUCCESS,
  ActionTypes.FIELD_GUIDE_REFERENCE_ADD_FAILURE,
)<RequestWithCallback<AddFieldGuideReferencePayload>, RequestWithMessage<InstanceResponse> | undefined, Error>();

export const deleteFieldGuideReferenceFromInstance = createAsyncAction(
  ActionTypes.FIELD_GUIDE_REFERENCE_DELETE,
  ActionTypes.FIELD_GUIDE_REFERENCE_DELETE_SUCCESS,
  ActionTypes.FIELD_GUIDE_REFERENCE_DELETE_FAILURE,
)<{ referenceId: number; instanceId: number }, InstanceResponse | undefined, Error>();

export const cleanInstancePdf = createAction(ActionTypes.CLEAN_INSTANCE_PDF)();

export const setSelectedPdfAreas = createAction(ActionTypes.SET_SELECTED_PDF_AREAS)<HighlightArea[] | null>();
