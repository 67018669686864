import React, { Fragment, useCallback, useEffect, useState } from "react";
import { FieldGuideDocument } from "../../../../../../shared/models/FieldGuideDocument";
import "./index.scss";
import { FieldGuideSelectHeader } from "../FieldGuideSelectHeader";
import { useDispatch, useSelector } from "react-redux";
import { useLoader, useSortFilter } from "../../../../../../shared/hooks";
import { getProfileFieldGuides, setFavoriteFieldGuide, setFieldGuide } from "../../../../../../shared/store/actions";
import { FieldGuideCard } from "../FieldGuideCard";
import { getCompanies } from "../../../../../CompaniesPlants/store/selectors";
import { getCompanies as getCompaniesAction } from "../../../../../CompaniesPlants/store/actions";
import { LOADERS_NAMES } from "../../../../../../shared";
import { getFieldGuides } from "../../../../../../shared/store/selectors";
import { setSelectedPdfAreas } from "../../../../store/actions";

interface CompanyFieldGuidesProps {
  companyId?: number;
}

const CompanyFieldGuides: React.FunctionComponent<CompanyFieldGuidesProps> = (props) => {
  const { companyId } = props;
  const dispatch = useDispatch();
  const [fieldGuideSelectValue, setFieldGuideSelectValue] = useState<{ value: string; label: string }>({
    label: "Only Favorites",
    value: "false",
  });

  const { isLoading, AppLoader } = useLoader({
    name: LOADERS_NAMES.COMPANIES_LOADER,
    actionTypes: [getCompaniesAction],
  });

  const {
    requestOptions,
    debouncedSearch,
    utils: { setSearchString, setSearchFavorites },
  } = useSortFilter({ showMineInitial: false });
  useEffect(() => {
    const { sortOrder, ...options } = requestOptions;
    dispatch(
      getCompaniesAction.request({
        showFieldGuides: true,
        ...(companyId ? { companyId: String(companyId) } : {}),
      }),
    );
    dispatch(
      getProfileFieldGuides.request({
        ...options,
        ...(companyId ? { companyId: String(companyId) } : {}),
      }),
    );
  }, [dispatch, requestOptions, companyId]);

  useEffect(() => {
    setSearchFavorites(fieldGuideSelectValue?.value);
  }, [fieldGuideSelectValue, setSearchFavorites]);

  useEffect(() => {
    dispatch(setFieldGuide(undefined));
  }, [dispatch]);

  const filteredFieldGuideIds = useSelector(getFieldGuides())?.map((fieldGuide) => fieldGuide.id);
  const companies = useSelector(getCompanies());
  const currentCompany = companyId ? companies.find((company) => company.id === companyId) : null;

  const fieldGuideStarClickHandler = useCallback(
    (fieldGuide: FieldGuideDocument, companyId: number) => {
      dispatch(
        setFavoriteFieldGuide.request({
          fieldGuideDocumentId: fieldGuide.id,
          companyId,
          isFavorite: !fieldGuide.isFavorite,
        }),
      );
    },
    [dispatch],
  );

  const fieldGuideClickHandler = (fieldGuide: FieldGuideDocument) => {
    dispatch(setFieldGuide(fieldGuide));
    dispatch(setSelectedPdfAreas(null));
  };

  const favoritesSelectHandler = (option: { value: string }) => {
    setFieldGuideSelectValue(option as { value: string; label: string });
  };

  useEffect(() => {
    const hasFavoriteFieldGuides = companies.some((company) =>
      company.fieldGuideDocuments?.some((fieldGuideDocument) => fieldGuideDocument.isFavorite),
    );
    if (
      hasFavoriteFieldGuides &&
      fieldGuideSelectValue &&
      fieldGuideSelectValue.value !== "true" &&
      fieldGuideSelectValue.label === "Only Favorites" &&
      !isLoading
    ) {
      setFieldGuideSelectValue({ label: "Only Favorites", value: "true" });
    }
  }, [companies, setFieldGuideSelectValue, fieldGuideSelectValue, isLoading]);

  return (
    <>
      <FieldGuideSelectHeader
        favoritesSelectHandler={favoritesSelectHandler}
        setSearchString={setSearchString}
        debouncedSearch={debouncedSearch}
        fieldGuideSelectValue={fieldGuideSelectValue}
      />
      <div className="company-field-guides-notification">
        <div className="company-field-guides-notification-icon"></div>
        <div className="company-field-guides-notification-text">
          The cards with the star are selected Field Guides that will be available in the offline mode for references
        </div>
      </div>
      {filteredFieldGuideIds?.length === 0 ? (
        <div className="no-matches-found" />
      ) : companyId ? (
        <div className="company-field-guides-section">
          {currentCompany?.fieldGuideDocuments
            ?.filter((fieldGuide: FieldGuideDocument) => filteredFieldGuideIds?.includes(fieldGuide.id))
            .map((fieldGuide: FieldGuideDocument) => (
              <FieldGuideCard
                key={fieldGuide.id}
                fieldGuide={fieldGuide}
                companyId={companyId}
                onStarClick={fieldGuideStarClickHandler}
                onClick={fieldGuideClickHandler}
              />
            ))}
        </div>
      ) : isLoading ? (
        <AppLoader />
      ) : (
        <div>
          {companies.map((company, index) => {
            const fieldGuides = company?.fieldGuideDocuments?.filter(
              (fieldGuide: FieldGuideDocument) =>
                filteredFieldGuideIds?.includes(fieldGuide.id) &&
                (!requestOptions.showFavorites || fieldGuide.isFavorite),
            );
            return fieldGuides?.length ? (
              <Fragment key={index}>
                <div className="company-field-guides-company">{company.displayName}</div>
                <div className="company-field-guides-section">
                  {fieldGuides.map((fieldGuide: FieldGuideDocument, index) => (
                    <FieldGuideCard
                      key={index}
                      fieldGuide={fieldGuide}
                      companyId={company.id}
                      onStarClick={fieldGuideStarClickHandler}
                      onClick={fieldGuideClickHandler}
                    />
                  ))}
                </div>
              </Fragment>
            ) : null;
          })}
        </div>
      )}
    </>
  );
};

export default CompanyFieldGuides;
