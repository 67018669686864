export enum REVIEW_STATUS_TITLE {
  PENDING = "Pending",
  APPROVED = "Approved",
  REJECTED = "Rejected",
}

export enum REVIEW_STATUS_ID {
  PENDING = 1,
  APPROVED = 2,
  REJECTED = 3,
  IMAGE_FROM_COMMENTS = 13,
}
