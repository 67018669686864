import React, { useMemo, useState } from "react";
import classnames from "classnames";

import "./expandListPanel.scss";

interface ExpandPanelProps {
  isDefaultOpen?: boolean;
  title: string;
  children: React.ReactNode;
  isGlobalOpened?: boolean;
  isHeadingItem?: boolean;
  level?: number;
}

const LEVELS_MAP: { [key: number]: string } = { 0: "", 1: "first-level", 2: "second-level" };
const getClassByLevel = (level?: number) => {
  if (!level) {
    return "";
  }

  return LEVELS_MAP[level];
};

const ExpandListPanel: React.FunctionComponent<ExpandPanelProps> = (props) => {
  const { isDefaultOpen = true, title, children, isGlobalOpened, isHeadingItem = false, level } = props;

  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  const isComposedOpened = useMemo(() => {
    return isGlobalOpened || isOpen;
  }, [isGlobalOpened, isOpen]);

  const toggleCollapse = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isGlobalOpened) {
      setIsOpen(!isOpen);
      event.stopPropagation();
    }
  };

  return (
    <div
      className={classnames("expand_list_panel", { opened: isComposedOpened, headingItem: isHeadingItem })}
      onClick={toggleCollapse}
    >
      <div className={classnames("expand_list_panel_header", getClassByLevel(level))}>
        <div
          className={classnames("expand_list_panel_header_text", {
            opened: isComposedOpened,
            headingItem: isHeadingItem,
          })}
        >
          {title}
        </div>
        {!isGlobalOpened && (
          <div
            className={classnames("expand_list_panel_header_arrow", { collapsed: !isComposedOpened })}
            onClick={toggleCollapse}
          />
        )}
      </div>
      <div className={classnames("bottom-line")} />
      <div className={classnames("expand_list_panel_content", { open: isComposedOpened })}>
        <div className="expand_list_panel_body" onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ExpandListPanel;
