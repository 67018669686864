import React, { useRef, useEffect, useState } from "react";

import { Overlay } from "../../../../shared/components";
import "./index.scss";
import { Company, Profile, Role } from "../../../../shared/models";
import { EditUserForm } from "../EditUserForm";

const DEFAULT_USER_IMAGE = "/icons/general/profile_image.svg";

interface EditUserProps {
  showHideUnsavedChangeModal: Function;
  submitUser: Function;
  user: Profile;
  roles: Role[];
  companies: Company[];
}

// eslint-disable-next-line
type FormRefType = RefObject<any>;

const EditUserSideout: React.FunctionComponent<EditUserProps> = (props) => {
  const { showHideUnsavedChangeModal, user, companies, roles, submitUser } = props;
  const [isFormChanged, setFormChanged] = useState(false);
  const [isFormDirty, setFormDirty] = useState(false);
  const formRef = useRef<HTMLFormElement>();

  const handleEditFormSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const isSuperadmin = !!user?.profileRoles?.find((profileRole) => profileRole.isActive && profileRole.roleId === 1);

  useEffect(() => {
    if (formRef.current && isFormChanged) {
      setFormDirty(formRef.current.dirty);
    }
    setFormChanged(false);
  }, [formRef, isFormChanged, setFormChanged]);

  return (
    <Overlay onClose={() => showHideUnsavedChangeModal(isFormDirty)}>
      <div className="edit-user-wrapper">
        <div className="edit-user-header">
          <div className="heading">View User</div>
        </div>
        <div className="edit-user-content">
          <div className="user-info">
            <div className="user-img">
              <img src={user.imageUrl || DEFAULT_USER_IMAGE} alt="user" />
            </div>
            <div className="user-name">
              {`${user.firstName} ${user.lastName}`}
              <div className="user-title">{user?.profileDetails?.title}</div>
            </div>
            <div className="user-status">{user.isActive ? "Active" : "Inactive"}</div>
          </div>
          <div className="split-line" />
          <div className="user-contact-info">
            <div className="contact-info-line">
              <div className="contact-info-icon">
                <img src="/icons/general/blue-location.svg" alt="location" />
              </div>
              <div className="contact-info-content">
                {user?.profileDetails?.address1}
                {user?.profileDetails?.city ? ", " + user?.profileDetails?.city : ""}
                {user?.profileDetails?.state ? ", " + user?.profileDetails?.state : ""}
              </div>
            </div>
            <div className="contact-info-line">
              <div className="contact-info-icon">
                <img src="/icons/general/blue-phone.svg" alt="phone" />
              </div>
              <div className="contact-info-content">
                {user?.profileDetails?.phone}
                {user?.profileDetails?.mobilePhone ? ", " + user?.profileDetails?.mobilePhone : ""}
              </div>
            </div>
            <div className="contact-info-line">
              <div className="contact-info-icon">
                <img src="/icons/general/blue-letter.svg" alt="email" />
              </div>
              <div className="contact-info-content">{user.email}</div>
            </div>
          </div>
          <div className="split-line" />
          <div className="user-roles">
            {isSuperadmin ? (
              <div className="user-roles-title">Super Admin access</div>
            ) : (
              <>
                <div className="user-roles-title">Access To</div>
                {!user.profileRoles || !user.profileRoles.length ? (
                  <div className="no-companies-and-plants">
                    <span>
                      No Companies/Plants Selected.
                      <br /> Please contact Admin.
                    </span>
                  </div>
                ) : (
                  <EditUserForm
                    formRef={formRef as FormRefType}
                    user={user}
                    roles={roles}
                    companies={companies}
                    submitUser={submitUser}
                    onChangeForm={() => setFormChanged(true)}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className="edit-user-actions">
          <div className="edit-user-button-panel">
            <div className="actions-block">
              <button
                className="action-button action-button-cancel"
                onClick={() => showHideUnsavedChangeModal(isFormDirty)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="action-button action-button-save"
                disabled={!isFormDirty}
                onClick={handleEditFormSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </Overlay>
  );
};

export default EditUserSideout;
