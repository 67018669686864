import { Reducer } from "redux";
import produce from "immer";
import { ActionTypes } from "./constants";
import { Image } from "../../../shared/models";

export interface ImagesState {
  images: Image[];
  count: number;
  loading: boolean;
}

const initialState: ImagesState = {
  images: [],
  count: 0,
  loading: false,
};

const reducer: Reducer<ImagesState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_IMAGES: {
      return produce(state, (nextState) => {
        nextState.loading = true;
      });
    }
    case ActionTypes.GET_IMAGES_SUCCESS: {
      return produce(state, (nextState) => {
        const { images, count } = action.payload;
        nextState.images = [...state.images].concat(images);
        nextState.count = count;
        nextState.loading = false;
      });
    }

    case ActionTypes.CLEAR_IMAGES: {
      return produce(state, (nextState) => {
        nextState.images = [];
        nextState.count = 0;
      });
    }

    case ActionTypes.REVIEW_IMAGE_SUCCESS:
      const { images } = action.payload;
      return produce(state, (nextState) => {
        nextState.images = state.images.map((image) => {
          const index = images.findIndex((mi: Image) => mi.id === image.id);
          return index === -1 ? image : images[index];
        });
      });

    default:
      return state;
  }
};

export default reducer;
