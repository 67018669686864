import React, { useEffect, useState } from "react";
import classnames from "classnames";

import { ORDER_TYPES } from "../../../shared/constants";
import { SearchInput, Select } from "../../../shared/formComponents/common";
import { Company, Profile } from "../../../shared/models";

interface UsersHeaderProps {
  profiles: Profile[];
  companies: Company[];
  sortOrder: ORDER_TYPES;
  searchString: string;
  searchCompanyId: string | undefined;
  searchPlantId: string | undefined;
  toggleSortOrder: () => void;
  setSearchPlantId: (option: { value: string }) => void;
  setSearchCompanyId: (option: { value: string }, clearPlant: boolean) => void;
  setSearchString: (search: string) => void;
}

interface SearchOptions {
  label: string;
  value: string;
}

const UsersHeader: React.FunctionComponent<UsersHeaderProps> = (props) => {
  const {
    companies,
    profiles,
    searchCompanyId,
    searchPlantId,
    sortOrder,
    searchString,
    toggleSortOrder,
    setSearchPlantId,
    setSearchCompanyId,
    setSearchString,
  } = props;

  const [companiesSearchOptions, setCompaniesSearchOptions] = useState<SearchOptions[]>([]);
  const [plantsSearchOptions, setPlantsSearchOptions] = useState<SearchOptions[]>([]);

  useEffect(() => {
    if (searchPlantId) {
      const companyId = companies
        .map((company) => company.plants)
        .flat()
        .find((plant) => String(plant?.id || 0) === searchPlantId)?.companyId;

      if (companyId) {
        setSearchCompanyId({ value: String(companyId) }, false);
      } else {
        setSearchCompanyId({ value: "" }, true);
      }
    }
    if (companies?.length && profiles.length && !companiesSearchOptions.length && !plantsSearchOptions.length) {
      const { companiesMap, plantsMap } = getCompaniesPlantsMaps(companies);
      profiles.forEach((profile) => {
        profile?.profileRoles?.forEach((role) => {
          if (role.companyId) {
            const company = companiesMap.get(role.companyId);
            if (company && !company.isActual) {
              companiesMap.set(role.companyId, { ...company, isActual: true });
            }
          } else {
            companies.forEach((company) =>
              companiesMap.set(company.id || 0, { name: String(company.displayName), isActual: true }),
            );
          }
          if (role.plantId) {
            const plant = plantsMap.get(role.plantId);
            if (plant && !plant.isActual) {
              plantsMap.set(role.plantId, { ...plant, isActual: true });
            }
          } else {
            companies
              .map((company) => company.plants)
              .flat()
              .forEach((plant) => plantsMap.set(plant?.id || 0, { name: String(plant?.displayName), isActual: true }));
          }
        });
      });

      const newCompaniesSearchOptions = getActualOptions(companiesMap);
      if (newCompaniesSearchOptions.length !== companiesSearchOptions.length) {
        setCompaniesSearchOptions(newCompaniesSearchOptions);
      }
      const newPlantsSearchOptions = getActualOptions(plantsMap);
      if (newPlantsSearchOptions.length !== plantsSearchOptions.length) {
        setPlantsSearchOptions(newPlantsSearchOptions);
      }
    }
  }, [
    companies,
    profiles,
    companiesSearchOptions,
    plantsSearchOptions,
    searchCompanyId,
    searchPlantId,
    setSearchCompanyId,
    setSearchPlantId,
  ]);

  const getActualOptions = (
    optionsMap: Map<
      number,
      {
        name: string;
        isActual: boolean;
      }
    >,
  ): SearchOptions[] => {
    const options: SearchOptions[] = [];
    optionsMap.forEach(({ name, isActual }, id) => {
      if (isActual) {
        options.push({ value: id.toString(), label: name });
      }
    });

    return options;
  };

  const getCompaniesPlantsMaps = (companies: Company[]) => {
    const companiesMap = new Map<number, { name: string; isActual: boolean }>();
    const plantsMap = new Map<number, { name: string; isActual: boolean }>();

    companies.forEach((company) => {
      if (company.id && company.displayName) {
        if (!companiesMap.get(company.id)) {
          companiesMap.set(company.id, { name: company.displayName, isActual: false });
        }
      }

      company.plants?.forEach((plant) => {
        if (plant.id && plant.displayName) {
          if (!plantsMap.get(plant.id)) {
            plantsMap.set(plant.id, { name: plant.displayName, isActual: false });
          }
        }
      });
    });

    return { companiesMap, plantsMap };
  };

  return (
    <div className="users-list-header">
      <div className="users-list-title">Users</div>
      <div className="filter-right-block">
        <div className="filter plant-list-block">
          <Select
            options={companiesSearchOptions}
            className="select"
            components={{
              IndicatorSeparator: () => null,
            }}
            placeholder="All Companies"
            onChange={(option) =>
              setSearchCompanyId(
                option as {
                  value: string;
                },
                true,
              )
            }
            isClearable
            value={companiesSearchOptions.filter((option) => option.value === searchCompanyId) || {}}
          />
        </div>
        <div className="filter plant-list-block">
          <Select
            options={plantsSearchOptions}
            className="select"
            components={{
              IndicatorSeparator: () => null,
            }}
            placeholder="All Plants"
            isClearable
            onChange={(option) =>
              setSearchPlantId(
                option as {
                  value: string;
                },
              )
            }
            value={plantsSearchOptions.filter((option) => option.value === searchPlantId) || {}}
          />
        </div>
        <div className="filter search-block">
          <SearchInput onChangeSearch={setSearchString} placeholder="Search" value={searchString} />
        </div>
        <div className="filter sort-order-block no-select">
          <div onClick={toggleSortOrder}>
            {sortOrder === ORDER_TYPES.DESC ? "Z-A" : "A-Z"}
            <span className={classnames("icon sort-order", { "v-flip": sortOrder === ORDER_TYPES.ASC })} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersHeader;
