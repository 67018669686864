import React, { useCallback, useState } from "react";
import { SlideIndicator } from "../..";
import { TutorialNavigation } from "../../components";
import { useDispatch } from "react-redux";
import { actions } from "../../../Users/store";
import classNames from "./tutorial.module.scss";

const slides = [
  {
    image: "/images/tutorial/slide1.png",
    title: "Field Notes",
    text: `The field note section allows you\nto see all of your past field notes`,
  },
  {
    image: "/images/tutorial/slide2.png",
    title: "Documents",
    text: `The document section houses all documents\nrelated to the selected plant`,
  },
  {
    image: "/images/tutorial/slide3.png",
    title: "Collaborators",
    text: `The section shows the co-workers with whom\nyou can collaborate on the field notes`,
  },
  {
    image: "/images/tutorial/slide4.png",
    title: "Plant Selection",
    text: `Click on the Plant icon to see\nplants you can access`,
  },
  {
    image: "/images/tutorial/slide5.png",
    title: "Aging Management Field Guide",
    text: `Click on the booklet icon to open\nthe Aging Management Field Guide section`,
  },
  {
    image: "/images/tutorial/slide6.png",
    title: "Add a note",
    text: `Click on the plus icon\nto create a new field note`,
  },
];

const Tutorial = () => {
  const [slideNumber, setSlideNumber] = useState(0);
  const dispatch = useDispatch();

  const handlePrevious = useCallback(() => {
    setSlideNumber(slideNumber - 1);
  }, [slideNumber]);
  const handleNext = useCallback(() => {
    setSlideNumber(slideNumber + 1);
  }, [slideNumber]);
  const handleFinish = useCallback(() => {
    dispatch(actions.markTutorialCompleted.request());
  }, [dispatch]);

  return (
    <div className={classNames.tutorialWrapper}>
      <div className={classNames.body}>
        <div className={classNames.content}>
          <div className={classNames.slide}>
            <img
              className={classNames.screenShot}
              width={754}
              height={462}
              src={slides[slideNumber].image}
              alt={slides[slideNumber].title}
            />
            <img
              className={classNames.frame}
              width={794}
              height={502}
              src="/images/tutorial/slide.svg"
              alt={slides[slideNumber].title}
            />
          </div>
          <div className={classNames.indicator}>
            <SlideIndicator currentIndex={slideNumber} totalCount={slides.length}></SlideIndicator>
          </div>
          <div className={classNames.title}>{slides[slideNumber].title}</div>
          <div className={classNames.separator}></div>
          <div className={classNames.text}>
            {slides[slideNumber].text.split("\n").map((text, index) => (
              <div key={index}>{text}</div>
            ))}
          </div>
          <div className={classNames.buttons}>
            <TutorialNavigation
              onFinish={handleFinish}
              onNext={handleNext}
              onPrevious={handlePrevious}
              showFinish={slideNumber + 1 === slides.length}
              showNext={slideNumber + 1 !== slides.length}
              showPrevious={slideNumber !== 0}
            ></TutorialNavigation>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tutorial;
