import React, { useRef } from "react";
import classes from "./BinaryImage.module.scss";
import classnames from "classnames";
import { useBinaryUrl } from "../../hooks/UseBinaryUrlHook";
import useOnScreen from "../../hooks/OnScreenHook";

/**
 * gets binary image
 */

interface BinaryImageProps {
  alt: string;
  uid?: string;
  errorImg?: string;
  placeholderImg?: string;
  onLoad?: Function;
  isLazy?: boolean;
}

const BinaryImage: React.FunctionComponent<BinaryImageProps> = (props) => {
  const {
    alt,
    uid,
    isLazy = false,
    errorImg = "/icons/general/no-image.png",
    placeholderImg = "/icons/general/image_empty.svg",
    onLoad,
  } = props;

  const ref = useRef<HTMLImageElement>(null);
  const isVisible = useOnScreen(ref);

  const { binary, errorSrc, loaded, handleLoad, handleError } = useBinaryUrl({
    binaryType: "image",
    isVisible,
    isLazy,
    uid,
    errorImg,
    onLoad,
  });

  return (
    <img
      ref={ref}
      className={classnames([classes.binary_image, { [classes.loading]: !binary || !loaded }])}
      src={errorSrc || binary || placeholderImg}
      onLoad={handleLoad}
      onError={handleError}
      alt={alt}
    />
  );
};

export default BinaryImage;
