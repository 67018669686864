import React, { useMemo } from "react";
import classnames from "classnames";

import classNames from "./notificationItem.module.scss";
import { formatDateAm } from "../../../../shared/utils";
import { NotificationHistory } from "../../../../shared/models";
import { NOTIFICATION_TYPES } from "../../../../shared/constants";

interface NotificationItemProps {
  onItemClick: (notificationHistory: NotificationHistory) => void;
  notificationHistory: NotificationHistory;
}

const NotificationItem: React.FunctionComponent<NotificationItemProps> = (props) => {
  const { notificationHistory, onItemClick } = props;

  const displayName = useMemo(() => {
    if (
      [NOTIFICATION_TYPES.REJECT_IMAGE, NOTIFICATION_TYPES.REMOVE_IMAGE_REMINDER].includes(notificationHistory.type)
    ) {
      return notificationHistory.instance?.fieldNote?.plant?.company?.displayName;
    } else {
      return notificationHistory.instance?.fieldNote?.plant?.displayName;
    }
  }, [notificationHistory]);

  return (
    <div
      className={classnames(classNames.notificationItem, { [classNames.viewed]: notificationHistory.isViewed })}
      onClick={() => onItemClick(notificationHistory)}
    >
      <div className={classNames.notificationHeader}>
        <div>
          <img src="/icons/general/plant.svg" alt="plant" />
          {displayName}
        </div>
        <div>{formatDateAm(new Date(notificationHistory.createdAt))}</div>
      </div>
      <div className={classNames.notificationText}>{notificationHistory.text}</div>
    </div>
  );
};
export default NotificationItem;
