import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { ATTACHMENT_TYPES_MAP, PERMISSION } from "../../../../../../shared/constants";
import { Attachment, Instance, Plant, RemoveFileType } from "../../../../../../shared/models";
import { mapToDefaultAttachmentTypes } from "../../../../../../shared/utils/attachmentFiles";
import { AccessControl, AppLoader, AttachmentActions, BinaryVideo } from "../../../../../../shared/components";
import { BinaryImage } from "../../../../../../shared/components/BinaryImage";
import { AttachmentUploadValue } from "../../../../../../shared/formComponents/common";
import { setUpdateLayout } from "../../../../../../shared/store/actions";
import MasonryLayout from "../../../../../../shared/components/MasonryLayout/MasonryLayout";

import "./instanceAttachments.scss";

interface InstanceAttachmentsProps {
  instance?: Instance;
  plant: Plant | null;
  isPhotoUpdating: boolean;
  setFileToEdit: (image: AttachmentUploadValue & { label: string }) => void;
  setFileToDelete: (file: RemoveFileType) => void;
}

const getLengthLabel = (attachments?: Attachment[]) => {
  const imagesCount = attachments?.length || 0;
  return `${imagesCount} attachment${imagesCount ? "s" : ""}`;
};

const InstanceAttachments: React.FunctionComponent<InstanceAttachmentsProps> = (props) => {
  const { instance, setFileToEdit, setFileToDelete, plant, isPhotoUpdating } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);

  const handleAttachmentLoad = useCallback(() => {
    setLoading(false);
    dispatch(setUpdateLayout(true));
  }, [dispatch, setLoading]);

  useEffect(() => {
    if (instance && !instance.attachments.length) {
      setLoading(false);
    }
  }, [instance]);

  const content = useMemo(() => {
    return mapToDefaultAttachmentTypes(instance?.attachments).map((attachment, index) => {
      const isImage = attachment.type === "image";

      const label = `
      ${index !== undefined && index < 5 ? "Photo " : ""}
      ${index !== undefined ? index + 1 : ""} - ${ATTACHMENT_TYPES_MAP[attachment.imageType].label}`;

      const handleEdit = () => setFileToEdit({ ...attachment, label });
      return (
        <div className="attachment" key={`${attachment.id}_${attachment.imageType}`}>
          <div className="attachment_label">{label}</div>
          <div className="attachment_container">
            {attachment?.voiceNote && (
              <div className="attachment_container_audio">
                <img src="/icons/general/speaker.svg" alt="has audio file" />
              </div>
            )}
            {attachment.key ? (
              isImage ? (
                <BinaryImage
                  uid={attachment.key}
                  alt="instance"
                  placeholderImg={"/icons/general/image_empty.svg"}
                  onLoad={handleAttachmentLoad}
                />
              ) : (
                <BinaryVideo uid={attachment.key} onLoad={handleAttachmentLoad} />
              )
            ) : loading ? (
              <AppLoader />
            ) : (
              <img
                className="attachment_default"
                src="/icons/general/image_empty.svg"
                alt="empty instance"
                onLoad={handleAttachmentLoad}
              />
            )}
            <AccessControl permissions={[PERMISSION.INSTANCE_EDIT]} plant={plant} instance={instance}>
              <div className="attachment_container_actions">
                <div className="attachment_container_actions_wrapper">
                  {attachment.key && (
                    <AttachmentActions
                      className="actions"
                      actions={!isImage ? ["delete"] : undefined}
                      onDelete={() => attachment.id && setFileToDelete({ id: attachment.id, type: attachment.type })}
                      onEdit={handleEdit}
                    />
                  )}
                </div>
              </div>
            </AccessControl>
          </div>
        </div>
      );
    });
  }, [handleAttachmentLoad, instance, plant, setFileToDelete, setFileToEdit, loading]);

  return (
    <>
      <div className="instance_view_content_main_label">{getLengthLabel(instance?.attachments)}</div>
      <div className="instance_view_content_main_attachments">
        {isPhotoUpdating && <AppLoader isBlurred />}
        <MasonryLayout>{content}</MasonryLayout>
      </div>
    </>
  );
};

export default InstanceAttachments;
