import produce from "immer";
import { Action, createReducer } from "typesafe-actions";

import { tokenHandler } from "../../../shared/utils";
import { AuthStateType } from "../interfaces";
import * as actions from "./actions";

const initialState: AuthStateType = {
  authorized: !!tokenHandler.getAccessToken(),
  forgotPasswordSubmitted: false,
  user_hash: null,
};

const reducer = createReducer<AuthStateType, Action>(initialState)
  .handleAction(actions.login.success, (state) =>
    produce(state, (nextState) => {
      nextState.authorized = true;
    }),
  )
  .handleAction(actions.epriLoginStatus.success, (state) =>
    produce(state, (nextState) => {
      nextState.authorized = true;
    }),
  )
  .handleAction(actions.requestPassword.success, (state) =>
    produce(state, (nextState) => {
      nextState.forgotPasswordSubmitted = true;
    }),
  )
  .handleAction(actions.submitPassword.success, (state) =>
    produce(state, (nextState) => {
      nextState.authorized = true;
    }),
  )
  .handleAction(actions.logout.success, (state) =>
    produce(state, (nextState) => {
      nextState.authorized = false;
    }),
  );

export default reducer;
