import React, { useContext, useMemo } from "react";
import classnames from "classnames";
import {
  IInstanceViewContainerContext,
  InstanceViewContainerContext,
} from "../../../containers/Instances/containers/InstanceFormContainer";
import "./attachmentActions.scss";

type Actions = "delete" | "edit";

interface AttachmentActionsProps {
  actions?: Actions[];
  onEdit: () => void;
  onDelete: () => void;
  className?: string;
}

export const defaultAttachmentActions: Actions[] = ["edit", "delete"];
const AttachmentActions: React.FC<AttachmentActionsProps> = ({
  actions = defaultAttachmentActions,
  className,
  onEdit,
  onDelete,
}) => {
  const { isEditingInstance = false }: IInstanceViewContainerContext = useContext(InstanceViewContainerContext);

  const availableActions = useMemo(() => (isEditingInstance ? ["delete"] : actions), [isEditingInstance, actions]);

  return (
    <div className={classnames("attachment_actions", className)}>
      {availableActions.map((act) => (
        <div key={act} className={`${act}_btn action_btn`} onClick={act === "edit" ? onEdit : onDelete} />
      ))}
    </div>
  );
};

export default AttachmentActions;
