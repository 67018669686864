import {
  ChunkedImage,
  ComponentPropertyRequest,
  Image,
  ComponentPropertyType,
  InstanceComponentProperties,
  InstanceUpdateFormValues,
  Instance,
} from "../models";
import { AttachmentUploadValue } from "../formComponents/common/AttachmentsUpload";
import { notEmpty, notEmptyArray } from "../utils";
import { ATTACHMENT_TYPES_MAP, ATTACHMENT_TYPES } from "../constants";

export const mapInstanceImagesToChunkedRequest = (
  images: AttachmentUploadValue[],
  initialPhotos?: Image[],
): { imagesToUpload: ChunkedImage[]; imagesToDelete: number[] } => {
  const imagesToUpload: ChunkedImage[] = [];
  const imagesToDelete: number[] = [];
  let currentPosition = 0;
  const imagesWithPositions = images.map((image) => {
    if (image.imageType === ATTACHMENT_TYPES.ATTACHMENT) {
      if (image.positionIndex !== undefined && image.positionIndex !== null) {
        currentPosition = image.positionIndex + 1;
      } else {
        return { ...image, positionIndex: currentPosition++ };
      }
    }
    return image;
  });

  imagesWithPositions.forEach(({ imageType, src, srcAnnotated, id, positionIndex }) => {
    if (!id && !!src) {
      imagesToUpload.push({
        src: src || undefined,
        srcAnnotated: srcAnnotated || undefined,
        imageTypeId: Object.values(ATTACHMENT_TYPES_MAP)[imageType].id,
        positionIndex,
      });
    }
  });

  initialPhotos?.forEach((initialImage) => {
    const alreadySavedImage = imagesWithPositions.find((image) => image.id === initialImage.id);
    if (!alreadySavedImage) {
      imagesToDelete.push(initialImage.id);
    } else {
      if (alreadySavedImage.src !== initialImage.key || !!alreadySavedImage.srcAnnotated) {
        imagesToUpload.push({
          id: alreadySavedImage.id,
          ...(alreadySavedImage.srcAnnotated
            ? { srcAnnotated: alreadySavedImage.srcAnnotated }
            : { src: alreadySavedImage.src }),
          imageTypeId: Object.values(ATTACHMENT_TYPES_MAP)[alreadySavedImage.imageType].id,
          positionIndex: alreadySavedImage.positionIndex,
        });
      }
    }
  });

  return { imagesToUpload, imagesToDelete };
};

export const mapConditionOptionsForm = (
  componentEnvironmentIds: string[],
  componentDisciplineId?: string,
  componentFormId?: string,
  componentTypeId?: string,
): ComponentPropertyRequest[] => {
  return [
    ...(componentDisciplineId
      ? [{ type: ComponentPropertyType.discipline, value: Number(componentDisciplineId) }]
      : []),
    ...(componentFormId ? [{ type: ComponentPropertyType.material, value: Number(componentFormId) }] : []),
    ...(componentTypeId ? [{ type: ComponentPropertyType.materialType, value: Number(componentTypeId) }] : []),
    ...componentEnvironmentIds.map((id) => ({ type: ComponentPropertyType.environment, value: Number(id) })),
  ];
};

export const mapComponentPropertiesToString = (instanceComponentProperties: InstanceComponentProperties[]): string => {
  const materialName = instanceComponentProperties.find(({ type }) => type === ComponentPropertyType.material)?.name;
  const disciplineName = instanceComponentProperties.find(({ type }) => type === ComponentPropertyType.discipline)
    ?.name;
  const typeName = instanceComponentProperties.find(({ type }) => type === ComponentPropertyType.materialType)?.name;
  const environmentNames = instanceComponentProperties
    .filter(({ type }) => type === ComponentPropertyType.environment)
    .map(({ name }) => name);

  return [materialName, typeName, disciplineName, ...environmentNames].filter(notEmpty).join(", ");
};

export const getVideosFormData = (instance: InstanceUpdateFormValues, oldInstance?: Instance) => {
  const videosForm = new FormData();
  videosForm.append("instanceId", instance.id.toString());

  const allVideos = instance.attachments.filter((att) => att.type === "video");
  allVideos.forEach(({ file, positionIndex, thumbnail }) => {
    if (file) {
      videosForm.append("videos[]", file);
      videosForm.append("indexes[]", String(positionIndex || 0));
      thumbnail && videosForm.append("thumbnails[]", thumbnail);
    }
  });
  if (!oldInstance) {
    return videosForm;
  }
  const oldVideos = oldInstance.attachments.filter((att) => att.type === "video");
  const newVideoIds = allVideos?.map((att) => att.id).filter(notEmptyArray);
  const oldVideoIds = oldVideos.map((att) => att.id) || [];
  const deleteVideoIds = oldVideoIds.filter((id) => !newVideoIds.includes(id));
  deleteVideoIds.forEach((id) => videosForm.append("deleteVideoIds[]", String(id)));

  return videosForm;
};
