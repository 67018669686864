import React, { useEffect, useCallback, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";

import { ConditionOptionsRequestParams, RouteComponentProps } from "../../../../shared/interfaces";
import {
  ComponentPropertyRequest,
  ComponentPropertyType,
  ConditionOption,
  InstanceFormValues,
} from "../../../../shared/models";
import { ROUTE_PATHS } from "../../../../shared/routes";
import { FormikChangeHandler } from "../../../../shared/formComponents/common";
import { ExpandPanel, InstanceHeader, Modal, RemoveModalContent, AppLoader } from "../../../../shared/components";
import { useConditionOptions, useDebounce, useLoader } from "../../../../shared/hooks";
import { mapConditionOptionsForm } from "../../../../shared/mappers";
import { FormGenerator } from "../../../../shared/formComponents";
import { notEmpty } from "../../../../shared/utils";

import { selectors as fieldNotesSelectors, actions as fieldNotesActions } from "../../../FieldNotes/store";
import { actions, selectors } from "../../store";
import {
  mapFieldsList,
  InstanceFormFields,
  validationSchema,
  prepareFormValues,
  FieldsListType,
  isValuesChanged,
} from "./formHelpers";
import { LOADERS_NAMES } from "../../../../shared/constants";
import MasonryLayout from "../../../../shared/components/MasonryLayout/MasonryLayout";

import "./instanceForm.scss";

interface InstanceFormContainerProps extends RouteComponentProps<{ id?: number; fieldNoteId: number }> {}

export interface IInstanceViewContainerContext {
  isEditingInstance: boolean;
}

export const InstanceViewContainerContext = React.createContext({ isEditingInstance: false });

const InstanceFormContainer: React.FunctionComponent<InstanceFormContainerProps> = (props) => {
  const {
    match: {
      params: { id: instanceId, fieldNoteId },
    },
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    getDisciplines,
    getEnvironments,
    getMaterials,
    getTypes,
    getRedirects,
    typesOptions: savedTypesOptions,
    disciplinesOptions: savedDisciplinesOptions,
    materialsOptions: savedMaterialsOptions,
    environmentsOptions: savedEnvironmentsOptions,
  } = useConditionOptions();

  const { isLoading, AppLoader: EditLoader } = useLoader({
    name: LOADERS_NAMES.INSTANCE_CREATE_EDIT_LOADER,
    actionTypes: [actions.updateInstance, actions.createInstance, actions.getInstance],
  });
  const [isComponentDisciplinesLoading, setIsComponentDisciplinesLoading] = useState(false);
  const [isComponentFormLoading, setIsComponentFormLoading] = useState(false);
  const [isComponentEnvironmentsLoading, setIsComponentEnvironmentsLoading] = useState(false);
  const [isInitialOptionsLoading, setIsInitialOptionsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(prepareFormValues());
  const [fieldsList, setFieldsList] = useState<FieldsListType | undefined>(undefined);
  const [isLeaveOpened, setIsLeaveOpened] = useState(false);

  const debouncedIsOptionsLoading = useDebounce(
    isComponentDisciplinesLoading || isComponentFormLoading || isComponentEnvironmentsLoading,
    300,
  );
  const currentFieldNote = useSelector(fieldNotesSelectors.getCurrentFieldNote());
  const instance = useSelector(selectors.getInstance());

  const redirectToInstancesPage = useCallback(() => {
    if (currentFieldNote) {
      history.push(
        generatePath(ROUTE_PATHS.INSTANCES_DASHBOARD, {
          fieldNoteId: String(currentFieldNote.id),
          plantId: String(currentFieldNote.plantId),
        }),
      );
    }
  }, [history, currentFieldNote]);

  const redirectToInstancesViewPage = useCallback(
    (instanceId: number) => {
      if (currentFieldNote) {
        history.push(
          generatePath(ROUTE_PATHS.INSTANCE_VIEW, {
            id: instanceId,
            fieldNoteId: String(currentFieldNote.id),
            plantId: String(currentFieldNote.plantId),
          }),
        );
      }
    },
    [history, currentFieldNote],
  );

  useEffect(() => {
    if (instanceId) {
      dispatch(actions.getInstance.request(instanceId));
    }
  }, [instanceId, dispatch]);

  useEffect(() => {
    if (!instanceId) {
      (async () => {
        const disciplinesOptions = await getDisciplines({});
        setIsInitialOptionsLoading(false);
        setFieldsList(mapFieldsList(disciplinesOptions, [], [], []));
      })();
    }
  }, [instanceId, getDisciplines]);

  useEffect(() => {
    if (fieldNoteId) {
      dispatch(fieldNotesActions.getFieldNote.request(fieldNoteId));
    }
  }, [dispatch, fieldNoteId]);

  useEffect(() => {
    //init upload if editing mode
    (async function fetchData() {
      if (instance && instanceId) {
        const material = instance.instanceComponentProperties
          .find(({ type }) => type === ComponentPropertyType.material)
          ?.value.toString();
        const discipline = instance.instanceComponentProperties
          .find(({ type }) => type === ComponentPropertyType.discipline)
          ?.value.toString();
        const environments = instance.instanceComponentProperties
          .filter(({ type }) => type === ComponentPropertyType.environment)
          .map(({ value }) => value.toString());

        let [
          disciplinesOptions,
          environmentsOptions,
          formsOptions,
          typesOptions,
          environmentsOptionsByMaterial,
        ] = await Promise.all([
          getDisciplines({}),
          getEnvironments({
            disciplines: [...[discipline].filter(notEmpty)],
          }),
          getMaterials({
            disciplines: [...[discipline].filter(notEmpty)],
            environments: [...environments.filter(notEmpty)],
          }),
          getTypes({
            disciplines: [...[discipline].filter(notEmpty)],
            materials: [...[material].filter(notEmpty)],
            environments: [...environments.filter(notEmpty)],
          }),
          getEnvironments(
            {
              materials: [...[material].filter(notEmpty)],
            },
            true,
          ),
        ]);

        environmentsOptions = environmentsOptions.map((environmentsOption) => {
          if (!environmentsOptionsByMaterial.some(({ id }) => id === environmentsOption.id)) {
            return { ...environmentsOption, isSecondary: true };
          }
          return { ...environmentsOption, isSecondary: false };
        });
        setIsInitialOptionsLoading(false);
        setFieldsList(mapFieldsList(disciplinesOptions, formsOptions, typesOptions, environmentsOptions));
        setInitialValues(prepareFormValues(instance, environmentsOptions, !typesOptions.length, !formsOptions.length));
      }
    })();
  }, [getDisciplines, getEnvironments, instance, getTypes, getMaterials, instanceId]);

  const handleSelectChange = async (
    changedValueName: keyof InstanceFormFields,
    values: InstanceFormFields,
    formikHelpers: {
      // eslint-disable-next-line
      setFieldValue: (field: string, value: any) => void;
      // eslint-disable-next-line
      setValues: (values: { [key: string]: any }, shouldValidate?: boolean | undefined) => void;
    },
  ) => {
    if (!savedDisciplinesOptions.length) {
      return;
    }

    const { setFieldValue, setValues } = formikHelpers;

    const disciplinesOptions: ConditionOption[] = savedDisciplinesOptions;
    let environmentsOptions: (ConditionOption & { isSecondary?: boolean })[] = savedEnvironmentsOptions;

    let typesOptions: ConditionOption[] = savedTypesOptions;
    let formsOptions: ConditionOption[] = savedMaterialsOptions;

    const isMetalDiscipline = values.componentDisciplineId
      ? disciplinesOptions.find((option) => option.id === Number(values.componentDisciplineId))?.name === "Metal"
      : false;
    const isElectricalDiscipline = values.componentDisciplineId
      ? disciplinesOptions.find((option) => option.id === Number(values.componentDisciplineId))?.name === "Electrical"
      : false;

    let areEnvironmentsDisabled = isElectricalDiscipline && !values.componentFormId;

    switch (changedValueName) {
      case "componentDisciplineId": {
        const params: ConditionOptionsRequestParams = {
          disciplines: [...[values.componentDisciplineId].filter(notEmpty)],
        };

        setIsComponentDisciplinesLoading(true);
        [environmentsOptions, typesOptions, formsOptions] = await Promise.all([
          getEnvironments(params),
          getTypes(params),
          getMaterials(params),
        ]);

        setIsComponentDisciplinesLoading(false);

        if (isElectricalDiscipline) {
          setValues({
            ...values,
            componentEnvironmentIds: [],
            componentTypeId: undefined,
            componentFormId: undefined,
            redirectErrors: [],
          });
        } else {
          setFieldValue("componentEnvironmentIds", []);
          setFieldValue("componentTypeId", undefined);
          setFieldValue("componentFormId", undefined);
          setFieldValue("redirectErrors", []);
        }

        areEnvironmentsDisabled = isElectricalDiscipline;
        break;
      }
      case "componentFormId": {
        const params: ConditionOptionsRequestParams = {
          disciplines: [...[values.componentDisciplineId].filter(notEmpty)],
          materials: [...[values.componentFormId].filter(notEmpty)],
        };
        setIsComponentFormLoading(true);
        const [typesOptions, environmentsOptionsByMaterial, redirectOptions] = await Promise.all([
          getTypes(params),
          params.materials?.length ? getEnvironments(params) : [],
          params.materials?.length
            ? getRedirects({ ...params, materialTypes: [...[values.componentTypeId].filter(notEmpty)] })
            : [],
        ]);
        setIsComponentFormLoading(false);

        if (values.componentFormId) {
          setFieldValue(
            "redirectErrors",
            redirectOptions.map(({ id, name }) => ({ name: id.toString(), text: name })),
          );
          environmentsOptions = environmentsOptionsByMaterial;
        } else {
          setFieldValue("redirectErrors", []);
        }

        const environmentsOptionsFormValues = values.componentEnvironmentIds.map(({ label, value }) => {
          const existedEnv = environmentsOptions.find(({ id }) => id === Number(value));
          return {
            value,
            label,
            isSecondary: !existedEnv,
          };
        });
        setFieldValue("componentEnvironmentIds", [...environmentsOptionsFormValues]);

        if (!typesOptions.some(({ id }) => id === Number(values.componentTypeId))) {
          setFieldValue("componentTypeId", undefined);
        }
        break;
      }

      case "componentTypeId": {
        const params: ConditionOptionsRequestParams = {
          disciplines: [...[values.componentDisciplineId].filter(notEmpty)],
          materials: [...[values.componentFormId].filter(notEmpty)],
          materialTypes: [...[values.componentTypeId].filter(notEmpty)],
        };
        setIsComponentFormLoading(true);
        const [redirectOptions] = await Promise.all([getRedirects(params)]);
        setIsComponentFormLoading(false);

        if (values.componentTypeId) {
          setFieldValue(
            "redirectErrors",
            redirectOptions.map(({ id, name }) => ({ name: id.toString(), text: name })),
          );
        } else {
          setFieldValue("redirectErrors", []);
        }

        break;
      }
      case "componentEnvironmentIds": {
        const params: ConditionOptionsRequestParams = {
          disciplines: [...[values.componentDisciplineId].filter(notEmpty)],
          environments: [...values.componentEnvironmentIds.map(({ value }) => value)],
        };

        setIsComponentEnvironmentsLoading(true);
        [formsOptions] = await Promise.all([getMaterials(params)]);
        setIsComponentEnvironmentsLoading(false);

        if (
          !formsOptions.some(({ id }) => id === Number(values.componentFormId)) ||
          !values.componentEnvironmentIds.length
        ) {
          if (!isElectricalDiscipline) {
            setFieldValue("componentFormId", undefined);
          } else {
            areEnvironmentsDisabled = !values.componentFormId;
          }
          setFieldValue("componentTypeId", undefined);
          setFieldValue("redirectErrors", []);
        }

        break;
      }
    }

    const isMaterialDisabled = !values.componentEnvironmentIds.length && isMetalDiscipline;
    const areTypesDisabled = !typesOptions.length || !values.componentFormId || !values.componentEnvironmentIds.length;

    setFieldValue("isComponentTypeIdEmpty", areTypesDisabled);
    setFieldValue("areEnvironmentIdsEmpty", areEnvironmentsDisabled || !environmentsOptions.length);
    setFieldValue("isComponentFormIdEmpty", !formsOptions.length || isMaterialDisabled);

    setFieldsList(
      mapFieldsList(
        disciplinesOptions,
        isMaterialDisabled ? [] : formsOptions,
        areTypesDisabled ? [] : typesOptions,
        areEnvironmentsDisabled ? [] : environmentsOptions,
      ),
    );
  };

  const handleLeavePage = () => {
    if (instanceId) {
      redirectToInstancesViewPage(instanceId);
    } else {
      redirectToInstancesPage();
    }
  };

  const handleCancelClicked = (values: InstanceFormFields) => {
    if (isValuesChanged(values, initialValues)) {
      setIsLeaveOpened(true);
    } else {
      handleLeavePage();
    }
  };

  const handleCreateInstance = (values: Omit<InstanceFormValues, "fieldNoteId">) => {
    if (currentFieldNote?.id) {
      dispatch(
        actions.createInstance.request({
          ...values,
          fieldNoteId: currentFieldNote.id,
          callback: redirectToInstancesViewPage,
        }),
      );
    }
  };

  const handleEditInstance = (id: number, values: Omit<InstanceFormValues, "fieldNoteId">) => {
    if (currentFieldNote?.id) {
      dispatch(
        actions.updateInstance.request({
          oldInstance: instance,
          ...values,
          id,
          fieldNoteId: currentFieldNote.id,
          callback: redirectToInstancesViewPage,
        }),
      );
    }
  };

  const handleSave = (formValues: InstanceFormFields) => {
    if (isLoading) {
      return;
    }

    const {
      componentDisciplineId,
      componentFormId,
      componentEnvironmentIds,
      componentTypeId,
      redirectErrors,
      isComponentFormIdEmpty,
      isComponentTypeIdEmpty,
      areEnvironmentIdsEmpty,
      ...values
    } = formValues;

    const componentProperties: ComponentPropertyRequest[] = mapConditionOptionsForm(
      componentEnvironmentIds.map(({ value }) => value),
      componentDisciplineId,
      componentFormId,
      componentTypeId,
    );

    if (values.id) {
      handleEditInstance(values.id, { ...values, componentProperties });
    } else {
      handleCreateInstance({ ...values, componentProperties });
    }
  };

  return (
    <InstanceViewContainerContext.Provider value={{ isEditingInstance: !!instanceId } as IInstanceViewContainerContext}>
      {(isLoading || isInitialOptionsLoading) && <EditLoader />}
      <Formik
        validationSchema={validationSchema}
        onSubmit={handleSave}
        initialValues={initialValues}
        enableReinitialize
      >
        {(formikProps) => {
          return (
            <FormikChangeHandler<InstanceFormFields>
              formikProps={formikProps}
              subscribeProps={[
                "componentDisciplineId",
                "componentFormId",
                "componentEnvironmentIds",
                "componentTypeId",
              ]}
              handleChange={handleSelectChange}
              skipInitUpdate={!!instanceId}
            >
              <div className="instance_form">
                <InstanceHeader
                  onBackClick={redirectToInstancesPage}
                  backLabel={currentFieldNote?.displayName}
                  headerLabel={`${!!instanceId ? "Edit" : "Create"} Instance`}
                  rightButtons={
                    <>
                      <div className="instances-cancel-button" onClick={() => handleCancelClicked(formikProps.values)}>
                        cancel
                      </div>
                      <div
                        className={classnames("instances-save-button", { disabled: !formikProps.dirty })}
                        onClick={formikProps.dirty ? formikProps.submitForm : () => {}}
                      >
                        save instance
                      </div>
                    </>
                  }
                />

                <div className="instance_form_text">
                  <p>
                    An Instance is where you and team members can add tags and findings to your photos and videos. Each
                    Instance can be shared as a PDF for future use.
                  </p>
                </div>
                {fieldsList && (
                  <div className="instance_form_panels">
                    <ExpandPanel
                      title="Name"
                      primaryText="Give this Instance a name"
                      secondaryText="Choose something that will be easily referenced by all team members"
                      isGlobalOpened={!!formikProps.errors["displayName"]}
                    >
                      <FormGenerator
                        className="instance_form_panels_form"
                        formikProps={formikProps}
                        fields={fieldsList.name}
                      />
                    </ExpandPanel>

                    <ExpandPanel
                      title="Location"
                      primaryText="Where in the plant is this Instance?"
                      isGlobalOpened={!!formikProps.errors["buildingName"] || !!formikProps.errors["elevation"]}
                    >
                      <FormGenerator
                        className="instance_form_panels_form"
                        formikProps={formikProps}
                        fields={fieldsList.location}
                      />
                    </ExpandPanel>

                    <ExpandPanel
                      title="Component Properties"
                      primaryText="Which component type are you documenting?"
                      isGlobalOpened={
                        !!formikProps.errors["nameOfComponent"] ||
                        !!formikProps.errors["componentDisciplineId"] ||
                        !!formikProps.errors["componentEnvironmentIds"] ||
                        !!formikProps.errors["componentFormId"] ||
                        !!formikProps.errors["componentTypeId"]
                      }
                    >
                      <div className="instance_form_panels_wrapper">
                        {debouncedIsOptionsLoading && <AppLoader isBlurred />}
                        <FormGenerator
                          className="instance_form_panels_form"
                          formikProps={formikProps}
                          fields={fieldsList.type}
                        />
                      </div>
                    </ExpandPanel>

                    <ExpandPanel title="Attachments">
                      <FormGenerator
                        className="instance_form_panels_form wrap"
                        formikProps={formikProps}
                        fields={fieldsList.attachments}
                        FieldsWrapper={MasonryLayout}
                      />
                    </ExpandPanel>
                  </div>
                )}
              </div>
            </FormikChangeHandler>
          );
        }}
      </Formik>
      <Modal isShowing={isLeaveOpened} onClose={() => setIsLeaveOpened(false)} boxPadding>
        <RemoveModalContent
          heading="Save Changes"
          content="Do you want to save the changes made to the Instance?"
          cancelText="Leave"
          removeText="Save"
          onClose={handleLeavePage}
          onDelete={() => setIsLeaveOpened(false)}
        />
      </Modal>
    </InstanceViewContainerContext.Provider>
  );
};

export default InstanceFormContainer;
