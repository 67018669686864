import { FieldGuideDocument } from "./FieldGuideDocument";
import { Plant } from "./Plant";

export interface Company extends Omit<CompanyRequest, "id"> {
  id: number | null;
  plants?: Plant[];
  fieldGuideDocuments?: FieldGuideDocument[] | null;
}

export interface CompanyRequest extends CompanyImagesReview {
  id?: number | null;
  displayName: string | null;
  epriid: string;
  canSyncWithCloud: boolean;
}

export type ImageReviewType = string | number | null;

export enum ImageReviewKeyTypes {
  pendingImagesReminder,
  secondPendingImagesReminder,
  pendingImagesStorageTime,
  rejectedImagesStorageTime,
}

export type CompanyImagesReview = { [key in keyof typeof ImageReviewKeyTypes]: ImageReviewType };
