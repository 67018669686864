import styled from "styled-components";

export const TextareaWrapper = styled.div`
  display: flex;
  flex-direction: column;

  textarea {
    resize: none;
    border: none;
    background: #f4f6fb;
    border-radius: 8px;
    color: black;
    padding: 10px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }

  textarea:hover,
  textarea:active,
  textarea:focus {
    outline: none;
  }

  textarea::placeholder {
    color: rgba(24, 25, 32, 0.4);
    font-size: 16px;
  }

  label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
`;
