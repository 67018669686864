import React, { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";

import { BinaryImage } from "../../../components/BinaryImage";
import MasonryLayout from "../../../components/MasonryLayout/MasonryLayout";
import { setUpdateLayout } from "../../../store/actions";
import { SimpleImagesUploadValue } from "./interfaces";

import "./simpleImagesUpload.scss";

interface SimpleImagesUploadProps {
  values?: SimpleImagesUploadValue[];
  name: string;
  setFieldValues: (field: string, values: SimpleImagesUploadValue[]) => void;
}

const emptyValue: SimpleImagesUploadValue = { src: "", file: undefined };

export const SimpleImagesUpload: React.FunctionComponent<SimpleImagesUploadProps> = (props) => {
  const { values, name, setFieldValues } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const dispatch = useDispatch();

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    const rawFile = files && files[0];
    const reader = new FileReader();

    if (rawFile) {
      reader.onload = (e) => {
        const src = e?.target?.result?.toString();

        if (src) {
          setFieldValues(name, [...(values || []), { ...emptyValue, src, file: rawFile }]);
        }
      };

      reader.readAsDataURL(rawFile);
    }

    e.target.value = "";
  };

  const handleImageSelectClick = () => {
    inputRef?.current?.click();
  };

  const handleRemoveImage = (indexToRemove: number) => {
    setFieldValues(name, [...(values?.filter((_, index) => index !== indexToRemove) || [])]);
  };
  const handleLoadImage = useCallback(() => {
    dispatch(setUpdateLayout(true));
  }, [dispatch]);

  return (
    <>
      <img
        className="simpleImage_btn"
        src="/images/dashboard/camera.svg"
        onClick={handleImageSelectClick}
        alt="add media file"
      />

      <MasonryLayout className="simpleImage" layoutOptions={{ columnWidth: 100 }}>
        {values?.map((value, index) => (
          <div key={index} className="simpleImage_container">
            {value.src?.startsWith("data") ? (
              <img className="simpleImage_container_image" src={value.src} alt="upload photos" />
            ) : (
              <BinaryImage key={value.src} uid={value.src} alt="upload photos" onLoad={handleLoadImage} />
            )}
            <div className="simpleImage_container_deleteBtn" onClick={() => handleRemoveImage(index)} />
          </div>
        ))}
        <input
          type="file"
          accept="image/jpeg,image/png,image/jpg"
          name={name}
          onChange={handleFileSelect}
          ref={inputRef}
        />
      </MasonryLayout>
    </>
  );
};
