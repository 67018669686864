import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import { Overlay } from "../";
import { FORM_ERROR_MESSAGES, PERMISSION } from "../../constants";
import { FieldItem, FieldItemType, FormGenerator } from "../../formComponents";
import { Plant, CompaniesPlants } from "../../models";
import { prepareSelectData } from "../../utils";
import { Option } from "../../interfaces";

import "./index.scss";
import { AccessControl } from "../AccessControl";

const generalValidation = {
  displayName: Yup.string()
    .required(`Plant Name ${FORM_ERROR_MESSAGES.REQUIRED}`)
    .max(100, `Plant Name ${FORM_ERROR_MESSAGES.LONG}`),
  address: Yup.string()
    .required(`Plant Address ${FORM_ERROR_MESSAGES.REQUIRED}`)
    .max(200, `Plant Address ${FORM_ERROR_MESSAGES.LONG}`),
  companyId: Yup.number().required(`Company ${FORM_ERROR_MESSAGES.REQUIRED}`),
};

const validationSchema = () => Yup.object().shape({ ...generalValidation });

const generateFormFields = (options?: Option[]): FieldItem[] => [
  {
    type: FieldItemType.TEXT,
    name: "displayName",
    label: "Plant Name",
    placeholder: "Start typing here",
    wrapperClass: "form-element-wrapper",
  },
  {
    type: FieldItemType.TEXT,
    name: "address",
    label: "Address",
    placeholder: "Start typing here",
    wrapperClass: "form-element-wrapper",
  },
  {
    type: FieldItemType.SELECT,
    name: "companyId",
    label: "Company",
    wrapperClass: "form-element-wrapper",
    options: options || [],
  },
];

interface EditPlantForm {
  displayName: string;
  address: string;
  companyId?: string;
}

interface EditPlantSideoutProps {
  companies: CompaniesPlants[];
  currentPlant: null | Plant;
  showHideUnsavedChangeModal: Function;
  submitPlant: Function;
  onPlantRemove: (prop: string) => void;
}

const preparePlantData = (data?: Plant): EditPlantForm => {
  return {
    displayName: data?.displayName || "",
    address: data?.address || "",
    companyId: data?.companyId?.toString() || undefined,
  };
};

const EditPlantSideout: React.FunctionComponent<EditPlantSideoutProps> = (props) => {
  const { showHideUnsavedChangeModal, currentPlant, onPlantRemove } = props;
  const [formValues, setFormValues] = useState(preparePlantData());
  const [fieldsList, setFieldsList] = useState(generateFormFields());
  const [validation, setValidation] = useState(validationSchema());

  useEffect(() => {
    if (currentPlant) {
      setFormValues(preparePlantData(currentPlant));
      setValidation(validationSchema());
      return;
    }
    setValidation(validationSchema());
  }, [currentPlant, props.companies]);

  useEffect(() => {
    setFieldsList(generateFormFields(prepareSelectData(props.companies)));
  }, [props.companies]);

  return (
    <Formik
      validationSchema={validation}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        props.submitPlant(values);
      }}
      initialValues={formValues}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {(formikProps) => (
        <Overlay onClose={() => showHideUnsavedChangeModal(formikProps.dirty)}>
          <div className="edit-plant-wrapper">
            <div className="edit-plant-header">
              <div className="heading">{currentPlant ? "Edit Plant" : "Create Plant"}</div>
            </div>
            <div className="edit-plant-content">
              <FormGenerator formikProps={formikProps} fields={fieldsList} />
            </div>
            <div className="edit-plant-actions">
              <div className="edit-plant-button-panel">
                <div className="actions-block">
                  {currentPlant && (
                    <AccessControl permissions={[PERMISSION.PLANT_DELETE]}>
                      <button className="action-button action-button-remove" onClick={() => onPlantRemove("delete")}>
                        Remove
                      </button>
                    </AccessControl>
                  )}
                </div>
                <div className="actions-block">
                  <button
                    className="action-button action-button-cancel"
                    onClick={() => showHideUnsavedChangeModal(formikProps.dirty)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="action-button action-button-save"
                    disabled={!formikProps.dirty}
                    onClick={formikProps.submitForm}
                  >
                    {currentPlant ? "Save" : "Create"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Overlay>
      )}
    </Formik>
  );
};

export default EditPlantSideout;
