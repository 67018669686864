export enum ORDER_TYPES {
  DESC = "DESC",
  ASC = "ASC",
}

export const DEBOUNCE_OPTIONS = {
  delay: 500,
};

export enum ModalTypes {
  delete = "delete",
  restore = "restore",
  leave = "leave",
}

export const ImageFileExtensions = ["jpeg", "jpg", "png"];
export const VideoFileExtensions = ["mp4"];
export const AudiFileExtensions = ["mp3"];
export const AttachmentsFileExtensions = ["jpeg", "jpg", "png", "mp4", "mp3"] as const;
