import produce from "immer";
import { Reducer } from "redux";

import { FieldNote } from "../../../shared/models";
import { ActionTypes } from "./constants";

export interface FieldNotesState {
  fieldNotes: FieldNote[];
  currentFieldNote: FieldNote | null;
  pdf: Blob | null;
}

const initialState: FieldNotesState = {
  fieldNotes: [],
  currentFieldNote: null,
  pdf: null,
};

const reducer: Reducer<FieldNotesState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_FIELD_NOTES_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.fieldNotes = action.payload;
      });
    }

    case ActionTypes.CREATE_FIELD_NOTE_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.fieldNotes = [...nextState.fieldNotes, { ...action.payload, instancesCount: 0 }].sort((a, b) =>
          a.createdAt < b.createdAt ? 1 : -1,
        );
      });
    }

    case ActionTypes.GET_FIELD_NOTE_PDF_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.pdf = action.payload;
      });
    }

    case ActionTypes.CLEAN_FIELD_NOTE_PDF: {
      return produce(state, (nextState) => {
        nextState.pdf = null;
      });
    }

    case ActionTypes.UPDATE_FIELD_NOTE_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.fieldNotes = nextState.fieldNotes
          .map((fieldNote) => (fieldNote.id === action.payload.id ? { ...fieldNote, ...action.payload } : fieldNote))
          .sort((a, b) => (a.displayName < b.displayName ? -1 : 1));
        nextState.currentFieldNote = { ...nextState.currentFieldNote, ...action.payload };
      });
    }

    case ActionTypes.DELETE_FIELD_NOTE_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.fieldNotes = nextState.fieldNotes.filter((fieldNote) => fieldNote.id !== action.payload.id);
        nextState.currentFieldNote = null;
      });
    }

    case ActionTypes.GET_FIELD_NOTE_SUCCESS: {
      const fieldNote: FieldNote = action.payload;

      return produce(state, (nextState) => {
        nextState.currentFieldNote = fieldNote;
      });
    }

    default:
      return state;
  }
};
export default reducer;
