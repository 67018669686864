import React, { useState } from "react";

import { Select } from "../../../../../../shared/formComponents/common";
import { Option } from "../../../../../../shared/interfaces";

import "./archivedSelect.scss";

enum FilterValues {
  active = "active",
  archived = "archived",
  all = "all",
}

const options: Option[] = [
  {
    label: "Show only active",
    value: FilterValues.active,
  },
  {
    label: "Show archived",
    value: FilterValues.archived,
  },
  {
    label: "View all",
    value: FilterValues.all,
  },
];

interface ArchivedSelectProps {
  handleFilterChanged: (value: { showInactive?: boolean; showActive?: boolean }) => void;
}

export const ArchivedSelect = (props: ArchivedSelectProps) => {
  const { handleFilterChanged } = props;

  const [value, setValue] = useState(options[0]);

  const handleChangeValue = (option: Option) => {
    setValue(option);

    const filterOptions = {
      ...(option.value === FilterValues.active ? { showActive: true } : {}),
      ...(option.value === FilterValues.archived ? { showInactive: true } : {}),
    };
    handleFilterChanged?.(filterOptions);
  };

  return (
    <div className="archived_select_wrapper">
      <Select
        className="archived_select"
        classNamePrefix="archived_select"
        onChange={(option) => {
          handleChangeValue(option as Option);
        }}
        value={value}
        options={options}
        name="archivedFilter"
      />
    </div>
  );
};
