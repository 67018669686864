import React from "react";
import { Plant } from "../../../../../../shared/models";

import "./index.scss";

interface PlantContainerInterface {
  plant: Plant;
}

const PlantCard = (props: PlantContainerInterface) => {
  const { plant } = props;

  return (
    <div className="plant-container">
      <div className="plant-header">
        <div className="plant-name">{plant.displayName}</div>
        {!plant.isActive && (
          <div className="plant-archived-wrapper">
            <div className="plant-archived-label">Archived</div>
          </div>
        )}
      </div>

      <div className="plant-buttons-divider"></div>
      <div className="plant-buttons">
        {!!plant.notesCount && (
          <div className="plant-button notes-button">
            <div className="button-label">{plant.notesCount} Notes</div>
          </div>
        )}
        {!!plant.documentsCount && (
          <div className="plant-button documents-button">
            <div className="button-label">{plant.documentsCount} Documents</div>
          </div>
        )}
        {!!plant.collaboratorsCount && (
          <div className="plant-button collaborators-button">
            <div className="button-label">{plant.collaboratorsCount} Collaborators</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlantCard;
