import React from "react";
import "./index.scss";
import ReactTooltip from "react-tooltip";

interface ITooltipProps {
  tooltipLabel: string;
  tooltip: string;
  show: boolean;
}

const Tooltip = ({ tooltipLabel, tooltip, show }: ITooltipProps) =>
  show ? (
    <div className="tooltip">
      <div className="tooltip-label">{tooltipLabel}</div>
      <div data-tip data-for={tooltip}>
        <ReactTooltip arrowColor="transparent" className="tooltip-content" id={tooltip}>
          <span>{tooltip}</span>
        </ReactTooltip>
        <img className="tooltip-icon" src="/icons/general/tooltip.svg" alt={tooltipLabel} />
      </div>
    </div>
  ) : null;

export default Tooltip;
