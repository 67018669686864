import React, { useEffect, useState } from "react";
import { QuestionsWithDegradation, ConditionOptionsWithConcern } from "../../../../../../shared/models";
import { notEmpty } from "../../../../../../shared/utils";

import { InstancesConditionNoteQuestions } from "../InstancesConditionNoteQuestions";

interface InstancesCheckingConcernProps {
  questionsWithDegradation: QuestionsWithDegradation[];
  handleSubmit: (conditionOptionsToAdd: { conditionOptionId: number; isAConcern: boolean }[]) => void;
}

export const InstancesConditionNoteQuestionsWrapper = (props: InstancesCheckingConcernProps) => {
  const { questionsWithDegradation, handleSubmit } = props;
  const [step, setStep] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState<QuestionsWithDegradation[]>([]);

  const nextStep = (isAConcern: boolean) => {
    const newQuestions = [...answeredQuestions];
    newQuestions[step].degradation = { ...newQuestions[step].degradation, isAConcern };
    setAnsweredQuestions(newQuestions);

    if (step === answeredQuestions.length - 1) {
      const conditionOptionsResult = answeredQuestions
        .map(({ questions }) =>
          questions
            .map(({ conditionOptionId, isAConcern }) => {
              if (conditionOptionId !== undefined) {
                return { conditionOptionId, isAConcern: isAConcern || false };
              }
              return undefined;
            })
            .filter(notEmpty),
        )
        .flat();

      handleSubmit(conditionOptionsResult);
    }

    setStep(step + 1);
  };

  const handleChangeQuestion = (question: ConditionOptionsWithConcern) => {
    const newQuestions = [...answeredQuestions];
    newQuestions[step].questions = newQuestions[step].questions.map((oldQuestion) => {
      if (question.id !== oldQuestion.id) {
        return oldQuestion;
      }
      return question;
    });

    setAnsweredQuestions(newQuestions);
  };

  useEffect(() => {
    setStep(0);
    setAnsweredQuestions(questionsWithDegradation);
  }, [questionsWithDegradation]);

  return (
    <div className="questions_wrapper">
      {answeredQuestions[step] && (
        <InstancesConditionNoteQuestions
          questions={answeredQuestions[step].questions}
          degradationLabel={answeredQuestions[step].degradation.name}
          nextStep={nextStep}
          handleChangeQuestion={handleChangeQuestion}
          isLastStep={step === answeredQuestions.length - 1}
        />
      )}
    </div>
  );
};
