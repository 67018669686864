import { API, METHODS } from "../../../shared/constants";
import { request } from "../../../shared/utils";
import { Company, CompanyRequest, Plant } from "../../../shared/models";
import { SortFilterOptionsWithInactive, SortFilterOptions } from "../../../shared/interfaces";
import { AddDocumentShape, UpdateDocumentShape } from "../interfaces";

const companiesApi = {
  getCompaniesPlantsList: (payload: SortFilterOptionsWithInactive) => {
    return request(
      METHODS.GET,
      API.COMPANY.COMPANIES_PLANTS_LIST,
    )({
      params: payload,
    });
  },
  getCompanies: (payload: SortFilterOptionsWithInactive) => {
    return request(
      METHODS.GET,
      API.COMPANY.COMPANIES,
    )({
      params: payload,
    });
  },
  getCompany: (id: string) => request(METHODS.GET, API.COMPANY.GET_COMPANY(id))(),
  updateCompany: ({ id, ...payload }: CompanyRequest) =>
    request(METHODS.PATCH, API.COMPANY.UPDATE_COMPANY((id && id.toString()) || ""))(payload),
  createCompany: (payload: Partial<Company>) => request(METHODS.POST, API.COMPANY.CREATE_COMPANY)(payload),
  deleteCompany: (id: string) => request(METHODS.DELETE, API.COMPANY.DELETE_COMPANY(id))(),
  restoreCompany: (id: string) => request(METHODS.PATCH, API.COMPANY.RESTORE_COMPANY(id))(),
};

const plantsApi = {
  getPlant: (id: number) => request(METHODS.GET, API.COMPANY.GET_PLANT(id))(),
  updatePlant: (payload: Partial<Plant>) =>
    request(
      METHODS.PATCH,
      API.COMPANY.UPDATE_PLANT((payload.id && payload.id.toString()) || ""),
    )({
      displayName: payload.displayName,
      address: payload.address,
      companyId: payload.companyId,
    }),
  createPlant: (payload: Partial<Plant>) => request(METHODS.POST, API.COMPANY.CREATE_PLANT)(payload),
  deletePlant: (id: string) => request(METHODS.DELETE, API.COMPANY.DELETE_PLANT(id))(),
  restorePlant: (id: string) => request(METHODS.PATCH, API.COMPANY.RESTORE_PLANT(id))(),
  getPlantDocumentList: (plantId: number, payload: SortFilterOptions) =>
    request(
      METHODS.GET,
      API.COMPANY.GET_PLANT_DOCUMENTS(plantId),
    )({
      params: payload,
    }),
  deletePlantDocument: (plantId: number, documentId: number) =>
    request(METHODS.DELETE, API.COMPANY.DELETE_PLANT_DOCUMENT(plantId, documentId))(),
  createPlantDocument: (data: AddDocumentShape, plantId: number) =>
    request(METHODS.POST, API.COMPANY.CREATE_PLANT_DOCUMENT(plantId))(data),
  updatePlantDocument: (data: UpdateDocumentShape, plantId: number, documentId: number) =>
    request(METHODS.PUT, API.COMPANY.UPDATE_PLANT_DOCUMENT(plantId, documentId))(data),
};

export default {
  ...companiesApi,
  ...plantsApi,
};
