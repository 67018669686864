import React, { CSSProperties, useState } from "react";
import { SearchInput, Select } from "../../../../../../shared/formComponents/common";
import "./index.scss";

interface FieldGuideSelectHeaderProps {
  favoritesSelectHandler: (option: { value: string }) => void;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
  debouncedSearch: string;
  fieldGuideSelectValue: { value: string; label: string };
}

const FieldGuideSelectHeader: React.FunctionComponent<FieldGuideSelectHeaderProps> = (props) => {
  const { favoritesSelectHandler, setSearchString, debouncedSearch, fieldGuideSelectValue } = props;
  const [options] = useState({
    All: { label: "All Field Guides", value: "false" },
    Favorites: { label: "Only Favorites", value: "true" },
  });

  return (
    <div className="field-guide-select-header">
      <div className="field-guide-select-title">Field Guides</div>
      <div className="filter-right-block">
        <div className="filter favorites-list-block">
          <Select
            options={Object.values(options)}
            value={fieldGuideSelectValue}
            className="select"
            components={{
              IndicatorSeparator: () => null,
            }}
            onChange={(option) =>
              favoritesSelectHandler(
                option as {
                  value: string;
                },
              )
            }
            placeholder={<div>All Field Guides</div>}
            isClearable
            styles={{
              placeholder: (styles: CSSProperties) => ({
                ...styles,
                color: "$global-placeholder-color",
              }),
            }}
          />
        </div>
        <div className="filter search-block">
          <SearchInput onChangeSearch={setSearchString} placeholder="Search" value={debouncedSearch} />
        </div>
      </div>
    </div>
  );
};

export default FieldGuideSelectHeader;
