import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Notification } from "../../interfaces";
import { clearNotification } from "../../store/actions";

interface NotificationComponentProps {
  notification: Notification | null;
}

const NotificationComponent = (props: NotificationComponentProps) => {
  const { notification } = props;
  const { addToast, removeAllToasts } = useToasts();

  const dispatch = useDispatch();

  useEffect(() => {
    removeAllToasts();

    if (notification) {
      addToast(notification.message, {
        appearance: notification.appearance,
        autoDismiss: true,
        onDismiss: () => {
          dispatch(clearNotification());
        },
      });
    }
  }, [addToast, removeAllToasts, notification, dispatch]);

  return null;
};

export default NotificationComponent;
