import { HighlightArea } from "@react-pdf-viewer/highlight";
import produce from "immer";
import { Reducer } from "redux";

import { Instance, InstanceCollaborator, InstanceComment, InstanceResponse, Profile } from "../../../shared/models";
import { mapInstance, sortDates, sortNumbers } from "../../../shared/utils";
import { ActionTypes } from "./constants";

export interface InstancesState {
  instances: Instance[];
  profiles: Profile[];
  collaborators: InstanceCollaborator[];
  selectedInstance?: Instance;
  comments: InstanceComment[];
  pdf: Blob | null;
  pdfAreas: HighlightArea[] | null;
}

const initialState: InstancesState = {
  selectedInstance: undefined,
  instances: [],
  profiles: [],
  collaborators: [],
  comments: [],
  pdf: null,
  pdfAreas: null,
};

const reducer: Reducer<InstancesState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_INSTANCES_SUCCESS: {
      return produce(state, (nextState) => {
        const payload: InstanceResponse[] = action.payload;
        const instances = payload.map(mapInstance);
        nextState.instances = [...instances].sort((a, b) =>
          sortDates(a.updatedAt || a.createdAt, b.updatedAt || b.createdAt),
        );
      });
    }

    case ActionTypes.GET_INSTANCE_SUCCESS: {
      return produce(state, (nextState) => {
        const payload: InstanceResponse | undefined = action.payload;
        const instance = payload ? mapInstance(payload) : payload;
        nextState.selectedInstance = instance
          ? {
              ...instance,
              attachments: instance?.attachments.sort((a, b) => sortNumbers(a.positionIndex, b.positionIndex)),
            }
          : undefined;
      });
    }

    case ActionTypes.GET_INSTANCE_PDF_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.pdf = action.payload;
      });
    }

    case ActionTypes.CLEAN_INSTANCE_PDF: {
      return produce(state, (nextState) => {
        nextState.pdf = null;
      });
    }

    case ActionTypes.SET_SELECTED_PDF_AREAS: {
      return produce(state, (nextState) => {
        nextState.pdfAreas = action.payload;
      });
    }

    case ActionTypes.GET_INSTANCES_BY_FIELD_NOTE_SUCCESS: {
      return produce(state, (nextState) => {
        const payload: InstanceResponse[] = action.payload;
        const instances = payload.map(mapInstance);
        nextState.instances = instances
          .map((instance) => ({
            ...instance,
            images: instance.images.sort((a, b) => sortNumbers(a.positionIndex, b.positionIndex)),
          }))
          .sort((a, b) => sortDates(a.updatedAt || a.createdAt, b.updatedAt || b.createdAt));
      });
    }

    case ActionTypes.CREATE_INSTANCE_SUCCESS: {
      return produce(state, (nextState) => {
        const payload: InstanceResponse = action.payload;
        const instance = mapInstance(payload);
        nextState.instances = [...state.instances, { ...instance }]
          .sort((a, b) => sortDates(a.updatedAt || a.createdAt, b.updatedAt || b.createdAt))
          .map((instance) => ({
            ...instance,
            images: instance.images.sort((a, b) => sortNumbers(a.positionIndex, b.positionIndex)),
          }));
      });
    }

    case ActionTypes.UPDATE_INSTANCE_SUCCESS: {
      return produce(state, (nextState) => {
        const updatedInstance: InstanceResponse = action.payload;
        nextState.instances = nextState.instances
          .map((instance) => (instance.id === updatedInstance.id ? { ...instance, ...updatedInstance } : instance))
          .sort((a, b) => sortDates(a.updatedAt || a.createdAt, b.updatedAt || b.createdAt))
          .map((instance) => ({
            ...instance,
            images: instance.images.sort((a, b) => sortNumbers(a.positionIndex, b.positionIndex)),
          }));
      });
    }

    case ActionTypes.REMOVE_INSTANCE_CONDITION_OPT_SUCCESS: {
      const payload: InstanceResponse | undefined = action.payload;
      const updatedInstance = payload ? mapInstance(payload) : payload;
      return produce(state, (nextState) => {
        nextState.selectedInstance = updatedInstance
          ? {
              ...updatedInstance,
              images: updatedInstance?.images.sort((a, b) => sortNumbers(a.positionIndex, b.positionIndex)),
            }
          : undefined;
      });
    }

    case ActionTypes.DELETE_INSTANCE_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.instances = nextState.instances.filter((instance) => instance.id !== action.payload.id);
      });
    }

    case ActionTypes.GET_PLANT_PROFILES_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.profiles = [...action.payload];
      });
    }

    case ActionTypes.GET_INSTANCE_COLLABORATORS_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.collaborators = [...action.payload];
      });
    }

    case ActionTypes.DELETE_INSTANCE_COLLABORATOR_SUCCESS: {
      return produce(state, (nextState) => {
        const collaboratorId = action.payload;
        nextState.collaborators = state.collaborators.filter((collaborator) => collaborator.id !== collaboratorId);
      });
    }

    case ActionTypes.GET_INSTANCE_COMMENTS_SUCCESS: {
      const comments: InstanceComment[] = action.payload;

      return produce(state, (nextState) => {
        nextState.comments = [...comments].sort((a, b) => {
          return sortDates(a.updatedAt || a.createdAt, b.updatedAt || b.createdAt);
        });
      });
    }

    case ActionTypes.CREATE_COMMENT_SUCCESS: {
      return produce(state, (nextState) => {
        nextState.comments = [...state.comments, action.payload].sort((a, b) => {
          return sortDates(a.updatedAt || a.createdAt, b.updatedAt || b.createdAt);
        });
      });
    }

    case ActionTypes.UPDATE_COMMENT_SUCCESS: {
      return produce(state, (nextState) => {
        const updatedComment: InstanceComment = action.payload;
        nextState.comments = state.comments
          .map((comment) => {
            if (comment.id === updatedComment.id) {
              return updatedComment;
            }
            return comment;
          })
          .sort((a, b) => {
            return sortDates(a.updatedAt || a.createdAt, b.updatedAt || b.createdAt);
          });
      });
    }

    case ActionTypes.DELETE_COMMENT_SUCCESS: {
      return produce(state, (nextState) => {
        const commentId: number = action.payload;

        nextState.comments = state.comments.filter(({ id }) => id !== commentId);
      });
    }

    case ActionTypes.FIELD_GUIDE_REFERENCE_ADD_SUCCESS: {
      return produce(state, (nextState) => {
        const payload: InstanceResponse | undefined = action.payload;
        const updatedInstance = payload ? mapInstance(payload) : payload;
        nextState.selectedInstance = updatedInstance
          ? {
              ...updatedInstance,
              images: updatedInstance?.images.sort((a, b) => sortNumbers(a.positionIndex, b.positionIndex)),
            }
          : undefined;
      });
    }

    case ActionTypes.FIELD_GUIDE_REFERENCE_DELETE_SUCCESS: {
      return produce(state, (nextState) => {
        const payload: InstanceResponse | undefined = action.payload;
        const updatedInstance = payload ? mapInstance(payload) : payload;
        nextState.selectedInstance = updatedInstance
          ? {
              ...updatedInstance,
              images: updatedInstance?.images.sort((a, b) => sortNumbers(a.positionIndex, b.positionIndex)),
            }
          : undefined;
      });
    }

    default:
      return state;
  }
};
export default reducer;
