import React from "react";
import classnames from "classnames";
import "./index.scss";

interface AppLoaderProps {
  text?: string;
  isBlurred?: boolean;
}

function AppLoader(props: AppLoaderProps) {
  return (
    <div className={classnames("app-loader", { blurred: props.isBlurred })}>
      <div className="ball-scale">
        <div />
      </div>
      {props.text && <div className="loader-text">{props.text}</div>}
    </div>
  );
}

export default AppLoader;
