import { API, METHODS } from "../../../shared/constants";
import { SortFilterOptions } from "../../../shared/interfaces";
import { request } from "../../../shared/utils";
import { EditUserShape } from "../interfaces";

const usersApi = {
  getUsersList: (params: SortFilterOptions & { showEpriDetails: boolean }) =>
    request(METHODS.GET, API.USER.GET_USERS_LIST)({ params }),
  updateUserRoles: (userId: string, payload: EditUserShape) =>
    request(METHODS.PUT, API.USER.UPDATE_USER_ROLES(userId))(payload),
  markTutorialCompleted: () => request(METHODS.POST, API.USER.MARK_TUTOTIAL_COMPLETED())(),
};

export default {
  ...usersApi,
};
