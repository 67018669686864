import { useCallback, useMemo, useState } from "react";
import { DEBOUNCE_OPTIONS, ORDER_TYPES } from "../constants";
import { IDateRange } from "../interfaces";
import useDebounce from "./DebounceHook";

interface useSortFilterProps {
  searchString?: string;
  sortOrder?: ORDER_TYPES;
  showMineInitial?: boolean;
  page?: number;
  pageSize?: number;
  searchReviewStatusId?: string;
}

const useSortFilter = (props: useSortFilterProps) => {
  const { showMineInitial } = props;

  const [searchString, setSearchString] = useState("");
  const [sortOrder, setSortOrder] = useState<ORDER_TYPES>(props.sortOrder || ORDER_TYPES.DESC);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [searchPlantId, setSearchPlantId] = useState<string | undefined>(undefined);
  const [searchReviewStatusId, setSearchReviewStatusId] = useState<string | undefined>(
    props.searchReviewStatusId ? props.searchReviewStatusId : undefined,
  );
  const [searchCompanyId, setSearchCompanyId] = useState<string | undefined>(undefined);
  const [searchFavorites, setSearchFavorites] = useState<string | undefined>(undefined);
  const [showMine, setShowMine] = useState<boolean | undefined>(
    showMineInitial !== undefined ? showMineInitial : undefined,
  );

  const [page, setPage] = useState<number | undefined>(props.page !== undefined ? props.page : undefined);
  const incrementPage = () => setPage((state = 0) => state + 1);
  const [pageSize, setPageSize] = useState<number | undefined>(props.pageSize ? props.pageSize : undefined);

  const debouncedSearch: string = useDebounce(searchString, DEBOUNCE_OPTIONS.delay);

  const toggleSortOrder = useCallback(() => {
    setSortOrder(sortOrder === ORDER_TYPES.ASC ? ORDER_TYPES.DESC : ORDER_TYPES.ASC);
  }, [sortOrder]);

  const toggleShowMine = () => {
    setShowMine(!showMine);
  };

  const setDateRange = ({ start_date, end_date }: IDateRange) => {
    setEndDate(end_date);
    setStartDate(start_date);
  };
  const sortOrderLabel = useMemo(() => (sortOrder === ORDER_TYPES.DESC ? "Latest first" : "Oldest first"), [sortOrder]);

  const requestOptions = useMemo(
    () => ({
      ...(debouncedSearch ? { search: debouncedSearch } : ""),
      ...(sortOrder ? { sortOrder } : ""),
      ...(startDate ? { startDate } : ""),
      ...(endDate ? { endDate } : ""),
      ...(searchPlantId ? { plantId: searchPlantId } : ""),
      ...(searchReviewStatusId ? { reviewStatusId: searchReviewStatusId } : ""),
      ...(searchFavorites === "true" ? { showFavorites: true } : ""),
      ...(searchCompanyId ? { companyId: searchCompanyId } : ""),
      ...(showMine ? { showMine: showMine } : ""),
      ...(page !== undefined ? { page } : ""),
      ...(pageSize ? { pageSize } : ""),
    }),
    [
      debouncedSearch,
      sortOrder,
      startDate,
      endDate,
      searchPlantId,
      searchReviewStatusId,
      showMine,
      searchCompanyId,
      searchFavorites,
      page,
      pageSize,
    ],
  );

  return {
    utils: {
      setDateRange,
      setSearchString,
      toggleSortOrder,
      setStartDate,
      setEndDate,
      setSearchPlantId,
      setSearchReviewStatusId,
      setSearchFavorites,
      setSortOrder,
      toggleShowMine,
      setSearchCompanyId,
      setPage,
      setPageSize,
      incrementPage,
    },
    searchCompanyId,
    requestOptions,
    debouncedSearch,
    searchString,
    sortOrder,
    setSortOrder,
    startDate,
    endDate,
    searchPlantId,
    searchReviewStatusId,
    searchFavorites,
    sortOrderLabel,
    showMine,
  };
};

export default useSortFilter;
