import React, { useEffect, useRef, useState } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getSimpleUTCDate } from "../../../../../utils";
import "./index.scss";

interface DateInputProps extends ReactDatePickerProps {
  label?: string;
  isRequiredField?: boolean;
}

const DateInput = (props: DateInputProps) => {
  const { value, onChange, label, isRequiredField, ...inpProps } = props;
  const [startDate, setStartDate] = useState<Date | null>(null);
  // eslint-disable-next-line
  const wrapperRef = useRef<any>(null);

  useEffect(() => {
    setStartDate(getSimpleUTCDate(value ? new Date(value) : null));
  }, [value]);

  const clear = () => {
    wrapperRef.current && wrapperRef.current.clear();
  };

  useEffect(() => {
    if (inpProps.inline && !props.selected) {
      clear();
    }
  }, [inpProps.inline, props.selected]);

  return (
    <div className="date-picker">
      <label className={inpProps.disabled ? "disabled" : ""}>
        {label} {isRequiredField && <span className="required-field">*</span>}
      </label>
      <DatePicker
        ref={wrapperRef}
        {...inpProps}
        minDate={props.minDate ? new Date(props.minDate) : null}
        maxDate={props.maxDate ? new Date(props.maxDate) : null}
        showPopperArrow={false}
        selected={startDate}
        showMonthDropdown={true}
        showYearDropdown={true}
        yearDropdownItemNumber={5}
        scrollableYearDropdown={true}
        onChange={(dates) => {
          setStartDate(getSimpleUTCDate(dates as Date | null));
          onChange(getSimpleUTCDate(dates as Date | null), undefined);
        }}
      />
      {!props.inline && (
        <div
          className="icon calendar"
          onClick={() => wrapperRef.current && wrapperRef.current.setOpen(!wrapperRef.current.isCalendarOpen())}
        />
      )}
    </div>
  );
};

export default DateInput;
