import React from "react";
import classNames from "./slideIndicator.module.scss";
import classnames from "classnames";

/**
 * Indicates current displayed slide number
 */
interface SlideIndicatorProps {
  currentIndex: number;
  totalCount: number;
}

const SlideIndicator: React.FunctionComponent<SlideIndicatorProps> = (props) => {
  const { currentIndex, totalCount } = props;

  return (
    <div className={classNames.slideIndicatorWrapper}>
      {[...Array(totalCount)].map((e, index) => (
        <div
          key={index}
          className={classnames(classNames.dot, { [classNames.selected]: index === currentIndex })}
        ></div>
      ))}
    </div>
  );
};

export default SlideIndicator;
