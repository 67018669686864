import * as Yup from "yup";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../../../../../shared/formComponents/common";
import { getNotification } from "../../../../../../shared/store/selectors";
import { FORM_ERROR_MESSAGES } from "../../../../../../shared/constants";

import { AuthShape, FormStepProps } from "../../../../interfaces";
import { FormGenerator, FieldItemType, FieldItem } from "../../../../../../shared/formComponents";
import { clearNotification } from "../../../../../../shared/store/actions";

const validationSchema = Yup.object<AuthShape>().shape({
  login: Yup.string().required(`EPRI Username ${FORM_ERROR_MESSAGES.REQUIRED}`),
  password: Yup.string().required(`Password ${FORM_ERROR_MESSAGES.REQUIRED}`),
});

const fields: FieldItem[] = [
  {
    type: FieldItemType.TEXT,
    name: "login",
    label: "EPRI Username",
    wrapperClass: "input-line",
  },
  {
    type: FieldItemType.PASSWORD,
    name: "password",
    label: "Password",
    wrapperClass: "input-line",
    isShowPasswordIcon: true,
  },
];

const LoginForm = (props: FormStepProps) => {
  const [formValues] = useState({
    login: "",
    password: "",
  });

  const dispatch = useDispatch();
  const notification = useSelector(getNotification());
  const { message } = notification || { message: null };

  useEffect(() => {
    dispatch(clearNotification());
  }, [dispatch]);

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.submitHandler && props.submitHandler(values);
        setSubmitting(false);
      }}
      initialValues={formValues}
    >
      {(formikProps) => (
        <>
          <div className={message ? "form-message" : "form-message-none"}>{message}</div>
          <FormGenerator fields={fields} formikProps={formikProps}>
            <Button
              className="submit-margin"
              type="submit"
              disabled={props.loading || !formikProps.isValid || !formikProps.dirty}
            >
              LOG IN
            </Button>
          </FormGenerator>
        </>
      )}
    </Formik>
  );
};

export default LoginForm;
