export enum ActionTypes {
  GET_USERS = "@@USERS",
  GET_USERS_SUCCESS = "@@USERS_SUCCESS",
  GET_USERS_FAILURE = "@@USERS_FAILURE",

  MARK_TUTORIAL_COMPLETED = "@@USERS/MARK_TUTORIAL_COMPLETED",
  MARK_TUTORIAL_COMPLETED_SUCCESS = "@@USERS/MARK_TUTORIAL_COMPLETED_SUCCESS",
  MARK_TUTORIAL_COMPLETED_FAILURE = "@@USERS/MARK_TUTORIAL_COMPLETED_FAILURE",

  UPDATE_USER_ROLES = "@@USERS/UPDATE_USER_ROLES",
  UPDATE_USER_ROLES_SUCCESS = "@@USERS/UPDATE_USER_ROLES_SUCCESS",
  UPDATE_USER_ROLES_FAILURE = "@@USERS/UPDATE_USER_ROLES_FAILURE",
}
