import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Overlay, FormGenerator, Modal, ModalContent } from "../../../../../../shared";
import { Company, CompanyRequest, CompanyImagesReview } from "../../../../../../shared/models";
import {
  validationSchema,
  fields,
  initValues,
  convertImageReviewOptions,
  getFieldsFromSchema,
  FieldItemHelpText,
} from "./formHelpers";

import "./index.scss";

interface EditCompanyContainerInterface {
  currentCompany: null | Company;
  showHideUnsavedChangeModal: Function;
  submitCompany: (companyValues: CompanyRequest) => void;
  onCompanyRemove: (prop: string) => void;
}

interface CompanyFrom extends CompanyImagesReview {
  displayName: string | null;
  epriid: string;
  canSyncWithCloud: { value: boolean };
}

const prepareCompanyData = (data: Company): CompanyFrom => {
  const { canSyncWithCloud } = data;

  return {
    ...data,
    canSyncWithCloud: { value: canSyncWithCloud },
    ...convertImageReviewOptions<Partial<Company>>(data, true),
  };
};

const EditCompany: React.FunctionComponent<EditCompanyContainerInterface> = (props) => {
  const { showHideUnsavedChangeModal, currentCompany, onCompanyRemove } = props;

  const [formValues, setFormValues] = useState<CompanyFrom>(initValues);
  const [, setFieldsList] = useState(fields);
  const [validation, setValidation] = useState(validationSchema());
  const [isShowingEditStorageTimeModal, setModalStatus] = useState<boolean>(false);

  useEffect(() => {
    if (currentCompany) {
      setFormValues(prepareCompanyData(currentCompany));
      setValidation(validationSchema());
      setFieldsList((fList) => {
        return [...fList];
      });
      return;
    }
    setValidation(validationSchema());
  }, [currentCompany]);

  const onClose = () => setModalStatus(false);

  const handleSubmit = (values: CompanyFrom, { setSubmitting }: { setSubmitting: (val: boolean) => void }) => {
    if (currentCompany && !isShowingEditStorageTimeModal) {
      setModalStatus(true);
      return;
    }

    const { canSyncWithCloud, ...data } = values;
    setSubmitting(false);

    const validData = getFieldsFromSchema<CompanyFrom>(data as CompanyFrom, !!currentCompany);

    props.submitCompany({
      ...validData,
      canSyncWithCloud: canSyncWithCloud?.value || false,
      ...convertImageReviewOptions<Partial<CompanyFrom>>(validData, false),
    });
  };

  const getFiedls = (fieldValue: string | number | null, fName: string[]) => {
    const MIN_PENDING_STORAGE_TIME = 1;
    const val = Number(fieldValue);

    return fields.map((f) => {
      if (fName.includes(f.name) && fieldValue) {
        if (val === MIN_PENDING_STORAGE_TIME) {
          f.disabled = true;
          f.helpText = FieldItemHelpText.PendingImagesReminderNote;
        } else if (val > MIN_PENDING_STORAGE_TIME) {
          f.disabled = false;
        }
      }
      return f;
    });
  };

  return (
    <Formik
      validationSchema={validation}
      onSubmit={handleSubmit}
      initialValues={formValues}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {(formikProps) => {
        const {
          values: { pendingImagesStorageTime },
        } = formikProps;
        return (
          <>
            <Overlay onClose={() => showHideUnsavedChangeModal(formikProps.dirty)}>
              <div className="edit-company-wrapper">
                <div className="edit-company-header">
                  <div className="heading">{currentCompany ? "Edit Company" : "Create Company"}</div>
                </div>
                <div className="edit-company-content">
                  <FormGenerator
                    formikProps={formikProps}
                    fields={getFiedls(pendingImagesStorageTime, [
                      "pendingImagesReminder",
                      "secondPendingImagesReminder",
                    ])}
                  />
                </div>
                <div className="edit-company-description">
                  WARNING: Only utility members with a signed license agreement should be given the ability to sync with
                  the cloud. BEFORE enabling this functionality in the box above, confirm that the utility has a signed
                  Digital Assistant license agreement. Contact Legal if you have questions.
                </div>
                <div className="edit-company-actions">
                  <div className="edit-company-button-panel">
                    <div className="action-block">
                      {currentCompany && (
                        <button
                          className="action-button action-button-remove"
                          onClick={() => onCompanyRemove("delete")}
                        >
                          Remove
                        </button>
                      )}
                    </div>
                    <div className="action-block">
                      <button
                        className="action-button action-button-cancel"
                        onClick={() => showHideUnsavedChangeModal(formikProps.dirty)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="action-button action-button-save"
                        disabled={!formikProps.dirty}
                        onClick={formikProps.submitForm}
                      >
                        {currentCompany ? "Save" : "Create"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Overlay>
            <Modal isShowing={!!currentCompany && isShowingEditStorageTimeModal} onClose={onClose} boxPadding>
              <ModalContent
                heading="Edit company"
                content="Please note that the updated settings will be applied only for the newly added images."
                minorActionText="Dismiss"
                mainActionText="Ok"
                onMinorAction={onClose}
                onMainAction={formikProps.submitForm}
              />
            </Modal>
          </>
        );
      }}
    </Formik>
  );
};

export default EditCompany;
