import React, { useEffect, useCallback, useMemo, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router";
import classnames from "classnames";

import { getNotification, getNotificationHistoryList } from "../../shared/store/selectors";
import { Notification, Toast } from "../../shared/components/Notification";
import { AppInitLoader } from "../../shared/components";
import { ROUTES_MAP, ROUTE_PATHS } from "../../shared/routes";
import * as sharedActions from "../../shared/store/actions";
import { getUser } from "../../shared/store/selectors";
import tokenHandler from "../../shared/utils/tokenHandler";
import { actions } from "../Auth/store";
import { PrivateRoute } from "../../shared/routes";
import { Navbar } from "./components";
import { NotificationHistory } from "../../shared/models";
import { initSocket } from "../../shared/utils";
import { Panels } from "../Instances/containers/InstanceViewContainer/InstanceViewContainer";
import "./index.scss";
import { NOTIFICATION_TYPES, REVIEW_STATUS_ID } from "../../shared/constants";

export default function PrivateRoutes() {
  const [isNotificationsOpened, setIsNotificationsOpened] = useState(false);
  const user = useSelector(getUser());
  const profile = tokenHandler.getProfile();
  const history = useHistory();
  const dispatch = useDispatch();

  const notification = useSelector(getNotification());
  const notificationHistoryList = useSelector(getNotificationHistoryList());

  useEffect(() => {
    dispatch(sharedActions.getNotificationHistoryList.request());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      initSocket(user.id, dispatch);
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (!profile) {
      dispatch(actions.logout.request());
      return;
    }

    if (!user) {
      dispatch(sharedActions.getUserData.request(profile.id));
    }
  }, [dispatch, user, profile]);

  const logout = useCallback(() => {
    dispatch(actions.logout.request());
  }, [dispatch]);

  const onMarkAllAsRead = useCallback(() => {
    dispatch(sharedActions.markAllNotificationsHistoryAsRead.request());
  }, [dispatch]);

  const onMarkAsRead = useCallback(
    (notificationHistory: NotificationHistory) => {
      if (!notificationHistory.isViewed) {
        dispatch(sharedActions.markOneNotificationHistoryAsRead.request(notificationHistory.id));
      }

      const params = {
        plantId: notificationHistory.instance?.fieldNote?.plantId,
        fieldNoteId: notificationHistory.instance?.fieldNote?.id,
        id: notificationHistory.instanceId,
      };

      if (
        notificationHistory.type === NOTIFICATION_TYPES.REJECT_IMAGE ||
        notificationHistory.type === NOTIFICATION_TYPES.REMOVE_IMAGE_REMINDER
      ) {
        history.push({
          pathname: generatePath(ROUTE_PATHS.IMAGE_REVIEWS_DASHBOARD, params),
          search: `?status=${
            notificationHistory.type === NOTIFICATION_TYPES.REJECT_IMAGE
              ? REVIEW_STATUS_ID.REJECTED
              : REVIEW_STATUS_ID.PENDING
          }`,
        });
      } else {
        history.push({
          pathname: generatePath(ROUTE_PATHS.INSTANCE_VIEW, params),
          search: `?at=${notificationHistory.createdAt}&notificationType=${notificationHistory.type}&panel=${
            notificationHistory.type === NOTIFICATION_TYPES.COMMENTED_INSTANCE ? Panels.Comments : Panels.Attachments
          }`,
        });
      }
    },
    [history, dispatch],
  );

  const routesMap = useMemo(
    () =>
      ROUTES_MAP.map((routeItem) => (
        <PrivateRoute
          key={routeItem.path}
          path={routeItem.path}
          component={routeItem.component}
          authenticated={routeItem.authenticated}
          exact={routeItem.exact}
          permissions={routeItem.permissions}
          roles={routeItem.roles}
        />
      )),
    [],
  );

  return !user ? (
    <AppInitLoader />
  ) : (
    <div className="app-container">
      <div className="app-menu">
        {isNotificationsOpened && <div className="main-overlay" />}
        <Navbar
          user={user}
          notificationHistory={notificationHistoryList}
          onCloseOpenNotification={setIsNotificationsOpened}
          onMarkAsRead={onMarkAsRead}
          onLogout={logout}
          onMarkAllAsRead={onMarkAllAsRead}
        />
      </div>
      <div className="app-content-wrapper">
        <div
          className={classnames("app-content", {
            license: history.location.pathname === ROUTE_PATHS.LICENSE_AGREEMENT,
          })}
        >
          <div id="dashboard-content" className="dashboard-content">
            <ToastProvider autoDismissTimeout={4000} components={{ Toast }}>
              <Notification notification={notification} />
              <Switch>
                {routesMap}
                <Route component={() => <div>Will be implemented soon</div>} />
              </Switch>
            </ToastProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
