import * as Yup from "yup";
import { FORM_ERROR_MESSAGES, FieldItem, FieldItemType, mapItemsToOptions } from "../../../../../../shared";
import { ImageReviewKeyTypes, ImageReviewType } from "../../../../../../shared/models";

export const generalValidation = {
  displayName: Yup.string()
    .required(`Company Name ${FORM_ERROR_MESSAGES.REQUIRED}`)
    .max(100, `Company Name ${FORM_ERROR_MESSAGES.LONG}`),
  epriid: Yup.string()
    .matches(/^[\d]+$/, `EPRI Internal ID name ${FORM_ERROR_MESSAGES.NUMBER}`)
    .required(`EPRI Internal ID name ${FORM_ERROR_MESSAGES.REQUIRED}`),
  canSyncWithCloud: Yup.object().shape({ value: Yup.boolean() }),
  pendingImagesStorageTime: Yup.number()
    .nullable()
    .required(`Pending images storage time ${FORM_ERROR_MESSAGES.REQUIRED}`),
  pendingImagesReminder: Yup.number()
    .nullable()
    .when("pendingImagesStorageTime", (pendingImagesStorageTime: number | null, schema: Record<string, Function>) => {
      return schema.test({
        test: (pendingImagesReminder: number | null) => {
          if (!pendingImagesReminder || !pendingImagesStorageTime || pendingImagesStorageTime === 1) return true;
          return (
            !!pendingImagesReminder && pendingImagesStorageTime && pendingImagesReminder < pendingImagesStorageTime
          );
        },
        message: "Pending image reminder can not be greater than the Storage time",
      });
    }),
  secondPendingImagesReminder: Yup.number()
    .nullable()
    .when("pendingImagesStorageTime", (pendingImagesStorageTime: number | null, schema: Record<string, Function>) => {
      return schema.test({
        test: (secondPendingImagesReminder: number | null) => {
          if (!secondPendingImagesReminder || !pendingImagesStorageTime || pendingImagesStorageTime === 1) return true;
          return (
            !!secondPendingImagesReminder &&
            pendingImagesStorageTime &&
            secondPendingImagesReminder < pendingImagesStorageTime
          );
        },
        message: "Second pending image reminder can not be greater than the Storage time",
      });
    })
    .when("pendingImagesReminder", (pendingImagesReminder: number | null, schema: Record<string, Function>) => {
      return schema.test({
        test: (secondPendingImagesReminder: number | null) => {
          if (!pendingImagesReminder || !secondPendingImagesReminder) return true;
          return (
            !!secondPendingImagesReminder &&
            pendingImagesReminder &&
            secondPendingImagesReminder > pendingImagesReminder
          );
        },
        message: "Second reminder can not be less than the first one",
      });
    }),
  rejectedImagesStorageTime: Yup.number()
    .nullable()
    .required(`Rejected images storage time ${FORM_ERROR_MESSAGES.REQUIRED}`),
};

export const getFieldsFromSchema = <T>(data: T, withId: boolean): T => {
  const initialValueKeys = Object.keys(initValues);

  Object.keys(data).forEach((key) => {
    if (!initialValueKeys.includes(key) || (key === "id" && !withId)) {
      delete data[key as keyof T];
    }
  });

  return data;
};

export const validationSchema = () => Yup.object().shape({ ...generalValidation });

export const convertImageReviewOptions = <T>(data: T, isStr: boolean) => {
  const valueType = isStr ? "number" : "string";

  return Object.fromEntries(
    Object.entries(data)
      .filter(([key]: [string, ImageReviewType]) => key in ImageReviewKeyTypes)
      .map(([key, val]: [string, ImageReviewType]) => [
        key,
        !!val && typeof val === valueType ? (isStr ? val.toString() : Number(val)) : val,
      ]),
  );
};

export const rejectOptions = mapItemsToOptions(7);
export const pendingOptions = mapItemsToOptions(14);

export enum FieldItemHelpText {
  PendingImagesStorageTime = "Please specify the storage time of pending images",
  PendingImagesReminder = "Please specify the time for reminder about the pending images before they will be auto deleted",
  RejectedImagesStorageTime = "Please specify the storage time of rejected images",
  PendingImagesReminderNote = "Note: The reminder can not be configured as the storage days is 1 day",
  SecondPendingImagesReminder = "Please specify the days for the first and second reminder about the pending images before they will be deleted",
}

export const fields: FieldItem[] = [
  {
    type: FieldItemType.TEXT,
    name: "displayName",
    label: "Company Name",
    placeholder: "Start typing here",
    wrapperClass: "form-element-wrapper",
  },
  {
    type: FieldItemType.TEXT,
    name: "epriid",
    label: "EPRI Internal ID",
    placeholder: "Start typing here",
    wrapperClass: "form-element-wrapper",
  },
  {
    type: FieldItemType.SELECT,
    name: "pendingImagesStorageTime",
    options: pendingOptions,
    label: "Pending images storage time (Days)",
    placeholder: "Please select",
    wrapperClass: "form-element-wrapper",
    isSearchable: false,
    tooltip: FieldItemHelpText.PendingImagesStorageTime,
    tooltipLabel: "Pending Images",
    relatedFields: {
      cleaningCondition: 1,
      fields: ["pendingImagesReminder", "secondPendingImagesReminder"],
    },
  },
  {
    type: FieldItemType.SELECT,
    name: "pendingImagesReminder",
    options: pendingOptions,
    label: "1st pending images reminder (Days) ",
    placeholder: "Please select",
    wrapperClass: "form-element-wrapper",
    isSearchable: false,
    tooltip: FieldItemHelpText.SecondPendingImagesReminder,
    tooltipLabel: "Pending Images Reminders",
  },
  {
    type: FieldItemType.SELECT,
    name: "secondPendingImagesReminder",
    options: pendingOptions,
    label: "2nd pending images reminder (Days)",
    placeholder: "Please select",
    wrapperClass: "form-element-wrapper",
    isSearchable: false,
  },
  {
    type: FieldItemType.SELECT,
    name: "rejectedImagesStorageTime",
    options: rejectOptions,
    label: "Rejected images storage time (Days)",
    placeholder: "Please select",
    wrapperClass: "form-element-wrapper",
    isSearchable: false,
    tooltip: FieldItemHelpText.RejectedImagesStorageTime,
    tooltipLabel: "Rejected Images",
  },
  {
    type: FieldItemType.CHECKBOX,
    name: "canSyncWithCloud",
    label: "Sync with Cloud",
    wrapperClass: "form-element-wrapper",
  },
];

export const initValues = {
  displayName: "",
  epriid: "",
  canSyncWithCloud: { value: true },
  pendingImagesReminder: null,
  secondPendingImagesReminder: null,
  pendingImagesStorageTime: null,
  rejectedImagesStorageTime: null,
};
