import { call, put, takeLatest } from "redux-saga/effects";

import api from "./api";
import { actions } from "./";
import { AnyAction } from "redux";
import { SortFilterOptions } from "../../../shared/interfaces";
import { tokenHandler } from "../../../shared/utils";
import { generatePath } from "react-router";
import { ROUTE_PATHS } from "../../../shared/routes";
import history from "../../../shared/utils/history";

function* getUsersList({ payload }: { payload: SortFilterOptions & { showEpriDetails: boolean } }) {
  try {
    const users = yield call(api.getUsersList, payload);
    yield put(actions.getUsers.success(users));
  } catch (error) {
    yield put(actions.getUsers.failure(error));
  }
}

function* updateUserRoles({ payload }: AnyAction) {
  try {
    const { id, profileRoles } = payload;
    const data = yield call(api.updateUserRoles, id, { profileRoles });
    yield put(actions.updateUserRoles.success(data));
  } catch (error) {
    yield put(actions.updateUserRoles.failure(error));
  }
}

function* markTutorialCompleted() {
  try {
    const data = yield call(api.markTutorialCompleted);
    yield put(actions.markTutorialCompleted.success(data));
    tokenHandler.setTutorialCompletionDate(data.tutorialCompletionDate);
    yield history.push(generatePath(ROUTE_PATHS.COMPANIES_DASHBOARD));
  } catch (error) {
    yield put(actions.markTutorialCompleted.failure(error));
  }
}

function* usersSaga() {
  yield takeLatest(actions.getUsers.request, getUsersList);
  yield takeLatest(actions.markTutorialCompleted.request, markTutorialCompleted);
  yield takeLatest(actions.updateUserRoles.request, updateUserRoles);
}

export default usersSaga;
