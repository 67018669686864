import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppLoader, PdfReader } from "../../../../shared/components";
import { getUserGuide, setFieldGuide } from "../../../../shared/store/actions";
import { getUserGuideDoc } from "../../../../shared/store/selectors";

import "./index.scss";

const UserGuideContainer: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFieldGuide(undefined));
  }, [dispatch]);

  const userGuide = useSelector(getUserGuideDoc());

  useEffect(() => {
    if (!userGuide) {
      dispatch(getUserGuide.request());
    }
  }, [userGuide, dispatch]);

  return (
    <div className="user-guide-container">
      {userGuide ? <PdfReader uid={userGuide.key} enableReferences={false} /> : <AppLoader />}
    </div>
  );
};

export default UserGuideContainer;
