import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";

import {
  InstanceFormValues,
  Image,
  Instance,
  InstanceUpdateFormValues,
  Profile,
  InstanceCollaborator,
  InstanceComment,
  InstanceCommentRequest,
  InstanceCommentUpdateRequest,
  InstanceConditionOptionsUpdateRequest,
  InstanceConditionOptionsDeleteRequest,
  UpdateImagePayload,
  VoiceNoteForImageRequest,
  InstanceResponse,
} from "../../../shared/models";
import { AddFieldGuideReferencePayload, RequestWithCallback, RequestWithQuery } from "../../../shared/interfaces";
import { getVideosFormData, mapInstanceImagesToChunkedRequest } from "../../../shared/mappers";

import api from "./api";
import { actions } from "./";
import { ATTACHMENT_TYPES_MAP } from "../../../shared/constants";

function* getInstances({ payload: { showEditable } }: { payload: { showEditable: boolean } }) {
  try {
    const instances: InstanceResponse[] = yield call(api.getInstances, showEditable);
    yield put(actions.getInstances.success(instances));
  } catch (error) {
    yield put(actions.getInstances.failure(error));
  }
}

function* getInstance({ payload: instanceId }: { payload: number }) {
  try {
    const instance: InstanceResponse = yield call(api.getInstance, instanceId);
    // filtering photo originals that have annotations
    instance.images = instance.images.filter((image: Image) => {
      if (image.parentId) {
        return true;
      }
      const foundAnnotated = instance.images.find((val: Image) => (val.parentId ? val.parentId === image.id : false));
      return !foundAnnotated;
    });
    yield put(actions.getInstance.success(instance));
  } catch (error) {
    yield put(actions.getInstance.failure(error));
  }
}

function* getInstancePDF({ payload: instanceId }: { payload: number }) {
  try {
    const instance = yield call(api.getInstancePDF, instanceId);

    yield put(actions.getInstancePDF.success(instance));
  } catch (error) {
    yield put(actions.getInstancePDF.failure(error));
  }
}

function* getInstancesByFieldNote({ payload }: { payload: RequestWithQuery<{ fieldNoteId: number }> }) {
  try {
    const instances: InstanceResponse[] = yield call(api.getInstancesByFieldNote, payload);

    yield put(actions.getInstancesByFieldNote.success(instances));
  } catch (error) {
    yield put(actions.getInstancesByFieldNote.failure(error));
  } finally {
  }
}

function* saveVoiceNoteForImage({
  payload: { imageId, voiceNote, instanceId },
}: {
  payload: VoiceNoteForImageRequest;
}) {
  try {
    if (voiceNote?.file) {
      const voiceNoteData = new FormData();
      voiceNoteData.append("instanceId", String(instanceId));
      voiceNoteData.append("voiceNote", voiceNote.file);
      const image: Image = yield api.saveVoiceNoteForImage(voiceNoteData, imageId);
      yield put(actions.saveVoiceNoteForImage.success(image));
    }
  } catch (error) {
    yield put(actions.saveVoiceNoteForImage.failure(error));
  }
}

function* updateImage({
  payload: { instanceId, fieldNoteId, image, voiceNote, isNewImage, isNewVoiceNote, callback },
}: {
  payload: RequestWithCallback<UpdateImagePayload>;
}) {
  try {
    let savedImages: Image[] = [];
    if (isNewImage) {
      savedImages = yield api.saveChunkedImages({
        deleteVoiceNote: !voiceNote, // to avoid copying voice note from parent if it was deleted by cross icon
        instanceId: instanceId,
        fieldNoteId: fieldNoteId,
        images: [
          {
            id: image.id,
            imageTypeId: Object.values(ATTACHMENT_TYPES_MAP)[image.imageType].id,
            positionIndex: image.positionIndex,
            ...(image.srcAnnotated ? { srcAnnotated: image.srcAnnotated } : { src: image.src }),
          },
        ],
      });
    }
    const imageId = savedImages?.[0]?.id || image.id;

    if (isNewVoiceNote && voiceNote?.file && imageId) {
      const voiceNoteData = new FormData();
      voiceNoteData.append("instanceId", String(instanceId));
      voiceNoteData.append("voiceNote", voiceNote.file);
      yield api.saveVoiceNoteForImage(voiceNoteData, imageId);
    }

    yield put(actions.getInstance.request(instanceId));
    yield put(actions.updateImage.success());
    callback?.(imageId);
  } catch (error) {
    yield put(actions.updateImage.failure(error));
  } finally {
  }
}

function* deleteImage({ payload: { imageId, instanceId } }: ReturnType<typeof actions.deleteImage.request>) {
  try {
    yield call(api.deleteFile, imageId); // returns Image
    yield put(actions.getInstance.request(instanceId));
  } catch (error) {
    yield put(actions.deleteImage.failure(error));
  } finally {
  }
}

function* deleteVideo({ payload: { videoId, instanceId } }: ReturnType<typeof actions.deleteVideo.request>) {
  try {
    yield call(api.deleteVideo, videoId);
    yield put(actions.getInstance.request(instanceId));
  } catch (error) {
    yield put(actions.deleteVideo.failure(error));
  } finally {
  }
}

function* createInstance({ payload }: { payload: RequestWithCallback<InstanceFormValues> }) {
  try {
    const { callback, attachments, ...instanceFields } = payload;

    const { imagesToUpload } = mapInstanceImagesToChunkedRequest(attachments.filter(({ type }) => type === "image"));

    const instance: InstanceResponse = yield call(api.createInstance, instanceFields);

    if (!!imagesToUpload.length) {
      yield call(api.saveChunkedImages, {
        instanceId: instance.id,
        fieldNoteId: instanceFields.fieldNoteId,
        images: imagesToUpload,
      });
    }
    const videosFormData = getVideosFormData({ attachments, ...instanceFields, id: instance.id });
    yield call(api.createVideos, videosFormData);

    yield put(actions.createInstance.success(instance));

    yield put(actions.getInstancesByFieldNote.request({ fieldNoteId: instanceFields.fieldNoteId }));
    if (instance.id) {
      callback?.(instance.id);
    } // redirect to view
  } catch (error) {
    yield put(actions.createInstance.failure(error));
  } finally {
  }
}

function* updateInstance({ payload }: { payload: RequestWithCallback<InstanceUpdateFormValues> }) {
  try {
    const { callback, oldInstance, attachments, ...instanceFields } = payload;

    const { imagesToDelete, imagesToUpload } = mapInstanceImagesToChunkedRequest(
      attachments.filter(({ type }) => type === "image"),
      oldInstance?.images,
    );

    if (!!imagesToUpload.length) {
      yield call(api.saveChunkedImages, {
        instanceId: instanceFields.id,
        fieldNoteId: instanceFields.fieldNoteId,
        images: imagesToUpload.filter((image) => Boolean(image.src)),
      });
    }

    if (!!imagesToDelete.length) {
      yield call(api.deleteChunkedImages, {
        instanceId: instanceFields.id,
        fieldNoteId: instanceFields.fieldNoteId,
        imagesIds: imagesToDelete,
      });
    }
    const videosFormData = getVideosFormData({ attachments, ...instanceFields }, oldInstance);
    yield call(api.createVideos, videosFormData);

    const instance: InstanceResponse = yield call(api.updateInstance, instanceFields);

    yield put(actions.updateInstance.success(instance));

    if (instance.id) {
      callback?.(instance.id);
    } // redirect to view
  } catch (error) {
    yield put(actions.updateInstance.failure(error));
  }
}

function* addInstanceConditionOptions({
  payload,
}: {
  payload: RequestWithCallback<InstanceConditionOptionsUpdateRequest>;
}) {
  try {
    const { callback, ...conditionOptions } = payload;

    const instance: Instance = yield call(api.addInstanceConditionOptions, conditionOptions);

    if (instance.id) {
      // redirect to view
      callback?.(instance.id);
    }
  } catch (error) {
    yield put(actions.addInstanceConditionOptions.failure(error));
  } finally {
  }
}

function* updateInstanceConditionOptions({
  payload,
}: {
  payload: RequestWithCallback<InstanceConditionOptionsUpdateRequest>;
}) {
  try {
    const { callback, ...conditionOptions } = payload;

    const instance: Instance = yield call(api.updateInstanceConditionOptions, conditionOptions);

    if (instance.id) {
      callback?.(instance.id);
    }
  } catch (error) {
    yield put(actions.updateInstanceConditionOptions.failure(error));
  } finally {
  }
}

function* removeInstanceConditionOptions({ payload }: { payload: InstanceConditionOptionsDeleteRequest }) {
  try {
    const instance: InstanceResponse = yield call(api.deleteInstanceConditionOptions, payload);
    yield put(actions.removeInstanceConditionOptions.success(instance));
  } catch (error) {
    yield put(actions.removeInstanceConditionOptions.failure(error));
  } finally {
  }
}

function* deleteInstance({
  payload: { instanceId, callback },
}: {
  payload: { instanceId: number; callback: () => void };
}) {
  try {
    const instance: InstanceResponse = yield call(api.deleteInstance, instanceId);

    yield put(actions.deleteInstance.success({ ...instance, message: "The Instance was successfully deleted" }));
    callback?.();
  } catch (error) {
    yield put(actions.deleteInstance.failure(error));
  } finally {
  }
}

function* getInstanceComments({ payload: { instanceId } }: { payload: { instanceId: number } }) {
  try {
    const comments: InstanceComment[] = yield call(api.getInstanceCommentsWithMedia, instanceId);

    yield put(actions.getInstanceComments.success(comments));
  } catch (error) {
    yield put(actions.getInstanceComments.failure(error));
  } finally {
  }
}

function* createComment({ payload: { instanceId, comment, images, audios } }: { payload: InstanceCommentRequest }) {
  try {
    const commentDetails = new FormData();
    commentDetails.append("instanceId", instanceId.toString());
    commentDetails.append("comment", comment);
    images.forEach((image) => image.file && commentDetails.append("image", image.file));
    audios.forEach((audio) => audio.file && commentDetails.append("voiceNote", audio.file));
    const createdComment: InstanceComment = yield call(api.createComment, commentDetails);

    yield put(actions.createComment.success(createdComment));
  } catch (error) {
    yield put(actions.createComment.failure(error));
  } finally {
  }
}

function* updateComment({
  payload: { comment, images, audios, id, instanceComment },
}: {
  payload: InstanceCommentUpdateRequest;
}) {
  try {
    const updateCommentDetails = new FormData();

    updateCommentDetails.append("instanceCommentId", id.toString());
    updateCommentDetails.append("comment", comment);

    images
      .map((image) => image.file)
      .filter(Boolean)
      .forEach((file) => file && updateCommentDetails.append("images[]", file));

    audios
      .map((audio) => audio.file)
      .filter(Boolean)
      .forEach((file) => file && updateCommentDetails.append("voiceNotes[]", file));

    const newImageIds = images?.map((image) => image.id).filter(Boolean) || [];
    const oldImageIds = instanceComment?.images?.map((image) => image.id) || [];
    const deleteImagesIds = oldImageIds.filter((id) => !newImageIds.includes(id));
    deleteImagesIds.forEach((id) => updateCommentDetails.append("deleteImageIds[]", String(id)));

    const newAudioIds = audios?.map((audio) => audio.id).filter(Boolean) || [];
    const oldAudioIds = instanceComment?.voiceNotes?.map((audio) => audio.id) || [];
    const deleteVoiceNoteIds = oldAudioIds.filter((id) => !newAudioIds.includes(id));
    deleteVoiceNoteIds.forEach((id) => updateCommentDetails.append("deleteVoiceNoteIds[]", String(id)));

    const updatedComment: InstanceComment = yield call(api.createComment, updateCommentDetails);
    yield put(actions.updateComment.success(updatedComment));
  } catch (error) {
    yield put(actions.updateComment.failure(error));
  } finally {
  }
}

function* deleteComment({ payload: { commentId } }: { payload: { commentId: number } }) {
  try {
    const deletedComment: { id: number } = yield call(api.deleteComment, commentId);

    yield put(actions.deleteComment.success(deletedComment.id));
  } catch (error) {
    yield put(actions.deleteComment.failure(error));
  } finally {
  }
}

function* getPlantProfiles({ payload }: AnyAction) {
  try {
    const { plantId, filter } = payload;

    const profiles: Profile[] = yield call(api.getPlantProfiles, plantId, filter);

    yield put(actions.getPlantProfiles.success(profiles));
  } catch (error) {
    yield put(actions.getPlantProfiles.failure(error));
  } finally {
  }
}

function* getInstanceCollaborators({ payload: instanceId }: { payload: number }) {
  try {
    const collaborators: InstanceCollaborator[] = yield call(api.getInstanceCollaborators, instanceId);
    yield put(actions.getInstanceCollaborators.success(collaborators));
  } catch (error) {
    yield put(actions.getInstanceCollaborators.failure(error));
  } finally {
  }
}

function* addInstanceCollaborators({
  payload: { profileIds, instanceId },
}: {
  payload: { profileIds: number[]; instanceId: number };
}) {
  try {
    yield Promise.all(profileIds.map((profileId) => api.addInstanceCollaborator({ profileId, instanceId })));

    yield put(actions.getInstanceCollaborators.request(instanceId));
  } catch (error) {
    yield put(actions.addInstanceCollaborators.failure(error));
  } finally {
  }
}

function* deleteInstanceCollaborator({
  payload: { profileId, instanceId },
}: {
  payload: { profileId: number; instanceId: number };
}) {
  try {
    yield put(actions.deleteInstanceCollaborator.success(profileId));

    yield call(api.deleteInstanceCollaborator, { profileId, instanceId });

    yield put(actions.getInstanceCollaborators.request(instanceId));
  } catch (error) {
    yield put(actions.deleteInstanceCollaborator.failure(error));
  } finally {
  }
}

function* addFieldGuideReferenceToInstance({
  payload,
}: {
  payload: RequestWithCallback<AddFieldGuideReferencePayload>;
}) {
  try {
    const { callback, ...referencePayload } = payload;
    const instance: InstanceResponse = yield call(api.addFieldGuideReferenceToInstance, referencePayload);

    yield put(actions.addFieldGuideReferenceToInstance.success({ ...instance, message: "The Reference was saved" }));

    callback?.();
  } catch (error) {
    yield put(actions.addFieldGuideReferenceToInstance.failure(error));
  } finally {
  }
}

function* deleteFieldGuideReferenceFromInstance({ payload }: AnyAction) {
  try {
    const instance: InstanceResponse = yield call(api.deleteFieldGuideReferenceToInstance, payload);

    yield put(actions.deleteFieldGuideReferenceFromInstance.success(instance));
  } catch (error) {
    yield put(actions.deleteFieldGuideReferenceFromInstance.failure(error));
  } finally {
  }
}

function* dashboardSaga() {
  yield takeLatest(actions.getInstances.request, getInstances);
  yield takeLatest(actions.getInstance.request, getInstance);
  yield takeLatest(actions.getInstancePDF.request, getInstancePDF);
  yield takeLatest(actions.getInstancesByFieldNote.request, getInstancesByFieldNote);
  yield takeLatest(actions.createInstance.request, createInstance);
  yield takeLatest(actions.deleteInstance.request, deleteInstance);
  yield takeLatest(actions.updateInstance.request, updateInstance);
  yield takeLatest(actions.addInstanceConditionOptions.request, addInstanceConditionOptions);
  yield takeLatest(actions.removeInstanceConditionOptions.request, removeInstanceConditionOptions);
  yield takeLatest(actions.updateInstanceConditionOptions.request, updateInstanceConditionOptions);

  yield takeLatest(actions.getInstanceComments.request, getInstanceComments);
  yield takeLatest(actions.createComment.request, createComment);
  yield takeLatest(actions.updateComment.request, updateComment);
  yield takeLatest(actions.deleteComment.request, deleteComment);

  yield takeLatest(actions.getPlantProfiles.request, getPlantProfiles);
  yield takeLatest(actions.getInstanceCollaborators.request, getInstanceCollaborators);
  yield takeLatest(actions.addInstanceCollaborators.request, addInstanceCollaborators);
  yield takeLatest(actions.deleteInstanceCollaborator.request, deleteInstanceCollaborator);

  yield takeLatest(actions.updateImage.request, updateImage);
  yield takeLatest(actions.deleteImage.request, deleteImage);

  yield takeLatest(actions.deleteVideo.request, deleteVideo);

  yield takeLatest(actions.saveVoiceNoteForImage.request, saveVoiceNoteForImage);

  yield takeLatest(actions.addFieldGuideReferenceToInstance.request, addFieldGuideReferenceToInstance);
  yield takeLatest(actions.deleteFieldGuideReferenceFromInstance.request, deleteFieldGuideReferenceFromInstance);
}

export default dashboardSaga;
