import { API, METHODS } from "../../../shared/constants";
import { ImageSortFilterOptions } from "../../../shared/interfaces";
import { request } from "../../../shared/utils";

const imageApi = {
  getImages: (params: ImageSortFilterOptions) => request(METHODS.GET, API.IMAGES.GET_IMAGES)({ params }),
  reviewImage: (params: { imageIds: number[]; reviewStatusId: number }) =>
    request(METHODS.PUT, API.ITEMS.REVIEW_IMAGE)(params),
  validateImagePositionIndex: (params: { imageId: number | string; instanceId: number; positionIndex: number }) =>
    request(METHODS.POST, API.ITEMS.VALIDATE_IMAGE_POSITION)(params),
};

export default { ...imageApi };
